import store from '../store'

const {
  state :{
    reservation
  }
} = store

const getCurrentStep = () => {
  return reservation.currentStep
}

const getIsConclusion = () => {
  return reservation.isConclusion
}

const getValuesReservation = () => {
  return reservation.values
}

const getIsDesignCut = () => {
  return reservation.isDesignCut
}
const getIsStaff = () => {
  return reservation.isStaff
}

const setCurrentStep = (currentStep) => {
  return store.dispatch('setCurrentStep', currentStep)
}

const setIsDesignCut = isDesignCut => {
  return store.dispatch('setIsDesignCut', isDesignCut)
}
const setIsStaff = isStaff => {
  return store.dispatch('setIsStaff', isStaff)
}
const setIsConclusion = isConclusion => {
  return store.dispatch('setIsConclusion', isConclusion)
}

const setValuesReservation = reservationValues => {
  return store.dispatch('setValues', reservationValues)
}

const resetReservation = () => {
  return store.dispatch('resetReservation')
}
const setOrangeMatches = orangeMatches => {
  return store.dispatch('setOrangeMatches',orangeMatches)
}
const getOrangeMatches = () => {
  return reservation.orangeMatches
}

const reservationService = {
  getCurrentStep,
  getIsDesignCut,
  getValuesReservation,
  getIsConclusion,
  setCurrentStep,
  setIsDesignCut,
  setValuesReservation,
  setIsConclusion,
  resetReservation,
  setIsStaff,
  getIsStaff,
  setOrangeMatches,
  getOrangeMatches
}

export default reservationService
