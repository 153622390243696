<template>
  <main-layout :title="title" :is-loading="isLoading">
    <login-component
      @setTitle="setTitle"
      @startLoading="startLoading"
      @endLoading="endLoading"
    >
    </login-component>
  </main-layout>
</template>

<script>
import MainLayout from '../components/layout/MainLayout'
import LoginComponent from '../components/login/LoginComponent'
import { version } from '../../package.json'
export default {
  name: 'Login',
  components: {
    MainLayout,
    LoginComponent
  },
   data() {
      return {
        isLoading: false,
        title: 'ログイン | Svenson',
        version: version
      }
    },
    mounted() {
      console.log('v'+ this.version)
      this.checkAppVersion();
    },
  methods: {
    setTitle(title) {
      this.title = title
    },
    startLoading() {
      this.isLoading = true
    },
    endLoading() {
      this.isLoading = false
    },
    checkAppVersion() {
      const currentVersion = this.version;
      const storedVersion = localStorage.getItem('appVersion');

      if (storedVersion !== currentVersion) {
        localStorage.setItem('appVersion', currentVersion);
        location.reload();
        console.log('New version detected');
      }
    }
  }
}
</script>

<style scoped></style>
