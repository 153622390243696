<template>
  <div :class="`input-area ${error ? 'is-error' : ''}`">
    <input
      :id="id"
      type="text"
      :name="name"
      :value="value"
      :class="className"
      :placeholder="placeholder"
      autocomplete="on"
      maxlength="100"
      @blur="validateEmail()"
      @keyup="onHandleChange($event)"
      @change="onHandleChange($event)"
    />
    <p :class="`error-txt ${error ? 'd-block' : ''}`">
      {{ error }}
    </p>
  </div>
</template>

<script>
import * as schema from '../../helpers/schemaValidate'

export default {
  name: 'InputLogin',
  props: {
    placeholder: {
      type: String,
      default: 'メールアドレス',
    },
    value: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: 'plchldr',
    },
    name: {
      type: String,
      default: 'email',
    },
    id: {
      type: String,
      default: 'email',
    },
    error: {
      type: String,
      default: '',
    },
  },
  watch: {
    value() {
      this.$emit('onValidate', this.name, '')
    },
  },
  methods: {
    validateEmail() {
      const values = {
        email: this.value,
      }
      schema.emailSchema
        .validateAt('email', values)
        .then(() => {
          this.$emit('onValidate', this.name, '')
        })
        .catch(error => {
          this.$emit('onValidate', this.name, error.message)
        })
    },
    onHandleChange(e) {
      const {
        target: { value },
      } = e
      this.$emit('onHandleChange', this.name, value)
      this.$emit('onValidate', this.name, '')
    },
  },
}
</script>
