import { render, staticRenderFns } from "./MypageProfileUnsub.vue?vue&type=template&id=06a90c21"
import script from "./MypageProfileUnsub.vue?vue&type=script&lang=js"
export * from "./MypageProfileUnsub.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports