import RegisterRepositoryFactory from '../service/Register/RegisterRepositoryFactory'
const registerService = RegisterRepositoryFactory.get('register')

const submitEmail = (email) => {
  return registerService.acceptEmail(email)
}

const submitPassword = (password) => {
  return registerService.acceptPassword(password)
}

const resetPasswordEmail = (data) => {
  const dataPost = {
    email: data.email,
    birthday: data.birthday
  }
  return registerService.resetPasswordEmail(dataPost)
}
export {
  submitEmail,
  submitPassword,
  resetPasswordEmail
}
