<template>
  <section class="l-content-area">
    <div class="l-content l-content--reservation">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="c-title__bloc c-title__bloc--reservation">
            <h1>ご希望スタジオ選択</h1>
            <div class="c-btn c-btn--title-back"><a href="#" @click="backToPage()">戻る</a></div>
          </section>

          <section class="p-bloc p-bloc__reservation">
            <div class="p-reservation__bloc">
              <div class="p-reservation__form">
                <dl class="c-form-parts">
                  <dt>ご来店希望スタジオ</dt>
                  <dd>
                    <input-select-group
                      :value="values.studio"
                      :text-error="textErrors.studio"
                      :list-data.sync="listStudio"
                      :list-all-data.sync="listAllStudio"
                      :error="errors.studio"
                      :default-value="'スタジオをお選びください'"
                      name="studio"
                      :value-selected="values.studio"
                      @onHandleChange="onHandleChange"
                      @onValidate="onValidate"
                    >
                    </input-select-group>
                  </dd>
                </dl>

                <dl v-if="studioInfo.postal_code || studioInfo.city" class="studio-info">
                  <dt>住所</dt>
                  <dd>
                    <span v-if="studioInfo.postal_code"> 〒{{ studioInfo.postal_code }} </span>
                    <br />
                    <span v-if="studioInfo.city">
                      {{ studioInfo.city }}
                    </span>
                    <span v-if="studioInfo.building">
                      {{ studioInfo.building }}
                    </span>
                    <br />
                    <span v-if="studioInfo.phone_number">
                      {{ studioInfo.phone_number }}
                    </span>
                    <br />
                  </dd>
                  <dt>営業時間／定休日</dt>
                  <dd v-if="studioInfo.opening_time">
                    <span v-if="studioInfo.opening_time">
                      平日：{{ studioInfo.opening_time.slice(0, 5) }}〜
                    </span>
                    <span v-if="studioInfo.closing_time">
                      {{ studioInfo.closing_time.slice(0, 5) }}
                    </span>
                    <br />
                    <span v-if="studioInfo.alt_opening_time">
                      土・日・祝：{{ studioInfo.alt_opening_time.slice(0, 5) }}〜
                    </span>
                    <span v-if="studioInfo.alt_closing_time">
                      {{ studioInfo.alt_closing_time.slice(0, 5) }}
                    </span>
                    <br />
                    <span> 定休日：{{ regularHolidayDisplay }} </span>
                    <br />
                  </dd>
                  <dt>アクセス</dt>
                  <dd>
                    {{ studioInfo.nearest_station }}
                  </dd>
                </dl>
              </div>

              <div v-if="location" class="p-reservation__map">
                <div id="map">
                  <iframe
                    title="map"
                    :src="location"
                    width="600"
                    height="450"
                    style="border:0;"
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                  >
                  </iframe>
                </div>
              </div>
            </div>
            <button-registration
              :prev-text="'戻る'"
              :next-text="'次へ'"
              :handle-prev-button="backToPage"
              :handle-next-button="nextToStep"
              :prev-text-color="'#787878'"
              :is-disable="isDisable"
            >
            </button-registration>
          </section>
          <!-- .p-bloc__reservation -->
        </div>
      </div>
    </div>
    <!-- .l-content--reservation -->
  </section>
  <!-- .l-content-area -->
</template>
<script>
import ButtonRegistration from '../../common/ButtonRegistration'
import InputSelectGroup from '../../common/InputSelectGroup'
import { STUDIO_INVALID } from '../../../constant/text'
import { getStoreByState, getAllStore, getStore } from '../../../actions/requestMypage'
import { listDayJPName } from '../../../constant/listDayJPName'
import * as yup from 'yup'

const rulesValidateStudio = yup.object().shape({
  studio: yup
    .string()
    .trim()
    .required(STUDIO_INVALID),
})
export default {
  name: 'MypageReservationStudio',
  components: {
    ButtonRegistration,
    InputSelectGroup,
  },
  data() {
    return {
      state: '',
      userId: '',
      listStudio: [],
      listAllStudio: [],
      listAllStudioInitial: [],
      studioInfo: {
        postal_code: '',
        city: '',
        building: '',
        opening_time: '',
        closing_time: '',
        alt_opening_time: '',
        alt_closing_time: '',
        nearest_station: '',
      },
      values: {
        studio: '',
      },
      errors: {
        studio: '',
      },
      textErrors: {
        studio: STUDIO_INVALID,
      },
      location: '',
      isDisable: false,
    }
  },
  computed: {
    isProductionEnvironment(){
      return process.env.VUE_APP_NODE_ENV === 'production';
    },
    regularHolidayDisplay() {
      if (this.studioInfo.regular_holidays) {
        let irregularHoliday = this.studioInfo.regular_holidays
            ? this.studioInfo.regular_holidays.split(',')
            : [],
          listName = [],
          strHoliday = ''
        irregularHoliday.map((value, index) => {
          if (listDayJPName[value]) {
            listName.push(listDayJPName[value])
          }
        })
        strHoliday = listName.join(', ')
        return strHoliday
      }
      return ''
    },
  },
  watch: {
    'values.studio'(newVal) {
      let studioSelected = this.listStudio.filter(studio => {
        return studio.id.toString() === newVal.toString()
      })

      if (studioSelected.length == 0) {
        studioSelected = this.listAllStudio.filter(studio => {
          return studio.id.toString() === newVal.toString()
        })
      }

      if (studioSelected.length > 0) {
        this.studioInfo = studioSelected[0]
        this.location = studioSelected[0]['map']
        sessionStorage.setItem('studioInfo', JSON.stringify(studioSelected[0]))
      } else {
        this.location = null
        this.studioInfo = {
          name: '',
          postal_code: '',
          city: '',
          building: '',
          opening_time: '',
          closing_time: '',
          alt_opening_time: '',
          alt_closing_time: '',
          nearest_station: '',
        }
      }
    },
  },

  mounted() {
    const linked = sessionStorage.getItem('linked')
    if (!linked || linked !== '1') {
      this.$toast.error('アカウント連携を行ってください。')
      return this.$router.push({
        name: 'account',
      })
    }
  },

  async created() {
    if (sessionStorage.getItem('userInfo')) {
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      this.state = userInfo.prefecture_code
      if (userInfo.store_id) {
        this.values.studio = userInfo.store_id.toString()
        getStore(userInfo.store_id)
          .then(res => {
            const {
              data: { success },
            } = res
            if (success) {
              const {
                data: { data },
              } = res
              this.studioInfo = data
              this.location = this.studioInfo.map
            }
          })
          .catch(err => {
            return err
          })
      }
    }

    this.$emit('startLoading')
    await getAllStore()
      .then(res => {
        const {
          data: { success },
        } = res
        if (success) {
          this.listAllStudio = res.data.data
          this.listAllStudioInitial = res.data.data
        }
        this.$emit('endLoading')
      })
      .catch(err => {
        this.$emit('endLoading')
        return err
      })
    getStoreByState(this.state)
      .then(res => {
        const {
          data: { success },
        } = res
        if (success && success === 1) {
          let state_id = this.state
          this.listStudio = res.data.data

          const listStudioNotSelected = this.listAllStudioInitial.filter(studio => {
            let prefecture_code = studio.prefecture_code
            if (parseInt(prefecture_code.slice(3, 5)) != parseInt(state_id.slice(3, 5))) {
              return studio
            }
          })
          if (listStudioNotSelected.length > 0) {
            this.listAllStudio = listStudioNotSelected
          } else {
            this.listAllStudio = this.listAllStudioInitial
          }
        }
        this.$emit('endLoading')
      })
      .catch(err => {
        this.$emit('endLoading')
        return err
      })
  },
  methods: {
    backToPage() {
      this.$router.push({
        name: 'mypage-reservation',
      })
    },
    nextToStep() {
      rulesValidateStudio
        .validate(this.values)
        .then(() => {
          this.$router.push({
            name: 'mypage-reservation',
            params: { studioInfo: this.studioInfo },
          })
        })
        .catch(err => {
          this.errors[err.path] = err.message
        })
    },
    onHandleChange(field, value) {
      this.values[field] = value
    },
    onValidate(field, value) {
      this.errors[field] = value
    },
  },
}
</script>
