<template>
  <section class="l-content-area">
    <div class="l-content l-content--reservation">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="c-title__bloc c-title__bloc--reservation">
            <h1>{{ textHeader }}</h1>
          </section>

          <section class="p-bloc p-bloc__reservation">
            <div v-if="status === 'pending'" class="p-reservation__finish">
              <h2>ご予約の登録が<span>完了いたしました</span></h2>
              <p>
                予約番号は、お問い合わせの際に必要となりますのでお控えください。<br />
                また、確認のメールをお送りしましたので、ご予約内容をご確認ください。
              </p>
              <div class="reservation-number">
                <h4>ご予約番号</h4>
                <p class="num">{{ reservationCode }}</p>
              </div>
              <p>マイページより予約番号・予約情報の確認が<span>可能でございます。</span></p>

              <button-registration
                :next-text="'マイページ'"
                :is-prev-button="false"
                :handle-next-button="nextToStep"
                :prev-text-color="'#787878'"
              >
              </button-registration>
            </div>
            <div v-else class="p-reservation__finish">
              <h2>キャンセル待ちの登録が完了いたしました。</h2>
              <p>
                キャンセル待ち予約番号は、お問い合わせの際に必要となりますのでお控えください。
                また、確認のメールをお送りしましたので、ご予約内容をご確認ください。
              </p>
              <div class="reservation-number">
                <h4>キャンセル待ち予約番号</h4>
                <p class="num">{{ reservationCode }}</p>
              </div>
              <p>
                マイページやメールにて、キャンセル待ち予約番号・キャンセル待ち予約情報の確認が可能となっております。
              </p>

              <button-registration
                :next-text="'マイページ'"
                :is-prev-button="false"
                :handle-next-button="nextToStep"
                :prev-text-color="'#787878'"
              >
              </button-registration>
            </div>

            <div class="p-reservation__attention">
              <div class="reservation-attention">
                <h3>ご注意事項</h3>
                <h4>【キャンセルに関する注意事項】</h4>
                <ul>
                  <li>
                    キャンセル受付締切：ご予約日の前日までマイページ、もしくはお電話よりキャンセルが可能です。
                  </li>
                  <li>キャンセルポリシー：ご予約いただきました店舗に直接お問い合わせください。</li>
                </ul>
              </div>
            </div>
          </section>
          <!-- .p-bloc__reservation -->
        </div>
      </div>
    </div>
    <!-- .l-content--reservation -->
  </section>
</template>
<script>
import ButtonRegistration from '../../common/ButtonRegistration'
import { scrollToTop } from '../../../helpers/index'
import { getDetailReservation } from '../../../actions/requestMypage'
export default {
  name: 'MypageReservationFinish',
  components: { ButtonRegistration },
  data() {
    return {
      status: '',
      reservationCode: '',
    }
  },
  computed: {
    textHeader() {
      return this.status === 'pending' ? '来店予約' : 'キャンセル待ち予約'
    },
  },
  created() {
    scrollToTop()
    this.status = this.$reservation.getValuesReservation().status
    getDetailReservation(this.$reservation.getValuesReservation().reservationId)
      .then(res => {
        const {
          data: { success },
        } = res
        if (success && success === 1) {
          this.reservationCode = res.data.data.code
        }
        this.$emit('endLoading')
      })
      .catch(err => {
        this.$emit('endLoading')
        return err
      })
    if (!this.$reservation.getCurrentStep()) {
      this.$router.push({
        name: 'mypage',
      })
    }
  },
  beforeDestroy() {
    this.$reservation.resetReservation()
  },
  methods: {
    nextToStep() {
      sessionStorage.removeItem('studioInfo')
      this.$router.push({
        name: 'mypage',
      })
    },
  },
}
</script>
<style scoped>
.v-application ul,
.v-application ol {
  padding-left: 0 !important;
}

.v-application a {
  color: #0a005a;
}
</style>
