<template>
  <section class="l-content-area">
    <div class="l-content l-content--registration">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="c-title__bloc c-title__bloc--registration">
            <h1>アカウント登録リクエストを受付ました。</h1>
          </section>

          <section class="p-bloc p-bloc__registration">
            <div class="p-registration_finish__bloc">
              アカウント確認メールを送信いたしましたので、そちらのメール本文記載のリンクにアクセスをお願い致します。アクセスいただくとご登録手続きは完了となります。<br />
              ※メール本文記載のリンクの有効期間はメール受信から24時間以内となっておりますのでご注意ください。ご確認ほどよろしくお願いいたします。
            </div>
            <button-registration
              :next-text="'ログイン'"
              :is-prev-button="false"
              :handle-next-button="redirectLoginPage"
            >
            </button-registration>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ButtonRegistration from '../../common/ButtonRegistration'

export default {
  name: 'ThirdStepRegistration',
  components: {
    ButtonRegistration,
  },
  data() {
    return {
      error: '',
    }
  },
  methods: {
    redirectLoginPage() {
      this.$router.push({
        name: 'login',
      })
    },
  },
}
</script>

<style scoped>
.p-registration_finish__bloc {
  text-align: left;
  line-height: 28px;
  padding: 0 8%;
  margin-bottom: 15%;
}
</style>
