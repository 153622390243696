import ReservationRepository from './ReservationRepository'

const repository = {
  reservation: ReservationRepository,
}

const ReservationRepositoryFactory = {
  get: (name) => repository[name],
}

export default ReservationRepositoryFactory
