import axios from 'axios'
import { anonymous } from '../constant/endpoint'
import { version } from '../../package.json'

const baseAPIURL = `${process.env.VUE_APP_BASE_URL_API}/${anonymous}/api`

const BaseAnonymousRepository = axios.create({
	baseURL: baseAPIURL,
	withCredentials: true,
	headers: {
		Authorization: `Bearer ${process.env.VUE_APP_ANONYMOUS_BEARER_TOKEN}`,
		common: {
			'Cache-Control': 'no-cache',
			Pragma: 'no-cache',
			Expires: '0',
			'X-Requested-With': 'XMLHttpRequest',
		},
		get: {
			Accept: 'application/json',
		},
		version,
	},
})

function isOngoingMaintenance(status) {
	if (status === 502 || status === 503) {
	  window.location.href = '/maintenance.html';
	}
  }
  
BaseAnonymousRepository.interceptors.response.use(
	response => {
		isOngoingMaintenance(response.status);
		return response;
	},
	error => {
		isOngoingMaintenance(error.response?.status);
		return Promise.reject(error);
	}
);

export default BaseAnonymousRepository
