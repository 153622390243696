<template>
  <section class="l-content-area">
    <div class="l-content l-content--mypage">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="c-title__bloc c-title__bloc--mypage">
            <h1>退会申請</h1>
          </section>
          <section class="p-bloc p-bloc__mypage bloc_unsubscribe">
            <div class="p-mypage__complete">
              <p>
                サービスから退会をされた場合、保存されているすべての情報は削除されますので、ご利用アカウントに関連付けられているすべてのデータにアクセスできなくな<br />
                ります点、ご注意ください。
              </p>
              <button-registration
                :prev-text-color="'#787878'"
                :prev-text="'戻る'"
                :next-text="'退会を申請する'"
                :handle-next-button="nextGoStep"
                :handle-prev-button="prevToStep"
              >
              </button-registration>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import ButtonRegistration from '../../common/ButtonRegistration'
import { unSubscribeUser } from '../../../actions/requestMypage'
export default {
  name: 'MypageProfileUnsubscribe',
  components: {
    ButtonRegistration,
  },
  created() {
    this.$authInfo.setToken(sessionStorage.getItem('token'))
  },
  methods: {
    prevToStep() {
      this.$router.push({ name: 'mypage-profile' })
    },
    nextGoStep() {
      this.$emit('startLoading')
      unSubscribeUser()
        .then(res => {
          const {
            data: { success },
          } = res
          if (success && success === 1) {
            this.$router.push({ name: 'mypage-profile-unsubscribe-finish' })
          }
          this.$emit('endLoading')
        })
        .catch(err => {
          this.$emit('endLoading')
          return err
        })
    },
  },
}
</script>
<style scoped>
.v-application ul,
.v-application ol {
  padding-left: 0 !important;
}

.v-application a {
  color: #fff;
}
</style>
