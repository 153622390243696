<template>
  <section class="l-content-area">
    <div class="l-content l-content--mypage">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="c-title__bloc c-title__bloc--mypage">
            <h1>登録情報の変更が<span>完了いたしました</span></h1>
          </section>
          <section class="p-bloc p-bloc__mypage">
            <div class="p-mypage__complete">
              <p class="text-center">マイページより、変更後の登録情報をご確認いただけます。</p>
              <div class="complete-btn">
                <div class="c-btn c-btn--tomypage"><a href="/mypage">マイページトップへ</a></div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'MypageEditProfileFinish',
  
}
</script>
<style scoped>
  .v-application ul, .v-application ol {
    padding-left: 0 !important;
  }

  .v-application a {
    color: #fff;
  }
</style>

