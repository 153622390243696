import LoginRepositoryFactory from '../service/Login/LoginRepositoryFactory'
const loginService = LoginRepositoryFactory.get('login')

// empty get call to check for maintenance on initial load
const checkMaintenanceMode = () => {
  return loginService.checkMaintenanceMode()
}

const login = (data) => {
  const dataLogin = {
    email: data.email,
    password: data.password
  }
  return loginService.login(dataLogin)
}

const agreeService = (payload) => {
  return loginService.agreeService(payload)
}

const logout = () => {
  return loginService.logout()
}

const linkStep1 = (userData) => {
  const dataPost = {
    token: sessionStorage.getItem('token'),
    first_name: userData.firstName,
    last_name: userData.lastName ,
    phone_number: userData.phone ,
    customer_no: userData.customerNo
  }
  return loginService.linkStep1(dataPost)
}

const linkStep2 = (userData) => {
  const dataPost = {
    token: userData
  }
  return loginService.linkStep2(dataPost)
}
export {
  checkMaintenanceMode,
  login,
  agreeService,
  logout,
  linkStep1,
  linkStep2
}
