<template>
  <div>
    <ul :class="`select-area ${error ? 'is-error' : ''}`">
      <li>
        <select id="" :name="name" :value="value" @change="onHandleChange($event)">
          <option v-if="defaultValue" selected value="">
            {{ defaultValue }}
          </option>
          <optgroup v-if="listData.length > 0" label="最寄スタジオ">
            <option v-for="(data, index) in listData"
                    :key="index" :value="data.id"
                    :selected="data.id.toString() === valueSelected.toString()"
            >
              {{ data.name }}
            </option>
          </optgroup>
          <optgroup label="全スタジオ">
            <option v-for="(data, index) in listAllData"
                    :key="index" :value="data.id"
                    :selected="data.id.toString() === valueSelected.toString()"
            >
              {{ data.name }}
            </option>
          </optgroup>
        </select>
      </li>
    </ul>
    <p :class="`error-txt ${error ? 'is-error' : ''}`">
      {{ error }}
    </p>
  </div>
</template>

<script>
  import { validateRequired } from '../../helpers/schemaValidate'

  export default {
    name: 'InputSelectGroup',
    props: {
      listData: {
        type: Array,
        default: () => {
          return []
        },
      },
      listAllData: {
        type: Array,
        default: () => {
          return []
        },
      },
      defaultValue: {
        type: String,
        default: '',
      },
      value: {
        type: String,
        default: '',
      },
      valueSelected: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: '',
      },
      error: {
        type: String,
        default: '',
      },
      textError: {
        type: String,
        default: '',
      },
    },
    watch: {
      value() {
        this.$emit('onValidate', this.name, '')
      },
    },
    methods: {
      onHandleChange(e) {
        const { target: { value } } = e
        this.$emit('onHandleChange', this.name, value)
        this.onValidate(value)
      },
      onValidate(value) {
        const values = {
          [this.name]: value,
        }
        validateRequired(this.name, this.textError)
          .validateAt(this.name, values)
          .then(() => {
            this.$emit('onValidate', this.name, '')
          })
          .catch((error) => {
            this.$emit('onValidate', this.name, error.message)
          })
      },
    },
  }
</script>

<style scoped>
  .v-application ul, .v-application ol {
    padding-left: 0 !important;
  }
</style>
