<template>
  <div :class="`input-datepicker ${error ? 'is-error' : ''}`">
    <span>
      <input
        :id="id"
        :value="value"
        type="text"
        :name="name"
        :class="className"
        :placeholder="placeholder"
        :textError="textError"
        :isRequired="isRequired"
        :pattern="pattern"
        v-bind="attr"
        autocomplete="on"
        @change="onHandleChange($event)"
        @click="onHandleClick()"
        @blur="validateData()"
        @keyup="onHandleChange($event)"
      />
    </span>
    <p v-if="isShowError" :class="`error-txt ${error ? 'd-block' : ''}`">
      {{ error }}
    </p>
  </div>
</template>

<script>
  import * as schema from '../../helpers/schemaValidate'
  import randomString from '../../helpers/randomString'

  export default {
    name: 'InputDateTime',
    props: {
      value: {
        type: String,
        default: ''
      },
      name: {
        type: String,
        default: 'text',
      },
      className: {
        type: String,
        default: 'plchldr',
      },
      placeholder: {
        type: String,
        default: '',
      },
      textError: {
        type: String,
        default: '',
      },
      id: {
        type: String,
        default: randomString(4),
      },
      error: {
        type: String,
        default: '',
      },
      isShowError: {
        type: Boolean,
        default: true
      },
      isRequired: {
        type: Boolean,
        default: false
      },
      pattern: {
        type: RegExp,
        default: null
      },
      attr: {
        type: Object,
        default: () => {
          return {}
        }
      }
    },
    methods: {
      onHandleChange(e) {
        const { target: { value } } = e
        this.$emit('onHandleChange', this.name, value)
      },
      onHandleClick() {
        this.$emit('onHandleClick', this.name)
      },
      validateData() {
        const values = {
          [this.name]: this.value
        }

        if (this.isRequired) {
          schema.validateRequired(this.name, this.textError)
            .validateAt(this.name, values)
            .then(() => {
              this.$emit('onValidate', this.name, '')
            })
            .catch(error => {
              this.$emit('onValidate', this.name, error.message)
            })
        }

        if (this.pattern) {
          schema.validatePattern(this.name, this.pattern, this.textError)
            .validateAt(this.name, values)
            .then(() => {
              this.$emit('onValidate', this.name, '')
            })
            .catch(error => {
              this.$emit('onValidate', this.name, error.message)
            })
        }
      }
    }
  }
</script>

<style scoped>

</style>
