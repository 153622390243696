<template>
  <main-layout :title="title" :is-loading="isLoading">
    <registration-component
      @setTitle="setTitle"
      @startLoading="startLoading"
      @endLoading="endLoading"
    >
    </registration-component>
  </main-layout>
</template>

<script>
  import MainLayout from '../components/layout/MainLayout'
  import RegistrationComponent from '../components/registration/index'

  export default {
    name: 'Registration',
    components: {
      MainLayout,
      RegistrationComponent
    },
    data() {
      return {
         isLoading: false,
         title: 'アカウント登録 | Svenson'
      }
    },
    methods: {
      setTitle(title) {
        this.title = title
      },
      startLoading() {
        this.isLoading = true
      },
      endLoading() {
        this.isLoading = false
      },
    }
  }
</script>

<style scoped>

</style>
