<template>
  <section class="l-content-area">
    <div class="l-content l-content--reservation">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="c-title__bloc c-title__bloc--reservation">
            <h1>予約内容のご確認</h1>
            <div class="c-btn c-btn--title-back"><a href="#" @click="prevToStep()">戻る</a></div>
          </section>

          <section class="p-bloc p-bloc__reservation">
            <div class="p-mypage-reservation__confirm">
              <p>下記のご予約内容（施術、オプション）をご確認の上お進みください。</p>

              <div class="p-reservation__confirm">
                <div class="mypage-reservation-confirm__bloc">
                  <h2>ご予約内容</h2>
                  <dl>
                    <dt>施術</dt>
                    <dd>{{ getListTreatment }}</dd>
                    <dt>オプション</dt>
                    <dd>{{ getListSubTreatment }}</dd>
                    <dt>施術時間目安</dt>
                    <dd>{{ getEstimatedTime }}</dd>
                    <dt>ご来店店舗</dt>
                    <dd>{{ studioName }}</dd>
                  </dl>
                </div>
              </div>
            </div>

            <button-registration
              v-if="canAddMoreService && isFemale === 'm'"
              :is-sub-button="true"
              :prev-text-color="'#787878'"
              :prev-text="'最初から選び直す'"
              :next-text="'担当者・日時選択へ'"
              :sub-text="'他施術メニューも選択'"
              :handle-next-button="nextToStep"
              :handle-sub-step="nextToSubStep"
              :handle-prev-button="prevToStep"
            >
            </button-registration>
            <button-registration
              v-else
              :prev-text-color="'#787878'"
              :prev-text="'最初から選び直す'"
              :next-text="'担当者・日時選択へ'"
              :handle-next-button="nextToStep"
              :handle-prev-button="prevToStep"
            >
            </button-registration>
          </section>
          <!-- .p-bloc__reservation -->
        </div>
      </div>
    </div>
    <!-- .l-content--reservation -->
  </section>
  <!-- .l-content-area -->
</template>
<script>
import ButtonRegistration from '../../common/ButtonRegistration.vue'
import { getStore } from '../../../actions/requestMypage'
export default {
  name: 'MypageReservationConfirm',
  components: {
    ButtonRegistration,
  },
  props: {
    estimatedDuration: {
      type: String,
      default: '0',
    },
    currentStep: {
      type: String,
      default: '4',
    },
    option: {
      type: Array,
      default: () => {
        return []
      },
    },
    treatment: {
      type: Array,
      default: () => {
        return []
      },
    },
    services: {
      type: Array,
      default: () => {
        return []
      },
    },
    listTimeMale: {
      type: Array,
      default: () => {
        return []
      },
    },
    listTimeFemale: {
      type: Array,
      default: () => {
        return []
      },
    },
    hasOption: {
      type: Boolean,
      default: true,
    },
    listAllServices: {
      type: Array,
      default: () => {
        return []
      },
    },
    compatibility: {
      type: Array,
      default: () => {
        return []
      },
    },
    isFemale: {
      type: String,
      default: '',
    },
    store: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      studioName: '',
    }
  },
  computed: {
    getListTreatment() {
      return this.treatment.length !== 0 ? '• ' + this.treatment.join('\n • ') : '選択なし'
    },
    getListSubTreatment() {
      return this.option.length !== 0 ? '• ' + this.option.join('\n • ') : '選択なし'
    },
    getEstimatedTime() {
      return this.estimatedDuration === '' ? '選択なし' : this.estimatedDuration / 60 + '時間'
    },
    canAddMoreService() {
      let optional = []
      this.listAllServices.map(service => {
        if (service.is_sub_treatment === 1) {
          optional.push(service.id)
        }
      })

      //remove optional from compatibility
      let tmp = this.compatibility.filter(service => {
        return !optional.includes(service)
      })
      //remove selected services from compatibility
      tmp = tmp.filter(service => {
        return !this.services.includes(service)
      })
      //when there is no related services,hide button
      if (tmp.length === 0) {
        return false
      }
      return true
    },
  },
  created() {
    if (this.store) this.getStoreById(this.store)
  },
  methods: {
    prevToStep() {
      /*this.$emit('prevToStep', 'reselect')
      const listServices = [...this.services]
      const listOption = [...this.option]
      const listTimeMalePop = [...this.listTimeMale]
      const listTimeFemalePop = [...this.listTimeFemale]
      const listNameTreatmentPush = [...this.treatment]
      listTimeMalePop.pop()
      listTimeFemalePop.pop()
      this.$emit('onHandleChange', 'listTimeMale', listTimeMalePop)
      this.$emit('onHandleChange', 'listTimeFemale', listTimeFemalePop)
      listServices.length = 0
      //if (this.hasOption) {
        //listServices.length = 0
     // }
      this.$emit('onHandleChange', 'services', listServices)
      //if (this.hasOption) {
        listOption.length = 0
     // }
      this.$emit('onHandleChange', 'option', listOption)
      listNameTreatmentPush.length = 0
      this.$emit('onHandleChange', 'treatment', listNameTreatmentPush)
      this.$emit('onHandleChange', 'isCategoryWig', [])
      this.$emit('onHandleChange', 'compatibility', [])
      this.$emit('onHandleChange', 'reselect', true)*/
      window.location.reload()
    },
    nextToStep() {
      this.$emit('nextToStep')
    },
    nextToSubStep() {
      this.$emit('onChangeStep', 'currentStep', '1')
    },
    getStoreById(storeId) {
      getStore(storeId)
        .then(res => {
          this.$emit('endLoading')
          const {
            data: { success },
          } = res
          if (success && success === 1) {
            const {
              data: { data },
            } = res
            this.studioName = data.name
          }
        })
        .catch(err => {
          this.$emit('endLoading')
          console.log('Has error when call get store mypage: ' + err)
          return err
        })
    },
  },
}
</script>
<style scoped>
.v-application ul,
.v-application ol {
  padding-left: 0 !important;
}

.v-application a {
  color: #0a005a;
}
.v-application p {
  color: #0a005a;
}
.p-mypage-reservation__confirm .mypage-reservation-confirm__bloc dd {
  white-space: pre-wrap;
  line-height: 28px;
}
</style>
