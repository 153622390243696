<template>
  <v-card height="100%">
    <v-tabs
      v-show="false"
      v-model="currentStep"
      centered
      grow
      :hide-slider="true"
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#1"
             :align-with-title="true"
             :disabled="disabled.firstTab"
      >
        ステップ1
      </v-tab>
      <v-tab href="#2"
             :align-with-title="true"
             :disabled="disabled.secondTab"
      >
        ステップ2
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentStep" :touch="onTouchEvent()">
      <template v-if="!isReminderPassword">
        <v-tab-item v-if="currentStep === '1'" value="1">
          <reminder-email
            :email="values.email"
            :is-force-validate="isForceValidate"
            @nextToStep="nextToStep"
            @onHandleChange="onChangeValue"
            @onValidateSwipe="onValidateSwipe"
            @startLoading="startLoading"
            @endLoading="endLoading"
          ></reminder-email>
        </v-tab-item>
        <v-tab-item v-if="currentStep === '2'" value="2">
          <reminder-complete
            @nextToStep="nextToStep"
            @prevToStep="prevToStep"
          ></reminder-complete>
        </v-tab-item>
      </template>
      <template v-else>
        <v-tab-item v-if="currentStep === '1'" value="1">
          <reminder-password
            :password="values.password"
            :is-force-validate="isForceValidate"
            @nextToStep="nextToStep"
            @onHandleChange="onChangeValue"
            @onValidateSwipe="onValidateSwipe"
          ></reminder-password>
        </v-tab-item>
        <v-tab-item v-if="currentStep === '2'" value="2">
          <reminder-password-complete
            @nextToStep="nextToStep"
            @prevToStep="prevToStep"
          ></reminder-password-complete>
        </v-tab-item>
      </template>
    </v-tabs-items>
  </v-card>
</template>

<script>
  import ReminderEmail from './ReminderEmail'
  import ReminderComplete from './ReminderComplete'
  import ReminderPassword from './ReminderPassword'
  import ReminderPasswordComplete from './ReminderPasswordComplete'
  export default {
    name: 'ReminderComponent',
    components: { ReminderPasswordComplete, ReminderPassword, ReminderComplete, ReminderEmail },
    props: {
      isReminderPassword: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        currentStep: '1',
        disabled: {
          firstTab: false,
          secondTab: true
        },
        values: {
          email: '',
          password: '',
        },
        isForceValidate: false
      }
    },
    watch: {
      currentStep(newVal) {
        this.isForceValidate = false
        if (newVal === '2') {
          this.disabled.secondTab = false
        }
      }
    },
    methods: {
      assignSwipeLeft() {
        this.isForceValidate = true
      },
      assignSwipeRight() {
        if (this.currentStep !== '1') {
          this.prevToStep()
        }
      },
      onChangeValue(value) {
        if (this.isReminderPassword) {
          this.values.password = value
        } else {
          this.values.email = value
        }
      },
      onValidateSwipe(value) {
        this.isForceValidate = value
      },
      onTouchEvent() {
        return {
          left: () => this.assignSwipeLeft(),
          right: () => this.assignSwipeRight(),
        }
      },
      nextToStep() {
        const nextStep = parseInt(this.currentStep) + 1
        this.currentStep = nextStep.toString()
      },
      prevToStep() {
        const prevStep = parseInt(this.currentStep) - 1
        this.currentStep = prevStep.toString()
      },
      startLoading() {
        this.$emit('startLoading')
      },
      endLoading() {
        this.$emit('endLoading')
      }  
    }
  }
</script>

<style scoped>

</style>
