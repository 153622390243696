import BaseAnonymousRepository from '../BaseAnonymousRepository'
import BaseUserRepository from '../BaseUserRepository'
import {
  registerUser,
  reminderReservation,
  registerReservation,
  surveyReservation,
  stores,
  prefectures,
  services,
} from '../../constant/endpoint' 

export default {
  createUser(userCreated) {
    return BaseAnonymousRepository.post(registerUser, userCreated)
  },
  updateUser(userId, userUpdated) {
    return BaseUserRepository.patch(`${registerUser}/${userId}`, userUpdated)
  },
  registerReservation(payload) {
    return BaseUserRepository.post(registerReservation, payload)
  },
  updateReservation(reservationId, payload) {
    return BaseUserRepository.patch(`${registerReservation}/${reservationId}`, payload)
  },
  surveyReservation(payload) {
    return BaseUserRepository.post(surveyReservation, payload)
  },
  updateSurvey(surveyId, payload) {
    return BaseUserRepository.patch(`${surveyReservation}/${surveyId}`, payload)
  },
  reminderReservation(payload) {
    return BaseUserRepository.post(reminderReservation, payload)
  },
  updateReminder(reminderId, payload) {
    return BaseUserRepository.patch(`${reminderReservation}/${reminderId}`, payload)
  },
  getReservationById(reservationId) {
    return BaseUserRepository.get(`${registerReservation}/${reservationId}`)
  },
  getReminderById(reminderId) {
    return BaseUserRepository.get(`${reminderReservation}/${reminderId}`)
  },
  getSurveyId(surveyId) {
    return BaseUserRepository.get(`${surveyReservation}/${surveyId}`)
  },
  getStudios(value) {
    if (value) {
      return BaseAnonymousRepository.get(
        `${stores}?prefecture_code=${value}&order_by=prefecture_code&order_direction=asc`
      )
    }
    return BaseAnonymousRepository.get(`${stores}?order_by=prefecture_code&order_direction=asc`)
  },
  async getStudioById(id) {
    return await BaseAnonymousRepository.get(`${stores}/${id}`)
  },
  getState() {
    return BaseAnonymousRepository.get(prefectures)
  },
  getSlotsStore(reservationId, date, stylists) {
    let params = {
      date : date
    }
    params['stylist-ids'] = stylists
    return BaseAnonymousRepository.get(`${registerReservation}/${reservationId}/slots`, {
      params: params,
    })
  },
  getPreSlotsStore(reservationId, date, stylists) {
    return BaseAnonymousRepository.get(`${registerReservation}/${reservationId}/pre-slots?date=${date}`)
  },
  getTreatmentByCategory(category, storeId) {
    return BaseAnonymousRepository.get(`${services}?category=${category}&store_id=${storeId}&is_sub_treatment=0`)
  },
  async getHolidaysByStoreId(storeId) {
    return await BaseAnonymousRepository.get(`${stores}/${storeId}/holidays`)
  },
}
