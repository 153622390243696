const listTreatment = [
    {
        id: '84',
        service: 'ウィッグ',
        isFree: true,
        symbol: '無料',
        name: '増毛お試し体験サービス',
        category: 'wig',
        description: '「スヴェンソン式増毛法 ※特許番号第3686998（編み込み式増毛法・Hair Weaving System）」による増毛ウィッグラインナップを体験いただけます。'
    },

    {
        id: '80',
        service: 'エクステ',
        isFree: false,
        symbol: '',
        name: 'マイヘアプラスお試し3セット',
        category: 'ext',
        description: 'エクステ増毛。手軽に部分的に増やしたい方にお薦めです。ご自身の髪１本１本に結びつけるタイプです（最大1800本）。※初回のご利用に限り、特別価格にてご案内となります。'
    },

    {
        id: '3',
        service: 'ヘアカット',
        isFree: false,
        symbol: '',
        name: 'デザインカット',
        category: 'cut',
        description: ' 増毛ではなく、ご自身の髪だけでスタイリッシュに薄毛をカバーいたします。※初回のご利用に限り、特別価格にてご案内となります '
    },

    {
        id: '4',
        service: 'ご相談 ',
        isFree: true,
        symbol: '無料',
        name: 'コンシェルジュサービス',
        category: 'con',
        description: ' 専任アドバイザーが毛髪や頭皮の状態を確認してアドバイスします。また髪に関するさまざまなご相談も承ります。'
    },

]

export {
    listTreatment
}
