<template>
  <section class="l-content-area">
    <div class="l-content l-content--reservation">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="c-title__bloc c-title__bloc--reservation">
            <h1>ご来店予約</h1>
            <div class="c-btn c-btn--title-back"><a href="#" @click="prevToStep()">戻る</a></div>
          </section>

          <section class="p-bloc p-bloc__reservation">
            <h2 class="p-reservation__step">STEP 2</h2>

            <div class="p-reservation__bloc">
              <p>施術メニューを選択してください。</p>

              <div class="p-reservation__service p-reservation__custom">
                <template v-if="category === 'wig'">
                  <div
                    v-if="exclusiveGroup.length > 0"
                    class="p-reservation__service"
                    :class="
                      services.includes(parseInt(checkMainServiceId)) ||
                      (compatibility.length > 0 &&
                        !compatibility.includes(parseInt(checkMainServiceId)))
                        ? 'disableMenu'
                        : ''
                    "
                  >
                    <div class="c-service-menu__bloc">
                      <a
                        href="#"
                        @click="addService(checkMainServiceId, '編み直し')"
                        @change="changeMenu()"
                      >
                        <h2>編み直し</h2>
                      </a>
                    </div>
                  </div>
                  <div
                    v-for="(treatmentItem, index) in nonExclusiveGroup"
                    :key="index"
                    class="p-reservation__service"
                    :class="
                      services.includes(parseInt(treatmentItem.id)) ||
                      (compatibility.length > 0 &&
                        !compatibility.includes(parseInt(treatmentItem.id)))
                        ? 'disableMenu'
                        : ''
                    "
                  >
                    <div class="c-service-menu__bloc">
                      <a
                        href="#"
                        @click="addService(treatmentItem.id, treatmentItem.name)"
                        @change="changeMenu()"
                      >
                        <h2>{{ treatmentItem.name }}</h2>
                        <!-- If there is any change in the treatment name "リセット編み直し" in future , please address this thing too -->
                      <h3 class="c-service-menu-subtext" v-if=" treatmentItem.name === 'リセット編み直し'">※前回の[通常編み直し]施術から2週間以内のお客様向け（1回のみ）</h3>
                       <dl>
                          {{ treatmentItem.description }}
                          <dt>施術時間目安：</dt>
                          <dd>
                            <span
                              v-if="
                                treatmentItem.duration_male !== null &&
                                  treatmentItem.duration_male !== 0
                              "
                              >男性{{ treatmentItem.duration_male / 60 }}時間</span
                            >
                            <span
                              v-if="
                                treatmentItem.duration_female !== null &&
                                  treatmentItem.duration_female !== 0
                              "
                              >、女性{{ treatmentItem.duration_female / 60 }}時間</span
                            >
                          </dd>
                        </dl>
                      </a>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div
                    v-for="(treatmentItem, index) in listTreatment"
                    :key="index"
                    class="d-box"
                    :class="isDisableMenu(treatmentItem.id)"
                  >
                    <input
                      :id="`consult-${treatmentItem.id}`"
                      v-model="listServicesPush"
                      type="checkbox"
                      :value="treatmentItem.id"
                      :class="services.includes(parseInt(treatmentItem.id)) ? 'checkedMenu' : ''"
                      hidden
                      @click="addService(treatmentItem.id, treatmentItem.name)"
                      @change="changeMenu()"
                    />
                    <label class="checkLabel" :for="`consult-${treatmentItem.id}`">
                      <h2>{{ treatmentItem.name }}</h2>
                      <dl>
                        {{ treatmentItem.description }}
                        <dt>施術時間目安：</dt>
                        <span
                          v-if="
                            treatmentItem.duration_male !== null &&
                              treatmentItem.duration_male !== 0
                          "
                          >男性{{ treatmentItem.duration_male / 60 }}時間</span
                        >
                        <span
                          v-if="
                            treatmentItem.duration_female !== null &&
                              treatmentItem.duration_female !== 0
                          "
                          >、女性{{ treatmentItem.duration_female / 60 }}時間</span
                        >
                      </dl>
                    </label>
                  </div>
                </template>
                <p v-if="errorMessage" class="mt-4 text-left text-danger">
                  施術を選択してください
                </p>
              </div>
            </div>
            <button-registration
              v-if="category === 'wig'"
              :prev-text="'戻る'"
              :prev-text-color="'#787878'"
              :handle-prev-button="prevToStep"
              :is-next-button="false"
            >
            </button-registration>
            <button-registration
              v-else
              :prev-text="'戻る'"
              :next-text="'次へ'"
              :prev-text-color="'#787878'"
              :handle-prev-button="prevToStep"
              :handle-next-button="nextToStep"
            >
            </button-registration>
          </section>
          <!-- .p-bloc__reservation -->
        </div>
      </div>
    </div>
    <!-- .l-content--reservation -->
  </section>
  <!-- .l-content-area -->
</template>
<script>
import ButtonRegistration from '../../common/ButtonRegistration.vue'
import { getCompatibilityServices } from '../../../actions/requestMypage'
import { handleErrors } from '../../../helpers/commonHelpers'
export default {
  name: 'MypageReservationTreatment',
  components: { ButtonRegistration },
  props: {
    category: {
      type: String,
      default: '',
    },
    currentStep: {
      type: String,
      default: '2',
    },
    services: {
      type: Array,
      default: () => {
        return []
      },
    },
    treatment: {
      type: Array,
      default: () => {
        return []
      },
    },
    listTimeMale: {
      type: Array,
      default: () => {
        return []
      },
    },
    listTimeFemale: {
      type: Array,
      default: () => {
        return []
      },
    },
    checkPush: {
      type: Boolean,
      default: false,
    },
    isCategoryWig: {
      type: Array,
      default: () => {
        return []
      },
    },
    selectedServices: {
      type: Array,
      default: () => {
        return []
      },
    },
    selectedServiceNames: {
      type: Array,
      default: () => {
        return []
      },
    },
    listAllServices: {
      type: Array,
      default: () => {
        return []
      },
    },
    compatibility: {
      type: Array,
      default: () => {
        return []
      },
    },
    mainServiceId: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      listTreatment: [],
      checkbox: false,
      listServicesPush: [],
      listNameTreatmentPush: [],
      compatibilityPush: [],
      errorMessage: false,
      exclusiveGroup: [],
      nonExclusiveGroup: [],
    }
  },
  computed: {
    checkMainServiceId() {
      return this.mainServiceId ? this.mainServiceId : 1
    },
  },
  watch: {
    category(newVal) {
      this.errorMessage = false
      this.compatibilityPush = []
      this.listTreatment = this.listAllServices.filter(service => {
        return service.category === newVal
      })
      this.exclusiveGroup = this.listTreatment.filter(filterExclusive => {
        return filterExclusive.exclusive_group !== null
      })
      this.nonExclusiveGroup = this.listTreatment.filter(filterNonExclusive => {
        return filterNonExclusive.exclusive_group == null
      })
    },
    services() {
      this.changeMenu()
    },
  },
  created() {
    this.$emit('startLoading')
    this.listTreatment = this.listAllServices.filter(service => {
      return service.category === this.category
    })
    this.exclusiveGroup = this.listTreatment.filter(filterExclusive => {
      return filterExclusive.exclusive_group !== null
    })
    this.nonExclusiveGroup = this.listTreatment.filter(filterNonExclusive => {
      return filterNonExclusive.exclusive_group == null
    })
    this.$emit('endLoading')
  },
  methods: {
    prevToStep() {
      this.listServicesPush = []
      this.listNameTreatmentPush = []
      this.compatibilityPush = []
      if (this.services.length === 0) {
        this.$emit('onHandleChange', 'compatibility', [])
      }
      this.changeMenu()
      this.$emit('prevToStep')
    },
    addService(treatmentId, treatmentName) {
      if (treatmentId && this.checkPush === true) {
        //if checkbox is check or uncheck
        let name_index = this.listNameTreatmentPush.indexOf(treatmentName)
        if (name_index === -1) {
          this.listNameTreatmentPush.push(treatmentName)
        } else {
          this.listNameTreatmentPush.splice(name_index, 1)
        }
        // check for select service Keyup to show / hide errorMessage
        this.listNameTreatmentPush.length > 0
          ? (this.errorMessage = false)
          : (this.errorMessage = true)
      }

      if (this.category == 'wig') {
        if (treatmentId && this.checkPush === true) {
          //if checkbox is check or uncheck
          let index = this.listServicesPush.indexOf(parseInt(treatmentId))
          if (index === -1) {
            this.listServicesPush.push(treatmentId)
          } else {
            this.listServicesPush.splice(index, 1)
            this.compatibilityPush = []
          }

          let oldSelectedServices = [...this.services]
          oldSelectedServices = oldSelectedServices.concat([treatmentId])
          this.getCompatibilityServices(oldSelectedServices)
          this.nextToStep()
        }
      }
    },
    nextToStep() {
      if (this.category !== 'wig') {
        if (this.services.length === 0) {
          if (this.listServicesPush.length === 0) {
            this.errorMessage = true
            return
          }
        } else {
          this.errorMessage = false
        }
      }

      //combine services + current selected services
      let tmpServices = [...this.services]
      let tmpServicesName = [...this.treatment]
      tmpServices = tmpServices.concat(this.listServicesPush)
      tmpServicesName = tmpServicesName.concat(this.listNameTreatmentPush)

      tmpServices = [...new Set(tmpServices)]
      tmpServicesName = [...new Set(tmpServicesName)]

      if (this.category == 'wig') {
        this.$emit('onHandleChange', 'isCategoryWig', this.listTreatment)
      }
      this.$emit('onHandleChange', 'selectedServices', this.listServicesPush)
      this.$emit('onHandleChange', 'selectedServiceNames', this.listNameTreatmentPush)
      this.$emit('onHandleChange', 'services', tmpServices)
      this.$emit('onHandleChange', 'treatment', tmpServicesName)
      //this.$emit('onHandleChange', 'compatibility', this.compatibilityPush)

      this.listServicesPush = []
      this.listNameTreatmentPush = []
      this.compatibilityPush = []
      this.errorMessage = false
      this.$emit('nextToStep')
    },
    changeMenu() {
      //check compatiable
      //add service ids of n time selection
      let oldSelectedServices = [...this.services]
      oldSelectedServices = oldSelectedServices.concat(this.listServicesPush)

      this.getCompatibilityServices(oldSelectedServices)
      //reset data for uncheck state of 1 st select of 1st round
      if (this.listServicesPush.length === 0 && this.services.length === 0) {
        this.$emit('onHandleChange', 'compatibility', [])
      }
    },
    isDisableMenu(id) {
      if (
        this.services.includes(parseInt(id)) ||
        (this.compatibility.length > 0 && !this.compatibility.includes(parseInt(id)))
      ) {
        return 'disableMenu'
      } else return ''
    },
    async getCompatibilityServices(oldSelectedServices) {
      if (oldSelectedServices.length > 0) {
        // remove for optional services id
        let optional = []
        this.listAllServices.map(service => {
          if (service.is_sub_treatment === 1) {
            optional.push(service.id)
          }
        })

        //remove optional from selected service list
        oldSelectedServices = oldSelectedServices.filter(service => {
          return !optional.includes(service)
        })
      }

      this.$emit('startLoading')
      await getCompatibilityServices(oldSelectedServices)
        .then(response => {
          const {
            data: { success },
          } = response
          if (success && success === 1) {
            this.compatibilityPush = response.data.data

            //add current selected ids
            this.compatibilityPush = this.compatibilityPush.concat(this.listServicesPush)
            this.compatibilityPush = [...new Set(this.compatibilityPush)]
            this.$emit('onHandleChange', 'compatibility', this.compatibilityPush)
          }
          this.$emit('endLoading')
        })
        .catch(err => {
          this.$emit('endLoading')
          return handleErrors(err)
        })
    },
  },
}
</script>
<style scoped>
.v-application ul,
.v-application ol {
  padding-left: 0 !important;
}

.v-application a {
  color: #0a005a;
}

.p-reservation__custom .d-box label.checkLabel:before {
  display: block;
  position: absolute;
  top: 37%;
  left: 37px;
  z-index: 1;
  content: '';
  width: 27px;
  height: 27px;
  margin-top: 0px;
  background: #ffffff;
  border: 1.5px solid #9a9b9e;
  border-radius: 5px;
}

.p-reservation__custom .d-box__custom:before {
  content: '';
  position: absolute;
  top: 50%;
  right: 8px;
  z-index: 1;
  width: 26px;
  height: 26px;
  margin-top: -13px;
  background-image: url(../../../../src/assets/img/ui_parts/service_bloc_arw.png);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% 100%;
}

.p-reservation__custom .d-box input[type='checkbox']:checked + label.checkLabel:before,
.p-reservation__custom .d-box input[type='checkbox'].checkedMenu + label.checkLabel:before {
  background-image: url(../../../../src/assets/img/ui_parts/checked_icon.png);
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 2px;
}

.p-reservation__custom .d-box.disableMenu label.checkLabel:before {
  border-color: #9a9b9e7d;
  background-color: #ffffff;
}

.p-reservation__custom .d-box {
  margin-bottom: 15px;
  padding: 13px 13px 13px 100px;
  position: relative;
  border: 1px solid #ebebeb;
  background-color: #f5f5f5;
  border-radius: 8px;
  cursor: pointer;
}

.p-reservation__custom .d-box__custom {
  padding-left: 0px;
}

.p-reservation__custom .d-box label {
  color: #0a005a;
  text-align: left;
  cursor: pointer;
}

.p-reservation__custom .d-box.disableMenu label {
  color: #00000099;
}

.p-reservation__custom .d-box h2 {
  margin: 0;
  padding: 0;
  border: none;
  font-size: 18px;
  margin-bottom: 4px;
}

.p-reservation__custom .d-box dl dt {
  font-size: 12px;
}
.p-reservation__custom .d-box dl {
  font-size: 15px;
}

.disabled {
  pointer-events: none;
}

.disableMenu {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  opacity: 0.5;
}

.c-service-menu-subtext{
  color: red;
  font-size: 12px;
  font-weight: normal;
  margin-bottom: 6px;
}

.text-danger {
  color: #ff0000;
}
</style>
