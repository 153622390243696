import axios from 'axios'
import { user } from '../constant/endpoint'
import store from '../store'
import { version } from '../../package.json'

const baseAPIURL = `${process.env.VUE_APP_BASE_URL_API}/${user}`

const BaseUserRepository = axios.create({
  withCredentials: true,
  headers: {
    common: {
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
      'X-Requested-With': 'XMLHttpRequest',
    },
    get: {
      Accept: 'application/json',
    },
    version,
  },
})

BaseUserRepository.interceptors.request.use(function(config) {
  config.baseURL = baseAPIURL
  const token = store.state.auth.token || sessionStorage.getItem("token")
  config.headers['Authorization'] = 'Bearer ' + token
  return config
})

const UNAUTHORIZED = 401
BaseUserRepository.interceptors.response.use(
  response => response,
  error => {
    const { status } = error.response
    if (status === UNAUTHORIZED) {
      window.location.href = '/'
      sessionStorage.setItem('token', '')
    }
    return Promise.reject(error)
  }
)

function isOngoingMaintenance(status) {
	if (status === 502 || status === 503) {
	  window.location.href = '/maintenance.html';
	}
}
  
BaseUserRepository.interceptors.response.use(
	response => {
		isOngoingMaintenance(response.status);
		return response;
	},
	error => {
		isOngoingMaintenance(error.response?.status);
		return Promise.reject(error);
	}
);

export default BaseUserRepository
