<template>
  <header class="l-header">
    <div class="l-header__inner">
      <div class="l-header__logo">
        <a @click="redirectToMyPage()"><img src="../../assets/img/header/logo.png" alt="MEN'S SVENSON" /></a>
      </div>
      <div v-if="isShowLoginHeader" class="l-header__ui">
        <div class="head-ui__btn">
          <div class="c-btn c-btn--head-login">
            <a href="#" @click="redirectLoginPage()">ログイン</a>
          </div>
        </div>
      </div>
      <div v-if="isShowLogoutHeader" class="l-header__ui">
        <div class="head-ui__btn">
          <div class="c-btn c-btn--head-logout"><a href="#" @click="Logout()">ログアウト</a></div>
        </div>
      </div>
      <div v-if="isShowUserHeader" class="l-header__ui">
        <div class="head-ui__btn">
          <div class="c-btn c-btn--head-mypage">
            <a href="#" @click="redirectToMyPage()">マイページ</a>
          </div>
        </div>
      </div>
    </div>
  </header>
</template>

<script>
import { logout } from '../../actions/requestLogin'
export default {
  name: 'Header',
  props: {
    isShowLoginHeader: {
      type: Boolean,
      default: false,
    },
    isShowLogoutHeader: {
      type: Boolean,
      default: false,
    },
    isShowUserHeader: {
      type: Boolean,
      default: false,
    },
  },
  created() {},
  methods: {
    Logout() {
      this.$authInfo.setToken(sessionStorage.getItem('token'));
      logout()
        .then(res => {
          const {
            data: { success },
          } = res
          if (success && success === 1) {
            this.$authInfo.setToken('');
            this.$authInfo.setUser({});
            sessionStorage.removeItem('token');
            sessionStorage.removeItem('userInfo');
            sessionStorage.removeItem('userLinked');
            sessionStorage.removeItem('studioInfo');
            sessionStorage.removeItem('userId');
            sessionStorage.removeItem('user');
            sessionStorage.removeItem('from_email_link');
            sessionStorage.removeItem('booking');
            sessionStorage.removeItem('linked');
            this.redirectLoginPage();
          }
        })
        .catch(err => {
          return err
        })
    },
    redirectLoginPage() {
      this.$router.push({
        name: 'login',
      });
      location.reload();
    },
    redirectToMyPage() {
      const currentUrl = window.location.pathname;
      if (currentUrl === "/account") {
        this.Logout();
      } else {
        this.$router.push({
          name: 'mypage',
        });
      }
    },
  },
}
</script>

<style scoped>
.v-application ul,
.v-application ol {
  padding-left: 0 !important;
}

.v-application a {
  color: #fff;
}
</style>
