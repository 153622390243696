<template>
  <section class="l-content-area">
    <div class="l-content l-content--login">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="c-title__bloc c-title__bloc--login">
            <h1>パスワードをお忘れの方</h1>
          </section>

          <section class="p-bloc p-bloc__reminder">
            <div class="p-reminder__bloc">
              <p>ご登録済のメールアドレスを入力してください。メールにてパスワード再設定用のURLをお送りいたします。</p>

              <div class="p-reminder__form">
                <dl class="c-form-parts">
                  <dt>メールアドレス</dt>
                  <dd>
                    <input-email
                      :value="email"
                      :error="error"
                      :placeholder="'svenson@svenson.co.jp'"
                      @onHandleChange="onHandleChange"
                      @onValidate="onValidate"
                    >
                    </input-email>
                  </dd>
                </dl>
              </div>
            </div>

            <button-registration
              :is-prev-button="false"
              :handle-next-button="onNextStep"
              :next-text="'送信する'"
            >
            </button-registration>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import InputEmail from '../common/InputEmail'
  import ButtonRegistration from '../common/ButtonRegistration'
  import * as schema from '../../helpers/schemaValidate'
  import { resetPasswordEmail } from '../../actions/requestRegister'
  import { RESET_EMAIL_INVALID } from '../../constant/text'

  export default {
    name: 'ReminderEmail',
    components: { ButtonRegistration, InputEmail },
    props: {
      email: {
        type: String,
        default: ''
      },
      isForceValidate: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        error: ''
      }
    },
    watch: {
      isForceValidate(newVal) {
        if (newVal) {
          this.onNextStep()
        }
      }
    },
    methods: {
      onHandleChange(field, value) {
        this.$emit('onHandleChange', value)
      },
      onValidate(field, message) {
        this.error = message
        this.$emit('onValidateSwipe', false)
      },
      onNextStep() {
        const values = {
          email: this.email
        }
        schema.emailSchema.validateAt('email', values)
        .then(() => {
          const data = {
            email: this.email,
          }
          this.$emit('startLoading')
          resetPasswordEmail(data)
            .then(res => {
              const { data: {success } } = res
              if(success & success === 1) {
                this.$emit('nextToStep'),
                this.$emit('endLoading')
              }
            })
            .catch(err => {
              this.$emit('endLoading')
              if( err.response.data.error && err.response.data.error.email) {
                this.error = err.response.data.error.email
              }
              else {
                this.error = RESET_EMAIL_INVALID
              }
              return err
            })
        })
        .catch(error => {
          this.error = error.message
        })
      }
    }
  }
</script>

<style scoped>

</style>
