import * as VueGoogleMaps from 'vue2-google-maps'
import Vue from 'vue'

Vue.use(VueGoogleMaps, {
  load: {
    key: 'AIzaSyD4oCKerIHtoHo1g9D7ftGEV_KGdnvqoaM',
    libraries: 'places',
  }
})

export default VueGoogleMaps
