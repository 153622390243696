<template>
  <main-layout :title="title" :is-loading="isLoading">
    <data-sync-failure
      @set-title="setTitle"
      @startLoading="startLoading"
      @endLoading="endLoading"
    >
    </data-sync-failure>
  </main-layout>
</template>
<script>
import MainLayout from '../components/layout/MainLayout.vue'
import DataSyncFailure from '../components/data-sync/DataSyncStudioFailure.vue'
export default {
  name: 'DataSyncStudio',
  components: {
    MainLayout,
    DataSyncFailure
  },
  data() {
    return {
      isLoading: false,
      title: 'MEN\'S SVENSON｜登録情報変更'
    }
  },
  methods: {
    setTitle(title) {
      this.title = title
    },
    startLoading() {
      this.isLoading = true
    },
    endLoading() {
      this.isLoading = false
    }
  }
}
</script>