<template>
  <section class="l-content-area">
    <div class="l-content l-content--reservation">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="c-title__bloc c-title__bloc--reservation">
            <h1>ご予約内容の確認</h1>
            <div class="c-btn c-btn--title-back">
              <a href="#" @click.prevent="prevToStep()">戻る</a>
            </div>
          </section>

          <p class="reconfirm__description">
            まだ予約は完了しておりません。画面下部の「予約する」ボタンを押してください。
          </p>

          <section class="p-bloc p-bloc__reservation">
            <h2>ご予約情報</h2>
            <div class="p-reservation__confirm">
              <div class="reservation-confirm__bloc">
                <div class="flex-wrapper">
                  <div class="row">
                    <div class="column label">
                      {{ isDesignCut ? 'ご予約日時' : '第1希望日時' }}
                    </div>
                    <div class="column value">
                      {{ formatReservationDateTime(getFullFirstTime) }}
                    </div>
                  </div>
                  <div v-if="!isDesignCut" class="row">
                    <div class="column label">
                      第2希望日時
                    </div>
                    <div class="column value">
                      {{ formatReservationDateTime(getFullSecondTime) }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="column label">
                      {{ isDesignCut ? '店舗' : 'ご利用店舗' }}
                    </div>
                    <div class="column value">
                      {{ values.address.studioName }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h2>施術内容</h2>
            <div class="p-reservation__confirm">
              <div class="reservation-confirm__bloc">
                <div class="flex-wrapper">
                  <div class="row">
                    <div class="column label">
                      施術
                    </div>
                    <div class="column value">
                      <span v-if="!isDesignCut && !isConclusion">{{
                        getTreatmentContentName
                      }}</span>
                      <span v-else>{{ getName }}</span>
                    </div>
                  </div>
                  <!-- <div class="row">
                    <div class="column label">
                      施術オプション
                    </div>
                    <div class="column value">
                      <span v-if="isDesignCut || isConclusion">{{ getTreatmentContentName }}</span>
                      <span v-else>選択なし</span>
                    </div>
                  </div> -->
                  <div v-if="!isConclusion" class="row">
                    <div class="column label">
                      施術時間目安
                    </div>
                    <div class="column value">
                      {{ getEstimatedTime }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div v-if="!isDesignCut">
              <h2>日程調整ご希望内容</h2>
              <div class="p-reservation__confirm">
                <div class="reservation-confirm__bloc">
                  <div class="flex-wrapper">
                    <div class="row">
                      <div class="column label">
                        連絡手段
                      </div>
                      <div class="column value">
                        {{ getContactMethodName }}
                      </div>
                    </div>
                    <!-- <div class="row">
                      <div class="column label">
                        ご希望予定
                      </div>
                      <div class="column value">
                        {{ getQuestionName }}
                      </div>
                    </div> -->
                    <div v-if="values.answers.other" class="row">
                      <div class="column label">
                        その他ご要望
                      </div>
                      <div class="column value">
                        <span>{{ values.answers.other }}</span>
                        <!-- <span v-else>選択なし</span> -->
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h2>アンケート回答内容</h2>
            <div class="p-reservation__confirm">
              <div class="reservation-confirm__bloc">
                <div class="flex-wrapper">
                  <div class="row">
                    <div class="column label">
                      他社利用
                    </div>
                    <div class="column value">
                      {{ getExperienceName }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="column label">
                      製品サービス
                    </div>
                    <div class="column value">
                      {{ getServiceName }}
                    </div>
                  </div>
                  <div v-if="values.answers.request" class="row request">
                    <div class="column label">
                      その他ご要望
                    </div>
                    <div class="column value">
                      <span>{{ values.answers.request }}</span>
                      <!-- <span v-else>選択なし</span> -->
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <button-registration
              :prev-text="'戻る'"
              :next-text="'予約する'"
              :handle-next-button="nextToStep"
              :handle-prev-button="prevToStep"
              :prev-text-color="'#787878'"
            >
            </button-registration>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ButtonRegistration from '../common/ButtonRegistration.vue'
import { formatReservationDateTime } from '../../helpers/formatDate'
import { listContactMethod, listExperience, listQuestions } from '../../constant/listQuestions'
import { listService } from '../../constant/listServices'
import {
  getReminderById,
  getReservationById,
  getSurveyById,
  getTreatmentByCategory,
  requestRegisterReservation,
} from '../../actions/requestReservation'
import { scrollToTop } from '../../helpers'
import { listTreatment } from '../../constant/listTreatment'
import { listDesignCut } from '../../constant/listDesignCut'

export default {
  name: 'ReservationConfirm',
  components: { ButtonRegistration },
  data() {
    return {
      listTreatment: [],
      isSubTreatment: 0,
      values: {
        reservationId: '',
        duration: '',
        reminderId: '',
        surveyId: '',
        userId: '',
        profiles: {
          firstName: '',
          lastName: '',
          firstNameKana: '',
          lastNameKana: '',
          gender: '',
          email: '',
          phone: '',
          year: '',
          month: '',
          date: '',
        },
        address: {
          zipCode: '',
          address: '',
          state: '',
          city: '',
          studio: '',
          studioName: '',
          region_id: '',
        },
        treatmentContent: [],
        timeVisited: {
          firstTime: {
            date: '',
            hour: '',
            minute: '',
          },
          secondTime: {
            date: '',
            hour: '',
            minute: '',
          },
        },
        answers: {
          other: '',
          question: '',
          contact: '',
          experience: '',
          service: [],
          request: '',
        },
      },
      isDesignCut: false,
      isConclusion: false,
      isModalVisible: false,
      errorMessage: '',
      formatReservationDateTime,
    }
  },
  computed: {
    getEstimatedTime() {
      return this.values.duration === '' ? '選択なし' : '約' + this.values.duration / 60 + '時間'
    },
    getFullFirstTime() {
      return this.values.timeVisited.firstTime.date + ' ' + this.values.timeVisited.firstTime.hour + ':' + this.values.timeVisited.firstTime.minute
    },
    getFullSecondTime() {
      return this.values.timeVisited.secondTime.date + ' ' + this.values.timeVisited.secondTime.hour + ':' + this.values.timeVisited.secondTime.minute
    },
    getContactMethodName() {
      const contactMethod = listContactMethod.filter(
        contact => contact.id === this.values.answers.contact
      )
      if (contactMethod.length > 0) {
        return contactMethod[0].value
      }
      return '選択なし'
    },
    getExperienceName() {
      const experienceName = listExperience.filter(
        experience => experience.id === this.values.answers.experience
      )
      if (experienceName.length > 0) {
        return experienceName[0].value
      }
      return '選択なし'
    },
    getName() {
      const treatments = listTreatment
      for (var key in treatments) {
        if (
          (this.isConclusion == true && treatments[key]['category'] == 'con') ||
          (this.isDesignCut == true && treatments[key]['category'] == 'cut')
        ) {
          return treatments[key]['name']
        }
      }
      return '選択なし'
    },
    getTreatmentContentName() {
      const treatmentName = this.listTreatment.filter(treatment => {
        return this.values.treatmentContent.indexOf(treatment.id) !== -1
      })
      if (treatmentName.length > 0) {
        const listTreatmentName = treatmentName.map(item => {
          return item.name
        })
        return listTreatmentName.join(', ')
      }
      return '選択なし'
    },
    getServiceName() {
      const servicesName = listService.filter(
        service => this.values.answers.service.indexOf(service.id) !== -1
      )

      if (servicesName.length > 0) {
        let listServiceName = servicesName.map(item => {
          return item.name
        })
        listServiceName = listServiceName.join(', ')
        return listServiceName
      }

      return '選択なし'
    },
    getQuestionName() {
      const questionsName = listQuestions.filter(question => {
        return this.values.answers.question === question.id
      })

      if (questionsName.length > 0) {
        return questionsName[0].name
      }

      return '選択なし'
    },
  },
  created() {
    scrollToTop()
    if (!this.$reservation.getCurrentStep()) {
      this.$router.push({
        name: 'reservation',
      })
    }
    const valuesReservation = this.$reservation.getValuesReservation()
    this.values = valuesReservation
    this.isDesignCut = this.$reservation.getIsDesignCut()
    this.isConclusion = this.$reservation.getIsConclusion()
    this.$emit('startLoading')
    getReservationById(this.values.reservationId)
    getSurveyById(this.values.surveyId)
    if (this.values.reminderId) {
      getReminderById(this.values.reminderId)
    }
    if (this.$reservation.getIsConclusion()) {
      getTreatmentByCategory('con', valuesReservation.address.studio)
        .then(res => {
          const {
            data: { success },
          } = res
          if (success && success === 1) {
            const {
              data: { data },
            } = res
            this.listTreatment = data.map(treatment => {
              return {
                id: treatment.id.toString(),
                name: treatment.name,
              }
            })
          }
        })
        .catch(error => {
          console.log(error)
        })
    } else if (this.$reservation.getIsDesignCut()) {
      this.listTreatment = listDesignCut
    } else {
      this.listTreatment = listTreatment
    }
    this.$emit('endLoading')
  },
  methods: {
    prevToStep() {
      this.$router.push({
        name: 'reservation',
      })
    },
    nextToStep() {
      const reservationValues = this.$reservation.getValuesReservation()
      const isDesignCut = this.$reservation.getIsDesignCut()
      const OrangeMatches = this.$reservation.getOrangeMatches()
      var status
      if (OrangeMatches === 1) {
        status = 'duplicate'
      } else {
        status =
          isDesignCut && this.values.treatmentContent[0] == 83
            ? 'created'
            : isDesignCut
            ? 'pending'
            : 'created'
      }
      if (reservationValues && reservationValues.reservationId) {
        this.$emit('startLoading')
        requestRegisterReservation(
          reservationValues.userId,
          reservationValues.address.studio,
          reservationValues.timeVisited,
          isDesignCut,
          reservationValues.treatmentContent,
          reservationValues.reservationId,
          status,
          1
        )
          .then(res => {
            this.$emit('endLoading')
            const {
              data: { success },
            } = res
            if (success === 1) {
              this.$router.replace({
                name: 'reservation-finish',
              })
            }
          })
          .catch(error => {
            this.$emit('endLoading')
            if (error.response.data.error != undefined) {
              this.errorMessage = error.response.data.error.status
            } else {
              for (const key in error.response.data.errors) {
                this.errorMessage = error.response.data.errors[key][0].replace(
                  /^(\s*<br\s*\/?\s*>\s*)*|(\s*<br\s*\/?\s*>\s*)*\s*$/g,
                  ''
                )
              }
            }
            this.$toast.error(this.errorMessage)
          })
      }
    },
  },
}
</script>

<style scoped>
.v-application ul,
.v-application ol {
  padding-left: 0 !important;
}

.v-application a {
  color: #0a005a;
}

.p-reservation__confirm {
  margin-top: 20px;
  padding: 20px 50px 20px;
}

.p-bloc__reservation h2:first-child {
  margin-top: 60px;
}

.p-bloc__reservation h2 {
  text-align: left;
  margin-top: 40px;
}

.flex-wrapper .row {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 60px;
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
  margin: 0;
  min-height: 60px;
  height: auto;
  text-align: justify;
}

.flex-wrapper .request {
  flex-wrap: wrap;
  padding: 20px 0;
}

.flex-wrapper .row:last-child {
  border-bottom: 0;
}

.column:first-child {
  min-width: 40%;
  width: 40%;
  padding-right: 20px;
}

.column:last-child {
  width: 60%;
}

.column {
  display: flex;
  flex-direction: column;
  padding: 5px;
  word-break: break-all;
}

.flex-wrapper .request .column {
  flex-direction: row;
}

.flex-wrapper .request .column:first-child,
.flex-wrapper .request .column:last-child {
  min-width: 100%;
  width: 100%;
}

.label {
  font-size: 14px;
  font-weight: normal;
}

.value {
  font-size: 16px;
  font-weight: bold;
}

.reservation-confirm__bloc dl dd + dt.address {
  padding-top: 10px;
}

.reconfirm__description {
  text-align: center;
  border: 2px solid red;
  color: red;
  padding: 10px;
  font-weight: bold;
}

.studio {
  padding: 40px 50px 20px;
}

@media screen and (max-width: 600px) {
  .p-reservation__confirm {
    margin-top: 20px;
    padding: 20px 40px 20px;
  }

  .flex-wrapper .row {
    flex-wrap: wrap;
    padding: 10px 0;
  }

  .column:first-child,
  .column:last-child {
    width: 100%;
    min-width: 100%;
  }
}

@media screen and (max-width: 375px) {
  .p-reservation__confirm {
    padding: 20px 20px 20px;
  }
}
</style>
