<template>
  <div id="bst-wrap">
    <v-overlay :opacity="0.25" :value="isLoading">
      <v-progress-circular
        :size="70"
        :width="7"
        color="#0a005a"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <div class="l-project-wrap">
      <Header
        :is-show-login-header="isShowLoginHeader"
        :is-show-logout-header="isShowLogoutHeader"
        :is-show-user-header="isShowUserHeader"
      >
      </Header>
      <slot> </slot>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import Header from './Header.vue'
import Footer from './Footer.vue'

export default {
  name: 'MainLayout',
  components: {
    Header,
    Footer,
  },
  props: {
    title: {
      type: String,
      default: "MEN'S SVENSON｜ログイン",
    },
    isShowLoginHeader: {
      type: Boolean,
      default: false,
    },
    isShowLogoutHeader: {
      type: Boolean,
      default: false,
    },
    isShowUserHeader: {
      type: Boolean,
      default: false,
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },
  created() {
    document.title = this.title
  },
}
</script>

<style scoped></style>
