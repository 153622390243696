<template>
  <main-layout :title="title" :is-loading="isLoading">
    <reminder-component
      @setTitle="setTitle"
      @startLoading="startLoading"
      @endLoading="endLoading"
    ></reminder-component>
  </main-layout>
</template>

<script>
  import MainLayout from '../components/layout/MainLayout'
  import ReminderComponent from '../components/reminder/index'
  export default {
    name: 'Reminder',
    components: { ReminderComponent, MainLayout },
    data() {
      return {
        title: "パスワードをお忘れの方 | Svenson",
        isLoading: false
      }
    },
    methods: {
      setTitle(title) {
        this.title = title
      },
      startLoading() {
        this.isLoading = true
      },
      endLoading() {
        this.isLoading = false
      },
      }
  }
</script>

<style scoped>

</style>
