<template>
  <div :class="`text-area ${error ? 'is-error' : '' }`">
    <textarea
      :name="name"
      :maxlength="maxLength"
      cols="50" rows="5"
      :error="error"
      :placeholder="placeholder"
      :value="value"
      :textError="textError"
      :isRequired="isRequired"
      :pattern="pattern"
      @change="onHandleChange($event)"
      @blur="validateData($event)"
      @paste="restrictEmoji($event)"
      @keyup="onHandleChange($event)"
    >
	</textarea>
    <p v-if="isShowError" :class="`error-txt ${error ? 'd-block' : ''}`">
      {{ error }}
    </p>
  </div>
</template>
<script>

import * as schema from '../../helpers/schemaValidate'
import * as regex from '../../helpers/regex'
import * as message from '../../constant/text'
export default {
	name: 'TextArea',
	props: {
		textValue: {
			type: String,
			default: ''
		},
		name: {
			type: String,
			default: 'text'
		},
		value: {
			type: String,
			default: ''
		},
		placeholder: {
			type: String,
			default: ''
		},
		error: {
			type: String,
			default: ''
		},
		textError: {
						type: String,
			default: ''
		},
		pattern: {
			type: RegExp,
			default: null
		},
		isShowError: {
        type: Boolean,
        default: true
    },
	isRequired: {
			type: Boolean,
			default: false
		},
		maxLength: {
			type: Number,
			default: 524288
		},
	},
	data() {
		return {
			catchErr: false
		}
	},
	
  watch: {
    value() {
		this.$emit('onValidate', this.name, '')
    }
  },
	methods: {
		onHandleChange(e) {
			const { target: { value } } = e
			this.$emit('onHandleChange', this.name, value)
		},
        restrictEmoji($event) {
			var clipboardData = $event.clipboardData || window.clipboardData
			var pastedData = clipboardData.getData('Text')
			if (pastedData.match(regex.patternEmojiRestrict)) {
				$event.preventDefault()
				this.$emit('onValidate', this.name, message.EMOJI_KANJI_INVALID)
			}
        },
		validateData($event) {
			this.catchErr = false
			const values = {
				[this.name]: this.value
			}

			if (this.isRequired) {
				schema.validateRequired(this.name, this.textError)
					.validateAt(this.name, values)
					.then(() => {
						//this.$emit('onValidate', this.name, '')
					})
					.catch(error => {
						error.message = message.COMMENT_INVALID
						this.catchErr = true
						this.$emit('onValidate', this.name, message.COMMENT_INVALID)
					})
			}
			const { target: { value } } = $event
			if (this.pattern) {
				if (value.match(regex.patternEmojiRestrict) || value.match(regex.patternKanji)) {
					this.catchErr = true
					this.$emit('onValidate', this.name, message.EMOJI_KANJI_INVALID)
				}
			}
			// avoid to fill error of null once there is one catch error
			if(this.catchErr == false) {
				this.$emit('onValidate', this.name, '')
			}
		}

	}
}
</script>
