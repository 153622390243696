const reservation = {
  state: () => ({
    isDesignCut: null,
    isConclusion: null,
    currentStep: null,
    isStaff: null,
    orangeMatches:null,
    values: {
      reservationId: '',
      reminderId: '',
      surveyId: '',
      userId: '',
      isCategoryWig:[],
      profiles: {
        firstName: '',
        lastName: '',
        firstNameKana: '',
        lastNameKana: '',
        gender: '',
        email: '',
        phone: '',
        year: '',
        month: '',
        date: '',
      },
      address: {
        zipCode: '',
        address: '',
        state: '',
        city: '',
        studio: '',
        studioName: '',
        region_id: '',
      },
      treatmentContent: [],
      timeVisited: {
        firstTime: {
          date: '',
          hour: '',
          minute: '',
        },
        secondTime: {
          date: '',
          hour: '',
          minute: '',
        },
      },
      answers: {
        other: '',
        question: '',
        contact: '',
        experience: '',
        service: [],
        request: '',
      },
    },
  }),
  mutations: {
    setIsDesignCut(state, isDesignCut) {
      state.isDesignCut = isDesignCut
    },
    setIsConclusion(state, isConclusion) {
      state.isConclusion = isConclusion
    },
    setIsStaff(state, isStaff) {
      state.isStaff = isStaff
    },
    setCurrentStep(state, currentStep) {
      state.currentStep = currentStep
    },
    setValues(state, values) {
      state.values = values
    },
    resetReservation(state) {
      state.isDesignCut = null
      state.currentStep = null
      state.isConclusion = null
      state.orangeMatches = null
      state.values = {}
    },
    setOrangeMatches(state,orangeMatches) {
      state.orangeMatches = orangeMatches
    }
  },
  actions: {
    setIsDesignCut({ commit }, isDesignCut) {
      commit('setIsDesignCut', isDesignCut)
    },
    setIsStaff({ commit }, isStaff) {
      commit('setIsStaff', isStaff)
    },
    setIsConclusion({ commit }, isConclusion) {
      commit('setIsConclusion', isConclusion)
    },
    setCurrentStep({ commit }, currentStep) {
      commit('setCurrentStep', currentStep)
    },
    setValues({ commit }, values) {
      commit('setValues', values)
    },
    resetReservation({commit}) {
      commit('resetReservation')
    },
    setOrangeMatches({commit},orangeMatches) {
      commit('setOrangeMatches',orangeMatches)
    }
  },
}

export default reservation
