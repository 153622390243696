var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"l-content-area"},[_c('div',{staticClass:"l-content l-content--reservation"},[_c('div',{staticClass:"l-wrap__outer"},[_c('div',{staticClass:"l-wrap__inner"},[_c('section',{staticClass:"c-title__bloc c-title__bloc--reservation"},[_c('h1',[_vm._v("ご来店予約")]),_c('div',{staticClass:"c-btn c-btn--title-back"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.prevToStep()}}},[_vm._v("戻る")])])]),_c('section',{staticClass:"p-bloc p-bloc__reservation"},[_c('h2',{staticClass:"p-reservation__step"},[_vm._v("STEP 3")]),_c('div',{staticClass:"p-reservation__bloc"},[_vm._m(0),_c('div',{staticClass:"p-reservation__service p-reservation__custom"},[_vm._l((_vm.listSubTreatment),function(subTreatment,index){return (!_vm.disabledMenu(subTreatment))?_c('div',{key:index,staticClass:"d-box"},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.listServicesOptionPush),expression:"listServicesOptionPush"}],attrs:{"id":`consult-${subTreatment.id}`,"type":"checkbox","hidden":""},domProps:{"value":subTreatment.id,"checked":Array.isArray(_vm.listServicesOptionPush)?_vm._i(_vm.listServicesOptionPush,subTreatment.id)>-1:(_vm.listServicesOptionPush)},on:{"click":function($event){return _vm.addService(subTreatment.id, subTreatment.name)},"change":function($event){var $$a=_vm.listServicesOptionPush,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=subTreatment.id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.listServicesOptionPush=$$a.concat([$$v]))}else{$$i>-1&&(_vm.listServicesOptionPush=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.listServicesOptionPush=$$c}}}}),_c('label',{staticClass:"checkLabel",attrs:{"for":`consult-${subTreatment.id}`}},[_c('h2',[_vm._v(_vm._s(subTreatment.name))]),_c('dl',[_vm._v(" "+_vm._s(subTreatment.description)+" "),_c('dt',[_vm._v("追加施術時間の目安：")]),(
                          subTreatment.duration_male !== null && subTreatment.duration_male !== 0
                        )?_c('span',[_vm._v("男性+"+_vm._s(subTreatment.duration_male / 60)+"時間")]):_vm._e(),(
                          subTreatment.duration_female !== null &&
                            subTreatment.duration_female !== 0
                        )?_c('span',[_vm._v("、女性+"+_vm._s(subTreatment.duration_female / 60)+"時間")]):_vm._e()])])]):_vm._e()})],2)]),_c('button-registration',{attrs:{"prev-text":'戻る',"next-text":'次へ',"prev-text-color":'#787878',"handle-prev-button":_vm.prevToStep,"handle-next-button":_vm.nextToStep}})],1)])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('p',[_vm._v(" 希望されない場合はそのままお進みください。"),_c('br'),_c('span',{staticClass:"p-reservation__subtext"},[_vm._v(" 製品のカラー / パーマの場合は、当日ご来店の際にお申し付けください。")])])
}]

export { render, staticRenderFns }