import RegisterRepository from "./RegisterRepository"

const repository = {
  register : RegisterRepository
}

const RegisterRepositoryFactory = {
  get: (name) => repository[name],
}

export default RegisterRepositoryFactory