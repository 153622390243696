<template>
  <v-card height="100%">
    <v-tabs
      v-show="false"
      v-model="currentStep"
      centered
      grow
      :hide-slider="true"
      :transition="false"
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#1"
             :align-with-title="true"
             :disabled="disabled.firstTab"
      >
        ステップ1
      </v-tab>
      <v-tab href="#2"
             :align-with-title="true"
             :disabled="disabled.secondTab"
      >
        ステップ2
      </v-tab>
      <v-tab href="#3"
             :align-with-title="true"
             :disabled="disabled.thirdTab"
      >
        ステップ3
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentStep" :touch="onTouchEvent()">
      <v-tab-item v-if="currentStep === '1'" value="1">
        <first-step-registration
          @nextToStep="nextToStep"
        >
        </first-step-registration>
      </v-tab-item>
      <v-tab-item v-if="currentStep === '2'" value="2">
        <second-step-registration
          :email="values.email"
          :is-force-validate="isForceValidate"
          @nextToStep="nextToStep"
          @prevToStep="prevToStep"
          @startLoading="startLoading"
          @endLoading="endLoading"
          @onHandleChange="onChangeValue"
          @onValidateSwipe="onValidateSwipe"
        ></second-step-registration>
      </v-tab-item>
      <v-tab-item v-if="currentStep === '3'" value="3">
        <third-step-registration></third-step-registration>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
  import FirstStepRegistration from './first-step'
  import SecondStepRegistration from './second-step'
  import ThirdStepRegistration from './third-step'

  export default {
    name: 'RegistrationComponent',
    components: {
      FirstStepRegistration,
      SecondStepRegistration,
      ThirdStepRegistration
    },
    data() {
      return {
        currentStep: '1',
        disabled: {
          firstTab: false,
          secondTab: true,
          thirdTab: true,
        },
        values: {
          email: '',
          password: '',
        },
        isForceValidate: false,
        isError: false
      }
    },
    watch: {
      currentStep(newValue) {
        this.isForceValidate = false
        switch (newValue) {
          case '2':
            this.disabled.secondTab = false
            break
          case '3':
            this.disabled.thirdTab = false
            break
          default:
            this.disabled.firstTab = false
            break
        }
      }
    },
    methods: {
      assignSwipeLeft() {
        if (this.currentStep === '1') {
          this.nextToStep()
        }
        this.isForceValidate = true
      },
      assignSwipeRight() {
        this.prevToStep()
      },
      nextToStep() {
        const nextStep = parseInt(this.currentStep) + 1
        this.currentStep = nextStep.toString()
      },
      prevToStep() {
        const prevStep = parseInt(this.currentStep) - 1
        this.currentStep = prevStep.toString()
      },
      onChangeValue(field, value) {
        this.values[field] = value
      },
      onValidateSwipe(value) {
        this.isForceValidate = value
      },
      onTouchEvent() {
        return {
          left: () => this.assignSwipeLeft(),
          right: () => this.assignSwipeRight(),
        }
      },
      startLoading() {
        this.$emit('startLoading')
      },
      endLoading() {
        this.$emit('endLoading')
      },
    },
  }
</script>
