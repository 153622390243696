const serviceDetails = 3
const genderPreference = 4
const dateSelection = 5
const confirm = 6

export const reservationSteps = {
    serviceDetails,
    genderPreference,
    dateSelection,
    confirm,
}