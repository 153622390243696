<template>
  <main-layout :is-show-login-header="isShowLoginHeader" :title="title" :is-loading="isLoading">
    <reservation-confirm
      @setTitle="setTitle"
      @startLoading="startLoading"
      @endLoading="endLoading"
    >
    </reservation-confirm>
  </main-layout>
</template>

<script>
  import MainLayout from '../components/layout/MainLayout'
  import ReservationConfirm from '../components/reservation/ReservationConfirm'

  export default {
    name: 'ConfirmReservation',
    components: {
      MainLayout,
      ReservationConfirm,
    },
    data() {
      return {
        isShowLoginHeader: true,
        title: 'MEN\'S SVENSON｜ご来店予約',
        isLoading: false,
      }
    },
    methods: {
      setTitle(title) {
        this.title = title
      },
      startLoading() {
        this.isLoading = true
      },
      endLoading() {
        this.isLoading = false
      },
    },
  }
</script>
