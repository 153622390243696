<template>
  <section class="l-content-area">
    <div class="l-content l-content--reservation">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="c-title__bloc c-title__bloc--reservation">
            <h1>登録情報変更</h1>
          </section>

          <section class="p-bloc p-bloc__reservation">
            <div class="p-reservation__bloc">
              <div class="p-reservation__form">
                <dl class="c-form-parts">
                  <dt>お名前</dt>
                  <dd v-if="isInit">
                    {{ values.lastName }} &nbsp;&nbsp;&nbsp;&nbsp;
                    {{ values.firstName }} &nbsp;&nbsp;&nbsp;&nbsp; (
                    {{ values.lastNameKana }} &nbsp;&nbsp;&nbsp;&nbsp; {{ values.firstNameKana }} )
                  </dd>
                  <dt>生年月日</dt>
                  <dd v-if="isInit">
                    {{ values.year }} -
                    {{ values.month > '9' ? values.month : '0' + values.month }} -
                    {{ values.date > '9' ? values.date : '0' + values.date }}
                  </dd>
                  <dt>性別</dt>
                  <dd v-if="isInit">
                    {{ values.gender === '1' ? '男性' : '女性' }}
                  </dd>
                  <dt>メールアドレス</dt>
                  <dd>
                    <input-email
                      :name="'email'"
                      :value="values.email"
                      :error="errors.email"
                      :placeholder="'メールアドレス'"
                      @onHandleChange="onHandleChange"
                      @onValidate="onValidate"
                    >
                    </input-email>
                  </dd>
                  <dt>ご連絡先<span>※ハイフン無し</span></dt>
                  <dd>
                    <input-phone
                      name="phone"
                      :max-length="11"
                      placeholder="08012345678"
                      :value="values.phone"
                      :error="errors.phone"
                      :text-error="textErrors.phone"
                      @onHandleChange="onHandleChange"
                      @onValidate="onValidate"
                    >
                    </input-phone>
                  </dd>
                </dl>
                <!-- <dl class="c-form-parts">
                  <dt>ご来店希望スタジオ</dt>
                  <dd v-if="isInit"> {{ studioInfo.name }} </dd>
                </dl>
                <dl v-if="studioInfo.postal_code || studioInfo.city" class="studio-info">
                  <dt>住所</dt>
                  <dd>
                    <span v-if="studioInfo.postal_code"> 〒{{ studioInfo.postal_code }} </span>
                    <br />
                    <span v-if="studioInfo.city">
                      {{ studioInfo.city }}
                    </span>
                    <span v-if="studioInfo.building">
                      {{ studioInfo.building }}
                    </span>
                    <br />
                    <span v-if="studioInfo.phone_number">
                      {{ studioInfo.phone_number }}
                    </span>
                    <br />
                  </dd>
                  <dt>営業時間／定休日</dt>
                  <dd v-if="studioInfo.opening_time">
                    <span v-if="studioInfo.opening_time">
                      平日：{{ studioInfo.opening_time.slice(0, 5) }} 〜
                    </span>
                    <span v-if="studioInfo.closing_time">
                      {{ studioInfo.closing_time.slice(0, 5) }}
                    </span>
                    <br />
                    <span v-if="studioInfo.alt_opening_time">
                      土・日・祝：{{ studioInfo.alt_opening_time.slice(0, 5) }} 〜
                    </span>
                    <span v-if="studioInfo.alt_closing_time">
                      {{ studioInfo.alt_closing_time.slice(0, 5) }}
                    </span>
                    <br />
                    <span> 定休日：{{ regularHolidayDisplay }} </span>
                    <br />
                  </dd>
                  <dt>アクセス</dt>
                  <dd>
                    {{ studioInfo.nearest_station }}
                  </dd>
                </dl> -->
              </div>
              <!-- <div v-if="location" class="p-reservation__map">
                <div id="map">
                  <iframe
                    title="map"
                    :src="location"
                    width="600"
                    height="450"
                    style="border:0;"
                    allowfullscreen=""
                    aria-hidden="false"
                    tabindex="0"
                  >
                  </iframe>
                </div>
              </div> -->
              <div class="p-reservation__form mt-20">
                <p>
                  本サービスより退会をご希望される場合は、<a href="#" @click="nextGoPage"> こちら</a>よりお申し込みをお願いいたします。
                </p>
              </div>
            </div>
            <button-registration
              :is-prev-button="false"
              :next-text="'変更する'"
              :handle-next-button="nextStep"
            >
            </button-registration>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import * as errorMessage from '../../../constant/text'
import * as regex from '../../../helpers/regex'
import InputEmail from '../../common/InputEmail'
import InputPhone from '../../common/InputPhone'
import { rulesValidateDataSyncProfile } from '../../../helpers/schemaValidate'
import { scrollToTop } from '../../../helpers'
import ButtonRegistration from '../../common/ButtonRegistration'
import { listDayJPName } from '../../../constant/listDayJPName'
import {
  getUser,
  updateUser,
  getStoreByState,
  getAllStore,
  getStore,
} from '../../../actions/requestMypage'
import InputSelectGroup from '../../common/InputSelectGroup'

export default {
  name: 'MypageProfileEdit',
  components: {
    ButtonRegistration,
    InputPhone,
    InputEmail,
    InputSelectGroup,
  },
  props: {
    currentStep: {
      type: String,
      default: '1',
    },
    isForceValidate: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
      default: '',
    },
    values: {
      type: Object,
      default: () => {
        return {
          firstName: '',
          lastName: '',
          firstNameKana: '',
          lastNameKana: '',
          gender: '',
          email: '',
          phone: '',
          year: '',
          month: '',
          date: '',
          studio: '',
          state: '',
        }
      },
    },
  },
  data() {
    return {
      listStudio: [],
      listAllStudio: [],
      listAllStudioInitial: [],
      studioInfo: {
        postal_code: '',
        city: '',
        building: '',
        opening_time: '',
        closing_time: '',
        alt_opening_time: '',
        alt_closing_time: '',
        nearest_station: '',
      },
      location: '',
      textErrors: {
        name: errorMessage.NAME_INVALID,
        nameKana: errorMessage.NAME_KANA_INVALID,
        phone: errorMessage.PHONE_INVALID,
        studio: errorMessage.STUDIO_INVALID,
        mypageInfoUpdateError: errorMessage.MYPAGE_INFO_UPDATE_INVALID,
      },
      errors: {
        firstName: '',
        lastName: '',
        firstNameKana: '',
        lastNameKana: '',
        email: '',
        gender: '',
        phone: '',
        date: '',
        studio: '',
      },
      regexKana: regex.patternKana,
      isInit: false,
    }
  },
  computed: {
    regularHolidayDisplay() {
      if (this.studioInfo.regular_holidays) {
        let regularHolidayArr =(this.studioInfo.regular_holidays)?  this.studioInfo.regular_holidays.split(',') :[],
            listName = [],
            strHoliday='';
        regularHolidayArr.map((value,index)=>{
            if(listDayJPName[value]){
                listName.push(listDayJPName[value])
            }
        })
        strHoliday = listName.join(', ')
        return strHoliday
      }
      return ''
    },
  },
  watch: {
    isForceValidate(newVal) {
      if (newVal && this.currentStep === '1') {
        this.nextStep()
      }
    },
    'values.studio'(newVal) {
      let studioSelected = this.listStudio.filter(studio => {
        return studio.id.toString() === newVal.toString()
      })
      if (studioSelected.length == 0) {
        studioSelected = this.listAllStudio.filter(studio => {
          return studio.id.toString() === newVal.toString()
        })
      }
      if (studioSelected.length > 0) {
        this.studioInfo = studioSelected[0]
        this.location = studioSelected[0]['map']
        sessionStorage.setItem('studioInfo', JSON.stringify(studioSelected[0]))
      } else {
        this.location = null
        this.studioInfo = {
          name: '',
          postal_code: '',
          city: '',
          building: '',
          opening_time: '',
          closing_time: '',
          alt_opening_time: '',
          alt_closing_time: '',
          nearest_station: '',
        }
      }
    },
  },
  async created() {
    this.$emit('startLoading')
    this.$authInfo.setToken(sessionStorage.getItem('token'))
    await getUser()
      .then(response => {
        const {
          data: { success },
        } = response
        if (success) {
          const {
            data: {
              data: {
                id,
                first_name,
                last_name,
                first_name_kana,
                last_name_kana,
                gender,
                email,
                phone_number,
                birthdate,
                store_id,
                prefecture_code,
              },
            },
          } = response
          let phoneNumber = ''
          let storeId = ''
          let userId = ''
          if (phone_number) {
            phoneNumber = phone_number.replaceAll('-', '')
          }
          if (id) {
            userId = id.toString()
          }
          if (store_id) {
            storeId = store_id.toString()
            getStore(store_id)
              .then(res => {
                const {
                  data: { success },
                } = res
                if (success) {
                  const {
                    data: { data },
                  } = res
                  this.studioInfo = data
                  this.location = this.studioInfo.map
                }
              })
              .catch(err => {
                return err
              })
          }
          this.$emit('onHandleChangeValue', 'userId', userId)
          this.$emit('onHandleChange', 'firstName', first_name || '')
          this.$emit('onHandleChange', 'lastName', last_name || '')
          this.$emit('onHandleChange', 'firstNameKana', first_name_kana || '')
          this.$emit('onHandleChange', 'lastNameKana', last_name_kana || '')
          this.$emit('onHandleChange', 'gender', gender === 'f' ? '2' : '1')
          this.$emit('onHandleChange', 'email', email || '')
          this.$emit('onHandleChange', 'phone', phoneNumber)
          this.$emit('onHandleChange', 'studio', storeId)
          this.$emit('onHandleChange', 'state', prefecture_code)
          if (birthdate) {
            const currentDate = birthdate.split('-')
            let month = ''
            let date = ''
            if (currentDate[1]) {
              month =
                parseInt(currentDate[1]) < 10 ? currentDate[1].replace('0', '') : currentDate[1]
            }
            if (currentDate[2]) {
              date =
                parseInt(currentDate[2]) < 10 ? currentDate[2].replace('0', '') : currentDate[2]
            }
            this.$emit('onHandleChange', 'year', currentDate[0] || '')
            this.$emit('onHandleChange', 'month', month)
            this.$emit('onHandleChange', 'date', date)
          }
        }
        this.isInit = true
      })
      .catch(err => {
        console.log(err)
        return err
      })

    await getAllStore()
      .then(res => {
        const {
          data: { success },
        } = res
        if (success) {
          this.listAllStudio = res.data.data
          this.listAllStudioInitial = res.data.data
        }
      })
      .catch(err => {
        return err
      })

    if (this.values.state) {
      getStoreByState(this.values.state)
        .then(res => {
          const {
            data: { success },
          } = res
          if (success) {
            this.listStudio = res.data.data
            const listStudioNotSelected = this.listAllStudioInitial.filter(studio => {
              let prefecture_code = studio.prefecture_code
              let state_id = this.values.state
              if (parseInt(prefecture_code.slice(3, 5)) != parseInt(state_id.slice(3, 5))) {
                return studio
              }
            })
            if (listStudioNotSelected.length > 0) {
              this.listAllStudio = listStudioNotSelected
            } else {
              this.listAllStudio = this.listAllStudioInitial
            }
          }
        })
        .catch(err => {
          return err
        })
    }
    this.$emit('endLoading')
  },
  methods: {
    onHandleChange(field, value) {
      this.$emit('onHandleChange', field, value)
    },
    onValidate(field, value) {
      this.errors[field] = value
      this.$emit('onValidateSwipe', false)
    },
    onValidateGender() {
      this.errors.gender = ''
    },
    nextGoPage() {
      this.$router.push({ name: 'mypage-profile-unsubscribe' })
    },
    nextStep() {
      rulesValidateDataSyncProfile
        .validate(this.values, { abortEarly: false })
        .then(() => {
          this.$emit('startLoading')
          if (this.userId) {
            const dataUpdate = {
              ...this.values,
            }
            updateUser(dataUpdate)
              .then(response => {
                const {
                  data: { success },
                } = response
                if (success) {
                  const {
                    data: { data },
                  } = response
                  sessionStorage.setItem('userInfo', JSON.stringify(data))
                  this.$emit('setUserId', data.id.toString())
                  this.$emit('nextToStep', '2')
                  this.$emit('endLoading')
                }
              })
              .catch(err => {
                if ((err.response.data.error)) {
                  this.errors.email = '入力された情報は変更できません。'
                  this.$toast.error('入力された情報は変更できません。')
                  this.$emit('endLoading')
                }
                if (Object.keys(err.response.data.errors)[0] === 'phone_number') {
                  this.errors.phone = 'すでに同じ電話番号(自宅)が登録されています。'
                }
                if (Object.keys(err.response.data.errors)[0] === 'email') {
                  this.errors.email = 'すでに登録。 ログインしてください。'
                }
                if (Object.keys(err.response.data.errors)[0]) {
                  this.$toast.error(this.textErrors.mypageInfoUpdateError)
                }
                this.$emit('endLoading')
              })
          }
        })
        .catch(err => {
          err.inner.forEach(error => {
            this.errors[error.path] = error.message
            this.$emit('endLoading')
          })
          scrollToTop()
        })
    },
  },
}
</script>

<style>
.v-application ul,
.v-application ol {
  padding-left: 0 !important;
}
.mt-60 {
  margin-top: 60px;
}
.disabled {
  pointer-events: none;
}
.disabled li select {
  background-color: #f5f5f5;
}
.disabled li:after {
  display: none;
  opacity: 0;
}
</style>
