import ReservationRepositoryFactory from '../service/Reservation/ReservationRepositoryFactory'
import { getDateTimeForReservation } from '../helpers/formatDate'

const reservationService = ReservationRepositoryFactory.get('reservation')

const createUser = (userCreated) => {
  const userCreatedRequest = {
    last_name: userCreated.lastName,
    first_name: userCreated.firstName,
    last_name_kana: userCreated.lastNameKana,
    first_name_kana: userCreated.firstNameKana,
    birthdate: userCreated.year + '-' + userCreated.month + '-' + userCreated.date,
    gender: userCreated.gender === '1' ? 'm' : 'f',
    email: userCreated.email,
    phone_number: userCreated.phone,
  }
  return reservationService.createUser(userCreatedRequest)
}

const updateUser = (userId, userUpdated, mode = 'basic') => {
  let userUpdatedRequest = {
    last_name: userUpdated.lastName,
    first_name: userUpdated.firstName,
    last_name_kana: userUpdated.lastNameKana,
    first_name_kana: userUpdated.firstNameKana,
    birthdate: userUpdated.year + '-' + userUpdated.month + '-' + userUpdated.date,
    gender: userUpdated.gender === '1' ? 'm' : 'f',
    email: userUpdated.email,
    phone_number: userUpdated.phone,
  }
  if (mode !== 'basic') {
    userUpdatedRequest = {
      postal_code: userUpdated.zipCode,
      prefecture_code: userUpdated.state,
      city: userUpdated.city,
      building: userUpdated.address,
      store_id: userUpdated.studio,
    }
  }

  return reservationService.updateUser(userId, userUpdatedRequest)
}

const requestRegisterReservation = (
  userId,
  studioId,
  timeVisited,
  isDesignCut,
  serviceId,
  reservationId,
  status,
  lastStep
) => {
  const reservationPayload = {
    user_id: userId,
    store_id: studioId,
    channel: 'web',
    start_date_option1: getDateTimeForReservation(timeVisited.firstTime),
    start_date_option2: !isDesignCut
      ? getDateTimeForReservation(timeVisited.secondTime) : null,
    services: serviceId,
    status: status,
    last_step: lastStep? lastStep : null
  }
  if (reservationId) {
    return reservationService.updateReservation(reservationId, reservationPayload)
  }
  return reservationService.registerReservation(reservationPayload)
}

const requestRegisterReservationCancel = (
  userId,
  storeId,
  timeVisited,
  isDesignCut,
  serviceId,
  reservationId,
  status,
  stylist
) => {
  const reservationPayload = {
    user_id: userId,
    store_id: storeId,
    channel: 'web',
    start_date_option1: timeVisited,
    start_date_option2: !isDesignCut
      ? getDateTimeForReservation(timeVisited.secondTime) : null,
    services: serviceId,
    status: status,
    stylists: stylist
  }
  if (reservationId) {
    return reservationService.updateReservation(reservationId, reservationPayload)
  }
  return reservationService.registerReservation(reservationPayload)

}
const requestSurvey = (userId, answer, surveyId) => {
  const payloadParams = {
    user_id: userId,
    tried_other_brands: answer.experience,
    interest_in_wig: answer.service.indexOf('1') !== -1 ? '1' : '0',
    interest_in_extension: answer.service.indexOf('2') !== -1 ? '1' : '0',
    interest_in_grow: answer.service.indexOf('3') !== -1 ? '1' : '0',
    interest_in_powder: answer.service.indexOf('4') !== -1 ? '1' : '0',
    interest_in_other: answer.service.indexOf('5') !== -1 ? '1' : '0',
    comment: answer.request,
  }
  if (surveyId) {
    return reservationService.updateSurvey(surveyId, payloadParams)
  }
  return reservationService.surveyReservation(payloadParams)
}

const requestReminders = (userId, answer, reminderId) => {
  const remindersParams = {
    user_id: userId,
    by_email: answer.contact === '1' ? '1' : '0',
    by_phone: answer.contact === '2' ? '1' : '0',
    type: answer.question,
    comment: answer.other,
  }
  if (reminderId) {
    return reservationService.updateReminder(reminderId, remindersParams)
  }
  return reservationService.reminderReservation(remindersParams)
}

const getTreatmentByCategory = (category, storeId) => {
  return reservationService.getTreatmentByCategory(category, storeId)
}

const getStudio = (value) => {
  return reservationService.getStudios(value)
}

const getStudioById = (id) => {
  return reservationService.getStudioById(id)
}

const getState = () => {
  return reservationService.getState()
}

const getReservationById = (reservationId) => {
  return reservationService.getReservationById(reservationId)
}

const getReminderById = (reminderId) => {
  return reservationService.getReminderById(reminderId)
}

const getSurveyById = (surveyId) => {
  return reservationService.getSurveyId(surveyId)
}

const getSlotsStore = (storeId, date, stylists) => {
  return reservationService.getSlotsStore(storeId, date, stylists)
}

const getPreSlotsStore = (storeId, date) => {
  return reservationService.getPreSlotsStore(storeId, date)
}

const getHolidaysByStoreId = (storeId) => {
  return reservationService.getHolidaysByStoreId(storeId)
}

export {
  createUser,
  updateUser,
  requestRegisterReservation,
  requestSurvey,
  requestReminders,
  getTreatmentByCategory,
  getStudio,
  getState,
  getStudioById,
  getReservationById,
  getSurveyById,
  getReminderById,
  getSlotsStore,
  getHolidaysByStoreId,
  requestRegisterReservationCancel,
  getPreSlotsStore
}
