<template>
  <section class="l-content-area">
    <div class="l-content l-content--reservation">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="c-title__bloc c-title__bloc--reservation">
            <h1 v-if="isReservation === 'isDetail' || isReservation === 'isProvisional'">
              ご来店予約
            </h1>
            <h1 v-else>キャンセル待ち</h1>
            <div class="c-btn c-btn--title-back"><a href="#" @click="prevToStep()">戻る</a></div>
          </section>

          <section class="p-bloc p-bloc__mypage">
            <div v-if="isReservation === 'isWaiting'" class="p-cancellation__attention">
              <p>
                キャンセル待ちの予約は、予約確定ではありません。ご希望の日時に空きが出た場合、メールにてご連絡いたします。
              </p>
            </div>
            <div
              v-if="
                (isReservation === 'isWaiting' && isReservationTodayOrBefore) ||
                  (isReservation === 'isDetail' && isReservationTodayOrBefore)
              "
              class="p-cancellation__attention"
            >
              <p>
                当日キャンセルご希望の場合、ご予約いただきました店舗に直接お問い合わせください。
              </p>
            </div>
            <div v-if="isReservation === 'isProvisional'" class="p-cancellation__attention">
              <p>
                予約確定ではございません。画面下部より「予約する」をクリックして予約の確定をしてください。
              </p>
              <p class="mt-5 mb-1">
                ※お願い※
              </p>
              <p>
                キャンセル待ちのお客様がいらっしゃいますので、仮押さえしているご予約がございましたらキャンセルをお願いいたします。
              </p>
            </div>
            <h2 v-if="isReservation === 'isDetail' || isReservation === 'isProvisional'">
              ご予約情報
            </h2>
            <h2 v-else>キャンセル待ち情報</h2>
            <div class="p-reservation__confirm">
              <div class="reservation-confirm__bloc">
                <div class="flex-wrapper">
                  <div class="row">
                    <div class="column label">
                      ご予約日時
                    </div>
                    <div class="column value">
                      {{ formatReservationDateTime(this.reservationDetail.start_date, this.reservationDetail.start_date_display_minutes_diff) }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="column label">
                      ご予約ID
                    </div>
                    <div class="column value">
                      {{ reservationDetail.code }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h2>施術内容</h2>
            <div class="p-reservation__confirm">
              <div class="reservation-confirm__bloc">
                <div class="flex-wrapper">
                  <div class="row">
                    <div class="column label">
                      施術
                    </div>
                    <div class="column value">
                      <span v-if="services_treatment.length >= 1">
                        <div v-for="service in services_treatment" :key="service._id">
                          {{ service.name }}
                        </div>
                      </span>
                      <span v-else>
                        選択なし
                      </span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="column label">
                      施術オプション
                    </div>
                    <div class="column value">
                      <span v-if="services_subtreatment.length >= 1">
                        <div v-for="service in services_subtreatment" :key="service._id">
                          {{ service.name }}
                        </div>
                      </span>
                      <span v-else>
                        選択なし
                      </span>
                    </div>
                  </div>
                  <div class="row">
                    <div class="column label">
                      担当者
                    </div>
                    <div class="column value">
                      {{ displayStylistName }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="column label">
                      施術時間目安
                    </div>
                    <div class="column value">
                      {{
                        reservationDetail.estimated_duration
                          ? '約' + parseInt(reservationDetail.estimated_duration) / 60 + '時間'
                          : ''
                      }}
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <h2 v-if="getUserComment">ご質問・ご要望</h2>
            <div v-if="getUserComment" class="p-reservation__confirm usercomment__section">
              <div class="reservation-confirm__bloc">
                <div class="flex-wrapper">
                  {{ getUserComment }}
                </div>
              </div>
            </div>

            <h2>ご来店店舗</h2>
            <div class="p-reservation__confirm studio">
              <div class="reservation-confirm__bloc">
                <dl>
                  <dd>{{ reservationDetail.store.name }}</dd>
                  <dt class="address">
                    〒{{ reservationDetail.store.postal_code }} {{ reservationDetail.store.city }}
                    {{ reservationDetail.store.building }}<br />
                  </dt>

                  <dt>
                    <br />
                    <img
                      src="../../assets/img/ui_parts/reservation_confirm_mail.png"
                      class="ico"
                    /><a href="mailto:ikebukuro@svenson.co.jp">
                      {{ reservationDetail.store.email }}
                    </a>
                  </dt>
                  <dt class="telnum">
                    <img
                      src="../../assets/img/ui_parts/reservation_confirm_tel.png"
                      class="ico"
                    /><a :href="`tel:${reservationDetail.store.phone_number}`">
                      {{ reservationDetail.store.phone_number }}
                    </a>
                  </dt>

                  <div v-if="location" class="p-reservation__map">
                    <div id="map">
                      <iframe
                        title="map"
                        :src="location"
                        width="600"
                        height="450"
                        style="border:0;"
                        allowfullscreen=""
                        aria-hidden="false"
                        tabindex="0"
                      >
                      </iframe>
                    </div>
                  </div>
                </dl>
              </div>
            </div>
            <button-registration
              v-if="isReservation === 'isDetail'"
              :prev-text-color="'#787878'"
              :prev-text="'戻る'"
              :is-disable="isReservationTodayOrBefore"
              :next-text="'ご予約キャンセル'"
              :handle-next-button="nextToStep"
              :handle-prev-button="prevToStep"
            >
            </button-registration>
            <button-registration
              v-if="isReservation === 'isWaiting'"
              :prev-text-color="'#787878'"
              :prev-text="'戻る'"
              :is-disable="isReservationTodayOrBefore"
              :next-text="'キャンセル待ち解除'"
              :handle-next-button="nextToStep"
              :handle-prev-button="prevToStep"
            >
            </button-registration>
            <button-registration
              v-if="isReservation === 'isProvisional'"
              :prev-text-color="'#787878'"
              :prev-text="'戻る'"
              :is-sub-button="true"
              :next-text="'予約する'"
              :sub-text="'キャンセル待ち取消'"
              :handle-next-button="reserveProvisionalReservation"
              :handle-sub-step="nextToStep"
              :handle-prev-button="prevToStep"
            >
            </button-registration>
          </section>
          <!-- .p-bloc__mypage -->
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import ButtonRegistration from '../common/ButtonRegistration.vue'
import { getDetailReservation, requestRegisterReservation } from '../../actions/requestMypage'
import { getDayOfWeekFromDateTime, getDateTimeFormatJapan, formatReservationDateTime } from '../../helpers/formatDate'
import moment from 'moment'

export default {
  name: 'MypageReservationDetailComponent',
  components: {
    ButtonRegistration,
  },
  data() {
    return {
      location: '',
      getDayOfWeekFromDateTime,
      getDateTimeFormatJapan,
      formatReservationDateTime,
      isReservation: '',
      reservationId: '',
      reservationDetail: {
        store: {},
      },
      services: [],
      services_treatment: [],
      services_subtreatment: [],
      stylists: [],
      status: 'pending',
      errorMessage: '',
      start_date_display_minutes_diff: 0,
      //hideBackButton: false,
      //hideReserveButton: false,
    }
  },
  computed: {
    isReservationTodayOrBefore() {
      const { reservationDetail } = this;

      if (!reservationDetail || !reservationDetail.start_date) {
        return false;
      }

      const currentDateTime = moment().startOf('day');
      const reservationStartDate = moment(reservationDetail.start_date).startOf('day');
      const differenceInDays = reservationStartDate.diff(currentDateTime, 'days');

      return differenceInDays < 1;
    },
    getUserComment() {
      if (this.reservationDetail.customer_comment) {
        return this.reservationDetail.customer_comment
      }
      return ''
    },
    displayStylistName() {
      // TODO: Make it into a helper function
      const { auto_assigned, free_status, gender_preference, stylists } = this.reservationDetail;
      if (auto_assigned && free_status) {
        switch (gender_preference) {
          case 'm':
            return '男性スタイリスト希望';
          case 'f':
            return '女性スタイリスト希望';
          default:
            return '指名なし';
        }
      }

      return (stylists && !free_status) ? stylists[0].name : '指名なし';
    },
  },
  created() {
    if (!sessionStorage.getItem('token')) {
      sessionStorage.setItem('from_email_link', 1)
      localStorage.setItem('reservationDetailId', this.$route.params.flag)
      this.$router.push({
        name: 'login',
      })
    } else if (parseInt(sessionStorage.getItem('booking')) !== 1) {
      this.$router.push({
        name: 'mypage',
      })
    } else {
      if (this.$route.params.flag) {
        this.reservationId = this.$route.params.flag
        localStorage.setItem('reservationDetailId', this.$route.params.flag)
        this.$authInfo.setToken(sessionStorage.getItem('token'))
        this.getDetailReservation()
      } else {
        this.$router.push({
          name: 'mypage',
        })
      }
    }
  },
  methods: {
    prevToStep() {
      localStorage.removeItem('reservationDetailId')
      localStorage.removeItem('reservationDetail')
      this.$router.push({
        name: 'mypage',
      })
    },
    nextToStep() {
      this.$router.push({
        name: 'reservation-cancel',
      })
    },
    reserveProvisionalReservation() {
      if (this.reservationDetail.id) {
        const isDesignCut = true
        const services = this.reservationDetail.services.map(item => {
          return item.id
        })
        const stylists = this.reservationDetail.stylists.map(item => {
          return item.id
        })
        this.$emit('startLoading')
        requestRegisterReservation({
          reservationId: this.reservationDetail.id,
          currentStep: 6,
          userId: this.reservationDetail.user.id,
          storeId: this.reservationDetail.store.id,
          timeVisited: this.reservationDetail.start_date_option1,
          isDesignCut,
          services,
          status: this.status,
          stylists,
          promote: 1,
        }
        )
          .then(res => {
            const {
              data: { success },
            } = res
            if (success === 1) {
              const {
                data: { data },
              } = res
              this.reservationCode = data.code
              sessionStorage.setItem('reservationCode', data.code)
              this.$router.push({
                name: 'mypage-reservation-reserve',
              })
              this.$emit('endLoading')
            }
          })
          .catch(error => {
            this.$emit('endLoading')
            this.$router.push({ name: 'mypageError' })
            return error
          })
      }
    },
    getDetailReservation() {
      this.$emit('startLoading')
      getDetailReservation(this.reservationId)
        .then(res => {
          const {
            data: { success },
          } = res
          if (success && success === 1) {
            const {
              data: { data },
            } = res
            this.reservationDetail = data
            this.services = data['services']
            for (var key in this.services) {
              if (this.services[key]['is_sub_treatment'] == false) {
                this.services_treatment.push(this.services[key])
              } else {
                this.services_subtreatment.push(this.services[key])
              }
            }
            if (data['stylists'].length > 0) {
              this.stylists = data['stylists'][0]
            }
            const {
              store: { map },
            } = data
            this.location = map || ''
            if (data.status === 'waiting') this.isReservation = 'isWaiting'
            if (data.status === 'pending') this.isReservation = 'isDetail'
            if (data.status === 'provisional') this.isReservation = 'isProvisional'
            localStorage.setItem('reservationDetail', this.isReservation)
            this.$emit('endLoading')
          }
        })
        .catch(err => {
          this.$emit('endLoading')
          console.log(err.response)
          if (err.response.status === 403) {
            this.$router.push({
              name: 'login',
            })
          }
          return err
        })
    },
  },
}
</script>
<style scoped>
.v-application ul,
.v-application ol {
  padding-left: 0 !important;
}

.v-application a {
  color: #2699fb;
}

.p-reservation__confirm {
  margin-top: 20px;
  padding: 20px 50px 20px;
}

.p-bloc__mypage h2:first-child {
  margin-top: 60px;
}

.p-bloc__mypage h2 {
  text-align: left;
  margin-top: 40px;
}

.flex-wrapper .row {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 60px;
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
  margin: 0;
  min-height: 60px;
  height: auto;
  text-align: justify;
}

.flex-wrapper .row:last-child {
  border-bottom: 0;
}

.column:first-child {
  min-width: 40%;
  width: 40%;
  padding-right: 20px;
}

.column:last-child {
  width: 60%;
}

.column {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.label {
  font-size: 14px;
  font-weight: normal;
}

.value {
  font-size: 16px;
  font-weight: bold;
}

.reservation-confirm__bloc dl dd + dt.address {
  padding-top: 10px;
}

.ico {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.studio {
  padding: 40px 50px 20px;
}

.p-reservation__map {
  padding-top: 10px;
}

.usercomment__section {
  min-height: 250px;
}

.usercomment__section div.flex-wrapper {
  text-align: justify;
}

@media screen and (max-width: 600px) {
  .p-reservation__confirm {
    margin-top: 20px;
    padding: 20px 40px 20px;
  }

  .flex-wrapper .row {
    flex-wrap: wrap;
    padding: 10px 0;
  }

  .column:first-child,
  .column:last-child {
    width: 100%;
    min-width: 100%;
  }
}

@media screen and (max-width: 375px) {
  .p-reservation__confirm {
    padding: 20px 20px 20px;
  }
}
</style>
