<template>
  <section class="l-content-area">
    <div class="l-content l-content--login">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="c-title__bloc c-title__bloc--login">
            <h1>パスワード再設定</h1>
          </section>

          <section class="p-bloc p-bloc__reminder">
            <div class="p-reminder__bloc">
              <p>新しいパスワードをご登録ください。</p>

              <div class="p-reminder__form">
                <dl class="c-form-parts">
                  <dt>パスワード</dt>
                  <dd>
                    <input-password
                      :value="password"
                      :error="error"
                      @onValidate="onValidate"
                      @onHandleChange="onHandleChange"
                    ></input-password>
                  </dd>
                </dl>
              </div>
            </div>
            <button-registration
              :is-prev-button="false"
              :next-text="'送信する'"
              :handle-next-button="nextToStep"
            >
            </button-registration>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import ButtonRegistration from '../common/ButtonRegistration'
  import { passwordSchema } from '../../helpers/schemaValidate'
  import InputPassword from '../common/InputPassword'

  export default {
    name: 'ReminderPassword',
    components: { InputPassword, ButtonRegistration },
    props: {
      password: {
        type: String,
        default: ''
      },
      isForceValidate: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        error: ''
      }
    },
    watch: {
      isForceValidate(newVal) {
        if (newVal) {
          this.nextToStep()
        }
      }
    },
    methods: {
      onHandleChange(value) {
        this.$emit('onHandleChange', value)
      },
      onValidate(field, message) {
        this.error = message
        this.$emit('onValidateSwipe', false)
      },
      nextToStep() {
        const values = {
          password: this.password
        }
        passwordSchema.validateAt('password', values)
        .then(() => {
          this.$emit('nextToStep')
        })
        .catch(error => {
          this.error = error.message
        })
      }
    }
  }
</script>

<style scoped>

</style>
