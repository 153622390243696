const listQuestions = [
  {
    id: '1',
    name: 'ご要望A'
  },
  {
    id: '2',
    name: 'ご要望B'
  },
  {
    id: '3',
    name: 'ご要望C'
  },
]

const listContactMethod = [
  {
    id: '1',
    value: 'メールを希望',
  },
  {
    id: '2',
    value: '電話を希望',
  },
]

const listExperience = [
  {
    id: '0',
    value: 'ご利用経験なし',
  },
  {
    id: '1',
    value: 'ご利用経験あり',
  },
]

export {
  listQuestions,
  listContactMethod,
  listExperience
}
