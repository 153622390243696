const formatTime = (time, isClose) => {
  const openTime = time.split(':')
  let hour = ''
  if(isClose) {
    hour = openTime[0] < 10 ? openTime[0].replace('0', '') : openTime[0] - 12
  } else {
    hour = openTime[0] < 10 ? "\xa0\xa0" + openTime[0].replace('0', '') : openTime[0]
  }
  return hour + ':' + openTime[1]
}

export {
  formatTime
}
