<template>
  <div class="p-agreement__bloc">
    <p>
      SVENSON店舗において、顧客IDをご発行済のお客様は、各規約をご確認いただき、
      アカウント作成をお願いいたします。
    </p>
    <h4>下記各規約をご確認いただき、規約に同意の上、会員登録へお進みください。</h4>
    <terms></terms>
    <h4>会員管理において、hubsynch株式会社が提供する「hubsynch」を利用します。</h4>
    <hub-synch-terms></hub-synch-terms>
  </div>
</template>

<script>
  import HubSynchTerms from './HubSynchTerms'
  import Terms from './Terms'
  export default {
    name: 'TermsAndCondition',
    components: {
      HubSynchTerms,
      Terms
    }
  }
</script>

<style scoped>

</style>
