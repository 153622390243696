import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import VueGoogleMaps from './plugins/gmap'
import Yubinbango from './plugins/zipcode'
import authInfo from './utils/authInfo'
import reservationService from './utils/reservationService'
import VueToast from 'vue-toast-notification'
import 'vue-toast-notification/dist/theme-sugar.css'
import VueGtm from '@gtm-support/vue2-gtm';

Vue.config.productionTip = false
Vue.prototype.$authInfo = authInfo
Vue.prototype.$reservation = reservationService
Vue.use(VueToast)
Vue.use(VueGtm, {
  id: 'GTM-TWV859K', 
  enabled: true, // Set to `true` to enable GTM in production environment
  debug: false, // Set to `true` for debugging messages
  loadScript: true, // Set to `false` if you want to manually load the GTM script
  vueRouter: router,
})

new Vue({
  router,
  store,
  vuetify,
  VueGoogleMaps,
  Yubinbango,
  render: (h) => h(App),
}).$mount('#app')
