import moment from 'moment'
import BaseUserRepository from '../BaseUserRepository'
import {
  me,
  store,
  mypageServices,
  reservation,
  mypageCompatibilityServices,
} from '../../constant/endpoint'

export default {
  getUser() {
    return BaseUserRepository.get(me)
  },
  unSubscribeUser() {
    return BaseUserRepository.delete(me)
  },
  updateUser(payload) {
    return BaseUserRepository.patch(`${me}`, payload)
  },
  updateUserStudio(payload) {
    return BaseUserRepository.patch(`${me}`, payload)
  },
  async getStore(id) {
    return await BaseUserRepository.get(`${store}/${id}`)
  },
  getAllStore() {
    return BaseUserRepository.get(`${store}?order_by=prefecture_code&order_direction=asc`)
  },
  getStoreByState(state) {
    return BaseUserRepository.get(
      `${store}?prefecture_code=${state}&order_by=prefecture_code&order_direction=asc`
    )
  },
  getServices(category = '', is_sub_treatment = '') {
    return BaseUserRepository.get(
      `${mypageServices}?category=${category}&is_sub_treatment=${is_sub_treatment}&is_in_registered_users_menu=1`
    )
  },
  getCompatibilityServices(services) {
    return BaseUserRepository.get(`${mypageCompatibilityServices}`, {
      params: { services: services },
    })
  },
  getAllReservationById() {
    return BaseUserRepository.get(`${reservation}?id`)
  },
  getReservationByStatus(status) {
    const format = 'YYYY-MM-DD'
    const current_date = moment(new Date()).format(format)
    return BaseUserRepository.get(`${reservation}`, {
      params:
      {
        status: status,
        start_date_from: current_date
      }
    })
  },
  registerReservation(payload) {
    return BaseUserRepository.post(reservation, payload)
  },
  updateReservation(reservationId, payload, step) {
    return BaseUserRepository.patch(`${reservation}/${reservationId}/step${step}`, payload)
  },
  getDetailReservation(reservationId) {
    return BaseUserRepository.get(`${reservation}/${reservationId}`)
  },
  cancelReservation(reservationId) {
    return BaseUserRepository.patch(`${reservation}/${reservationId}/cancel`)
  },
  getStylist(storeId, services) {
    return BaseUserRepository.get(`${store}/${storeId}/stylists`, {
      params: services,
    })
  },
  async getHolidaysByStoreId(storeId) {
    return await BaseUserRepository.get(`${store}/${storeId}/holidays`)
  },
  getSlotStore(reservationId, date, waiting, stylists) {
    let params = {
      date : date,
      waiting : waiting
    }
    params['stylist-ids'] = stylists
    return BaseUserRepository.get(`${reservation}/${reservationId}/slots`, {
      params: params,
    })

    /*return BaseUserRepository.get(
      `${reservation}/${reservationId}/slots?date=${date}&waiting=${waiting}&stylist-ids=${stylists}`
    )*/
  },
  getPreSlots(reservationId, date, waiting) {
    return BaseUserRepository.get(
      `${reservation}/${reservationId}/pre-slots?date=${date}&waiting=${waiting}`
    )
  },
}
