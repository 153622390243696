<template>
  <div :class="`input-area input-area--password ${error ? 'is-error' : ''}`">
    <input :id="id" :type="isVisible ? 'text' : 'password'" :name="name"
           :class="className" :placeholder="placeholder"
           autocomplete="on"
           @change="onHandleChange($event)"
           @blur="validatePassword()"
           @keyup="onHandleChange($event)"
    >
    <div v-if="isIcon" class="eye-btn" @click="toggleVisible()">
      <a :class="!isVisible ? 'is-show' : ''" href="#" aria-label="eye"></a>
    </div>
    <p :class="`error-txt ${error ? 'd-block' : ''}`">
      {{ error }}
    </p>
  </div>
</template>

<script>
  import * as schema from '../../helpers/schemaValidate'

  export default {
    name: 'InputPassword',
    props: {
      placeholder: {
        type: String,
        default: '＊＊＊＊＊＊＊＊＊＊',
      },
      value: {
        type: String,
        default: '',
      },
      className: {
        type: String,
        default: 'plchldr',
      },
      name: {
        type: String,
        default: 'password',
      },
      id: {
        type: String,
        default: 'password',
      },
      error: {
        type: String,
        default: '',
      },
      isIcon: {
        type: Boolean,
        default: false,
      },
      isVisible: {
        type: Boolean,
        default: false,
      },
    },
    watch: {
      value() {
        this.$emit('onValidate', this.name, '')
      },
    },
    methods: {
      validatePassword() {
        const values = {
          password: this.value,
        }
        schema.passwordSchema.validateAt('password', values)
          .then(() => {
            this.$emit('onValidate', this.name, '')
          })
          .catch(error => {
            this.$emit('onValidate', this.name, error.message)
          })
      },
      onHandleChange(e) {
        const { target: { value } } = e
        this.$emit('onHandleChange', value)
      },
      toggleVisible() {
        this.$emit('toggleVisible')
      },
    },
  }
</script>
