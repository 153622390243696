<template>
  <section class="l-content-area">
    <div class="l-content l-content--login">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="c-title__bloc c-title__bloc--login">
            <h1>パスワードの再設定を<span>承りました</span></h1>
          </section>

          <section class="p-bloc p-bloc__reminder">
            <div class="p-reminder__complete">
              <p>ご登録のメールアドレス宛にパスワード再設定用のURLをお送りいたしましたのでご確認ください</p>
              <p>パスワード再設定用のURLよりアクセスいただき、パスワードを再設定いただくと、再設定完了となります。</p>
              <button-registration
                :next-text="'ログイン'"
                :is-prev-button="false"
                :handle-next-button="onHandleNextButton"
              >
              </button-registration>
            </div>

            <div class="p-reminder__attention">
              <div class="reminder-attention">
                <h3>ご注意事項</h3>
                <p>
                  メールが届いていない場合は、ご登録いただいたメールアドレスが間違っているか、
                  何らかの原因で送信できていない可能性がございますので、
                  サポートセンターまでご連絡をお願いいたします。
                </p>
                <h3 class="no-icon">サポートセンター </h3>
                <a href="https://hubsynch.com/contactus/">https://hubsynch.com/contactus/</a>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import ButtonRegistration from '../common/ButtonRegistration'
  export default {
    name: 'ReminderComplete',
    components: { ButtonRegistration },
    methods: {
      onHandleNextButton() {
        this.$router.push({
          'name': 'login'
        })
      }
    }
  }
</script>

<style scoped lang="scss">
.p-reminder__attention .reminder-attention h3.no-icon {
  margin-top: 25px;
  &::before {
    content: none;
  }
}
</style>
