import BaseUserRepository from '../BaseUserRepository'
import BaseAnonymousRepository from '../BaseAnonymousRepository'
import { login, agreeService, logout, linkAccStep1, linkAccStep2, ping } from '../../constant/endpoint'


export default {
  checkMaintenanceMode() {
    return BaseAnonymousRepository.get(ping)
  },
  login(payload) {
    return BaseUserRepository.post(login, payload)
  },
  agreeService(payload) {
    return BaseUserRepository.post(agreeService, payload)
  },
  logout() {
    return BaseUserRepository.post(logout)
  },
  linkStep1(payload) {
    return BaseUserRepository.post(linkAccStep1, payload)
  },
  linkStep2(payload) {
    return BaseUserRepository.post(linkAccStep2, payload)
  }
}
