const anonymous = 'anonymous'
const user = 'user'
const registerUser = 'users'
const registerReservation = 'reservations'
const surveyReservation = 'surveys'
const reminderReservation = 'reminders'
const stores = 'stores'
const prefectures = 'prefectures'
const services = 'services'
const register = 'register'
const login = '/auth/login'
const logout = '/auth/logout'
const agreeService = '/api/agree'
const me = '/api/me'
const store = '/api/stores'
const linkAccStep1 = '/api/link/step1'
const linkAccStep2 = '/api/link/step2'
const mypageServices = '/api/services'
const mypageCompatibilityServices = '/api/services-compatibility-intersect'
const reservation = '/api/reservations'
const resetPassword = 'password-reset-email'
const ping = '/ping'

export {
  anonymous,
  user,
  registerReservation,
  registerUser,
  reminderReservation,
  surveyReservation,
  stores,
  prefectures,
  services,
  register,
  login,
  logout,
  agreeService,
  me,
  store,
  mypageServices,
  mypageCompatibilityServices,
  reservation,
  resetPassword,
  linkAccStep1,
  linkAccStep2,
  ping
}
