const EMAIL_INVALID = 'ご入力内容が正しくありません'
const EMAIL_REQUIRED = 'メールアドレスを入力してください'
const PASSWORD_INVALID = 'パスワードが違います'
const PASSWORD_REQUIRED = 'パスワードを入力してください'
const NAME_INVALID = '姓・名を正しく入力してください'
const NAME_KANA_INVALID = 'セイ・メイを正しく入力してください'
const PHONE_INVALID = '電話番号を入力してください'
const GENDER_INVALID = '性別を選択してください'
const DATE_INVALID = '生年月日を入力してください'
const ZIP_CODE_INVALID = '郵便番号を入力してください'
const STATE_INVALID = '都道府県を選択してください'
const CITY_INVALID = '市区町村を入力してください'
const ADDRESS_INVALID = '番地・ビル名を入力してください'
const STUDIO_INVALID = 'ご来店希望スタジオを選択してください'
const DATE_TIME_INVALID = '希望日時を選択してください'
const COMMENT_INVALID = '備考を入力してください'
const QUESTION_INVALID = 'ご要望を選択してください'
const CONTACT_INVALID = 'ご連絡手段を選択してください'
const EXPERIENCE_INVALID = '上記より一つ選択してください'
const SERVICE_INVALID = '選択してください'
const REQUEST_INVALID = 'ご質問・ご要望を入力してください'
const TIME_INVALID = '選択してください'
const TIME_SELECT_INVALID = 'ご希望時間を選択してください'
const BUILDING_INVALID = '建物を選択してください'
const PHONE_IS_NUMBER = '電話番号は数値のみを入力してください'
const ZIPCODE_IS_NUMBER = '郵便番号は数値のみを入力してください'
const ZIPCODE_IS_MIN7 = '郵便番号を正しく入力してください'
const CUSTOMER_NO_IS_NUMBER = '顧客番号を８桁で入力してください'
const EMOJI_KANJI_INVALID = '絵文字と外字は入力できません'
const RESET_EMAIL_INVALID = '入力されたメールアドレスは正しくありません'
const MYPAGE_INFO_UPDATE_INVALID = 'ご登録情報の変更はできません'

export {
  EMAIL_INVALID,
  EMAIL_REQUIRED,
  PASSWORD_INVALID,
  PASSWORD_REQUIRED,
  NAME_INVALID,
  NAME_KANA_INVALID,
  PHONE_INVALID,
  GENDER_INVALID,
  DATE_INVALID,
  ZIP_CODE_INVALID,
  STATE_INVALID,
  CITY_INVALID,
  ADDRESS_INVALID,
  STUDIO_INVALID,
  DATE_TIME_INVALID,
  COMMENT_INVALID,
  QUESTION_INVALID,
  CONTACT_INVALID,
  EXPERIENCE_INVALID,
  SERVICE_INVALID,
  REQUEST_INVALID,
  TIME_INVALID,
  TIME_SELECT_INVALID,
  BUILDING_INVALID,
  PHONE_IS_NUMBER,
  ZIPCODE_IS_NUMBER,
  ZIPCODE_IS_MIN7,
  CUSTOMER_NO_IS_NUMBER,
  EMOJI_KANJI_INVALID,
  RESET_EMAIL_INVALID,
  MYPAGE_INFO_UPDATE_INVALID,
}
