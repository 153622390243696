<template>
  <main-layout :is-show-login-header="isShowLoginHeader" :title="title" :is-loading="isLoading">
    <reservation-finish
      @setTitle="setTitle"
      @startLoading="startLoading"
      @endLoading="endLoading"
    >
    </reservation-finish>
  </main-layout>
</template>

<script>
  import MainLayout from '../components/layout/MainLayout'
  import ReservationFinish from '../components/reservation/ReservationFinish'

  export default {
    name: 'FinishReservation',
    components: {
      ReservationFinish,
      MainLayout,
    },
    data() {
      return {
        isShowLoginHeader: true,
        title: 'MEN\'S SVENSON｜ご来店予約',
        isLoading: false,
      }
    },
    methods: {
      setTitle(title) {
        this.title = title
      },
      startLoading() {
        this.isLoading = true
      },
      endLoading() {
        this.isLoading = false
      },
    },
  }
</script>
