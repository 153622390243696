<template>
  <v-card height="100%">
    <v-tabs
      v-show="false"
      v-model="currentStep"
      centered
      grow
      :hide-slider="true"
      :transition="false"
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#1"
             :align-with-title="true"
             :disabled="disabled.firstTab"
      >
        ステップ1
      </v-tab>
      <v-tab href="#2"
             :align-with-title="true"
             :disabled="disabled.secondTab"
      >
        ステップ2
      </v-tab>
      <v-tab href="#3"
             :align-with-title="true"
             :disabled="disabled.thirdStep"
      >
        ステップ3
      </v-tab>
      <v-tab href="#4"
             :align-with-title="true"
             :disabled="disabled.fourthStep"
      >
        ステップ4
      </v-tab>
      <v-tab href="#5"
             :align-with-title="true"
             :disabled="disabled.fivethStep"
      >
        ステップ5
      </v-tab>
      <v-tab href="#6"
             :align-with-title="true"
             :disabled="disabled.sixthStep"
      >
        ステップ6
      </v-tab>
      <v-tab href="#7"
             :align-with-title="true"
             :disabled="disabled.seventhStep"
      >
        ステップ7
      </v-tab>
      <v-tab href="#8"
             :align-with-title="true"
             :disabled="disabled.eighthStep"
      >
        ステップ8
      </v-tab>
      <v-tab href="#9"
             :align-with-title="true"
             :disabled="disabled.ninthStep"
      >
        ステップ9
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentStep" :touch="onTouchEvent()" :continuous="false">
      <v-tab-item id="1" value="1">
        <mypage-reservation-service
          :is-force-validate="isForceValidate"
          :is-disable-swipe="isDisabledSwipe"
          :current-step="currentStep"
          :check-push="checkPush"
          :services="values.services"
          :is-category-wig="values.isCategoryWig"
          :compatibility="values.compatibility"
          :list-all-services="values.listAllServices"
          @onHandleChange="onHandleChange"
          @onValidateSwipe="onValidateSwipe"
          @nextToStep="nextToStep"
          @prevToStep="prevToStep"
          @startLoading="startLoading"
          @endLoading="endLoading"
        >
        </mypage-reservation-service>
      </v-tab-item>
      <v-tab-item id="2" value="2">
        <mypage-reservation-treatment
          :category="values.category"
          :current-step="currentStep"
          :services="values.services"
          :selected-services="values.selectedServices"
          :selected-service-names="values.selectedServiceNames"
          :is-category-wig="values.isCategoryWig"
          :list-all-services="values.listAllServices"
          :compatibility="values.compatibility"
          :check-push="checkPush"
          :treatment="values.treatment"
          :list-time-male="values.listTimeMale"
          :list-time-female="values.listTimeFemale"
          :main-service-id="values.mainServiceId"
          @onHandleChange="onHandleChange"
          @prevToStep="prevToStep"
          @nextToStep="nextToStep"
          @startLoading="startLoading"
          @endLoading="endLoading"
        >
        </mypage-reservation-treatment>
      </v-tab-item>
      <v-tab-item id="3" value="3">
        <mypage-reservation-option
          :current-step="currentStep"
          :category="values.category"
          :services="values.services"
          :list-all-services="values.listAllServices"
          :selected-services="values.selectedServices"
          :selected-service-names="values.selectedServiceNames"
          :compatibility="values.compatibility"
          :check-push="checkPush"
          :has-option="hasOption"
          :option="values.option"
          :treatment="values.treatment"
          :store="values.store"
          :user-id="values.userId"
          :list-time-male="values.listTimeMale"
          :list-time-female="values.listTimeFemale"
          :reservation-id="values.reservationId"
          @onChangeStep="onChangeStep"
          @onHandleChange="onHandleChange"
          @prevToStep="prevToStep"
          @nextToStep="nextToStep"
          @startLoading="startLoading"
          @endLoading="endLoading"
        >
        </mypage-reservation-option>
      </v-tab-item>
      <v-tab-item id="4" value="4">
        <mypage-reservation-confirm
          :current-step="currentStep"
          :option="values.option"
          :estimated-duration="values.estimated_duration"
          :treatment="values.treatment"
          :services="values.services"
          :compatibility="values.compatibility"
          :list-all-services="values.listAllServices"
          :list-time-male="values.listTimeMale"
          :list-time-female="values.listTimeFemale"
          :is-female="values.isFemale"
          :check-push="checkPush"
          :has-option="hasOption"
          :store="values.store"
          @prevToStep="prevToStep"
          @nextToStep="nextToStep"
          @onChangeStep="onChangeStep"
          @onHandleChange="onHandleChange"
        >
        </mypage-reservation-confirm>
      </v-tab-item>
      <v-tab-item id="5" value="5">
        <mypage-reservation-person
          :current-step="currentStep"
          :is-staff="isStaff"
          :store-id="values.store"
          :user-id="values.userId"
          :services="values.services"
          :reservation-id="values.reservationId"
          @prevToStep="prevToStep"
          @nextToStep="nextToStep"
          @onChangeStep="onChangeStep"
          @onHandleChange="onHandleChange"
        >
        </mypage-reservation-person>
      </v-tab-item>
      <v-tab-item id="6" value="6">
        <mypage-reservation-staff v-if="isStaff"
                                  :current-step="currentStep"
                                  :store-id="values.store"
                                  :user-id="values.userId"
                                  :services="values.services"
                                  :reservation-id="values.reservationId"
                                  @prevToStep="prevToStep"
                                  @nextToStep="nextToStep"
                                  @onHandleChange="onHandleChange"
                                  @startLoading="startLoading"
                                  @endLoading="endLoading"
        >
        </mypage-reservation-staff>
        <!-- nextToPage added to replace nextToStep in this.$emit. Skips to next page. -->
        <mypage-reservation-time v-else
                                 :current-step="currentStep"
                                 :time-visited="values.timeVisited"
                                 :is-design-cut="true"
                                 :services="values.services"
                                 :store="values.store"
                                 :user-id="values.userId"
                                 :reservation-id="values.reservationId"
                                 :stylist="values.stylist"
                                 :status="values.status"
                                 :estimated-duration="values.estimated_duration"
                                 @prevToStep="prevToStep"
                                 @nextToStep="nextToStep"
                                 @nextToPage="nextToPage"
                                 @onHandleChange="onHandleChangeTime"
                                 @onChangeStep="onChangeStep"
                                 @setReservationId="setReservationId"
                                 @startLoading="startLoading"
                                 @endLoading="endLoading"
                                 @onChangeStatus="onHandleChange"
        >
        </mypage-reservation-time>
      </v-tab-item>
      <v-tab-item id="7" value="7">
        <!-- nextToPage added to replace nextToStep in this.$emit. Skips to next page. -->
        <mypage-reservation-time v-if="isStaff"
                                 :current-step="currentStep"
                                 :time-visited="values.timeVisited"
                                 :is-design-cut="true"
                                 :services="values.services"
                                 :store="values.store"
                                 :user-id="values.userId"
                                 :gender-preference="values.genderPreference"
                                 :reservation-id="values.reservationId"
                                 :stylist="values.stylist"
                                 :status="values.status"
                                 :estimated-duration="values.estimated_duration"
                                 @prevToStep="prevToStep"
                                 @nextToStep="nextToStep"
                                 @nextToPage="nextToPage" 
                                 @onHandleChange="onHandleChangeTime"
                                 @onChangeStep="onChangeStep"
                                 @setReservationId="setReservationId"
                                 @startLoading="startLoading"
                                 @endLoading="endLoading"
                                 @onChangeStatus="onHandleChange"
        >
        </mypage-reservation-time>
        <mypage-reservation-requests v-else
                                     :current-step="currentStep"
                                     :time-visited="values.timeVisited"
                                     :is-design-cut="true"
                                     :services="values.services"
                                     :store="values.store"
                                     :user-id="values.userId"
                                     :reservation-id="values.reservationId"
                                     :stylist="values.stylist"
                                     :status="values.status"
                                     :values="values"
                                     :gender-preference="values.genderPreference"
                                     @onHandleChange="onHandleChange"
                                     @prevToStep="prevToStep"
                                     @nextToStep="nextToStep"
                                     @onChangeStep="onChangeStep"
                                     @setReservationId="setReservationId"
                                     @nextToPage="nextToPage"
                                     @startLoading="startLoading"
                                     @endLoading="endLoading"
                                     @onChangeStatus="onHandleChange"
        >
        </mypage-reservation-requests>
      </v-tab-item>
      <v-tab-item id="8" value="8">
        <mypage-reservation-requests
          :current-step="currentStep"
          :time-visited="values.timeVisited"
          :is-design-cut="true"
          :services="values.services"
          :store="values.store"
          :user-id="values.userId"
          :reservation-id="values.reservationId"
          :stylist="values.stylist"
          :status="values.status"
          :values="values"
          :gender-preference="values.genderPreference"
          @onHandleChange="onHandleChange"
          @prevToStep="prevToStep"
          @nextToStep="nextToStep"
          @onChangeStep="onChangeStep"
          @setReservationId="setReservationId"
          @nextToPage="nextToPage"
          @startLoading="startLoading"
          @endLoading="endLoading"
          @onChangeStatus="onHandleChange"
        >
        </mypage-reservation-requests>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
import MypageReservationService from './MyPageReservationService.vue'
import MypageReservationTreatment from './MypageReservationTreatment.vue'
import MypageReservationOption from './MypageReservationOption.vue'
import MypageReservationConfirm from './MypageReservationConfirm.vue'
import MypageReservationPerson from './MypageReservationPerson.vue'
import MypageReservationStaff from './MypageReservationStaff.vue'
import MypageReservationTime from './MypageResevationTime.vue'
import MypageReservationRequests from './MypageResevationRequests.vue'
  export default {
    name: 'MypageReservationComponent',
    components: {
      MypageReservationService,
      MypageReservationTreatment,
      MypageReservationOption,
      MypageReservationConfirm,
      MypageReservationPerson,
      MypageReservationStaff,
      MypageReservationTime,
      MypageReservationRequests,
    },
    data() {
      return {
        currentStep: '1',
        isForceValidate: false,
        isDisabledSwipe: false,
        isDesignCut: false,
        isStaff: false,
        checkPush: true,
        hasOption: true,
        disabled: {
          firstTab: false,
          secondTab: true,
          thirdStep: true,
          fourthStep: true,
          fiveStep: true,
          sixthStep: true,
          seventhStep: true,
          eighthStep: true,
        },
        values: {
          requestMsg:'',
          estimated_duration:'',
          reservationId: '',
          isCategoryWig: [],
          listAllServices:[],
          compatibility:[],
          category: '',
          store: '1',
          treatment: [],
          option: [],
          services: [],
          selectedServices: [],
          selectedServiceNames: [],
          userId: '86',
          isFemale: '',
          mainServiceId: '',
          stylist: [],
          stylistName: '',
          genderPreference: '',
          status: '',
          listTimeMale: [],
          listTimeFemale: [],
          timeVisited: {
            firstTime: {
              date: '',
              hour: '',
              minute: '',
            },
            secondTime: {
              date: '',
              hour: '',
              minute: '',
            },
          },
        },
      }
    },
    watch: {
      currentStep(newVal) {
        window.scrollTo(0, 0)
        switch (newVal) {
          case '2':
            this.disabled.secondTab = false
            break
          case '3':
            this.disabled.thirdStep = false
            break
          case '4':
            this.disabled.fourthStep = false
            break
          case '5':
            this.disabled.fivethStep = false
            break
          case '6':
            this.disabled.sixthStep = false
            break
          case '7':
            this.disabled.seventhStep = false
            break
          case '8':
            this.disabled.eighthStep = false
            break
        }
      },
    },
    created() {

      if (parseInt(sessionStorage.getItem('booking')) !== 1) {
        this.$router.push({
          name: 'mypage'
        })
      } else {
        this.$authInfo.setToken(sessionStorage.getItem('token'))
        if(sessionStorage.getItem('userId')) {
          this.values.userId = sessionStorage.getItem('userId')
        }
        if(this.$route.params.studioInfo) {
          this.values.store = this.$route.params.studioInfo.id.toString()
        } else if(sessionStorage.getItem('studioInfo')) {
          const storeInfo = JSON.parse(sessionStorage.getItem('studioInfo'))
          if(storeInfo?.id)
          this.values.store = storeInfo.id.toString()
        }
        this.$emit('setTitle', 'MEN\'S SVENSON｜ご来店予約')
        if (this.$reservation.getCurrentStep()) {
          this.values = this.$reservation.getValuesReservation()
          this.currentStep = this.$reservation.getCurrentStep()
          this.isStaff = this.$reservation.getIsStaff()
        }
      }

    },
    methods: {
      assignSwipeLeft() {
        if (!this.isDisabledSwipe) {
          this.isForceValidate = true
        }
      },
      assignSwipeRight() {
        this.isForceValidate = false
        if (!this.isDisabledSwipe) {
          this.prevToStep()
        }
      },
      onChangeStep(field, value) {
        this[field] = value
      },
      onHandleChange(field, value) {
        this.values[field] = value
      },
      onHandleChangeTime(field, value) {
        this.values.timeVisited[field] = value
      },
      onTouchEvent() {
        return {
          left: () => this.assignSwipeLeft(),
          right: () => this.assignSwipeRight(),
        }
      },
      onValidateSwipe(value) {
        this.isForceValidate = value
      },
      nextToStep() {
        const nextStep = parseInt(this.currentStep) + 1
        this.currentStep = nextStep.toString()
      },
      prevToStep(value) {
        let prevStep
        if (value === 'reselect'){
          prevStep = parseInt(this.currentStep) - 3
        }
        else if(value === 'confirm'){
          prevStep = 4
        }
        else {
          prevStep = parseInt(this.currentStep) - 1
        }
        this.currentStep = prevStep.toString()
      },
      setUserId(value) {
        this.values.userId = value
      },
      setReservationId(value) {
        this.values.reservationId = value
      },
      nextToPage() {
        this.$reservation.setIsStaff(this.isStaff)
        this.$reservation.setCurrentStep(this.currentStep)
        this.$reservation.setValuesReservation(this.values)
        this.$router.push({
          name: 'mypage-reservation-reconfirm',
        })
      },
      startLoading() {
        this.$emit('startLoading')
      },
      endLoading() {
        this.$emit('endLoading')
      }
    }
  }
</script>
<style>
  .v-window.v-item-group.theme--light.v-tabs-items {
    height: 100%;
  }
</style>

