<template>
  <section class="l-content-area">
    <div class="l-content l-content--login">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="c-title__bloc c-title__bloc--login">
            <h1>ログイン</h1>
          </section>

          <section v-if="displayUpcomingMaintenanceMessage" class="p-bloc maintenance__bloc">
            <p class="maintenance__title">メンテナンスのお知らせ</p>
            <div>
              <p v-html="upcomingMaintenanceMessage"></p>
            </div>
          </section>

          <section v-if="displayHolidayMessage" class="p-bloc holiday__bloc">
            <div>
              <p v-html="holidayMessage"></p>
            </div>
          </section>

          <form>
            <section class="p-bloc p-bloc__login">
              <div class="p-login__bloc">
                <div class="p-login__form">
                  <dl class="c-form-parts">
                    <dt>メールアドレス</dt>
                    <dd>
                      <input-login
                        :value="values.email"
                        :error="errors.email"
                        :placeholder="'svenson@svenson.co.jp'"
                        @onHandleChange="onHandleChangeEmail"
                        @onValidate="onValidateEmail"
                      >
                      </input-login>
                    </dd>

                    <dt>パスワード</dt>
                    <dd>
                      <input-password
                        :placeholder="'＊＊＊＊＊＊＊＊＊＊'"
                        :error="errors.password"
                        :value="values.password"
                        :is-icon="true"
                        :is-visible="isVisible"
                        @onHandleChange="onHandleChangePassword"
                        @toggleVisible="toggleVisible"
                        @onValidate="onValidatePassword"
                      >
                      </input-password>
                    </dd>
                  </dl>
                </div>
                <p class="registration">
                  アカウントをお持ちでない場合は、オンラインストアより<br />
                  アカウント作成をお願いしております。<br />
                  オンラインストアの「
                  <a href="#" @click="redirectHubsynchRegisterPage()">新規会員登録はこちら</a>
                  」から<br />
                  アカウント登録をお願いいたします。
                </p>
                <button-submit :title="'ログイン'" :handle-click="validateDataForm"></button-submit>
                <p class="reminder">
                  パスワードをお忘れの方は<br />
                  <a href="#" @click="redirectReminderPage()">こちら</a>
                  より再設定をお願いいたします。
                </p>
              </div>
            </section>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ButtonSubmit from '../common/ButtonSubmit'
import * as yup from 'yup'
import * as regex from '../../helpers/regex'
import * as errorString from '../../constant/text'
import InputLogin from '../common/InputLogin'
import InputPassword from '../common/InputPassword'
import { login, checkMaintenanceMode } from '../../actions/requestLogin'

const loginSchema = yup.object().shape({
  email: yup
    .string()
    .required(errorString.EMAIL_INVALID)
    .matches(regex.patternEmail, errorString.EMAIL_INVALID)
    .email(errorString.EMAIL_INVALID),
  password: yup.string().required(errorString.PASSWORD_INVALID),
})

export default {
  name: 'LoginComponent',
  components: {
    InputPassword,
    InputLogin,
    ButtonSubmit,
  },
  data() {
    return {
      values: {
        email: '',
        password: '',
      },
      isVisible: false,
      // Update dates manually (use format: 'yyyy/mm/dd')
      holidayDateFrom: '2023/12/31',
      holidayDateTo: '2024/01/05',
      inquiriesDate: '2024/01/06',
      errors: {
        email: '',
        password: '',
      },
    }
  },
  created() {
    checkMaintenanceMode();
  },
  computed: {
    displayHolidayMessage() {
      const today = new Date().setHours(0, 0, 0, 0)
      const holidayDateFrom = new Date(this.holidayDateFrom).setHours(0, 0, 0, 0)
      const holidayDateTo = new Date(this.holidayDateTo).setHours(23, 59, 59, 999)
      const daysBeforeHoliday = new Date(holidayDateFrom)

      // 20 days before, adjustable
      daysBeforeHoliday.setDate(daysBeforeHoliday.getDate() - 20)

      return today >= daysBeforeHoliday && today < holidayDateTo
    },
    holidayMessage() {
      return `
      年末年始も予約システムは通常どおりご利用いただけます。<br>
      店舗業務は${this.formatDate(this.holidayDateFrom)} ~ ${this.formatDate(this.holidayDateTo)}
      まで休業となりますので、お問い合わせ等につきましては、${this.formatDate(
        this.inquiriesDate
      )}より対応させていただきます。<br> <br>
      ご不便をおかけしますが、ご了承の程、よろしくお願い申し上げます。
      `
    },
    displayUpcomingMaintenanceMessage() {
      const now = new Date().getTime();

      const maintenanceDateStart = new Date('2024/07/25').setHours(0, 0, 0, 0) // 25th July 2024
      const maintenanceDateEnd = new Date('2024/07/25').setHours(6, 0, 0, 0) // 25th July 2024
      // const daysBeforeMaintenance = new Date(maintenanceDateStart)
      // 5 days before, adjustable
      // daysBeforeMaintenance.setDate(daysBeforeMaintenance.getDate() - 5)

      return now < maintenanceDateEnd;
    },
    upcomingMaintenanceMessage() {
      return `システムメンテナンスのため、サイトを以下の時間帯に停止させていただきます。ご不便をおかけいたしますが、何卒ご理解いただきますよう、お願い申しあげます。<br> <br>
      2024年7月25日 (木) 0:00 ~ 6:00`
    },
    isProductionEnvironment(){
      return process.env.VUE_APP_NODE_ENV === 'production';
    },
    registrationLink() {
      return this.isProductionEnvironment ? 'https://www.mens-svenson.net/mypage/login/' : 'https://dev.mens-svenson.net/mypage/login/'
    },
  },
  methods: {
    toggleVisible() {
      this.isVisible = !this.isVisible
    },
    formatDate(date) {
      const daysOfWeek = ['日', '月', '火', '水', '木', '金', '土']
      const [year, month, day] = date.split('/')

      const dayOfWeekNumber = new Date(`${year}-${month}-${day}`).getDay()
      const japaneseDayOfWeek = daysOfWeek[dayOfWeekNumber]

      return `${year}年${month}月${day}日(${japaneseDayOfWeek})`
    },

    validateDataForm() {
      loginSchema
        .validate(this.values, { abortEarly: false })
        .then(() => {
          this.startLoading()
          login(this.values)
            .then(res => {
              const {
                data: { success },
              } = res
              if (success && success === 1) {
                const {
                  data: {
                    data: { token, agree, linked, booking },
                  },
                } = res

                this.$authInfo.setUser(this.values)
                sessionStorage.setItem('user', JSON.stringify(this.values))
                if (token) {
                  this.$authInfo.setToken(token)
                  sessionStorage.setItem('token', token)
                }
                if (booking) {
                  this.$authInfo.setBooking(booking)
                  sessionStorage.setItem('booking', 1)
                }
                if (linked) {
                  this.$authInfo.setLinked(linked)
                  sessionStorage.setItem('linked', 1)
                }
                if (!agree) {
                  this.$router.push({
                    name: 'other-services',
                  })
                } else if (!linked) {
                  this.$router.push({
                    name: 'account',
                  })
                } else {
                  if (parseInt(sessionStorage.getItem('from_email_link')) === 1) {
                    this.$router.push({
                      name: 'reservation-detail',
                      params: { flag: localStorage.getItem('reservationDetailId') },
                    })
                  } else {
                    this.$router.push({
                      name: 'mypage',
                    })
                  }
                }
              }
              this.endLoading()
            })
            .catch(err => {
              this.endLoading()
              // redirect to account freeze page when api respone 403 (Forbidden)
              if (err.response && err.response.status === 403) {
                this.$router.push({ name: 'account-freeze' })
              }
              if (err.response) {
                const {
                  response: {
                    data: {
                      error: { email, password },
                    },
                  },
                } = err
                if (email) {
                  if (
                    email.includes('ログインIDかパスワードが正しくない')
                  ) {
                    this.errors.email = 'ログインに失敗しました。'
                  } else {
                    this.errors.email = email
                  }
                } else if (password) {
                  this.errors.password = password
                } else {
                  this.$toast.error('しばらくしてから再度ログインしてください。')
                }
              } else {
                this.$toast.error('しばらくしてから再度ログインしてください。')
              }
            })
        })
        .catch(err => {
          err.inner.forEach(error => {
            this.errors[error.path] = error.message
          })
        })
    },
    onValidateEmail(field, message) {
      this.errors.email = message
    },
    onValidatePassword(field, message) {
      this.errors.password = message
    },
    onValidate(value) {
      this.errors = value
    },
    onHandleChangeEmail(field, value) {
      this.values.email = value
    },
    onHandleChangePassword(value) {
      this.values.password = value
    },
    redirectReminderPage() {
      this.$router.push({
        name: 'reset-password',
      })
    },
    redirectHubsynchRegisterPage() {
      window.open(this.registrationLink, '_blank')
    },
    startLoading() {
      this.$emit('startLoading')
    },
    endLoading() {
      this.$emit('endLoading')
    },
  },
}
</script>

<style scoped></style>
