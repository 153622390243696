<template>
  <section class="l-content-area">
    <div class="l-content l-content--reservation">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="c-title__bloc c-title__bloc--reservation">
            <h1 v-if="values.status === 'pending'">ご予約内容の確認</h1>
            <h1 v-else>キャンセル待ち内容のご確認</h1>
            <div class="c-btn c-btn--title-back"><a href="#" @click="prevToStep()">戻る</a></div>
          </section>
          <p class="reconfirm__description">
            まだ予約は完了しておりません。画面下部の「予約する」ボタンを押してください。
          </p>

          <section class="p-bloc p-bloc__reservation">
            <h2>ご予約時間</h2>
            <div class="p-reservation__confirm">
              <div class="reservation-confirm__bloc">
                <div class="flex-wrapper">
                  <div class="row">
                    <div class="column label">
                      ご予約日時
                    </div>
                    <div class="column value">
                      {{ formatReservationDateTime(getFullFirstTime) }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="column label">
                      店舗情報
                    </div>
                    <div class="column value">
                      {{ storeName }}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h2>施術内容</h2>
            <div class="p-reservation__confirm">
              <div class="reservation-confirm__bloc">
                <div class="flex-wrapper">
                  <div class="row">
                    <div class="column label">
                      施術
                    </div>
                    <div class="column value">
                      <div v-if="getListTreatmentName">
                        <span class="list" v-for="listName in getListTreatmentName" :key="listName"
                          >• {{ listName }}</span
                        >
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="column label">
                      オプション
                    </div>
                    <div class="column value">
                      <div v-if="getListSubTreatmentName">
                        <span
                          class="list"
                          v-for="listSubName in getListSubTreatmentName"
                          :key="listSubName"
                          >• {{ listSubName }}</span
                        >
                      </div>
                      <div v-else>
                        <span class="list">選択なし</span>
                      </div>
                    </div>
                  </div>
                  <div class="row">
                    <div class="column label">
                      担当者
                    </div>
                    <div class="column value">
                      {{ getStylistName }}
                    </div>
                  </div>
                  <div class="row">
                    <div class="column label">
                      施術時間目安
                    </div>
                    <div class="column value">{{ getEstimatedTime }}時間</div>
                  </div>
                </div>
              </div>
            </div>
            <h2 v-if="getUserComment">ご質問・ご要望</h2>
            <div v-if="getUserComment" class="p-reservation__confirm usercomment__section">
              <div class="reservation-confirm__bloc">
                <div class="flex-wrapper">
                  {{ getUserComment }}
                </div>
              </div>
            </div>
          </section>
          <!-- .p-bloc__reservation -->

          <button-registration
            :prev-text="'戻る'"
            :next-text="getButtonText"
            :handle-prev-button="prevToStep"
            :handle-next-button="nextToStep"
            :prev-text-color="'#787878'"
          >
          </button-registration>
        </div>
      </div>
    </div>
    <!-- .l-content--reservation -->
  </section>
</template>
<script>
import ButtonRegistration from '../../common/ButtonRegistration'
import { scrollToTop } from '../../../helpers/index'
import { formatReservationDateTime } from '../../../helpers/formatDate'
import { getStore, requestRegisterReservation } from '../../../actions/requestMypage'
import moment from 'moment'

export default {
  name: 'MypageReservationReconfirm',
  components: {
    ButtonRegistration,
  },
  data() {
    return {
      storeName: '',
      values: {
        listTimeMale: [],
        listTimeFemale: [],
        reservationId: '',
        category: '',
        store: '',
        treatment: '',
        option: '',
        services: [],
        userId: '',
        stylist: [],
        stylistName: '',
        status: '',
        timeVisited: {
          firstTime: {
            date: '',
            hour: '',
            minute: '',
          },
          secondTime: {
            date: '',
            hour: '',
            minute: '',
          },
        },
      },
      isModalVisible: false,
      errorMessage: '',
      formatReservationDateTime,
    }
  },
  computed: {
    getFullFirstTime() {
      const firstTime = this.values.timeVisited.firstTime
      return moment(firstTime.date).format('YYYY-MM-DD') + ' ' + firstTime.hour + ':' + firstTime.minute
    },
    getListTreatmentName() {
      if (this.values.treatment != '') {
        const listTreatmentName = this.values.treatment
        return listTreatmentName
      }
      return '選択なし'
    },
    getListSubTreatmentName() {
      if (this.values.option != '') {
        const listSubTreatmentName = this.values.option
        return listSubTreatmentName
      }
      return ''
    },
    getEstimatedTime() {
      let estimate_time = parseInt(
        this.values.estimated_duration ? this.values.estimated_duration : ''
      )
      return estimate_time === '' ? '選択なし' : estimate_time / 60
    },
    getTimeMale() {
      if (this.values.listTimeMale) {
        let totalTimeMale = 0
        this.values.listTimeMale.map(time => {
          totalTimeMale += parseInt(time)
        })
        return totalTimeMale / 60
      }
      return 0
    },
    getTimeFemale() {
      if (this.values.listTimeFemale) {
        let totalTimeFemale = 0
        this.values.listTimeFemale.map(time => {
          totalTimeFemale += parseInt(time)
        })
        return totalTimeFemale / 60
      }
      return 0
    },
    getStylistName() {
      if (this.values.stylistName) {
        return this.values.stylistName;
      }

      switch (this.values.genderPreference) {
        case 'm':
          return '男性スタイリスト希望';
        case 'f':
          return '女性スタイリスト希望';
        default:
          return '指名なし';
      }
    },
    getUserComment() {
      if (this.values.requestMsg != '') {
        return this.values.requestMsg
      }
      return ''
    },

    getButtonText() {
      if (this.values.status == 'waiting') {
        return 'キャンセル待ちする'
      }
      return '予約する'
    },
  },
  created() {
    scrollToTop()
    if (!this.$reservation.getCurrentStep()) {
      this.$router.push({
        name: 'mypage',
      })
    }
    const valuesReservation = this.$reservation.getValuesReservation()
    this.values = valuesReservation
    if (this.values.store) {
      getStore(this.values.store).then(res => {
        const {
          data: { success },
        } = res
        if (success && success === 1) {
          this.storeName = res.data.data.name
        }
      })
    }
  },
  methods: {
    nextToStep() {
      const reservationValues = this.$reservation.getValuesReservation()
      if (reservationValues && reservationValues.reservationId) {
        const isDesignCut = true
        this.$emit('startLoading')
        requestRegisterReservation({
          reservationId: reservationValues.reservationId,
          currentStep: 6,
          userId: reservationValues.userId,
          storeId: reservationValues.store,
          timeVisited: reservationValues.timeVisited,
          isDesignCut: isDesignCut,
          services: reservationValues.services,
          status: reservationValues.status,
          stylist: reservationValues.stylist,
          genderPreference: reservationValues.genderPreference,
          waiting: reservationValues.waiting,
          requestMsg: reservationValues.requestMsg,
        })
          .then(res => {
            this.$emit('endLoading')
            const {
              data: { success },
            } = res
            if (success === 1) {
              this.$router.replace({
                name: 'mypage-reservation-finish',
              })
              sessionStorage.removeItem('stylistName')
            }
          })
          .catch(error => {
            this.$emit('endLoading')
            if (error) {
              // this.errorMessage = handleErrors(error.response)
              this.$router.push({ name: 'mypageError' })
            }
          })
      }
    },
    prevToStep() {
      this.$router.push({
        name: 'mypage-reservation',
      })
    },
  },
}
</script>
<style scoped>
.v-application ul,
.v-application ol {
  padding-left: 0 !important;
}

.v-application a {
  color: #0a005a;
}

.p-reservation__confirm {
  margin-top: 20px;
  padding: 20px 50px 20px;
}

.p-bloc__reservation h2:first-child {
  margin-top: 60px;
}

.p-bloc__reservation h2 {
  text-align: left;
  margin-top: 40px;
}

.flex-wrapper .row {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 60px;
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
  margin: 0;
  min-height: 60px;
  height: auto;
  text-align: justify;
}

.flex-wrapper .row:last-child {
  border-bottom: 0;
}

.column:first-child {
  min-width: 40%;
  width: 40%;
  padding-right: 20px;
}

.column:last-child {
  width: 60%;
}

.column {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.label {
  font-size: 14px;
  font-weight: normal;
}

.value {
  font-size: 16px;
  font-weight: bold;
}

.value span.list {
  display: block;
  line-height: 28px;
}

.reservation-confirm__bloc dl dd + dt.address {
  padding-top: 10px;
}
.reconfirm__description {
  text-align: center;
  border: 2px solid red;
  color: red;
  padding: 10px;
  font-weight: bold;
}
.usercomment__section {
  min-height: 250px;
  word-wrap: break-word;
}

.usercomment__section div.flex-wrapper {
  text-align: justify;
}

.ico {
  width: 20px;
  height: 20px;
  margin-right: 10px;
}

.studio {
  padding: 40px 50px 20px;
}

.p-reservation__map {
  padding-top: 10px;
}

@media screen and (max-width: 600px) {
  .p-reservation__confirm {
    margin-top: 20px;
    padding: 20px 40px 20px;
  }

  .flex-wrapper .row {
    flex-wrap: wrap;
    padding: 10px 0;
  }

  .column:first-child,
  .column:last-child {
    width: 100%;
    min-width: 100%;
  }
}

@media screen and (max-width: 375px) {
  .p-reservation__confirm {
    padding: 20px 20px 20px;
  }
}
</style>
