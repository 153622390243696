const listDesignCut = [
  {
    id: '25',
    name: 'デザインカット＋頭皮ケア',
    price: '5,500',
    description: '自分の髪を活かしてカッコよさを実感する'
  },
  {
    id: '23',
    name: 'デザインカット＋頭皮クレンジング',
    price: '5,500',
    description: '脂・匂いなどの頭皮の問題をスピーディにすっきり解決！'
  },
  {
    id: '34',
    name: 'ヘアライズ',
    price: '5,500',
    description: 'あと少し欲しい髪にハリとコシを特殊コーティングで実現'
  },
  {
    id: '33',
    name: 'デザインカット＋ヘアライズ',
    price: '8200',
    description: 'スタイリッシュなヘアスタイルを自毛＋特殊コーティングで実現'
  },
  {
    id: '83',
    name: 'カットメニューご相談',
    price: '',
    description: 'カットメニューをご来店時にスタイリストと決められたい方'
  },
]

export { listDesignCut }
