<template>
  <section class="l-content-area">
    <div class="l-content l-content--reservation">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="c-title__bloc c-title__bloc--reservation">
            <h1>{{ getTopTitle }}</h1>
          </section>
          <section class="p-bloc p-bloc__reservation bloc_finish">
            <div class="p-reservation__finish">
              <h2>{{ getTitle }}</h2>
              <p v-html="getContent">
                {{ getContent }}
              </p>

              <p v-if="statusDuplicated === 1">
                <strong
                  >※ご予約はまだ確定しておりませんので、
                  ご予約店舗よりご連絡をさせていただきます点ご了承ください。
                </strong>
              </p>
              <p v-if="!isDesignCut && statusDuplicated === 0">
                <strong
                  >※ご来店日時はまだ確定しておりませんので、
                  弊社サポートセンターよりご連絡をさせていただきます点ご了承ください。
                </strong>
              </p>

              <div class="reservation-number">
                <h4>ご予約番号</h4>
                <p class="num">{{ reservationCode }}</p>
              </div>
              <p>マイページより、予約番号・予約情報の確認が<span>可能となっております。</span></p>
            </div>

            <button-registration
              :next-text="'ログイン'"
              :prev-text="'men’s svenson公式サイト'"
              :handle-next-button="nextToStep"
              :handle-prev-button="prevToStep"
              :is-next-button="false"
              :prev-text-color="'#787878'"
            ></button-registration>

            <div class="p-reservation__attention">
              <div class="reservation-attention">
                <h3>ご注意事項</h3>
                <p>
                  メールが届いていない場合は、ご登録いただいたメールアドレスが間違っているか、
                  何らかの原因で送信できていない可能性がございますので、
                  マイページ＞登録情報よりご自身のメールアドレスをご確認ください。
                </p>
                <h4>【キャンセルに関する注意事項】</h4>
                <ul>
                  <li>
                    キャンセル受付締切：ご予約日の前日までマイページ、もしくはお電話よりキャンセルが可能です。
                  </li>
                  <li>キャンセルポリシー：ご予約いただきました店舗に直接お問い合わせください。</li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ButtonRegistration from '../common/ButtonRegistration'
import { scrollToTop } from '../../helpers'
import { getReservationById } from '../../actions/requestReservation'

export default {
  name: 'ReservationFinish',
  components: { ButtonRegistration },
  data() {
    return {
      statusDuplicated: '',
      isDesignCut: '',
      reservationCode: '',
      topTitle: '',
      title: '',
      content: '',
      values: {
        reservationId: '',
      },
    }
  },
  computed: {
    getTopTitle() {
      if (this.statusDuplicated === 1) {
        this.topTitle = 'ご来店予約'
      } else if (this.statusDuplicated === 0 && this.isDesignCut) {
        this.topTitle = 'ご来店予約'
      } else if (this.statusDuplicated === 0 && !this.isDesignCut) {
        this.topTitle = 'お申込み完了'
      }
      return this.topTitle
    },
    getTitle() {
      if (this.statusDuplicated === 1) {
        this.title = 'ご来店申し込みを承りました。'
      } else if (this.statusDuplicated === 0 && this.isDesignCut) {
        this.title = 'ご予約の登録が完了いたしました。'
      } else if (this.statusDuplicated === 0 && !this.isDesignCut) {
        this.title = 'ご来店の申込みを承りました'
      }
      return this.title
    },
    getContent() {
      if (this.statusDuplicated === 1) {
        this.content =
          '予約番号は、お問い合わせの際に必要となりますのでお控えください。<br>また、確認のメールをお送りしましたので、ご予約内容をご確認ください。'
      } else if (this.statusDuplicated === 0 && this.isDesignCut) {
        this.content =
          '予約番号は、お問い合わせの際に必要となりますのでお控えください。<br>また、確認のメールをお送りしましたので、ご予約内容をご確認ください。'
      } else if (this.statusDuplicated === 0 && !this.isDesignCut) {
        this.content =
          '予約番号は、お問い合わせの際に必要となりますのでお控えください。<br>また、確認のメールをお送りしましたので、ご予約内容をご確認ください。'
      }
      return this.content
    },
  },
  created() {
    this.isDesignCut = this.$reservation.getIsDesignCut()
    this.statusDuplicated = this.$reservation.getOrangeMatches()
    getReservationById(this.$reservation.getValuesReservation().reservationId)
      .then(res => {
        const {
          data: { success },
        } = res
        if (success && success === 1) {
          this.reservationCode = res.data.data.code
        }
        this.$emit('endLoading')
      })
      .catch(err => {
        this.$emit('endLoading')
        return err
      })
    scrollToTop()
    if (!this.$reservation.getCurrentStep()) {
      this.$router.push({
        name: 'reservation',
      })
    }
  },
  beforeDestroy() {
    this.$reservation.resetReservation()
  },
  methods: {
    nextToStep() {
      this.$router.push('/')
    },
    prevToStep() {
      window.location.href = 'https://www.mens-svenson.net/'
    },
  },
}
</script>

<style scoped>
.v-application ul,
.v-application ol {
  padding-left: 0 !important;
}

.v-application a {
  color: #0a005a;
}
</style>
