<template>
  <section class="l-content-area">
    <div class="l-content l-content--registration">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="c-title__bloc c-title__bloc--registration">
            <h1>既に他サービスで<span>hubsynchをご利用の方</span></h1>
          </section>

          <section class="p-bloc p-bloc__registration">
            <div class="p-registration__bloc">
              <p>
                お客様のメールアドレスは既にhubsynchに登録されております。
                本サービスをご利用いただくには、以下のメールアドレスにおいて、本サービスの利用承認をお願いいたします。
              </p>

              <div class="p-registration__reenter">
                <dl>
                  <dt>メールアドレス</dt>
                  <dd>
                    <p>{{ user.email }}</p>
                  </dd>
                </dl>
              </div>
            </div>
            <button-registration
              :is-prev-button="false"
              :handle-next-button="onHandleNextButton"
              :next-text="'予約システムを利用'"
            >
            </button-registration>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import ButtonRegistration from '../../common/ButtonRegistration'
  import { mapState } from 'vuex'
  import { agreeService } from '../../../actions/requestLogin'

  export default {
    name: 'FirstStepRegistrationReenter',
    components: { ButtonRegistration },
    props: {
      isForceUpdate: {
        type: Boolean,
        default: false,
      },
    },
    computed: mapState({
      user: ({ auth }) => auth.user,
    }),
    watch: {
      isForceUpdate(newVal) {
        if (newVal) {
          this.onHandleNextButton()
        }
      },
    },
    methods: {
      onHandleNextButton() {
        this.startLoading()
        agreeService(this.user)
          .then(response => {
            const { data: { success, data: { has_snow_id } } } = response
            if (success) {
              this.$router.push({
                name: 'association-completed',
                query: {
                  has_snow_id: has_snow_id,
                },
              })
            }
            this.endLoading()
          })
          .catch((err) => {
            this.endLoading()
            return err
          })
      },
      startLoading() {
        this.$emit('startLoading')
      },
      endLoading() {
        this.$emit('endLoading')
      },
    },
  }
</script>
