import LoginRepository from './LoginRepository'

const repository = {
  login: LoginRepository,
}

const LoginRepositoryFactory = {
  get: (name) => repository[name],
}

export default LoginRepositoryFactory
