import store from '../store'

const {
  state :{
    auth
  }
} = store

const setToken = (token) => {
  return store.dispatch('setToken', token)
}

const getToken = () => {
  return auth.token
}

const setUser = (user) => {
  return store.dispatch('setUser', user)
}

const getUser = () => {
  return auth.user
}

const setBooking = (booking) => {
  return store.dispatch('setBooking', booking)
}

const setLinked = (linked) => {
  return store.dispatch('setLinked', linked)
}

const getBooking = () => {
  return auth.booking
}

const getLinked = () => {
  return auth.linked
}

const authInfo = {
  setToken,
  getToken,
  setUser,
  getUser,
  setBooking,
  getBooking,
  setLinked,
  getLinked,
}

export default authInfo
