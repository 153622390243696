<template>
  <div v-if="$route.name === 'mypage-profile-unsubscribe'" class="p-registration__btn">
    <div
      v-if="isPrevButton"
      class="c-btn c-btn--registration-back"
      @click.prevent="handlePrevButton()"
    >
      <a href="#" class="a-prev" :style="`color: ${prevTextColor};`">
        {{ prevText }}
      </a>
    </div>
    <div v-if="isNextButton" class="c-btn c-btn--registration-next">
      <button type="button" @click="handleNextButton()">
        {{ nextText }}
      </button>
    </div>
    <div v-if="isSubButton" class="c-btn c-btn--registration-next">
      <button type="button" @click="handleSubStep()">
        {{ subText }}
      </button>
    </div>
  </div>
  <div v-else class="p-registration__btn">
    <div v-if="isNextButton && isDisable == ''" class="c-btn c-btn--registration-next">
      <button type="button" @click="handleNextButton()">
        {{ nextText }}
      </button>
    </div>
    <div v-if="isNextButton && isDisable != ''" class="c-btn c-btn--registration-next disableMenu">
      <button type="button" @click="handleNextButton()">
        {{ nextText }}
      </button>
    </div>
    <div v-if="isSubButton" class="c-btn c-btn--registration-next">
      <button type="button" @click="handleSubStep()">
        {{ subText }}
      </button>
    </div>
    <div
      v-if="isPrevButton"
      class="c-btn c-btn--registration-back"
      @click.prevent="handlePrevButton()"
    >
      <a href="#" class="a-prev" :style="`color: ${prevTextColor};`">
        {{ prevText }}
      </a>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ButtonRegistration',
  props: {
    handleNextButton: {
      type: Function,
      default: null,
    },
    handlePrevButton: {
      type: Function,
      default: null,
    },
    handleSubStep: {
      type: Function,
      default: null,
    },
    nextText: {
      type: String,
      default: '送信する',
    },
    prevText: {
      type: String,
      default: '戻る',
    },
    subText: {
      type: String,
      default: '送信する',
    },
    isNextButton: {
      type: Boolean,
      default: true,
    },
    isPrevButton: {
      type: Boolean,
      default: true,
    },
    isSubButton: {
      type: Boolean,
      default: false,
    },
    nextTextColor: {
      type: String,
      default: '#1976d2',
    },
    prevTextColor: {
      type: String,
      default: '#777777',
    },
    isDisable: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    currentRouteName() {
      return this.$route.name
    },
  },
}
</script>
<style>
.a-prev {
  border-color: #777777 !important ;
  color: #777777 !important ;
}
.c-btn--registration-back:before {
  content: '';
  position: absolute;
  top: 57%;
  left: 20px;
  z-index: 1;
  width: 20px;
  height: 20px;
  margin-top: -15px;
  background-image: url(../../../src/assets/img/form/btn-back.svg);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% 100%;
}
.bloc_unsubscribe .a-prev {
  border-color: #2699fb !important ;
  color: #2699fb !important;
  height: 48px;
  font-size: 16px;
}

.bloc_unsubscribe .c-btn--registration-back:before {
  background-image: url(../../../src/assets/img/form/btn_arw_prev_blue.png);
  width: 16px;
  height: 16px;
  top: 65%;
}

.bloc_unsubscribe .c-btn--registration-next button {
  background-color: #2699fb;
  border: 0;
  height: 48px;
  font-size: 16px;
}

.bloc_unsubscribe .c-btn--registration-next:after {
  background-image: url(../../../src/assets/img/form/btn_arw_next_white.png);
  width: 16px;
  height: 16px;
  top: 65%;
}

.bloc_finish .c-btn--registration-back:before {
  content: none;
}

.bloc_finish .c-btn--registration-back:after {
  content: '';
  position: absolute;
  top: 57%;
  right: 20px;
  z-index: 1;
  width: 20px;
  height: 20px;
  margin-top: -15px;
  background-image: url(../../../src/assets/img/form/btn-back.svg);
  transform: scaleX(-1);
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% 100%;
}

.unsubscribe_finish .p-registration__btn {
  padding-top: 70px;
}

.disableMenu {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  opacity: 0.5;
}

.disableMenu button {
  background-color: #f5f5f5;
  border-color: #f5f5f5;
  color: #000000ba;
}

.c-btn--registration-next.disableMenu:after {
  background-image: url(../../../src/assets/img/form/btn_arw_disable.png);
}
</style>
