<template>
  <section class="l-content-area">
    <div class="l-content l-content--login">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="c-title__bloc c-title__bloc--login">
            <h1>パスワード再設定が<span>完了いたしました</span></h1>
          </section>

          <section class="p-bloc p-bloc__reminder">
            <div class="p-reminder__complete">
              <p>ログインページより、新しく設定したパスワードにて、マイページへログインをお願いいたします。</p>
              <button-registration
                :next-text="'ログイン'"
                :is-prev-button="false"
                :handle-next-button="redirectLoginPage"
              >
              </button-registration>
            </div>
          </section><!-- .p-bloc__reminder -->
        </div>
      </div>
    </div><!-- .l-content--login -->
  </section>
</template>

<script>
  import ButtonRegistration from '../common/ButtonRegistration'
  export default {
    name: 'ReminderPasswordComplete',
    components: { ButtonRegistration },
    methods: {
      redirectLoginPage() {
        this.$router.push({
          'name': 'login'
        })
      }
    }
  }
</script>

<style scoped>

</style>
