<template>
  <main-layout :title="title" :is-loading="isLoading">
    <registration-complete-component
      @setTitle="setTitle"
      @startLoading="startLoading"
      @endLoading="endLoading"
    >
    </registration-complete-component>
  </main-layout>
</template>

<script>
import MainLayout from '../components/layout/MainLayout'
import RegistrationCompleteComponent from '../components/registration/fourth-step/index'

export default {
  name: 'RegistrationComplete',
  components: {
    MainLayout,
    RegistrationCompleteComponent,
  },
  data() {
    return {
      isLoading: false,
      title: "アカウント登録 | Svenson",
    }
  },
  methods: {
    setTitle(title) {
      this.title = title
    },
    startLoading() {
      this.isLoading = true
    },
    endLoading() {
      this.isLoading = false
    },
  },
}
</script>

<style scoped></style>
