<template>
  <div id="modal-over" class="is-visible">
    <div class="modal-zoom">
      <div id="modal-inner" class="inner">
        <select-date
          v-if="isShowSelectTime"
          :date="date"
          :studio="studio"
          :is-design-cut="isDesignCut"
          :text-error="textError"
          :error="errors.date"
          :hour="hour"
          :minute="minute"
          :status="status"
          :is-show-select-time-cancel="isShowSelectTimeCancel"
          :is-time-cancel="isTimeCancel"
          :reservation-id="reservationId"
          :max-date="maxDate"
          :list-holiday="listHoliday"
          :pre-slots="preSlots"
          :estimated-duration="estimatedDuration"
          @onChangeShowSelectTime="onChangeShowSelectTime"
          @onChangeTime="onChangeTime"
          @onSubmitDateTime="onSubmitDateTime"
          @nextToTimeCancel="nextToTimeCancel"
          @onChangeStatus="onChangeStatus"
          @onSetHoliday="onSetHoliday"
          @onHandleChange="onHandleChange"
        >
        </select-date>
        <select-date-cancel
          v-else-if="isShowSelectTimeCancel"
          :date="date"
          :studio="studio"
          :is-design-cut="isDesignCut"
          :text-error="textError"
          :error="errors.date"
          :hour="hour"
          :minute="minute"
          :status="status"
          :is-time-cancel="isTimeCancel"
          :reservation-id="reservationId"
          :max-date="maxDate"
          :list-holiday="listHoliday"
          :pre-slots="preSlots"
          :estimated-duration="estimatedDuration"
          @onChangeShowSelectTimeCancel="onChangeShowSelectTimeCancel"
          @onChangeTime="onChangeTime"
          @onSubmitDateTime="onSubmitDateTime"
          @nextToTimeCancel="nextToTimeCancel"
          @onChangeStatus="onChangeStatus"
          @onHandleChange="onHandleChange"
        >
        </select-date-cancel>
        <div v-else class="p-reservation-modal__bloc p-reservation-modal__bloc--date">
          <h2 class="modal__title">ご希望日時の選択</h2>
          <div class="modal__bloc modal__bloc--date">
            <h3>ご来店希望日時を設定してください。</h3>
            <div class="calendar__bloc is-error">
              <date-picker
                :name="name"
                :value="date"
                :list-holiday="listHoliday"
                :available-booking-start-period="availableBookingStartPeriod"
                :available-booking-end-period="availableBookingEndPeriod"
                :max-date="maxDate"
                :reservation-id="reservationId"
                :is-time-cancel="isTimeCancel"
                :is-design-cut="isDesignCut"
                @onHandleChange="onHandleChange"
                @onSetHoliday="onSetHoliday"
                @onSetPreSlots="onSetPreSlots"
              >
              </date-picker>
              <div class="c-form-parts">
                <ul :class="`select-area ${errors.date ? 'is-error' : ''}`"></ul>
                <p :class="`error-txt mt-5 ${errors.date ? 'is-error' : ''}`">
                  {{ errors.date }}
                </p>
              </div>
            </div>

            <div v-if="!isDesignCut" class="time__bloc">
              <dl class="c-form-parts">
                <dt>予約時間</dt>
                <dd>
                  <ul :class="`select-area ${errors.hour ? 'is-error' : ''}`">
                    <li>
                      <select
                        name="time-visited"
                        :value="selectedTime"
                        @change="onHandleChange('time-visited', $event.target.value)"
                      >
                        <option value="">時間を選択</option>
                        <option
                          v-for="(item, index) in listTimeCanVisit"
                          :key="index"
                          :value="item"
                          :selected="item === selectedTime"
                        >
                          {{ item }}
                        </option>
                      </select>
                    </li>
                  </ul>
                  <p :class="`error-txt ${errors.hour ? 'is-error' : ''}`">
                    {{ errors.hour }}
                  </p>
                </dd>
              </dl>
            </div>

            <button-registration
              v-if="isDesignCut"
              prev-text="戻る"
              :is-next-button="false"
              :handle-prev-button="onCancelDateTime"
              prev-text-color="#787878"
            >
            </button-registration>
            <button-registration
              v-else
              prev-text="戻る"
              next-text="希望日時を設定"
              :handle-next-button="onSubmitDateTime"
              :handle-prev-button="onCancelDateTime"
              prev-text-color="#787878"
            >
            </button-registration>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import DatePicker from '../common/DatePicker'
import ButtonRegistration from '../common/ButtonRegistration'
import { rulesValidateDateTime, validateRequired } from '../../helpers/schemaValidate'
import { TIME_INVALID, TIME_SELECT_INVALID } from '../../constant/text'
import SelectDate from './SelectDate'
import SelectDateCancel from './SelectDateCancel.vue'
import { scrollModalToTop, scrollToTop } from '../../helpers'
import listTimeVisited from '../../constant/listTimeVisited'

export default {
  name: 'SelectDateTime',
  components: {
    SelectDate,
    ButtonRegistration,
    DatePicker,
    SelectDateCancel,
  },
  props: {
    name: {
      type: String,
      default: '',
    },
    date: {
      type: String,
      default: '',
    },
    studio: {
      type: String,
      default: '',
    },
    isDesignCut: {
      type: Boolean,
      default: false,
    },
    hour: {
      type: String,
      default: '',
    },
    minute: {
      type: String,
      default: '',
    },
    availableBookingStartPeriod: {
      type: String,
      default: '',
    },
    availableBookingEndPeriod: {
      type: String,
      default: '',
    },
    maxDate: {
      type: String,
      default: '',
    },
    listHoliday: {
      type: Array,
      default: () => {
        return []
      },
    },
    isTimeCancel: {
      type: Boolean,
      default: false,
    },
    reservationId: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
    openingTime: {
      type: String,
      default: '',
    },
    closingTime: {
      type: String,
      default: '',
    },
    altOpeningTime: {
      type: String,
      default: '',
    },
    altClosingTime: {
      type: String,
      default: '',
    },
    estimatedDuration: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isShowSelectTime: false,
      isShowSelectTimeCancel: false,
      textError: TIME_SELECT_INVALID,
      listTimeVisited,
      listTimeCanVisit: [],
      isHoliday: false,
      selectedTime: '',
      errors: {
        date: '',
        hour: '',
        minute: '',
      },
      isInit: false,
      preSlots: [],
    }
  },
  watch: {
    date(newVal) {
      const values = {
        date: newVal,
      }
      validateRequired('date', TIME_INVALID)
        .validateAt('date', values)
        .then(() => {
          this.errors.date = ''
          this.getListTimeCanVisit()
        })
        .catch(error => {
          this.errors.date = error.message
        })
    },
    hour(newVal) {
      const values = {
        hour: newVal,
      }
      if (!this.isInit) {
        validateRequired('hour', TIME_INVALID)
          .validateAt('hour', values)
          .then(() => {
            this.errors.hour = ''
            var chooseTime = values.hour + ':' + this.minute
            this.selectedTime = chooseTime
          })
          .catch(error => {
            this.selectedTime = ''
            this.errors.hour = error.message
          })
      }
    },
    minute(newVal) {
      const values = {
        minute: newVal,
      }
      validateRequired('minute', TIME_INVALID)
        .validateAt('minute', values)
        .then(() => {
          this.errors.minute = ''
        })
        .catch(error => {
          this.errors.minute = error.message
        })
    },
    openingTime() {
      this.getListTimeCanVisit()
    },
    closingTime() {
      this.getListTimeCanVisit()
    },
    altOpeningTime() {
      this.getListTimeCanVisit()
    },
    altClosingTime() {
      this.getListTimeCanVisit()
    },
  },
  created() {
    this.getListTimeCanVisit()
    this.getSelectedTime(1)
  },
  mounted() {
    scrollModalToTop('modal-inner')
  },
  methods: {
    onHandleChange(field, value) {
      if (field === 'time-visited') {
        const timeArray = value.split(':')
        if (timeArray.length) {
          this.isInit = false
          this.$emit('onHandleChange', 'hour', timeArray[0])
          this.$emit('onHandleChange', 'minute', timeArray[1])
        } else {
          this.$emit('onHandleChange', 'hour', '')
          this.$emit('onHandleChange', 'minute', '')
        }
      } else {
        this.$emit('onHandleChange', field, value)
        if (this.isDesignCut && !this.isShowSelectTimeCancel) {
          scrollToTop()
          this.isShowSelectTime = true
        }
      }
    },
    onChangeShowSelectTime(value) {
      this.isShowSelectTime = value
      scrollModalToTop('modal-inner')
    },
    onChangeShowSelectTimeCancel(value) {
      this.isShowSelectTimeCancel = value
      scrollModalToTop('modal-inner')
    },
    onSubmitDateTime() {
      const values = {
        date: this.date,
        hour: this.hour,
        minute: this.minute,
      }
      rulesValidateDateTime
        .validate(values, { abortEarly: false })
        .then(() => {
          this.errors = {}
          this.$emit('onSubmitDateTime', this.name)
        })
        .catch(err => {
          err.inner.forEach(error => {
            this.errors[error.path] = error.message
          })
        })
    },
    onCancelDateTime() {
      this.$emit('onCancelDateTime', this.name)
    },
    onChangeTime(value) {
      const timeSelected = value.split('～')
      const startTime = timeSelected[0]
      const hourStart = startTime.split(':')
      const hour = hourStart[0]
      const minute = hourStart[1]
      this.errors.hour = ''
      this.errors.minute = ''
      this.$emit('onHandleChange', 'hour', hour)
      this.$emit('onHandleChange', 'minute', minute)
    },
    nextToTimeCancel() {
      this.isShowSelectTime = false
      this.isShowSelectTimeCancel = true
    },
    onChangeStatus(field, value) {
      this.$emit('onChangeStatus', field, value)
    },
    onSetHoliday(value) {
      this.isHoliday = value
    },
    onSetPreSlots(value) {
      this.preSlots = value
    },
    getListTimeCanVisit() {
      const openTimeArray = this.openingTime.split(':')
      const closeTimeArray = this.closingTime.split(':')
      const openAltTimeArray = this.altOpeningTime.split(':')
      const closeAltTimeArray = this.altClosingTime.split(':')
      if (
        this.date &&
        openAltTimeArray.length &&
        closeTimeArray.length &&
        openTimeArray.length &&
        closeAltTimeArray.length
      ) {
        let hourOpen = 0
        let minuteOpen = 0
        let hourClose = 0
        let minuteClose = 0
        if (!this.isHoliday) {
          hourOpen = parseInt(openTimeArray[0])
          minuteOpen = parseInt(openTimeArray[1])
          hourClose = parseInt(closeTimeArray[0])
          minuteClose = parseInt(closeTimeArray[1])
        } else {
          hourOpen = parseInt(openAltTimeArray[0])
          minuteOpen = parseInt(openAltTimeArray[1])
          hourClose = parseInt(closeAltTimeArray[0])
          minuteClose = parseInt(closeAltTimeArray[1])
        }
        this.listTimeCanVisit = this.getListTime(hourOpen, minuteOpen, hourClose, minuteClose)
      } else if (!this.date) {
        this.listTimeCanVisit = []
      } else {
        this.listTimeCanVisit = listTimeVisited
      }
      //filter time slot with current time
      let date = moment(this.date).format('YYYY-MM-DD')
      let today = moment().format('YYYY-MM-DD')
      if (today == date) {
        let listAvailableSlot = this.listTimeCanVisit.filter(slot => {
          let start_time = slot + ':00'
          let now = moment()
          let current_time = now.hour() + ':' + now.minutes() + ':' + now.seconds()
          let beginningTime = moment(start_time, 'h:mma')
          let endTime = moment(
            moment(current_time, 'h:mma')
              .add('30', 'minutes')
              .format('HH:mm:ss'),
            'h:mma'
          )
          if (beginningTime.isAfter(endTime)) {
            return slot
          }
        })
        this.listTimeCanVisit = listAvailableSlot
      }
      this.getSelectedTime(0)
    },
    getListTime(hourOpen, minuteOpen, hourClose, minuteClose) {
      const listTimeSelected = []
      for (let i = hourOpen; i < hourClose; i++) {
        if (i === hourOpen) {
          if (minuteOpen === 30) {
            listTimeSelected.push(i < 10 ? `0${i}:30` : `${i}:30`)
          } else {
            listTimeSelected.push(i < 10 ? `0${i}:00` : `${i}:00`)
            listTimeSelected.push(i < 10 ? `0${i}:30` : `${i}:30`)
          }
        } else if (i === hourClose - 1) {
          if (minuteClose === 0) {
            listTimeSelected.push(i < 10 ? `0${i}:00` : `${i}:00`)
          } else {
            listTimeSelected.push(i < 10 ? `0${i}:00` : `${i}:00`)
            listTimeSelected.push(i < 10 ? `0${i}:30` : `${i}:30`)
          }
        } else {
          listTimeSelected.push(i < 10 ? `0${i}:00` : `${i}:00`)
          listTimeSelected.push(i < 10 ? `0${i}:30` : `${i}:30`)
        }
      }
      return listTimeSelected
    },
    getSelectedTime(created) {
      // remove keeping the selectedTimeValue of previous choosing date
      // show 時間を選択 in time selection box when change date via user
      // show selected TimeValue in created State
      this.isInit = true
      const timeSelected = this.hour + ':' + this.minute
      const listTimeSelected = this.listTimeCanVisit.filter(time => time === timeSelected)
      if (listTimeSelected.length && created === 1) {
        this.selectedTime = listTimeSelected[0]
      } else {
        this.$emit('onHandleChange', 'hour', '')
        this.$emit('onHandleChange', 'minute', '')
        this.selectedTime = ''
        this.errors.hour = ''
      }
    },
  },
}
</script>

<style scoped>
.v-application ul,
.v-application ol {
  padding-left: 0 !important;
}

.time__bloc {
  display: block;
  width: 100%;
}

.c-form-parts dt {
  text-align: center;
}

.modal__bloc .time__bloc dl {
  width: 100%;
}
</style>
