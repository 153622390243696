const handleErrors = (error) => {
  // List of error messages location, add as needed
  const locations = [
    'message',
    'data.error.status',
    'response.message',
    'response.data.message',
    'response.data.error',
    'response.data.error.status',
    'response.data.error.csv',
    'response.data.error.floor',
    'response.data.error.message',
    'response.data.errors',
    'response.data.errors.csv',
    'response.data.errors.message',
  ]

  const findErrorMessage = (error, keys) => {
    if (!error || keys.length === 0) return null
    const [firstKey, ...restKeys] = keys

    if (!error[firstKey]) return null
    return restKeys.length === 0 ? error[firstKey] : findErrorMessage(error[firstKey], restKeys)
  }

  /*
    Message must be longer than 5-40 (adjustable) characters and a string
    This prevents errors like empty strings/Objects/long messages etc..
    For more details, check: https://hivelocity.atlassian.net/wiki/spaces/DXdev/pages/edit-v2/1040547870
    */
  const validateErrorMessage = (errorMessage) => {
    if (
      errorMessage &&
      typeof errorMessage === 'string' &&
      errorMessage.length > 5 &&
      errorMessage.length < 40
    ) {
      if (errorMessage.includes('method is not supported')) {
        // This is mainly for DEVs, should never be seen in PROD, unless request is manipulated.
        return '通信エラーです。 (wrong request type)'
      }

      if (errorMessage.includes('This action is unauthorized')) {
        // This is if the user tries an action with no permissions or it's bypassed.
        return '権限がありません'
      }

      return errorMessage
    }

    return null
  }

  for (const location of locations) {
    const keys = location.split('.')
    const errorMessage = findErrorMessage(error, keys)

    const validatedMessage = validateErrorMessage(errorMessage)
    if (validatedMessage) {
      return validatedMessage
    }
  }

  const errorStyle = `
    background: #f43a3a;
    border: 1px solid white;
    color: white;
    font-size: 14px;
    padding: 4px;
  `

  // Default error message + console warning
  console.log(`%cError message not found, investigate location/status`, `${errorStyle}`, error)
  return 'エラーが発生しました。'
}

export {
  handleErrors,
}
