<template>
  <v-card height="100%">
    <v-tabs
      v-show="false"
      v-model="currentStep"
      centered
      grow
      :hide-slider="true"
      :transition="false"
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#1"
             :align-with-title="true"
             :disabled="disabled.firstTab"
      >
        ステップ1
      </v-tab>
      <v-tab href="#2"
             :align-with-title="true"
             :disabled="disabled.secondTab"
      >
        ステップ2
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentStep" :touch="onTouchEvent()" :continuous="false">
      <v-tab-item id="1" value="1">
        <mypage-profile-edit
          :is-force-validate="isForceValidate"
          :is-disable-swipe="isDisabledSwipe"
          :values="values.profiles"
          :user-id="values.userId"
          :current-step="currentStep"
          @onHandleChange="onHandleChange"
          @onHandleChangeValue="onHandleChangeValue"
          @onValidateSwipe="onValidateSwipe"
          @nextToStep="nextToStep"
          @startLoading="startLoading"
          @endLoading="endLoading"
        >
        </mypage-profile-edit>
      </v-tab-item>
      <v-tab-item id="2" value="2">
        <mypage-profile-edit-finish>
        </mypage-profile-edit-finish>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>
<script>
  import MypageProfileEdit from './MypageProfileEdit.vue'
  import MypageProfileEditFinish from './MypageProfileEditFinish.vue'
  export default {
    name: 'MypageProfileComponent',
    components: {
      MypageProfileEdit,
      MypageProfileEditFinish
    },
    data() {
      return {
        currentStep: '1',
        isForceValidate: false,
        isDisabledSwipe: false,
        isDesignCut: false,
        disabled: {
          firstTab: false,
          secondTab: true,
        },
        values: {
          reservationId: '',
          userId: '',
          profiles: {
            firstName: '',
            lastName: '',
            firstNameKana: '',
            lastNameKana: '',
            gender: '',
            email: '',
            phone: '',
            year: '',
            month: '',
            date: '',
            studio: '',
            state: ''
          },
        },
      }
    },
    watch: {
      currentStep(newVal) {
        window.scrollTo(0, 0)
        switch (newVal) {
          case '2':
            this.disabled.secondTab = false
            break
        }
      },
    },
    created() {
      this.$emit('setTitle', 'MEN\'S SVENSON｜ご来店予約')
    },
    methods: {
      assignSwipeLeft() {
        if (!this.isDisabledSwipe) {
          this.isForceValidate = true
        }
      },
      assignSwipeRight() {
        this.isForceValidate = false
        if (!this.isDisabledSwipe) {
          this.prevToStep()
        }
      },
      onHandleChange(field, value) {
        this.values.profiles[field] = value
      },
      onHandleChangeValue(field, value) {
        this.values[field] = value
      },
      onTouchEvent() {
        return {
          left: () => this.assignSwipeLeft(),
          right: () => this.assignSwipeRight(),
        }
      },
      onValidateSwipe(value) {
        this.isForceValidate = value
      },
      nextToStep() {
        const nextStep = parseInt(this.currentStep) + 1
        this.currentStep = nextStep.toString()
      },
      prevToStep() {
        const prevStep = parseInt(this.currentStep) - 1
        this.currentStep = prevStep.toString()
      },
      startLoading() {
        this.$emit('startLoading')
      },
      endLoading() {
        this.$emit('endLoading')
      }
    }
  }
</script>
<style>
  .v-window.v-item-group.theme--light.v-tabs-items {
    height: 100%;
  }
</style>

