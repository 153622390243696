<template>
  <section class="l-content-area">
    <div class="l-content l-content--registration">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="c-title__bloc c-title__bloc--registration">
            <h1>既存顧客様向け<span>アカウント登録</span></h1>
          </section>

          <section class="p-bloc p-bloc__registration">
            <terms-and-condition></terms-and-condition>
            <div class="p-agreement__attention">
              <p>
                上記各規約を必ずご確認いただき、<br>
                規約に同意のうえ登録へお進みください。
              </p>
            </div>
            <button-registration
              :is-prev-button="false"
              :next-text="'同意のうえ登録へ'"
              :handle-next-button="nextToStep"
            >
            </button-registration>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import TermsAndCondition from './TermsAndCondition'
  import ButtonRegistration from '../../common/ButtonRegistration'

  export default {
    name: 'FirstStepRegistration',
    components: {
      TermsAndCondition,
      ButtonRegistration
    },
    methods: {
      nextToStep() {
        this.$emit('nextToStep')
      }
    },
  }
</script>

<style scoped>

</style>
