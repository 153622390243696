<template>
  <section class="l-content-area">
    <div class="l-content l-content--reservation">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="c-title__bloc c-title__bloc--reservation">
            <h1>ご来店予約</h1>
            <div class="c-btn c-btn--title-back"><a href="#" @click="prevToStep()">戻る</a></div>
          </section>

          <section class="p-bloc p-bloc__reservation">
            <h2 class="p-reservation__step">STEP 4</h2>

            <div class="p-reservation__bloc">
              <p>担当者を選択してください。</p>

              <div class="p-reservation__staff">
                <h2 v-if="listPerson.length > 0">担当者を指名する場合</h2>
                <div
                  v-for="(person, index) in listPerson"
                  :key="index"
                  :class="
                    `c-staff__bloc ` +
                      (person.gender === 'f' ? `c-staff__bloc--woman` : `c-staff__bloc--man`)
                  "
                >
                  <a href="#" @click="nextToStep(person.id, person.name, null)">
                    <h2>
                      {{ person.name }}
                      <!-- <span v-if="person.active" class="previously">前回担当者</span> -->
                    </h2>
                  </a>
                </div>
                <h2>スタイリストの性別希望がある場合</h2>

                <div class="c-staff__bloc c-staff__bloc--man">
                  <a href="#" @click="nextToStep(null, null, 'm')">
                    <h2>男性スタイリスト希望</h2>
                  </a>
                </div>
                <div class="c-staff__bloc c-staff__bloc--woman">
                  <a href="#" @click="nextToStep(null, null, 'f')">
                    <h2>女性スタイリスト希望</h2>
                  </a>
                </div>


              </div>
            </div>
            <button-registration
              :is-next-button="false"
              :prev-text-color="'#787878'"
              :prev-text="'戻る'"
              :handle-prev-button="prevToStep"
            >
            </button-registration>
          </section>
          <!-- .p-bloc__reservation -->
        </div>
      </div>
    </div>
    <!-- .l-content--reservation -->
  </section>
  <!-- .l-content-area -->
</template>
<script>
import ButtonRegistration from '../../common/ButtonRegistration.vue'
import { getStylist, requestRegisterReservation } from '../../../actions/requestMypage'

export default {
  name: 'MypageReservationStaff',
  components: {
    ButtonRegistration,
  },
  props: {
    currentStep: {
      type: String,
      default: '4',
    },
    storeId: {
      type: String,
      default: '',
    },
    userId: {
      type: String,
      default: '',
    },
    services: {
      type: Array,
      default: () => {
        return []
      },
    },
    reservationId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      listPerson: [],
      favoriteStylists: [],
      isModalVisible: false,
      errorMessage: '',
    }
  },
  watch: {
    services(newVal) {
      if (this.storeId) {
        getStylist(this.storeId, newVal)
          .then(res => {
            const {
              data: { success },
            } = res
            if (success && success === 1) {
              this.listPerson = res.data.data
              this.$emit('endLoading')
            }
          })
          .catch(err => {
            this.$emit('endLoading')
            return err
          })
      }
    },
  },
  created() {
    if (sessionStorage.getItem('userInfo')) {
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      this.favoriteStylists = userInfo?.favorite_stylists
    }
    this.$emit('startLoading')
    if (this.storeId) {
      if (this.favoriteStylists?.length > 0) {
        getStylist(this.storeId, this.services)
          .then(res => {
            const {
              data: { success },
            } = res
            if (success && success === 1 && res.data.data.length > 0) {
              this.listPerson = res.data.data
            }
          })
          .catch(err => {
            return err
          })
      }
    }
    this.$emit('endLoading')
  },
  methods: {
    prevToStep() {
      this.$emit('prevToStep')
    },
    nextToStep(id, name, gender_preference) {
      let stylist = null
      if (id != null) {
        stylist = [id]
      }
      this.$emit('onHandleChange', 'stylist', stylist)
      this.$emit('onHandleChange', 'stylistName', name)
      this.$emit('onHandleChange', 'genderPreference', gender_preference)
      const dateTime = ''
      const isDesignCut = true
      this.$emit('startLoading')
      requestRegisterReservation({
        reservationId: this.reservationId,
        currentStep: 4,
        userId: this.userId,
        storeId: this.storeId,
        timeVisited: dateTime,
        isDesignCut,
        services: this.services,
        status: null,
        stylist,
        genderPreference: gender_preference,
        waiting: 0,
        requestMsg: '',
      })
        .then(res => {
          const {
            data: { success },
          } = res
          if (success && success === 1) {
            this.$emit('nextToStep')
            this.$emit(
              'onHandleChange',
              'estimated_duration',
              res.data.data.estimated_duration.toString()
            )
          }
          this.$emit('endLoading')
        })
        .catch(err => {
          this.$emit('endLoading')
          this.$router.push({ name: 'mypageError' })
          return err
        })
    },
  },
}
</script>
<style scoped>
.v-application ul,
.v-application ol {
  padding-left: 0 !important;
}

.v-application a {
  color: #0a005a;
}
</style>
