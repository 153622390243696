const listDayJPName = {};
listDayJPName['sunday']= '日曜日';
listDayJPName['monday']= '月曜日';
listDayJPName['tuesday']= '火曜日';
listDayJPName['wednesday']= '水曜日';
listDayJPName['thursday']= '木曜日';
listDayJPName['friday']= '金曜日';
listDayJPName['saturday']= '土曜日';

export {
  listDayJPName
}