<template>
  <section class="l-content-area">
    <div class="l-content l-content--login">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="c-title__bloc c-title__bloc--login">
            <h1>ご契約者さま<span>アカウント連携</span></h1>
          </section>

          <section class="p-bloc p-bloc__data-sync">
            <div class="p-data-sync__bloc">
              <p>
                予約サービスをご利用するためには、お客様情報の連携が必要です。<br />
                店舗利用時にご登録されているお客様情報をご入力ください。
              </p>
              <div class="p-data-sync__form">
                <dl class="c-form-parts">
                  <dt>メールアドレス</dt>
                  <dd>
                    <p>{{ values.email }}</p>
                  </dd>
                  <dt>お名前</dt>
                  <dd>
                    <ul class="input-area">
                      <li>
                        <input-text
                          id="lastName"
                          :error="errors.lastName"
                          :value="values.lastName"
                          :placeholder="'姓'"
                          :name="'lastName'"
                          :text-error="textErrors.name"
                          :is-required="true"
                          @onHandleChange="onHandleChange"
                          @onValidate="onValidate"
                        ></input-text>
                      </li>
                      <li>
                        <input-text
                          id="firstName"
                          :error="errors.firstName"
                          :value="values.firstName"
                          :placeholder="'名'"
                          :name="'firstName'"
                          :text-error="textErrors.name"
                          :is-required="true"
                          @onHandleChange="onHandleChange"
                          @onValidate="onValidate"
                        ></input-text>
                      </li>
                    </ul>
                  </dd>
                  <dt>ご連絡先<span>※ハイフン無し</span></dt>
                  <dd>
                    <input-phone
                      name="phone"
                      :max-length="11"
                      placeholder="08012345678"
                      :value="values.phone"
                      :error="errors.phone"
                      :text-error="textErrors.phone"
                      @onHandleChange="onHandleChange"
                      @onValidate="onValidate"
                    >
                    </input-phone>
                  </dd>
                  <dt>顧客番号</dt>
                  <dd>
                    <input-text
                      id="customerNo"
                      :error="errors.customerNo"
                      :value="values.customerNo"
                      placeholder="01234567"
                      :name="'customerNo'"
                      :text-error="textErrors.customerNo"
                      :is-required="true"
                      :pattern="regex"
                      :max-length="8"
                      @onHandleChange="onHandleChange"
                      @onValidate="onValidate"
                    ></input-text>
                  </dd>
                  <p>※顧客番号は、お会計時のレシートもしくはご契約書に記載している８桁の数字です。</p>
                </dl>
              </div>
            </div>

            <button-registration
              :next-text="'次へ'"
              :prev-text="'戻る'"
              :handle-next-button="nextToStep"
              :handle-prev-button="prevToStep"
              :prev-text-color="'#787878'"
            ></button-registration>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import InputText from '../common/InputText.vue'
import * as errorMessage from '../../constant/text'
import InputPhone from '../common/InputPhone'
import * as regex from '../../helpers/regex'
import ButtonRegistration from '../common/ButtonRegistration'
import { getState } from '../../actions/requestReservation'
import { rulesValidateDatasync } from '../../helpers/schemaValidate'
import { scrollToTop } from '../../helpers'
import { linkStep1, logout } from '../../actions/requestLogin'

export default {
  name: 'DataSync',
  components: {
    InputText,
    InputPhone,
    ButtonRegistration,
  },
  props: {
    currentStep: {
      type: String,
      default: '1',
    },
    isForceUpdate: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Object,
      default: () => {
        return {
          userId: '',
          firstName: '',
          lastName: '',
          phone: '',
          customerNo: '',
          email: '',
        }
      },
    },
  },
  data() {
    return {
      listState: [],
      stateSelected: {
        id: '',
        name: '',
      },
      textErrors: {
        name: errorMessage.NAME_INVALID,
        phone: errorMessage.PHONE_INVALID,
        customerNo: errorMessage.CUSTOMER_NO_IS_NUMBER,
      },
      errors: {
        firstName: '',
        lastName: '',
        phone: '',
        customerNo: '',
      },
      regex: regex.patternCustomerNo,
      click: false,
    }
  },
  watch: {
    isForceUpdate(newVal) {
      if (newVal && this.currentStep === '1') {
        this.nextToStep()
      }
    },
  },
  created() {
    const userInfo = JSON.parse(sessionStorage.getItem('user'))
    this.values.email = userInfo.email
    this.onHandleChange('email', userInfo.email)
    this.getState(this.values.region_id)
  },
  methods: {
    onHandleChange(field, value) {
      this.$emit('onHandleChange', field, value)
    },
    onValidate(field, value) {
      this.errors[field] = value
      this.$emit('onValidate', false)
    },
    prevToStep() {
      this.$emit('prevToStep')
      this.$authInfo.setToken(sessionStorage.getItem('token'))
      logout()
        .then(res => {
          const {
            data: { success },
          } = res
          if (success && success === 1) {
            this.$authInfo.setToken('')
            this.$authInfo.setUser({})
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('userInfo')
            sessionStorage.removeItem('userLinked')
            sessionStorage.removeItem('studioInfo')
            sessionStorage.removeItem('userId')
            sessionStorage.removeItem('user')
            sessionStorage.removeItem('booking')
            sessionStorage.removeItem('linked')
            this.$router.push({ name: 'login' })
          }
        })
        .catch(err => {
          return err
        })
    },
    nextToStep() {
      this.$emit('startLoading')
      rulesValidateDatasync
        .validate(this.values, { abortEarly: false })
        .then(() => {
          // call linked Step1 func ( check linked acc exist or not )
          linkStep1(this.values)
            .then(res => {
              const {
                data: { success },
              } = res
              if (success) {
                const {
                  data: { data },
                } = res
                // define contact method
                if (data[Object.keys(data)[1]] == 1) {
                  this.$emit('onHandleChange', 'contactMethod', 'sms')
                } else if (data[Object.keys(data)[1]] == 2) {
                  this.$emit('onHandleChange', 'contactMethod', 'email')
                }
                // notes: both customerInfo matched true or false, goes to next step
                // send matched true , false value to next step
                if (data.matched) {
                  this.$emit('onHandleChange', 'customerInfo', true)
                  if (!this.click) {
                    this.$emit('nextToStep')
                  }
                  this.click = true
                } else {
                  this.$emit('onHandleChange', 'customerInfo', false)
                  if (!this.click) {
                    this.$emit('nextToStep')
                  }
                  this.click = true
                  //this.$router.push({name: 'account-error'})
                }
              }
            })
            .catch(err => {
              this.$emit(err)
              this.$emit('endLoading')
              this.$router.push({ name: 'account-error' })
            })
        })
        .catch(err => {
          scrollToTop()
          err.inner.forEach(error => {
            this.errors[error.path] = error.message
          })
          this.$emit('endLoading')
        })
      // Prevent multiple clicks on button by emitting 'endLoading' event only when encountering an error. The next page (DataSyncAuth) emits 'endLoading' on creation.
    },
    getState(selectedValue = '') {
      this.$emit('startLoading')
      getState()
        .then(res => {
          this.$emit('endLoading')
          const {
            data: { success },
          } = res
          if (success === 1) {
            const responseListState = res.data.data
            this.listState = Object.keys(responseListState).map(key => {
              return {
                id: key,
                name: responseListState[key],
              }
            })
            if (selectedValue) {
              const listStateSelected = this.listState.filter(state => {
                return (
                  state.id === `jp-${selectedValue >= 10 ? selectedValue : '0' + selectedValue}`
                )
              })
              if (listStateSelected.length > 0) {
                this.stateSelected = listStateSelected[0]
                this.onHandleChange('state', listStateSelected[0].id)
              }
            }
          }
        })
        .catch(error => {
          this.$emit('endLoading')
          return error
        })
    },
  },
}
</script>
<style scoped>
.v-application ul,
.v-application ol {
  padding-left: 0 !important;
}

.v-application a {
  color: #0a005a;
}
</style>
