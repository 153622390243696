<template>
  <section class="l-content-area">
    <div class="l-content l-content--reservation">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="c-title__bloc c-title__bloc--reservation">
            <h1>来店予約</h1>
          </section>
          <section class="p-bloc p-bloc__reservation">
            <div class="p-reservation__finish">
              <h2>ご予約の登録が<span>完了いたしました</span></h2>
              <p>
                予約番号は、お問い合わせの際に必要となりますのでお控えください。<br />
                また、確認のメールをお送りしましたので、ご予約内容をご確認ください。
              </p>
              <div class="reservation-number">
                <h4>ご予約番号</h4>
                <p class="num">{{ code }}</p>
              </div>
              <p>マイページより、予約番号・予約情報の確認が<span>可能となっております。</span></p>

              <button-registration
                :next-text="'マイページ'"
                :is-prev-button="false"
                :handle-next-button="nextToStep"
                :prev-text-color="'#787878'"
              >
              </button-registration>
            </div>
            <div class="p-reservation__attention">
              <div class="reservation-attention">
                <h3>ご注意事項</h3>
                <h4>【キャンセルに関する注意事項】</h4>
                <ul>
                  <li>
                    キャンセル受付締切：ご予約日の前日までマイページ、もしくはお電話よりキャンセルが可能です。
                  </li>
                  <li>キャンセルポリシー：ご予約いただきました店舗に直接お問い合わせください。</li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import ButtonRegistration from '../common/ButtonRegistration'
export default {
  name: 'MypageReservationComponents',
  components: {
    ButtonRegistration,
  },
  data() {
    return {
      code: '',
    }
  },
  created() {
    if (sessionStorage.getItem('reservationCode')) {
      this.code = sessionStorage.getItem('reservationCode')
    } else {
      this.$router.push({
        name: 'mypage',
      })
    }
  },
  methods: {
    nextToStep() {
      sessionStorage.removeItem('reservationCode')
      this.$router.push({
        name: 'mypage',
      })
    },
  },
}
</script>
<style scoped>
.v-application ul,
.v-application ol {
  padding-left: 0 !important;
}

.v-application a {
  color: #0a005a;
}
</style>
