<template>
  <main-layout :title="title">
    <reminder-component :is-reminder-password="true"></reminder-component>
  </main-layout>
</template>

<script>
  import MainLayout from '../components/layout/MainLayout'
  import ReminderComponent from '../components/reminder/index'
  export default {
    name: 'ReminderPassword',
    components: { ReminderComponent, MainLayout },
    data() {
      return {
        title: "MEN'S SVENSON｜パスワード再設定"
      }
    }
  }
</script>

<style scoped>

</style>
