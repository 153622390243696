<template>
  <div>
    <section class="l-content-area">
      <div class="l-content l-content--reservation">
        <div class="l-wrap__outer">
          <div class="l-wrap__inner">
            <section class="c-title__bloc c-title__bloc--reservation">
              <h1>ご来店予約</h1>
              <div class="c-btn c-btn--title-back">
                <a href="#" @click.prevent="prevToStep()">戻る</a>
              </div>
            </section>

            <section class="p-bloc p-bloc__reservation">
              <h2 class="p-reservation__step">STEP 5</h2>

              <div class="p-reservation__bloc">
                <p>ご来店希望日時を選択してください。</p>

                <div class="p-reservation__form">
                  <dl class="c-form-parts">
                    <dt v-if="!isDesignCut">第１希望</dt>
                    <dd>
                      <input-date-time
                        :id="'firstTime'"
                        :error="errors.alreadyTaken || errors.firstTimeDisplay"
                        :placeholder="'希望日時を選択'"
                        :name="'firstTime'"
                        :value="firstTimeDisplay"
                        :attr="{ readonly: 'readonly' }"
                        @onHandleClick="onHandleClick"
                      />
                    </dd>
                    <dt v-if="!isDesignCut">第２希望</dt>
                    <dd v-if="!isDesignCut">
                      <input-date-time
                        :id="'secondTime'"
                        :error="errors.secondTimeDisplay"
                        :placeholder="'希望日時を選択'"
                        :name="'secondTime'"
                        :value="secondTimeDisplay"
                        :attr="{ readonly: 'readonly' }"
                        @onHandleClick="onHandleClick"
                      >
                      </input-date-time>
                    </dd>
                  </dl>
                </div>

                <div class="p-bloc p-bloc__call p-bloc__call--step4">
                  <p>
                    ご予約内容に関するご確認事項などございましたら、<br />
                    直接ご予約店舗にご連絡をお願いいたします。
                  </p>
                  <div class="telnum">
                    <span>{{ phoneNumberStore }}</span>
                  </div>
                  <div class="time">
                    <table>
                      <tbody>
                        <tr>
                          <td class="text-left">平日</td>
                          <td class="text-left">
                            : AM{{ openingTimeDisplay }}～PM{{ closingTimeDisplay }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">土･日･祝</td>
                          <td class="text-left">
                            : AM{{ altOpeningTimeDisplay }}～PM{{ altClosingTimeDisplay }}
                          </td>
                        </tr>
                        <tr>
                          <td class="text-left">定休日</td>
                          <td class="text-left">: {{ regularHolidayDisplay }}</td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>

              <button-registration
                :prev-text="'戻る'"
                :next-text="'次へ'"
                :handle-prev-button="prevToStep"
                :handle-next-button="nextToStep"
                :prev-text-color="'#787878'"
              >
              </button-registration>
            </section>
          </div>
        </div>
      </div>
    </section>
    <select-date-time
      v-if="isShowDateTime && timeIsSelect === 'firstTime'"
      :name="'firstTime'"
      :date.sync="timeVisited.firstTime.date"
      :hour.sync="timeVisited.firstTime.hour"
      :minute.sync="timeVisited.firstTime.minute"
      :list-holiday="listHoliday"
      :available-booking-start-period="availableBookingStartPeriod"
      :available-booking-end-period="availableBookingEndPeriod"
      :max-date="maxDate"
      :is-design-cut="isDesignCut"
      :is-time-cancel="true"
      :studio="store"
      :status="status"
      :reservation-id="reservationId"
      :estimated-duration="estimatedDuration"
      @onHandleChange="onHandleChange"
      @onSubmitDateTime="onSubmitDateTime"
      @onCancelDateTime="onCancelDateTime"
      @onChangeStatus="onChangeStatus"
    >
    </select-date-time>
    <select-date-time
      v-if="isShowDateTime && timeIsSelect === 'secondTime'"
      :name="'secondTime'"
      :date.sync="timeVisited.secondTime.date"
      :hour.sync="timeVisited.secondTime.hour"
      :minute.sync="timeVisited.secondTime.minute"
      :list-holiday="listHoliday"
      :available-booking-start-period="availableBookingStartPeriod"
      :available-booking-end-period="availableBookingEndPeriod"
      :max-date="maxDate"
      :is-design-cut="isDesignCut"
      :is-time-cancel="true"
      :reservation-id="reservationId"
      :status="status"
      @onHandleChange="onHandleChange"
      @onSubmitDateTime="onSubmitDateTime"
      @onCancelDateTime="onCancelDateTime"
      @onChangeStatus="onChangeStatus"
    >
    </select-date-time>
  </div>
</template>

<script>
import ButtonRegistration from '../../common/ButtonRegistration'
import InputDateTime from '../../common/InputDateTime'
import SelectDateTime from '../../reservation/SelectDateTime.vue'
import moment from 'moment'
import { validateRequired, rulesValidateReservationTime } from '../../../helpers/schemaValidate'
import { DATE_TIME_INVALID } from '../../../constant/text'
import {
  requestRegisterReservation,
  getHolidaysByStoreId,
  getStore,
} from '../../../actions/requestMypage'
import { formatTime } from '../../../helpers/formatTimeStudio'
import { listDays } from '../../../constant/listDayNumber'
import { listDayJPName } from '../../../constant/listDayJPName'

export default {
  name: 'MypageReservationTime',
  components: {
    SelectDateTime,
    InputDateTime,
    ButtonRegistration,
  },
  props: {
    currentStep: {
      type: String,
      default: '5',
    },
    isForceValidate: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
      default: '',
    },
    store: {
      type: String,
      default: '',
    },
    genderPreference: {
      type: String,
      default: '',
    },
    treatmentContent: {
      type: String,
      default: '',
    },
    isDesignCut: {
      type: Boolean,
      default: false,
    },
    services: {
      type: Array,
      default: () => {
        return []
      },
    },
    status: {
      type: String,
      default: '',
    },
    timeVisited: {
      type: Object,
      default: () => {
        return {
          firstTime: {
            date: '',
            hour: '',
            minute: '',
          },
          secondTime: {
            date: '',
            hour: '',
            minute: '',
          },
        }
      },
    },
    reservationId: {
      type: String,
      default: '',
    },
    stylist: {
      type: Array,
      default: () => {
        return []
      },
    },
    estimatedDuration: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      isShowDateTime: false,
      timeIsSelect: 'firstTime',
      availableBookingStartPeriod: '',
      availableBookingEndPeriod: '',
      maxDate: '',
      listHoliday: [],
      errors: {
        firstTimeDisplay: '',
        secondTimeDisplay: '',
        alreadyTaken: '',
      },
      phoneNumberStore: '',
      regularHoliday: '',
      irregularHoliday: '',
      openingTime: '',
      closingTime: '',
      endWeekOpenTime: '',
      endWeekCloseTime: '',
    }
  },
  computed: {
    firstTimeDisplay() {
      if (this.timeVisited.firstTime.date) {
        return (
          this.timeVisited.firstTime.date +
          ' ' +
          this.timeVisited.firstTime.hour +
          ':' +
          this.timeVisited.firstTime.minute
        )
      }
      return ''
    },
    secondTimeDisplay() {
      if (this.timeVisited.secondTime.date) {
        return (
          this.timeVisited.secondTime.date +
          ' ' +
          this.timeVisited.secondTime.hour +
          ':' +
          this.timeVisited.secondTime.minute
        )
      }
      return ''
    },
    openingTimeDisplay() {
      if (this.openingTime) {
        return formatTime(this.openingTime)
      }
      return ''
    },
    closingTimeDisplay() {
      if (this.closingTime) {
        return formatTime(this.closingTime, true)
      }
      return ''
    },
    altOpeningTimeDisplay() {
      if (this.endWeekOpenTime) {
        return formatTime(this.endWeekOpenTime)
      }
      return ''
    },
    altClosingTimeDisplay() {
      if (this.endWeekCloseTime) {
        return formatTime(this.endWeekCloseTime, true)
      }
      return ''
    },
    regularHolidayDisplay() {
      if (this.regularHoliday) {
        let regularHolidayArr = this.regularHoliday ? this.regularHoliday.split(',') : [],
          listName = [],
          strHoliday = ''
        regularHolidayArr.map((value, index) => {
          if (listDayJPName[value]) {
            listName.push(listDayJPName[value])
          }
        })
        strHoliday = listName.join(', ')
        return strHoliday
      }
      return ''
    },
  },
  watch: {
    isForceValidate(newVal) {
      if (
        newVal &&
        ((this.isDesignCut && this.currentStep === '5') ||
          (!this.isDesignCut && this.currentStep === '4'))
      ) {
        this.nextToStep()
      }
    },
    firstTimeDisplay(newVal) {
      const values = {
        firstTimeDisplay: newVal,
      }
      validateRequired('firstTimeDisplay', DATE_TIME_INVALID)
        .validateAt('firstTimeDisplay', values)
        .then(() => {
          this.errors.firstTimeDisplay = ''
        })
        .catch(error => {
          this.errors.firstTimeDisplay = error.message
        })
    },
    secondTimeDisplay(newVal) {
      const values = {
        secondTimeDisplay: newVal,
      }
      validateRequired('secondTimeDisplay', DATE_TIME_INVALID)
        .validateAt('secondTimeDisplay', values)
        .then(() => {
          this.errors.secondTimeDisplay = ''
        })
        .catch(error => {
          this.errors.secondTimeDisplay = error.message
        })
    },
    store(newVal) {
      this.getStore()
    },
  },
  created() {
    const errorPage = localStorage.getItem('ErrorPage')
    if (errorPage === 'true') {
      localStorage.removeItem('ErrorPage')
      this.unsetTimeDisplays();
    }

    this.$emit('startLoading')
    if (this.store) {
      this.getStore()
    }
  },
  beforeDestroy() {
    localStorage.removeItem('firstTime')
    localStorage.removeItem('secondTime')
  },
  methods: {
    unsetTimeDisplays() {
      this.$emit('onHandleChange', 'firstTime', { date: '', hour: '', minute: '' })
      this.$emit('onHandleChange', 'firstTimeDisplay', '');
      this.$emit('onHandleChange', 'secondTime', { date: '', hour: '', minute: '' })
      this.$emit('onHandleChange', 'secondTimeDisplay', '');
    },
    prevToStep() {
      this.unsetTimeDisplays();
      this.$emit('prevToStep')
    },
    nextToStep() {
      localStorage.setItem('statusMsg', this.status)
      const values = {
        firstTimeDisplay: this.firstTimeDisplay,
        secondTimeDisplay: this.secondTimeDisplay,
      }
      this.$emit('startLoading')
      if (!this.isDesignCut) {
        rulesValidateReservationTime
          .validate(values, { abortEarly: false })
          .then(() => {
            this.updateReservation()
          })
          .catch(err => {
            err.inner.forEach(error => {
              this.errors[error.path] = error.message
              this.$emit('endLoading')
            })
          })
      } else {
        validateRequired('firstTimeDisplay', DATE_TIME_INVALID)
          .validateAt('firstTimeDisplay', values)
          .then(() => {
            this.updateReservation()
          })
          .catch(err => {
            this.errors.firstTimeDisplay = err.message
            this.$emit('endLoading')
          })
      }
    },
    onSubmitDateTime(time) {
      if (time === 'firstTime') {
        localStorage.setItem('firstTime', JSON.stringify(this.timeVisited.firstTime))
      } else {
        localStorage.setItem('secondTime', JSON.stringify(this.timeVisited.secondTime))
      }
      window.scrollTo(0, 0)
      this.isShowDateTime = false
      this.$emit('disableSwipeRight', false)
      this.$emit('onValidateSwipe', false)
    },
    updateReservation() {
      let waiting = this.status === 'waiting' ? 1 : 0
      const autoAssigned = waiting ? 1 : 0

      requestRegisterReservation({
        currentStep: 5,
        userId: this.userId,
        storeId: this.store,
        timeVisited: this.timeVisited,
        isDesignCut: this.isDesignCut,
        services: this.services,
        reservationId: this.reservationId,
        status: null,
        stylist: this.stylist,
        genderPreference: this.genderPreference,
        waiting: waiting,
        requestMsg: '',
        autoAssigned: autoAssigned,
      })
        .then(response => {
          const {
            data: { success },
          } = response
          if (success && success === 1) {
            const {
              data: { data },
            } = response
            this.$emit('setReservationId', data.id.toString())
          }
          this.$emit('nextToPage') // Changed to next page in order to skip step 6.
          this.$emit('endLoading')
        })
        .catch(error => {
          error = error.response.data.error
          if (error.error_code && error.error_code == 1001) {
            this.errors.alreadyTaken = error.status
            this.$emit('onHandleChange', 'firstTime', { date: '', hour: '', minute: '' })
            this.$emit('endLoading')
          } else {
            this.$router.push({ name: 'mypageError' })
          }
          return error
        })
    },
    onCancelDateTime(time) {
      const oldFirstTime = localStorage.getItem('firstTime')
      const oldSecondTime = localStorage.getItem('secondTime')
      const defaultTime = {
        date: '',
        hour: '',
        minute: '',
      }
      if (time === 'firstTime') {
        if (oldFirstTime) {
          this.$emit('onHandleChange', time, JSON.parse(oldFirstTime))
        } else {
          this.$emit('onHandleChange', time, defaultTime)
        }
      } else {
        if (oldSecondTime) {
          this.$emit('onHandleChange', time, JSON.parse(oldSecondTime))
        } else {
          this.$emit('onHandleChange', time, defaultTime)
        }
      }
      this.isShowDateTime = false
      window.scrollTo(0, 0)
      this.$emit('disableSwipeRight', false)
      this.$emit('onValidateSwipe', false)
    },
    onHandleClick(value) {
      // Clear the errors;
      this.errors.firstTimeDisplay = ''
      this.errors.secondTimeDisplay = ''
      this.errors.alreadyTaken = ''

      this.isShowDateTime = true
      this.timeIsSelect = value
      window.scrollTo(0, 0)
      this.$emit('disableSwipeRight', true)
    },
    onHandleChange(field, value) {
      const values = {
        date: this.timeVisited[this.timeIsSelect].date,
        hour: this.timeVisited[this.timeIsSelect].hour,
        minute: this.timeVisited[this.timeIsSelect].minute,
      }
      const newValues = {
        ...values,
        [field]: value,
      }
      this.$emit('onHandleChange', this.timeIsSelect, newValues)
    },
    getListHoliday(studioId) {
      getHolidaysByStoreId(studioId)
        .then(res => {
          this.$emit('endLoading')
          const {
            data: { success },
          } = res
          if (success === 1) {
            const {
              data: { data },
            } = res
            this.listHoliday = data
            if (this.regularHoliday) {
              this.getRegularHoliday()
            } else {
              this.getStartAndEndDates()
            }
            if (this.irregularHoliday) {
              this.getIrregularHoliday()
            }
          }
        })
        .catch(error => {
          this.$emit('endLoading')
          console.log('Has error when call get holiday mypage: ' + error)
          return error
        })
    },
    onChangeStatus(field, value) {
      this.$emit('onChangeStatus', field, value)
    },
    getStore() {
      getStore(this.store)
        .then(res => {
          this.$emit('endLoading')
          const {
            data: { success },
          } = res
          if (success && success === 1) {
            const {
              data: { data },
            } = res
            this.phoneNumberStore = data.phone_number !== null ? data.phone_number : ''
            this.openingTime = data.opening_time
            this.closingTime = data.closing_time
            this.endWeekOpenTime = data.alt_opening_time
            this.endWeekCloseTime = data.alt_closing_time
            this.regularHoliday = data.regular_holidays
            this.irregularHoliday = data.irregular_holidays
            this.getListHoliday(this.store)
          }
        })
        .catch(err => {
          this.$emit('endLoading')
          console.log('Has error when call get store mypage: ' + err)
          return err
        })
    },
    getRegularHoliday() {
      var currentDate = new Date();
      var startMonth = currentDate.getMonth() + 1;
      startMonth = startMonth < 10 ? '0' + startMonth : startMonth;
      var startYear = currentDate.getFullYear();
      var startDay = currentDate.getDate();
      startDay = startDay < 10 ? '0' + startDay : startDay;

      var currentMonth = currentDate.getMonth() + 1;
      var inThreeMonths = (currentMonth + 3) % 12; 
      var endYear = startYear; // Default to current year.
      var endMonth = inThreeMonths;

      // Adjust year if inThreeMonths exceeds 12, meaning it has crossed into the next year.
      if (inThreeMonths < currentMonth) {
          endYear++;
      }

      endMonth = endMonth < 10 ? '0' + endMonth : endMonth;

      this.availableBookingStartPeriod = startYear + '-' + startMonth + '-' + startDay;
      this.availableBookingEndPeriod = endYear + '-' + endMonth + '-' + 10;
      this.maxDate = endYear + '-' + endMonth + '-' + 10;

      let regularHolidayArr = this.regularHoliday ? this.regularHoliday.split(',') : [],
        start = moment(availableBookingStartPeriod),
        end = moment(availableBookingEndPeriod),
        result = []

      // Loop for mulitple regular holidays
      if (regularHolidayArr.length) {
        regularHolidayArr.map((value, index) => {
          let day = parseInt(Object.keys(listDays).find(key => listDays[key] === value))

          if (!isNaN(day)) {
            let current = start.clone()
            while (current.day(day).isBefore(end)) {
              result.push(current.clone())
              current.day(7 + day)
            }
          }
        })

        let regular_holidays = result.length ? result.map(m => m.format('YYYY-MM-DD')) : []
        this.listHoliday = this.listHoliday.concat(regular_holidays)
        this.listHoliday = [...new Set(this.listHoliday)]
      }
    },
    getIrregularHoliday() {
      let irre_holidays = this.irregularHoliday.split(',')

      if (irre_holidays.length > 0) {
        let today = new Date()
        let current_month = today.getMonth()
        let year = today.getFullYear()
        let next_year = today.getFullYear() + 1

        let holidays = irre_holidays.map(holiday => {
          let month_day = holiday.split('/')
          let month = month_day[0]
          let day = month_day[1]
          if (day != undefined) {
            if (parseInt(month) < parseInt(current_month)) {
              return next_year + '-' + month + '-' + day
            } else {
              return year + '-' + month + '-' + day
            }
          }
        })
        this.listHoliday = this.listHoliday.concat(holidays)
        this.listHoliday = [...new Set(this.listHoliday)]
      }
    },
    getStartAndEndDates() {
      var currentDate = new Date()
      var startMonth = currentDate.getMonth() + 1
      startMonth = startMonth < 10 ? '0' + startMonth : startMonth
      var startYear = currentDate.getFullYear()
      var startDay = currentDate.getDate()

      startDay = startDay < 10 ? '0' + startDay : startDay
      var endDate = currentDate
      var availableEndDate = new Date(endDate.setMonth(endDate.getMonth() + 3))
      var endMonth = availableEndDate.getMonth() + 1
      endMonth = endMonth < 10 ? '0' + endMonth : endMonth
      var endYear = availableEndDate.getFullYear()
      var availableBookingStartPeriod = startYear + '-' + startMonth + '-' + startDay
      this.availableBookingStartPeriod = availableBookingStartPeriod
      var availableBookingEndPeriod = endYear + '-' + endMonth + '-' + 10
      this.availableBookingEndPeriod = availableBookingEndPeriod
      this.maxDate = endYear + '-' + endMonth + '-' + 10
    },
  },
}
</script>

<style scoped>
.v-application ul,
.v-application ol {
  padding-left: 0 !important;
}

.v-application a {
  color: #0a005a;
}

.p-bloc__call .telnum span:before,
.p-bloc__call .telnum a:before {
  display: inline-block;
  vertical-align: top;
  content: '';
  width: 27px;
  height: 27px;
  margin: 0px 10px 0 0;
  background-image: url(../../../../src/assets/img/form/phone.png);
  background-position: 0 0;
  background-size: 100% 100%;
}

.time {
  display: flex;
  justify-content: center;
}
.text-left {
  text-align: left !important;
}
</style>
