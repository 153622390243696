<template>
  <div class="overlayContent">
    <v-overlay :opacity="0.1" :value="!isRendered">
      <v-progress-circular
        :size="70"
        :width="7"
        color="rgba(0, 0, 0, 0.7)"
        indeterminate
      ></v-progress-circular>
    </v-overlay>
    <div class="modal-zoom-window">
      <div id="modal-time" class="p-reservation-modal__bloc p-reservation-modal__bloc--time">
        <h2 class="modal__title">希望時間を選択</h2>

        <div class="modal__bloc modal__bloc--time">
          <div class="select__bloc">
            <ul>
              <li class="arrow arrow--prev" :class="handleLeftArrow">
                <a @click="changeCancelDate('pre')"
                  ><img src="../../assets/img/ui_parts/modal_date_arw_left.png" alt="＜"
                /></a>
              </li>
              <li class="month">{{ dateTime }}</li>
              <li class="arrow arrow--next" :class="handleRightArrow">
                <a @click="changeCancelDate('next')"
                  ><img src="../../assets/img/ui_parts/modal_date_arw_right.png" alt="＜"
                /></a>
              </li>
            </ul>
            <p>想定施術時間：{{ estimatedTime }}時間</p>
          </div>
          <h3 v-if="!isLoading">{{ displayTitle }}</h3>

          <div class="time-zone__bloc">
            <div class="c-time-zone-parts">
              <ul :class="`radio-area ${error ? 'is-error' : ''}`">
                <li v-for="(slot, index) in sortedSlots" :key="index">
                  <input
                    v-if="isTimeCancel"
                    :id="`slot_${index}`"
                    type="radio"
                    name="time-zone"
                    :value="slot.start_date.slice(11, 16)"
                    :checked="slot.start_date.slice(11, 16) === time"
                    @change="getValueSelected($event)"
                    @input="slot.value = $event.target.value"
                  />
                  <input
                    v-else
                    :id="`slot_${index}`"
                    type="radio"
                    name="time-zone"
                    :value="slot.start_date.slice(11, 16)"
                    :checked="slot.start_date.slice(11, 16) === time"
                    @change="getValueSelected($event)"
                    @input="slot.value = $event.target.value"
                  />
                  <label :for="`slot_${index}`">
                    <span>{{
                      slot.start_date.slice(11, 16) + '~' + slot.end_date.slice(11, 16)
                    }}</span>
                  </label>
                </li>
              </ul>
              <p class="error-txt">{{ textError }}</p>
            </div>
          </div>
          <div v-if="BtnDisplay">
            <button-registration
              v-if="isTimeCancel"
              prev-text="戻る"
              :is-next-button="false"
              prev-text-color="#787878"
              :handle-prev-button="onChangeShowSelectTimeCancel"
            >
            </button-registration>
            <button-registration
              v-else-if="isDesignCut"
              prev-text="戻る"
              prev-text-color="#787878"
              :is-next-button="false"
              :handle-prev-button="onChangeShowSelectTimeCancel"
            >
            </button-registration>
            <button-registration
              v-else
              next-text="希望日時を設定"
              prev-text="戻る"
              prev-text-color="#787878"
              :handle-next-button="onSubmitDateTime"
              :handle-prev-button="onChangeShowSelectTimeCancel"
            >
            </button-registration>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import moment from 'moment'
import { getDateTimeWithDayOfWeekFormatJapan } from '../../helpers/formatDate'
import { scrollModalToTop, scrollToTop } from '../../helpers'
import ButtonRegistration from '../common/ButtonRegistration'
import { getSlotsStore, getReservationById } from '../../actions/requestReservation'
import { getSlotStore } from '../../actions/requestMypage'

export default {
  name: 'SelectDateCancel',
  components: { ButtonRegistration },
  props: {
    date: {
      type: String,
      default: '',
    },
    isDesignCut: {
      type: Boolean,
      default: true,
    },
    isTimeCancel: {
      type: Boolean,
      default: false,
    },
    studio: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    textError: {
      type: String,
      default: 'ご希望時間を選択してください。',
    },
    hour: {
      type: String,
      default: '',
    },
    minute: {
      type: String,
      default: '',
    },
    reservationId: {
      type: String,
      default: '',
    },
    status: {
      type: String,
      default: '',
    },
    maxDate: {
      type: String,
      default: '',
    },
    listHoliday: {
      type: Array,
      default: () => {
        return []
      },
    },
    preSlots: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      listSlot: [],
      isLoading: true,
      isRendered: false,
    }
  },
  computed: {
    dateTime() {
      return getDateTimeWithDayOfWeekFormatJapan(this.date)
    },
    time() {
      if (this.status === 'waiting') {
        const slotSelected = this.listSlot.filter(slot => {
          const timeSelected = slot.start_date.slice(11, 16)
          const startTime = timeSelected
          if (startTime === this.hour + ':' + this.minute) {
            return slot
          }
        })
        if (slotSelected.length) {
          return slotSelected[0]['start_date'].slice(11, 16)
        }
      }
      return null
    },
    displayTitle() {
      if (this.listSlot.length > 0) {
        return this.textError
      } else if (this.slotLength === 0) {
        return '現在利用可能な予約枠はありません'
      } else {
        return ''
      }
    },
    BtnDisplay() {
      if (
        (!this.isLoading && this.isRendered && this.listSlot.length > 0) ||
        (!this.isLoading && this.slotLength == 0)
      ) {
        return true
      } else {
        return false
      }
    },
    sortedSlots: function() {
      this.listSlot.sort((a, b) => {
        return new Date(a.start_date) - new Date(b.start_date)
      })
      this.isRendered = true
      return this.listSlot
    },
    handleLeftArrow: function() {
      let previous_date = moment(this.date)
        .subtract(1, 'days')
        .format('YYYY-MM-DD')
      if (
        moment(previous_date).isBetween(
          moment().format('YYYY-MM-DD'),
          moment(this.maxDate).format('YYYY-MM-DD'),
          undefined,
          '[]'
        )
      ) {
        return ''
      } else {
        return 'disabledArrow'
      }
    },
    handleRightArrow: function() {
      let next_date = moment(this.date)
        .add(1, 'days')
        .format('YYYY-MM-DD')
      if (
        moment(next_date).isBetween(
          moment().format('YYYY-MM-DD'),
          moment(this.maxDate).format('YYYY-MM-DD'),
          undefined,
          '[]'
        )
      ) {
        return ''
      } else {
        return 'disabledArrow'
      }
    },
  },
  watch: {
    hour() {
      this.$emit('onSubmitDateTime')
      this.$emit('onChangeStatus', 'status', 'waiting')
    },
    status() {
      this.$emit('onSubmitDateTime')
    },
  },
  created() {},
  mounted() {
    this.isRendered = false
    this.estimatedDuration = parseInt(this.estimatedDuration ? this.estimatedDuration : 0) / 60
    scrollModalToTop('modal-inner')
    //check holiday or not from select date vue
    if (!this.listHoliday.includes(this.date)) {
      this.getCancelSlots(this.date)
    } else {
      console.log('this is holiday!')
      this.listSlot = []
      this.slotLength = 0
      this.isRendered = true
    }
  },
  methods: {
    onChangeShowSelectTimeCancel() {
      this.$emit('onChangeShowSelectTimeCancel', false)
      scrollToTop()
    },
    getValueSelected(event) {
      const {
        target: { value },
      } = event
      this.$emit('onChangeTime', value)
      this.$emit('onChangeStatus', 'status', 'waiting')
    },
    onSubmitDateTime() {
      scrollToTop()
      this.$emit('onSubmitDateTime')
    },
    getCancelSlots(date) {
      if (this.isTimeCancel) {
        //this is for mypage
        getSlotStore(this.reservationId, date, 1, this.preSlots)
          .then(res => {
            this.isLoading = true
            const {
              data: { success },
            } = res
            if (success === 1) {
              const {
                data: { data },
              } = res
              this.listSlot = data
              this.isLoading = false
              this.filterSlot()
            }
          })
          .catch(err => {
            console.log(err)
            this.isLoading = false
          })
      } else {
        //this is for annomynous
        getSlotsStore(this.reservationId, date, this.preSlots)
          .then(res => {
            this.isLoading = true
            const {
              data: { success },
            } = res
            if (success === 1) {
              const {
                data: { data },
              } = res
              this.listSlot = data
              this.isLoading = false
              this.filterSlot()
            }
          })
          .catch(err => {
            console.log(err)
            this.isLoading = false
          })
      }
    },
    filterSlot() {
      this.isLoading = true
      let date = moment(this.date).format('YYYY-MM-DD')
      let today = moment().format('YYYY-MM-DD')
      if (today == date) {
        let listAvailableSlot = this.listSlot.filter(slot => {
          let start_time = slot.start_date.slice(11, 16)
          let now = moment()
          let current_time = now.hour() + ':' + now.minutes() + ':' + now.seconds()
          let beginningTime = moment(start_time, 'h:mma')
          let endTime = moment(
            moment(current_time, 'h:mma')
              .add('30', 'minutes')
              .format('HH:mm:ss'),
            'h:mma'
          )
          if (beginningTime.isAfter(endTime)) {
            return slot
          }
        })
        this.listSlot = listAvailableSlot
      }
      if (this.listSlot.length === 0) {
        this.slotLength = 0
        this.isRendered = true
      }
      this.isLoading = false
    },
    changeCancelDate(type) {
      let selectedDate = ''
      if (type === 'pre') {
        selectedDate = moment(this.date)
          .subtract(1, 'days')
          .format('YYYY-MM-DD')
      }
      if (type === 'next') {
        selectedDate = moment(this.date)
          .add(1, 'days')
          .format('YYYY-MM-DD')
      }
      //check if selected date is between min and max date
      let minDate = moment().format('YYYY-MM-DD'),
        maxDate = moment(this.maxDate).format('YYYY-MM-DD')

      if (moment(selectedDate).isBetween(minDate, maxDate, undefined, '[]')) {
        this.$emit('onHandleChange', 'date', selectedDate)
        //check if selected date is holiday or not
        if (!this.listHoliday.includes(selectedDate)) {
          this.isRendered = false
          this.getCancelSlots(selectedDate)
        } else {
          //clean slots and show error message
          console.log('this is holiday!')
          this.listSlot = []
          this.slotLength = 0
          this.isRendered = true
        }
      } else {
        console.log('selected waiting date is not between min and max date.')
      }
    },
  },
}
</script>

<style scoped>
.disabledArrow {
  pointer-events: none;
  opacity: 0.6;
}
</style>
