<template>
  <section class="l-content-area">
    <div class="l-content l-content--mypage">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="c-title__bloc c-title__bloc--mypage">
            <h1>マイページ</h1>
          </section>

          <section class="p-bloc p-bloc__mypage">
            <div class="p-mypage__user">
              <p class="user-status user-status--platinum"> {{ values.memberClassName }}</p>
              <h2 class="user-name">
                {{ values.lastName + ' ' + values.firstName }}<span>さま</span>
              </h2>
              <ul class="user-info">
                <li>顧客番号：{{ values.customerNumber || '--' }}</li>
                <li>所属店舗：{{ values.storeName || '--' }}</li>
              </ul>
              <div class="menu-btn">

                <div v-if="this.$authInfo.getBooking()" class="c-btn c-btn--mypage-menu">
                  <router-link v-if="(store.name) "
                   to="/mypage/reservation">ご来店予約はこちら</router-link>
<router-link v-else
 to="/mypage/reservation_studio">ご来店予約はこちら</router-link>
                </div>
                <div class="c-btn c-btn--mypage-menu">
                  <router-link to="/mypage/profile">ご登録情報の変更</router-link>
                </div>
              </div>
            </div>

            <div v-if="this.$authInfo.getBooking()">
              <div v-show="false" class="p-mypage__counter">
                <div class="mypage-counter">
                  <dl class="counter__title">
                    <dt>予約制限</dt>
                  </dl>
                  <template>
                    <v-simple-table>
                      <template v-slot:default>
                        <tbody>
                          <tr>
                            <td class="text-left"><span class="month-tag">今月</span></td>
                            <td>通常 :</td>
                            <td class="font-weight-bold">{{ counter.regular }}</td>
                            <td>指定 :</td>
                            <td class="font-weight-bold">{{ counter.specified }}</td>
                            <td>キャンセル待ち :</td>
                            <td class="font-weight-bold">{{ counter.waiting }}</td>
                          </tr>
                          <tr>
                            <td class="text-left"><span class="month-tag">来月</span></td>
                            <td>通常 :</td>
                            <td class="font-weight-bold">{{ counter.regular_next_month }}</td>
                            <td>指定 :</td>
                            <td class="font-weight-bold">{{ counter.specified_next_month }}</td>
                            <td>キャンセル待ち :</td>
                            <td class="font-weight-bold">{{ counter.waiting_next_month }}</td>
                          </tr>
                          <tr>
                            <td class="text-left"><span class="month-tag">再来月</span></td>
                            <td>通常 :</td>
                            <td class="font-weight-bold">{{ counter.regular_third_month }}</td>
                            <td>指定 :</td>
                            <td class="font-weight-bold">{{ counter.specified_third_month }}</td>
                            <td>キャンセル待ち :</td>
                            <td class="font-weight-bold">{{ counter.waiting_third_month }}</td>
                          </tr>
                        </tbody>
                      </template>
                    </v-simple-table>
                  </template>
                </div>
              </div>

              <div v-if="listReservationPending.length ||listReservationWaiting.length" class="p-mypage__schedule">
                <div class="mypage-schedule">
                  <dl v-if="listReservationPending.length" class="schedule__title">
                    <dt>来店予約スケジュール</dt>
                  </dl>
                  <div
                    v-for="(reservation, index) in listReservationPending"
                    :key="index"
                    class="schedule__bloc"
                    :value="index + 1"
                  >
                    <a href="#" @click="reservationDetail((value = 'isDetail'), reservation.id)">
                      <p v-if="reservation.start_date" class="date">
                        {{
                          formatReservationDateTime(reservation.start_date, reservation.start_date_display_minutes_diff)
                        }}
                      </p>
                      <p class="studio-name">{{ reservation.store.name }}</p>
                      <div v-if="reservation.services" class="servicesName">
                        <p v-for="service in reservation.services" :key="service.id">
                          {{ service.name }}
                        </p>
                      </div>
                    </a>
                  </div>
                  <dl v-if="listReservationWaiting.length" class="schedule__title">
                    <dt>キャンセル待ち</dt>
                    <dd>※予約確定ではございません</dd>
                  </dl>
                  <div
                    v-for="(reservation, index) in listReservationWaiting"
                    :key="reservation.id"
                    class="schedule__bloc"
                    :value="index + 1"
                  >
                    <a href="#" @click="reservationDetail((value = 'isWaiting'), reservation.id)">
                      <p v-if="reservation.start_date" class="date">
                        {{
                          formatReservationDateTime(reservation.start_date, reservation.start_date_display_minutes_diff)
                        }}
                      </p>
                      <p class="studio-name">{{ reservation.store.name }}</p>
                      <div v-if="reservation.services" class="servicesName">
                        <p v-for="service in reservation.services" :key="service.id">
                          {{ service.name }}
                        </p>
                      </div>
                    </a>
                  </div>
                </div>
              </div>

              <div v-if="store.campaign" class="p-mypage__news">
                <h2>お知らせ</h2>
                <div class="">
                  <div class="d-flex news__bloc">
                    <p class="txt mb-1">
                      <ul class="tag">
                        <li class="c-tag"><span>{{ store.name }}</span></li>
                      </ul>
                    </p>
                    <p class="date mb-1">
                      {{
                        new Date(store.updated_at).getFullYear() +
                          '.' +
                          (new Date(store.updated_at).getMonth() + 1) +
                          '.' +
                          new Date(store.updated_at).getDate()
                      }}
                    </p>
                  </div>
                  <p class="text-left breakWord">{{ store.campaign }}</p>
                </div>
              </div>
            </div>
          </section>
          <section v-if="debugMode" class="debug-mode">
            <h2>Response Tester</h2>
            <a target="_blank" href="https://rsv-api-dev.mens-svenson.net/docs-user/">For docs click here(use VPN)</a>
            <div>
              <div class="row align-center justify-center mt-5">
              <v-text-field v-model="debugEndpoint" id="endpoint" type="text" placeholder="Endpoint" />
              </div>
            </div>
            <div class="row align-center">
              <v-radio-group v-model="debugMethod" row>
                <v-radio label="GET" value="GET"></v-radio>
                <v-radio class="mx-4" label="POST" value="POST"></v-radio>
                <v-radio class="mx-4" label="PUT" value="PUT"></v-radio>
                <v-radio class="mx-4" label="PATCH" value="PATCH"></v-radio>
                <v-radio class="mx-4" label="DELETE" value="DELETE"></v-radio>
              </v-radio-group>
            </div>
            <div class="row align-center justify-start">
              <v-checkbox v-model="addParams" />
              <label for="params">Parameters (JSON):</label>
            </div>
            <div>
              <v-textarea v-if="addParams" v-model="debugParams" id="params" placeholder="Enter parameters as JSON"></v-textarea>
            </div>
            <v-btn @click="sendDebugRequest" class="mt-8">Send Request</v-btn>
            <div class="mt-8">
              <h3>Response:</h3>
              <pre>{{ debugResponse || '--' }}</pre>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import { getUser, getStore, getAllReservationByStatus } from '../../actions/requestMypage'
import { scrollToTop } from '../../helpers/index'
import { getDayOfWeekFromDateTime, getDateTimeFormatJapan, formatReservationDateTime } from '../../helpers/formatDate'
import { handleErrors } from '../../helpers/commonHelpers'

export default {
  name: 'MypageHome',
  components: {},
data() {
    return {
      // Debugging
      currentKonamiIndex: 0,
      debugEndpoint: '',
      debugParams: '',
      debugResponse: null,
      konamiCode: ['#','d', 'm', 'd', 'x', '.', 'Enter'],
      debugMode: false,
      addParams: false,
      debugMethod: [],

      accepted: 'accepted',
      waiting: 'waiting',
      pending: 'pending',
      provisional: 'provisional',
      getDayOfWeekFromDateTime,
      getDateTimeFormatJapan,
      formatReservationDateTime,
      listReservationPending: [],
      listReservationAccepted: [],
      listReservationWaiting: [],
      store: {},
      values: {
        memberClassName: '',
        firstName: '',
        lastName: '',
        userId: '',
        storeName: '',
        customerNumber: '',
      },
      state: 'jp-13',
      counter: {
        regular: '',
        regular_next_month: '',
        regular_third_month: '',
        specified: '',
        specified_next_month: '',
        specified_third_month: '',
        waiting: '',
        waiting_next_month: '',
        waiting_third_month: '',
      },
      isProduction : process.env.VUE_APP_NODE_ENV === 'production'
    }
  },

  mounted() {
    const linked = sessionStorage.getItem('linked')
    if (!linked || linked !== '1') {
      this.$toast.error('アカウント連携を行ってください。')
      return this.$router.push({
        name: 'account',
      })
    }
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleKonamiKeyPress);
  },

  async created() {
    document.addEventListener('keydown', this.handleKonamiKeyPress);
    this.$reservation.resetReservation()
    scrollToTop()
    if (!sessionStorage.getItem('token')) {
      this.$router.push({
        name: 'home',
      })
    } else {
      this.$emit('startLoading')
      this.$authInfo.setToken(sessionStorage.getItem('token'))
      this.$authInfo.setBooking(parseInt(sessionStorage.getItem('booking')) === 1)
      this.$authInfo.setLinked(parseInt(sessionStorage.getItem('linked')) === 1)
      await getAllReservationByStatus([this.pending ,this.waiting, this.provisional]).then(res => {
        const {
          data: { success },
        } = res
        if (success) {
          this.listReservationPending = res.data.data.filter(list => {
            return list.status === 'pending'
          }).sort((a, b) => {
            return new Date(a.start_date) - new Date(b.start_date)
          });

          this.listReservationWaiting = res.data.data.filter(list => {
            return list.status === 'waiting' || list.status === 'provisional'
          }).sort((a, b) => {
            return new Date(a.start_date) - new Date(b.start_date)
          });
        }
      })
      await getUser()
        .then(response => {
          const {
            data: { success },
          } = response
          if (success) {
            const {
              data: {
                data: { id, member_class_name, first_name, last_name, store_id, customer_no, regular_counter, specified_counter, waiting_counter, regular_counter_next_month, specified_counter_next_month, waiting_counter_next_month, regular_counter_third_month, specified_counter_third_month, waiting_counter_third_month },
              },
            } = response
            this.values.userId = id
            this.values.memberClassName = member_class_name || '一般会員'
            this.values.customerNumber = customer_no
            sessionStorage.setItem('userId', id)
            sessionStorage.setItem('customerNumber', customer_no)
            sessionStorage.setItem('memberClassName', member_class_name)
            sessionStorage.setItem('userInfo', JSON.stringify(response.data.data))
            this.values.firstName = first_name || ''
            this.values.lastName = last_name || ''
            this.counter.regular = regular_counter
            this.counter.regular_next_month = regular_counter_next_month
            this.counter.regular_third_month = regular_counter_third_month
            this.counter.specified = specified_counter
            this.counter.specified_next_month = specified_counter_next_month
            this.counter.specified_third_month = specified_counter_third_month
            this.counter.waiting = waiting_counter
            this.counter.waiting_next_month = waiting_counter_next_month
            this.counter.waiting_third_month = waiting_counter_third_month
            if (store_id) {
              getStore(store_id)
                .then(responseStore => {
                  if (responseStore.data.success) {
                    this.store = responseStore.data.data
                    this.values.storeName = responseStore.data.data.name
                    sessionStorage.setItem('studioInfo', JSON.stringify(responseStore.data.data))
                  }
                })
                .catch(err => {
                  return err
                })
            } else {
              this.values.storeName = ''
            }
          }
          this.$emit('endLoading')
        })
        .catch(err => {
          this.$emit('endLoading')
          return err
        })
    }
  },
  methods: {
    reservationDetail(value, id) {
      if (value) {
        localStorage.setItem('reservationDetail', value)
        localStorage.setItem('reservationDetailId', id)
      }
      this.$router.push({
        name: 'reservation-detail',params:{flag:id}
      })
    },
        handleKonamiKeyPress(event) {
    if (event.key === this.konamiCode[this.currentKonamiIndex]) {
      this.currentKonamiIndex++;
      if (this.currentKonamiIndex === this.konamiCode?.length) {
        this.currentKonamiIndex = 0;
        this.debugMode = !this.debugMode;
        console.log('Debug mode: ', this.debugMode); // Deliberately left in
      }
    } else {
      this.konamiReset();
    }
  },
    konamiReset() {
      this.currentKonamiIndex = 0;
    },
async sendDebugRequest() {
  const token = sessionStorage.getItem('token');
  try {
    let headers = {
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`,
      'X-Requested-With': 'XMLHttpRequest'
    };

    if (this.debugMethod !== 'GET') {
      headers['Content-Type'] = 'application/json';
    }

    let options = {
      method: this.debugMethod,
      headers: headers,
    };

    // Add payload if addParams is true and method is not GET
    if (this.addParams && this.debugMethod !== 'GET') {
      options.body = JSON.stringify(this.debugParams);
    }

    const response = await fetch(`${process.env.VUE_APP_BASE_URL_API}/${this.debugEndpoint}`, options);
    const data = await response.json();
    if (response.status !== 200) {
      console.log('hit');
      this.debugResponse = handleErrors(data);
    } else {
      console.log('hit3');
      this.debugResponse = data;
    }
  } catch (error) {
    console.error('Original Request error:', error);
    this.debugResponse = handleErrors(error);
  }}}
}
</script>
<style scoped>
.v-application ul,
.v-application ol {
  padding-left: 0 !important;
}

.debug-mode {
  margin-top: 20px;
  text-align: center;
  border: 1px solid #ccc;
  padding: 40px;
}

.v-application a {
  color: #0a005a;
}

.servicesName p {
  margin-bottom: 5px;
  font-size: 13px;
}

.news__bloc {
  justify-content: space-between;
}
.news__bloc .date {
  line-height: 27px;
  padding: 0;
  font-size: 15px;
}

.breakWord {
  word-break: break-word;
  white-space: pre-wrap;
}
</style>
