<template>
  <section class="l-content-area">
    <div class="l-content l-content--reservation">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="c-title__bloc c-title__bloc--reservation">
            <h1>ご来店予約</h1>
            <div class="c-btn c-btn--title-back"><a href="#" @click="prevToStep()">戻る</a></div>
          </section>

          <section class="p-bloc p-bloc__reservation">
            <h2 class="p-reservation__step">STEP 4</h2>

            <div class="p-reservation__bloc">
              <p>担当者の指名有無を選択してください。</p>

              <div class="p-reservation__staff">
                <div class="c-nominate__bloc">
                  <a href="#" @click="nextToStep((value = 'apoint'))">
                    <h2>指名なし</h2>
                  </a>
                </div>
                <div class="c-nominate__bloc">
                  <a href="#" @click="nextToStep((value = 'person'))">
                    <h2>指名あり</h2>
                  </a>
                </div>
              </div>
            </div>

            <button-registration
              :is-next-button="false"
              :prev-text-color="'#787878'"
              :prev-text="'戻る'"
              :handle-prev-button="prevToStep"
            >
            </button-registration>
          </section>
          <!-- .p-bloc__reservation -->
        </div>
      </div>
    </div>
    <!-- .l-content--reservation -->
  </section>
  <!-- .l-content-area -->
</template>
<script>
import ButtonRegistration from '../../common/ButtonRegistration.vue'
import {requestRegisterReservation } from '../../../actions/requestMypage'

export default {
  name: 'MypageReservationPerson',
  components: {
    ButtonRegistration,
  },
  props: {
    currentStep: {
      type: String,
      default: '4',
    },
    isStaff: {
      type: Boolean,
      default: false,
    },
    storeId: {
      type: String,
      default: '',
    },
    userId: {
      type: String,
      default: '',
    },
    services: {
      type: Array,
      default: () => {
        return []
      },
    },
    reservationId: {
      type: String,
      default: '',
    },
  },
  methods: {
    prevToStep() {
      this.$emit('prevToStep')
    },
    nextToStep(value) {
      if (value === 'apoint') {
        this.$emit('onChangeStep', 'isStaff', false)
        this.$emit('onHandleChange', 'stylistName', '')
        this.$emit('onHandleChange', 'stylist', null)
        this.$emit('onHandleChange', 'genderPreference', null)
        const dateTime = ''
        const isDesignCut = true
        this.$emit('startLoading')
        requestRegisterReservation({
        reservationId: this.reservationId,
        currentStep: 4,
        userId: this.userId,
        storeId: this.storeId,
        timeVisited: dateTime,
        isDesignCut,
        services: this.services,
        status: null,
        stylist: null,
        genderPreference: null,
        waiting: 0,
        requestMsg: '',
      })
        .then(res => {
          const {
            data: { success },
          } = res
          if (success && success === 1) {
            this.$emit('nextToStep')
          }
          this.$emit('endLoading')
        })
        .catch(err => {
          this.$emit('endLoading')
          this.$router.push({ name: 'mypageError' })
          return err
        })
      }
      if (value === 'person') {
        this.$emit('onChangeStep', 'isStaff', true)
        this.$emit('nextToStep')
      }
    },
  },
}
</script>
<style scoped>
.v-application ul,
.v-application ol {
  padding-left: 0 !important;
}

.v-application a {
  color: #0a005a;
}
</style>
