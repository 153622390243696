import Vue from 'vue'
import VueRouter from 'vue-router'
import Login from '../views/Login.vue'
import Registration from '../views/Registration'
import RegistrationFinish from '../views/RegistrationFinish'
import RegistrationReenter from '../views/RegistrationReenter'
import Reminder from '../views/Reminder'
import ReminderPassword from '../views/ReminderPassword'
// import Reservation from '../views/Reservation'
import ConfirmReservation from '../views/ConfirmReservation'
import FinishReservation from '../views/FinishReservation'
import Mypage from '../views/Mypage'
import MypageProfile from '../views/MypageProfile'
import MypageReservation from '../views/MypageReservation'
import DataSync from '../views/DataSync'
// import DataSyncSuccess from '../views/DataSyncSuccess'
import DataSyncFailure from '../views/DataSyncFailure'
import DataSyncFreeze from '../views/DataSyncFreeze'
import DataSyncStudioFailure from '../views/DataSyncStudioFailure'
import MypageReservationDetail from '../views/MypageReservationDetail'
import MypageReservationConfirm from '../views/MypageReservationConfirm'
import MypageReservationFinish from '../views/MypageReservationFinish'
import MypageReservationStudio from '../views/MypageReservationStudio'
import MypageReservationCancel from '../views/MypageReservationCancel'
import MypageReservationCancelFinish from '../views/MypageReservationCancelFinish'
import MypageReservationReserve from '../views/MypageReservationReserve'
import MyPageReservationLimitError from '../views/MypageReservationLimitError'
import MypageProfileUnsubComplete from '../views/MypageProfileUnsubComplete'
import MypageProfileUnsub from '../views/MypageProfileUnsub'
import ReservationError from '../views/ReservationError'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    component: Login,
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/About.vue'),
  },
  {
    path: '/login',
    name: 'login',
    component: Login,
  },
  {
    path: '/registration',
    name: 'registration',
    component: Registration,
  },
  {
    path: '/registration/finish',
    name: 'registration-finish',
    component: RegistrationFinish,
  },
  {
    path: '/registration-reenter',
    name: 'registration-reenter',
    component: RegistrationReenter,
  },
  {
    path: '/reset-password',
    name: 'reset-password',
    component: Reminder,
  },
  {
    path: '/mypage/reminder',
    name: 'reminder-password',
    component: ReminderPassword,
  },
  // {
  //   path: '/reservation',
  //   name: 'reservation',
  //   component: Reservation,
  // },
  {
    path: '/reservation_confirm',
    name: 'reservation-confirm',
    component: ConfirmReservation,
  },
  {
    path: '/reservation_complete',
    name: 'reservation-finish',
    component: FinishReservation,
  },
  {
    path: '/mypage',
    name: 'mypage',
    component: Mypage,
  },
  {
    path: '/mypage/profile',
    name: 'mypage-profile',
    component: MypageProfile,
  },
  {
    path: '/mypage/reservation',
    name: 'mypage-reservation',
    component: MypageReservation,
  },
  {
    path: '/otherservices',
    name: 'other-services',
    component: () => import('../views/OtherServices.vue'),
  },
  {
    path: '/associationcompleted',
    name: 'association-completed',
    component: () => import('../views/AssociationCompleted.vue'),
  },
  {
    path: '/account',
    name: 'account',
    component: DataSync,
  },
  {
    path: '/account/error',
    name: 'account-error',
    component: DataSyncFailure,
  },
  {
    path: '/account/freeze',
    name: 'account-freeze',
    component: DataSyncFreeze,
  },
  {
    path: '/studio/error',
    name: 'studio-error',
    component: DataSyncStudioFailure,
  },
  // {
  //   path: '/reservation-limit/error',
  //   name: 'limit-error',
  //   component: ReservationLimitError,
  // },
  //TODO: separate ticket to clean up unregistered account routes
  {
    path: '/mypage/reservation-limit/error',
    name: 'mypageError',
    component: MyPageReservationLimitError,
  },
  {
    path: '/mypage/reservation_detail/:flag',
    name: 'reservation-detail',
    component: MypageReservationDetail,
  },
  {
    path: '/mypage/reservation_cancel',
    name: 'reservation-cancel',
    component: MypageReservationCancel,
  },
  {
    path: '/mypage/reservation_cancel_finish',
    name: 'reservation-cancel-finish',
    component: MypageReservationCancelFinish,
  },
  {
    path: '/mypage/reservation_reconfirm',
    name: 'mypage-reservation-reconfirm',
    component: MypageReservationConfirm,
  },
  {
    path: '/mypage/reservation_finish',
    name: 'mypage-reservation-finish',
    component: MypageReservationFinish,
  },
  {
    path: '/mypage/reservation_studio',
    name: 'mypage-reservation-studio',
    component: MypageReservationStudio,
  },
  {
    path: '/mypage/reservation_reserve',
    name: 'mypage-reservation-reserve',
    component: MypageReservationReserve,
  },
  {
    path: '/reservation/error',
    name: 'reservation-error',
    component: ReservationError,
  },
  {
    path: '/mypage/profile/unsubcribe/finish',
    name: 'mypage-profile-unsubscribe-finish',
    component: MypageProfileUnsubComplete,
  },
  {
    path: '/mypage/profile/unsubcribe',
    name: 'mypage-profile-unsubscribe',
    component: MypageProfileUnsub,
  },
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
})

router.beforeEach((to, from, next) => {
  if (!to.query.utm_source) {
    from.query.utm_source ? next({ path: to.path, query: from.query }) : next()
  } else {
    next()
  }
})

// 解决上面跳转加参数报错问题
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) return originalPush.call(this, location, onResolve, onReject)
  return originalPush.call(this, location).catch(err => err)
}

// 修改路由replace方法,阻止重复点击报错
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}

export default router
