<template>
  <section class="l-content-area">
    <div class="l-content l-content--registration">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="p-bloc p-bloc__registration">
            <div class="p-registration__finish">
              <h2>サービスのご利用が<span>承認されました</span></h2>
              <p>お客様の顧客情報連携が済んでいない方は、マイページより既存アカウント連携をお願いいたします。</p>
              <p>顧客情報をご連携後、マイページより以下の機能をご利用いただけます。</p>
              <ul>
                <li><strong>ご来店予約</strong></li>
                <li><strong>ご予約情報の履歴確認</strong></li>
                <li><strong>ご登録情報の変更</strong></li>
              </ul>
              <button-registration
                :is-prev-button="false"
                :handle-next-button="onHandleNextButton"
                :next-text="'予約システムを利用'"
              >
              </button-registration>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import ButtonRegistration from '../../common/ButtonRegistration'

  export default {
    name: 'SecondStepRegistrationReenter',
    components: { ButtonRegistration },
    methods: {
      onHandleNextButton() {
        const routeName = this.$route.query.has_snow_id ? 'mypage' : 'account';
        this.$router.push({
          name: routeName,
        })
      },
    },
  }
</script>

<style scoped>

</style>
