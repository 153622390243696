<template>
  <section class="l-content-area">
    <div class="l-content l-content--login">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="p-bloc p-bloc__data-sync">
            <div class="p-data-sync__finish">
              <h2>アカウント連携ができませんでした。</h2>
              <p>お手数ですが、再度店舗利用時にご登録されているお客様情報とご入力内容をご確認いただき、アカウント連携を行ってください。</p>
              <button-registration
                next-text="アカウントを連携"
                :is-prev-button="false"
                :handle-next-button="nextToStep"
              ></button-registration>
            </div>
          </section><!-- .p-bloc__data-sync -->
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import ButtonRegistration from '../common/ButtonRegistration'
export default {
  name: 'DataSyncFailure',
  components: { ButtonRegistration },
  methods: {
    nextToStep() {
      this.$router.push({
        name: 'login'
      })
    }
  }
}
</script>
