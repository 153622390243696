const scrollToTop = (coordinatesX = 0, coordinatesY = 0 ) => {
  window.scroll(coordinatesX, coordinatesY)
}

const scrollModalToTop = (modalId = '') => {
  document.getElementById(modalId).scrollTop = 0
}

export {
  scrollToTop,
  scrollModalToTop
}
