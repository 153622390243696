import MypageRepositoryFactory from '../service/Mypage/MypageRepositoryFactory'
import { getDateTimeForReservation } from '../helpers/formatDate'
import { reservationSteps } from '../constant/reservationSteps'

const mypageService = MypageRepositoryFactory.get('mypage')

const getUser = () => {
  return mypageService.getUser()
}

const unSubscribeUser = () => {
  return mypageService.unSubscribeUser()
}

const getStore = id => {
  return mypageService.getStore(id)
}

const getAllStore = () => {
  return mypageService.getAllStore()
}

const updateUser = userUpdated => {
  let userUpdatedRequest = {
    email: userUpdated.email,
    phone_number: userUpdated.phone,
    store_id: userUpdated.studio,
  }
  return mypageService.updateUser(userUpdatedRequest)
}

const updateUserStudio = userUpdated => {
  return mypageService.updateUserStudio(userUpdated)
}

const requestRegisterReservation = ({
  reservationId,
  currentStep,
  userId,
  storeId,
  timeVisited,
  isDesignCut,
  services,
  status,
  stylist,
  genderPreference,
  waiting,
  customerComment,
  autoAssigned,
  promote, // forces the API to broadcast an 'reservation-updated' event instead of 'reservation-created'
}) => {
  let reservationPayload = {
    user_id: userId,
    store_id: storeId,
    channel: 'web',
    start_date_option1: timeVisited?.firstTime ? getDateTimeForReservation(timeVisited.firstTime) : null,
    start_date_option2: !isDesignCut && timeVisited?.secondTime ? getDateTimeForReservation(timeVisited.secondTime) : null,
    services: services,
    status: status,
    admins: stylist,
    gender_preference: genderPreference,
    waiting: waiting,
    customer_comment: customerComment,
    auto_assigned: autoAssigned,
    promote: promote,
  }

  if (currentStep === 6) {
    const { promote, status, waiting } = reservationPayload;
    reservationPayload = { promote, status, waiting };
  }

  if (reservationId) {
    let step;
    switch (currentStep) {
      case 3:
        step = reservationSteps.serviceDetails;
        break
      case 4:
        step = reservationSteps.genderPreference;
        break
      case 5:
        step = reservationSteps.dateSelection;
        break
      case 6:
        step = reservationSteps.confirm;
        break
    }
    return mypageService.updateReservation(reservationId, reservationPayload, step)
  }
  return mypageService.registerReservation(reservationPayload)
}

const getServices = (category = '', is_sub_treatment = '') => {
  return mypageService.getServices(category, is_sub_treatment)
}

const getCompatibilityServices = (services = []) => {
  return mypageService.getCompatibilityServices(services)
}

const getStoreByState = state => {
  return mypageService.getStoreByState(state)
}

const getAllReservationById = () => {
  return mypageService.getAllReservationById()
}

const getAllReservationByStatus = status => {
  return mypageService.getReservationByStatus(status)
}

const getDetailReservation = id => {
  return mypageService.getDetailReservation(id)
}

const cancelReservation = id => {
  return mypageService.cancelReservation(id)
}

const getStylist = (storeId, services) => {
  var params = {
    services: services,
    isPreferred: 1, // Previously set from argument
    active: 1,
  }
  return mypageService.getStylist(storeId, params)
}

const getHolidaysByStoreId = storeId => {
  return mypageService.getHolidaysByStoreId(storeId)
}

const getSlotStore = (storeId, date, waiting, stylists) => {
  return mypageService.getSlotStore(storeId, date, waiting, stylists)
}

const getPreSlots = (storeId, date, waiting) => {
  return mypageService.getPreSlots(storeId, date, waiting)
}

const updateMyStore = storeId => {
  return mypageService.updateUser({
    store_id: storeId,
  })
}

export {
  updateUser,
  getUser,
  unSubscribeUser,
  getStore,
  getServices,
  getCompatibilityServices,
  getStoreByState,
  getAllReservationById,
  requestRegisterReservation,
  getDetailReservation,
  updateUserStudio,
  cancelReservation,
  getAllReservationByStatus,
  getStylist,
  getHolidaysByStoreId,
  getSlotStore,
  getAllStore,
  // requestRegisterReservationCancel,
  updateMyStore,
  getPreSlots
}
