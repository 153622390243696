import moment from 'moment'

const getDateTimeFormatJapan = (dateString = '') => {

  return getDateTimeFormatString(dateString, '-', '年', '月', '日');
}

function getDateTimeFormatString(dateString = '', separator = '-', ...suffixes) {
  
  const [year, month, day] = dateString.split('-').map(Number);
  const dateFormat = new Date(year, month - 1, day);

  const paddedMonth = (dateFormat.getMonth() + 1).toString().padStart(2, '0');
  const paddedDay = dateFormat.getDate().toString().padStart(2, '0');

  if (suffixes.length > 2) {
    return `${year}${suffixes[0]}${paddedMonth}${suffixes[1]}${paddedDay}${suffixes[2]}`;
  }

  return `${year}${separator}${paddedMonth}${separator}${paddedDay}`;
}

const getDateTimeForReservation = (time) => {
  return time.date + ' '+ time.hour + ':' + time.minute + ':00'
}

const getDayOfWeekFromDateTime = (dateString = '') => {

  const [year, month, day] = dateString.split('-').map(Number);
  const dateFormat = new Date(year, month - 1, day);

  const dayOfWeek = dateFormat.getDay()
  switch (dayOfWeek) {
    case 1:
      return '(月)'
    case 2:
      return '(火)'
    case 3:
      return '(水)'
    case 4:
      return '(木)'
    case 5:
      return '(金)'
    case 6:
      return '(土)'
    default:
      return '(日)'
  }
}

const getDateTimeWithDayOfWeekFormatJapan = (dateString = '') => {

  let dateTime = getDateTimeFormatJapan(dateString);
  let dayOfWeek = getDayOfWeekFromDateTime(dateString);
  return dateTime+' '+dayOfWeek
}

const formatReservationDateTime = (start_date, start_date_display_minutes_diff = null) => {
  if (start_date) {
    const date = moment(start_date).format('YYYY-MM-DD')
    const dayOfWeek = getDayOfWeekFromDateTime(date);
    const japaneseDate = getDateTimeFormatJapan(date);
    let time;
    if(start_date_display_minutes_diff){
      time = moment(start_date).subtract(Math.abs(start_date_display_minutes_diff), 'minutes').format('HH:mm')
    } else {
      time = moment(start_date).format('HH:mm')
    }
    return (
      `${japaneseDate}${dayOfWeek} ${time}`
    )
  }
  return ''
}

export {
  getDateTimeFormatJapan,
  getDateTimeFormatString,
  getDayOfWeekFromDateTime,
  getDateTimeForReservation,
  getDateTimeWithDayOfWeekFormatJapan,
  formatReservationDateTime
}
