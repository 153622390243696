import BaseAnonymousRepository from '../BaseAnonymousRepository'
import { register, resetPassword } from '../../constant/endpoint'

export default {
  acceptEmail(emailRegister) {
    return BaseAnonymousRepository.post(`${register}/step1`, emailRegister)
  },
  acceptPassword(passwordRegister) {
    return BaseAnonymousRepository.post(`${register}/hubsynch`, passwordRegister)
  },
  resetPasswordEmail(payload) {
    return BaseAnonymousRepository.post(resetPassword, payload)
  }
}
