<template>
  <div :class="`input-area ${error ? 'is-error' : ''}`">
    <input
      :id="id"
      type="text"
      pattern="\d*"
      :name="name"
      :maxlength="maxLength"
      :value="value"
      :class="className"
      :placeholder="placeholder"
      :textError="textError"
      @blur="validatePhone()"
      @keypress="isAllowChar($event)"
      @keyup="onHandleChange($event)"
      @change="onHandleChange($event)"
    />
    <p :class="`error-txt ${error ? 'd-block' : ''}`">
      {{ error }}
    </p>
  </div>
</template>

<script>
import { validatePhoneNumber } from '../../helpers/schemaValidate'

export default {
  name: 'InputPhone',
  props: {
    placeholder: {
      type: String,
      default: 'メールアドレス',
    },
    value: {
      type: String,
      default: '',
    },
    className: {
      type: String,
      default: 'plchldr',
    },
    name: {
      type: String,
      default: 'phone',
    },
    id: {
      type: String,
      default: 'phone',
    },
    textError: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: '',
    },
    maxLength: {
      type: Number,
      default: 10,
    },
  },
  watch: {
    value() {
      this.$emit('onValidate', this.name, '')
    },
  },
  methods: {
    validatePhone() {
      const values = {
        [this.name]: this.value,
      }
      validatePhoneNumber(this.name, this.textError)
        .validateAt(this.name, values)
        .then(() => {
          this.$emit('onValidate', this.name, '')
        })
        .catch(error => {
          this.$emit('onValidate', this.name, error.message)
        })
    },
    isAllowChar(e) {
      const charCode = e.which ? e.which : e.keyCode
      if (charCode < 48 || charCode > 57) {
        e.preventDefault()
      }
    },
    onHandleChange(e) {
      const {
        target: { value },
      } = e
      if (value.length < 12) {
        this.$emit('onHandleChange', this.name, value)
      }
    },
  },
}
</script>

<style scoped>
.input-area input {
  height: 48px;
  padding: 0 15px;
  display: block;
  box-sizing: border-box;
  width: 100%;
  color: #0a005a;
  font-size: 16px;
  line-height: 24px;
  font-weight: normal;
  position: relative;
  border-radius: 3px;
  border: 1px solid #999;
  outline: none;
  appearance: none;
  background-color: #f5f5f5;
}

.input-area input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
</style>
