var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',{staticClass:"l-content-area"},[_c('div',{staticClass:"l-content l-content--reservation"},[_c('div',{staticClass:"l-wrap__outer"},[_c('div',{staticClass:"l-wrap__inner"},[_c('section',{staticClass:"c-title__bloc c-title__bloc--reservation"},[_c('h1',[_vm._v("ご来店予約")]),_c('div',{staticClass:"c-btn c-btn--title-back"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.prevToStep()}}},[_vm._v("戻る")])])]),_c('section',{staticClass:"p-bloc p-bloc__reservation"},[_c('h2',{staticClass:"p-reservation__step"},[_vm._v("STEP 2")]),_c('div',{staticClass:"p-reservation__bloc"},[_c('p',[_vm._v("施術メニューを選択してください。")]),_c('div',{staticClass:"p-reservation__service p-reservation__custom"},[(_vm.category === 'wig')?[(_vm.exclusiveGroup.length > 0)?_c('div',{staticClass:"p-reservation__service",class:_vm.services.includes(parseInt(_vm.checkMainServiceId)) ||
                    (_vm.compatibility.length > 0 &&
                      !_vm.compatibility.includes(parseInt(_vm.checkMainServiceId)))
                      ? 'disableMenu'
                      : ''},[_c('div',{staticClass:"c-service-menu__bloc"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.addService(_vm.checkMainServiceId, '編み直し')},"change":function($event){return _vm.changeMenu()}}},[_c('h2',[_vm._v("編み直し")])])])]):_vm._e(),_vm._l((_vm.nonExclusiveGroup),function(treatmentItem,index){return _c('div',{key:index,staticClass:"p-reservation__service",class:_vm.services.includes(parseInt(treatmentItem.id)) ||
                    (_vm.compatibility.length > 0 &&
                      !_vm.compatibility.includes(parseInt(treatmentItem.id)))
                      ? 'disableMenu'
                      : ''},[_c('div',{staticClass:"c-service-menu__bloc"},[_c('a',{attrs:{"href":"#"},on:{"click":function($event){return _vm.addService(treatmentItem.id, treatmentItem.name)},"change":function($event){return _vm.changeMenu()}}},[_c('h2',[_vm._v(_vm._s(treatmentItem.name))]),( treatmentItem.name === 'リセット編み直し')?_c('h3',{staticClass:"c-service-menu-subtext"},[_vm._v("※前回の[通常編み直し]施術から2週間以内のお客様向け（1回のみ）")]):_vm._e(),_c('dl',[_vm._v(" "+_vm._s(treatmentItem.description)+" "),_c('dt',[_vm._v("施術時間目安：")]),_c('dd',[(
                              treatmentItem.duration_male !== null &&
                                treatmentItem.duration_male !== 0
                            )?_c('span',[_vm._v("男性"+_vm._s(treatmentItem.duration_male / 60)+"時間")]):_vm._e(),(
                              treatmentItem.duration_female !== null &&
                                treatmentItem.duration_female !== 0
                            )?_c('span',[_vm._v("、女性"+_vm._s(treatmentItem.duration_female / 60)+"時間")]):_vm._e()])])])])])})]:_vm._l((_vm.listTreatment),function(treatmentItem,index){return _c('div',{key:index,staticClass:"d-box",class:_vm.isDisableMenu(treatmentItem.id)},[_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.listServicesPush),expression:"listServicesPush"}],class:_vm.services.includes(parseInt(treatmentItem.id)) ? 'checkedMenu' : '',attrs:{"id":`consult-${treatmentItem.id}`,"type":"checkbox","hidden":""},domProps:{"value":treatmentItem.id,"checked":Array.isArray(_vm.listServicesPush)?_vm._i(_vm.listServicesPush,treatmentItem.id)>-1:(_vm.listServicesPush)},on:{"click":function($event){return _vm.addService(treatmentItem.id, treatmentItem.name)},"change":[function($event){var $$a=_vm.listServicesPush,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=treatmentItem.id,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.listServicesPush=$$a.concat([$$v]))}else{$$i>-1&&(_vm.listServicesPush=$$a.slice(0,$$i).concat($$a.slice($$i+1)))}}else{_vm.listServicesPush=$$c}},function($event){return _vm.changeMenu()}]}}),_c('label',{staticClass:"checkLabel",attrs:{"for":`consult-${treatmentItem.id}`}},[_c('h2',[_vm._v(_vm._s(treatmentItem.name))]),_c('dl',[_vm._v(" "+_vm._s(treatmentItem.description)+" "),_c('dt',[_vm._v("施術時間目安：")]),(
                          treatmentItem.duration_male !== null &&
                            treatmentItem.duration_male !== 0
                        )?_c('span',[_vm._v("男性"+_vm._s(treatmentItem.duration_male / 60)+"時間")]):_vm._e(),(
                          treatmentItem.duration_female !== null &&
                            treatmentItem.duration_female !== 0
                        )?_c('span',[_vm._v("、女性"+_vm._s(treatmentItem.duration_female / 60)+"時間")]):_vm._e()])])])}),(_vm.errorMessage)?_c('p',{staticClass:"mt-4 text-left text-danger"},[_vm._v(" 施術を選択してください ")]):_vm._e()],2)]),(_vm.category === 'wig')?_c('button-registration',{attrs:{"prev-text":'戻る',"prev-text-color":'#787878',"handle-prev-button":_vm.prevToStep,"is-next-button":false}}):_c('button-registration',{attrs:{"prev-text":'戻る',"next-text":'次へ',"prev-text-color":'#787878',"handle-prev-button":_vm.prevToStep,"handle-next-button":_vm.nextToStep}})],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }