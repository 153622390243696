<template>
  <section class="l-content-area">
    <div class="l-content l-content--reservation">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="c-title__bloc c-title__bloc--reservation">
            <h1>ご来店予約</h1>
            <div class="c-btn c-btn--title-back"><a href="/mypage">戻る</a></div>
          </section>

          <section class="p-bloc p-bloc__reservation">
            <h2 class="p-reservation__step">STEP 1</h2>

            <div class="p-reservation__bloc">
              <p>
                施術サービスの種類を選択してください。<br />
                来店店舗を変更されたい場合は、<a href="#" color="" @click="nextToStudio()">こちら</a
                >より<br />店舗選択を行ってください。
              </p>

              <div class="p-reservation__service">
                <div class="c-service__bloc" :class="isWig ? 'disableMenu' : ''">
                  <ul class="tag">
                    <li class="c-tag"><span>ウィッグ</span></li>
                  </ul>
                  <a href="#" :value="'wig'" @click="nextToStep((value = 'wig'))">
                    <h2>スヴェンソン式増髪法</h2>
                    <p>ウィッグ製品ご利用のお客様はこちら</p>
                  </a>
                </div>

                <div class="c-service__bloc" :class="isExt || isFemale ? 'disableMenu' : ''">
                  <ul class="tag">
                    <li class="c-tag"><span>エクステ</span></li>
                  </ul>
                  <a href="#" :value="'ext'" @click="nextToStep((value = 'ext'))">
                    <h2>マイヘアプラス</h2>
                    <p>マイヘアプラス製品ご利用のお客様はこちら</p>
                  </a>
                </div>

                <div class="c-service__bloc" :class="isCut || isFemale ? 'disableMenu' : ''">
                  <ul class="tag">
                    <li class="c-tag"><span>ヘアカット</span></li>
                  </ul>
                  <a href="#" :value="'cut'" @click="nextToStep((value = 'cut'))">
                    <h2>デザインカット</h2>
                    <p>ご自毛のカットと育毛のサービスご利用のお客様はこちら</p>
                  </a>
                </div>
              </div>
            </div>

            <button-registration
              :is-next-button="false"
              :prev-text-color="'#787878'"
              :prev-text="'戻る'"
              :handle-prev-button="prevToStep"
            >
            </button-registration>
          </section>
        </div>
      </div>
    </div>
    <!-- .l-content--reservation -->
  </section>
  <!-- .l-content-area -->
</template>
<script>
import ButtonRegistration from '../../common/ButtonRegistration.vue'
import { scrollToTop } from '../../../helpers'
import { getServices } from '../../../actions/requestMypage'

export default {
  name: 'MypageReservationService',
  components: { ButtonRegistration },
  props: {
    currentStep: {
      type: String,
      default: '1',
    },
    studio: {
      type: String,
      default: '',
    },
    category: {
      type: String,
      default: '',
    },
    checkPush: {
      type: Boolean,
      default: true,
    },
    services: {
      type: Array,
      default: () => {
        return []
      },
    },
    isCategoryWig: {
      type: Array,
      default: () => {
        return []
      },
    },
    listAllServices: {
      type: Array,
      default: () => {
        return []
      },
    },
    compatibility: {
      type: Array,
      default: () => {
        return []
      },
    },
  },
  data() {
    return {
      listServices: [],
      listWig: [],
      listExt: [],
      listCut: [],
      gender: 'f',
    }
  },
  computed: {
    isWig() {
      this.listWig = this.listAllServices.filter(service => {
        return service.category === 'wig'
      })
      //check for already select or not
      /* let is_found_selected_wig = false
      if (this.services.length > 0 && this.isCategoryWig.length > 0) {

        this.isCategoryWig.map(s => {
          if (this.services.indexOf(parseInt(s.id)) !== -1) {
            is_found_selected_wig = true
          }
        })
      }*/
      //check for there is enable items or not
      let is_found = []
      if (this.compatibility.length > 0) {
        this.listWig.map(s => {
          if (this.compatibility.includes(parseInt(s.id))) {
            is_found.push(s.id)
          }
        })
        if (is_found.length === 0) {
          return true
        }
      }

      return false
    },
    isExt() {
      this.listExt = this.listAllServices.filter(service => {
        return service.category === 'ext'
      })
      //check for there is enable items or not
      if (this.compatibility.length > 0) {
        let is_found = []
        this.listExt.map(s => {
          if (this.compatibility.includes(parseInt(s.id))) {
            is_found.push(s.id)
          }
        })
        if (is_found.length === 0) {
          return true
        }
      }
      return false
    },
    isCut() {
      this.listCut = this.listAllServices.filter(service => {
        return service.category === 'cut'
      })
      //check for there is enable items or not
      if (this.compatibility.length > 0) {
        let is_found = []
        this.listCut.map(s => {
          if (this.compatibility.includes(parseInt(s.id))) {
            is_found.push(s.id)
          }
        })
        if (is_found.length === 0) {
          return true
        }
      }
      return false
    },
    isFemale() {
      if (this.gender && this.gender == 'f') {
        return true
      }
      return false
    },
  },
  watch: {},

  mounted() {
    const linked = sessionStorage.getItem('linked')
    if (!linked || linked !== '1') {
      this.$toast.error('アカウント連携を行ってください。')
      return this.$router.push({
        name: 'account',
      })
    }
  },

  async created() {
    scrollToTop()
    this.$emit('startLoading')
    localStorage.removeItem('ErrorPage');
    await getServices()
      .then(res => {
        const {
          data: { success },
        } = res
        if (success && success === 1) {
          this.listServices = res.data.data
          this.$emit('endLoading')
        }
      })
      .catch(err => {
        this.$emit('endLoading')
        return err
      })

    //get login user info
    if (sessionStorage.getItem('userInfo')) {
      const userInfo = JSON.parse(sessionStorage.getItem('userInfo'))
      if (userInfo.gender) {
        this.gender = userInfo.gender
        this.onHandleChange('isFemale', this.gender)
      }

      const main_service_id = userInfo.main_service_id !== null ? userInfo.main_service_id : 1
      this.onHandleChange('mainServiceId', main_service_id)
    }
  },
  methods: {
    prevToStep() {
      //if there is selected services, go to confirm page.if not, go to mypage.
      if (this.services.length > 0) {
        //go to confirm page
        this.$emit('prevToStep', 'confirm')
      } else {
        this.$router.push({
          name: 'mypage',
        })
        this.$reservation.resetReservation()
      }
    },
    nextToStep(value) {
      this.$emit('nextToStep')
      this.onHandleChange('listAllServices', this.listServices)
      this.onHandleChange('category', value)
    },
    onHandleChange(field, value) {
      this.$emit('onHandleChange', field, value)
    },
    nextToStudio() {
      this.$router.push({
        name: 'mypage-reservation-studio',
      })
    },
  },
}
</script>
<style scoped>
.v-application ul,
.v-application ol {
  padding-left: 0 !important;
}

.v-application a {
  color: #0a005a;
}

.disableMenu {
  display: none;
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  opacity: 0.6;
}
</style>
