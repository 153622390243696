<template>
  <section class="l-content-area">
    <div class="l-content l-content--registration">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="p-bloc p-bloc__registration">
            <div class="p-registration__finish">
              <h2>アカウント登録が<span>完了いたしました</span></h2>
              <p>マイページにログインいただき、お客様の顧客情報連携をお願いいたします。</p>
              <p>顧客情報をご連携後、マイページより以下の機能をご利用いただけます。</p>
              <ul>
                <li><strong>ご来店予約</strong></li>
                <li><strong>ご予約情報の履歴確認</strong></li>
                <li><strong>ご登録情報の変更</strong></li>
              </ul>
              <button-registration
                :next-text="'ログイン'"
                :is-prev-button="false"
                :handle-next-button="redirectLoginPage"
              >
              </button-registration>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
  import ButtonRegistration from '../../common/ButtonRegistration'
  export default {
    name: 'FourthStepRegistration',
    components: {
      ButtonRegistration
    },
    methods: {
      redirectLoginPage() {
        this.$router.push({
          'name': 'login'
        })
      }
    }
  }
</script>

<style scoped>

</style>
