<template>
  <div :class="`input-area ${error ? 'is-error' : ''}`">
    <input
      :id="id"
      :value="value"
      type="text"
      :maxlength="maxLength"
      :name="name"
      :class="className"
      :placeholder="placeholder"
      :textError="textError"
      :isRequired="isRequired"
      :pattern="pattern"
      :allowSpace="allowSpace"
      v-bind="attr"
      @change="onHandleChange($event)"
      @blur="validateData($event)"
      @keyup="onHandleChange($event)"
      @paste="restrictEmoji($event)"
    />
    <p v-if="isShowError" :class="`error-txt ${error ? 'd-block' : ''}`">
      {{ error }}
    </p>
  </div>
</template>

<script>
import * as schema from '../../helpers/schemaValidate'
import randomString from '../../helpers/randomString'
import * as regex from '../../helpers/regex'
import * as message from '../../constant/text'

export default {
  name: 'InputText',
  props: {
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: 'text',
    },
    className: {
      type: String,
      default: 'plchldr',
    },
    placeholder: {
      type: String,
      default: '',
    },
    textError: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: randomString(4),
    },
    error: {
      type: String,
      default: '',
    },
    isShowError: {
      type: Boolean,
      default: true,
    },
    isRequired: {
      type: Boolean,
      default: false,
    },
    allowSpace: {
      type: Boolean,
      default: false,
    },
    pattern: {
      type: RegExp,
      default: null,
    },
    attr: {
      type: Object,
      default: () => {
        return {}
      },
    },
    maxLength: {
      type: Number,
      default: 50,
    },
  },
  watch: {
    value() {
      this.$emit('onValidate', this.name, '')
    },
  },
  methods: {
    onHandleChange(e) {
      const {
        target: { value },
      } = e
      this.$emit('onHandleChange', this.name, value)
    },
    restrictEmoji($event) {
      var clipboardData = $event.clipboardData || window.clipboardData
      var pastedData = clipboardData.getData('Text')
      if (pastedData.match(regex.patternEmojiRestrict)) {
        $event.preventDefault()
        this.$emit('onValidate', this.name, message.EMOJI_KANJI_INVALID)
      }
    },
    validateData($event) {
      this.catchErr = false
      const values = {
        [this.name]: this.value,
      }

      if (this.isRequired) {
        schema
          .validateRequired(this.name, this.textError)
          .validateAt(this.name, values)
          .then(() => {
            //this.$emit('onValidate', this.name, '')
          })
          .catch(error => {
            this.catchErr = true
            this.$emit('onValidate', this.name, error.message)
          })
      }
      const {
        target: { value },
      } = $event
      if (this.pattern) {
        if (value.match(regex.patternEmojiRestrict) || value.match(regex.patternKanji)) {
          this.catchErr = true
          this.$emit('onValidate', this.name, message.EMOJI_KANJI_INVALID)
        }
        else if (!this.allowSpace && !value.match(regex.patternName)) {
          this.catchErr = true
          this.$emit('onValidate', this.name, message.NAME_INVALID)
        }
        else {
          schema
          .validatePattern(this.name, this.pattern, this.textError)
          .validateAt(this.name, values)
          .then(() => {
            //this.$emit('onValidate', this.name, '')
          })
          .catch(error => {
            this.catchErr = true
            this.$emit('onValidate', this.name, error.message)
          })
        }
      } else {
        if (value.match(regex.patternEmojiRestrict) || value.match(regex.patternKanji)) {
          this.catchErr = true
          this.$emit('onValidate', this.name, message.EMOJI_KANJI_INVALID)
        }
        else if (!this.allowSpace && !value.match(regex.patternName)) {
          this.catchErr = true
          this.$emit('onValidate', this.name, message.NAME_INVALID)
        }
        else {
          this.catchErr = false
        }
      }
      // avoid to fill error of null once there is one catch error
      if (this.catchErr == false) {
        this.$emit('onValidate', this.name, '')
      }
    },
  },
}
</script>

<style scoped></style>
