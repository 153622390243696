<template>
  <section class="l-content-area">
    <div class="l-content l-content--mypage">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="c-title__bloc c-title__bloc--mypage">
            <h1>予約のキャンセルが<span>完了いたしました</span></h1>
          </section>
          <section class="p-bloc p-bloc__mypage">
            <div class="p-mypage__complete">
              <p>
                ご予約のキャンセルが完了いたしました。マイページより、現在のご予約状況をご確認いただけます。
              </p>
              <div class="complete-btn">
                <div class="c-btn c-btn--tomypage">
                  <a href="#" @click="nextToStep()">マイページトップへ</a>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
export default {
  name: 'MypageReservationCancelFinish',
  data() {
    return {
      isReservation: '',
    }
  },
  created() {
    if (localStorage.getItem('reservationDetail')) {
      this.isReservation = localStorage.getItem('reservationDetail')
    }
  },
  methods: {
    nextToStep() {
      this.$router.push({
        name: 'mypage',
      })
      localStorage.removeItem('reservationDetail')
    },
  },
}
</script>
<style scoped>
.v-application ul,
.v-application ol {
  padding-left: 0 !important;
}

.v-application a {
  color: #fff;
}
</style>
