<template>
  <section class="l-content-area">
    <div class="l-content l-content--login">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="c-title__bloc c-title__bloc--login">
            <h1>ご契約者さま <br />アカウント連携</h1>
          </section>

          <section class="p-bloc p-bloc__data-sync">
            <div class="p-data-sync__bloc">
              <p v-html="title"></p>

              <div class="p-data-sync__form form-container">
                <h2>認証コード</h2>
                <div class="userInput">
                  <OtpInput
                    :num-inputs="6"
                    separator=""
                    :should-auto-focus="true"
                    :is-input-num="true"
                    @on-change="handleOnChange"
                    @on-complete="handleOnComplete"
                  />
                </div>
                <p class="validate_error-txt">
                  {{ validationError }}
                </p>
              </div>
              <div class="p-data-sync__btn auth_btn">
                <div class="c-btn c-btn--data-sync">
                  <button-registration
                    next-text="送信する"
                    :is-prev-button="false"
                    :handle-next-button="onValidate"
                    :prev-text-color="'#787878'"
                  ></button-registration>
                </div>
              </div>
              <p>
                認証コードが送られない場合は、 <br /><a @click="resendEmail()">再送信</a
                >してください。
              </p>
            </div>
          </section>
          <!-- .p-bloc__data-sync -->
        </div>
      </div>
    </div>
    <!-- .l-content--login -->
  </section>
  <!-- .l-content-area -->
</template>
<script>
import ButtonRegistration from '../common/ButtonRegistration'
import OtpInput from '@bachdgvn/vue-otp-input'
import { linkStep2 } from '../../actions/requestLogin'
import { linkStep1 } from '../../actions/requestLogin'
export default {
  name: 'DataSyncAuth',
  components: {
    ButtonRegistration,
    OtpInput,
  },
  props: {
    currentStep: {
      type: String,
      default: '2',
    },
    isForceUpdate: {
      type: Boolean,
      default: false,
    },
    numInputs: {
      default: 4,
      type: Number,
    },
    separator: {
      type: String,
      default: '**',
    },
    inputClasses: {
      type: String,
      default: '',
    },
    isInputNum: {
      type: Boolean,
    },
    shouldAutoFocus: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Object,
      default: () => {
        return {
          customerInfo: false,
          userId: '',
          firstName: '',
          lastName: '',
          phone: '',
          customerNo: '',
          email: '',
        }
      },
    },
  },
  data() {
    return {
      otp: '',
      title: '',
      validationError: '',
    }
  },
  created() {
    this.$emit('endLoading') // Clear loading overlay from previous component.
    if (this.values.contactMethod === 'sms') {
      this.title =
        'ご本人確認のため、入力いただきました<b>携帯電話</b>に送信された、<br />認証コード（6桁）を入力してください。'
    } else if (this.values.contactMethod === 'email') {
      this.title =
        'ご本人確認のため、入力いただきました<b>メールアドレス</b>に送信された、<br />認証コード（6桁）を入力してください。'
    } else {
      this.title = ''
    }
  },
  methods: {
    onHandleChange(field, value) {
      this.$emit('onHandleChange', field, value)
    },
    handleOnChange(value) {
      this.otp = value
    },
    handleOnComplete(value) {
      this.otp = value
    },
    onValidate() {
      this.validationError = ''
      if (this.otp != '' && this.otp.length === 6) {
        this.nextToStep()
      } else {
        this.validationError = '認証コードは、必ず指定してください。'
      }
    },
    resendEmail() {
      this.$emit('startLoading')
      linkStep1(this.values)
        .then(res => {
          const {
            data: { success },
          } = res
          if (success) {
            this.$emit('endLoading')
            this.$toast.success('認証コードの再送信を完了しました。')
          } else {
            this.$emit('endLoading')
            this.$toast.error('Try Again')
          }
        })
        .catch(err => {
          this.$emit(err)
          this.$emit('endLoading')
          this.$router.push({ name: 'account-error' })
        })
    },
    nextToStep() {
      // 1. to implement OTP check code here
      this.$emit('startLoading')
      linkStep2(this.otp)
        .then(res => {
          const {
            data: { success },
          } = res
          // Notes: api response success and customerInfo matched == true, proceed
          if (success && this.values.customerInfo == true) {
            const {
              data: { data },
            } = res
            // api success but link acc not success
            if (data.orange_id == null || data.orange_id == '') {
              this.$router.push({ name: 'account-error' })
            }
            // linked API response success, implement to go next step , pass studio & state ID
            else {
              if (data.store_id) {
                this.$emit('onHandleChange', 'studio', data.store_id.toString())
              }
              this.$emit('onHandleChange', 'state', data.prefecture_code)
              this.$emit('nextToStep')
            }
          } else {
            this.$router.push({ name: 'account-error' })
          }
          this.$emit('endLoading')
        })
        .catch(err => {
          // redirect to account freeze page when api respone 429 (attempts too much error)
          if (err.response && err.response.status === 429) {
            this.$router.push({ name: 'account-freeze' })
          }
          const {
            response: {
              data: {
                errors: { token },
              },
            },
          } = err
          if (token) {
            this.$router.push({ name: 'account-error' })
          }
          this.$emit('endLoading')
        })
    },
  },
}
</script>
<style scoped>
.validate_error-txt {
  text-align: center;
  color: #ef5757;
  font-size: 20px;
  line-height: 24px;
  font-weight: normal;
  padding: 0 10px 0 22px;
  margin-top: 11px;
}
.pdt-25 {
  padding-top: 25px;
}
</style>
