<template>
  <footer class="l-footer">
    <div class="l-footer__logo">
      <!-- In case we want to return the logo and slogan -->
      <!-- <a href="https://www.mens-svenson.net/" target="_blank" rel="noopener">
        <img src="../../assets/img/header/logo.png" alt="MEN'S SVENSON" />
      </a>
      <p>髪を、活かそう。スヴェンソン｜メンズスヴェンソン</p> -->
    </div>
    <!-- <address>&copy;&nbsp;SVENSON,&nbsp;Inc.</address> -->
  </footer>
</template>

<script>

export default {
  name: 'Footer',
  data() {
   return {}
 }
}
</script>

<style scoped></style>
