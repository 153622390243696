<template>
  <main-layout :title="title" :is-loading="isLoading">
    <mypage-reservation-cancel-finish
      @set-title="setTitle"
      @startLoading="startLoading"
      @endLoading="endLoading"
    >
    </mypage-reservation-cancel-finish>
  </main-layout>
</template>
<script>
import MainLayout from '../components/layout/MainLayout.vue' 
import MypageReservationCancelFinish from '../components/mypage/MypageReservationCancelFinish.vue'
export default {
  name: 'MypageReservation',
  components: {
    MainLayout,
    MypageReservationCancelFinish
  },
  data() {
    return {
      isLoading: false,
      title: '予約キャンセル完了 | Svenson'
    }
  },
  methods: {
    setTitle(title) {
      this.title = title
    },
    startLoading() {
      this.isLoading = true
    },
    endLoading() {
      this.isLoading = false
    }
  }
}
</script>