<template>
  <section class="l-content-area">
    <div class="l-content l-content--mypage">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="c-title__bloc c-title__bloc--reservation">
            <h1>ご来店予約</h1>
          </section>
          <section class="p-bloc p-bloc__reservation">
            <div class="p-reservation__finish">
              <h2>ご入力のデータでは登録ができません。</h2>
              <p>
                ご入力いただいたデータでは登録することができません。既に一度ご来店いただいているなどのケースが考えられますので、その場合はこちらより既存顧客連携を行ってください。
              </p>
              <button-registration
                :is-next-button="false"
                :prev-text="'トップページへ'"
                :handle-prev-button="prevToStep"
                :prev-text-color="'#787878'"
              >
              </button-registration>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import ButtonRegistration from '../common/ButtonRegistration.vue'
export default {
  name: 'ReservationErrorComponent',
  components: { ButtonRegistration },
  created() {},
  methods: {
    prevToStep() {
      this.$emit('startLoading')
      setTimeout(() => {
        this.$emit('endLoading')
        this.$router.push({
          name: 'reservation',
        })
      }, 2000)
    },
  },
}
</script>
<style scoped>
.v-application ul,
.v-application ol {
  padding-left: 0 !important;
}

.v-application a {
  color: #fff;
}

.c-title__bloc h1 {
  align-items: unset;
}
</style>
