<template>
  <div class="p-login__btn">
    <div class="c-btn c-btn--login">
      <button type="button" @click="handleClick()">
        {{ title }}
      </button>
    </div>
  </div>
</template>

<script>
  export default {
    name: 'ButtonSubmit',
    props: {
      title: {
        type: String,
        default: 'ボタン'
      },
      handleClick: {
        type: Function,
        default: null
      },

    },
  }
</script>

<style scoped>

</style>
