<template>
  <v-card height="100%">
    <v-tabs v-show="false" v-model="currentStep" centered grow :hide-slider="true">
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#1" :align-with-title="true" :disabled="disabled.firstTab">
        ステップ1
      </v-tab>
      <v-tab href="#2" :align-with-title="true" :disabled="disabled.secondTab">
        ステップ2
      </v-tab>
      <v-tab href="#3" :align-with-title="true" :disabled="disabled.thirdTab">
        ステップ3
      </v-tab>
      <v-tab href="#4" :align-with-title="true" :disabled="disabled.fourthTab">
        ステップ4
      </v-tab>
      <!-- <v-tab href="#5"
             :align-with-title="true"
             :disabled="disabled.fifthTab"
      >
        ステップ5
      </v-tab> -->
    </v-tabs>

    <v-tabs-items v-model="currentStep" :touch="onTouchEvent()">
      <v-tab-item value="1">
        <data-sync
          :is-force-update="isForceUpdate"
          :current-step="currentStep"
          :values="values"
          @nextToStep="nextToStep"
          @onHandleChange="onHandleChange"
          @onValidate="onValidateSwipe"
          @startLoading="startLoading"
          @endLoading="endLoading"
        ></data-sync>
      </v-tab-item>
      <v-tab-item value="2">
        <data-sync-auth
          :is-force-update="isForceUpdate"
          :current-step="currentStep"
          :values="values"
          @nextToStep="nextToStep"
          @prevToStep="prevToStep"
          @onHandleChange="onHandleChange"
          @onValidate="onValidateSwipe"
          @startLoading="startLoading"
          @endLoading="endLoading"
        ></data-sync-auth>
      </v-tab-item>
      <!-- <v-tab-item value="3">
        <data-sync-studio
          :is-force-update="isForceUpdate"
          :current-step="currentStep"
          :values="values"
          @nextToStep="nextToStep"
          @onHandleChange="onHandleChange"
          @onValidate="onValidateSwipe"
          @startLoading="startLoading"
          @endLoading="endLoading"
        ></data-sync-studio>
      </v-tab-item> -->
      <v-tab-item value="3">
        <data-sync-success
          :is-force-update="isForceUpdate"
          :current-step="currentStep"
          @nextToStep="nextToMyPage"
          @prevToStep="prevToStep"
        ></data-sync-success>
      </v-tab-item>
      <v-tab-item value="4">
        <data-sync-failure
          :is-force-update="isForceUpdate"
          :current-step="currentStep"
          @nextToStep="nextToStep"
          @prevToStep="prevToStep"
        ></data-sync-failure>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
import DataSync from './DataSync.vue'
import DataSyncAuth from './DataSyncAuth.vue'
import DataSyncSuccess from './DataSyncSuccess.vue'
import DataSyncFailure from './DataSyncFailure.vue'
// import DataSyncStudio from './DataSyncStudio'
import { scrollToTop } from '../../helpers'

export default {
  name: 'DataSyncComponent',
  components: {
    DataSyncAuth,
    // DataSyncStudio,
    DataSync,
    DataSyncSuccess,
    DataSyncFailure,
  },
  data() {
    return {
      isForceUpdate: false,
      currentStep: '1',
      disabled: {
        firstTab: false,
        secondTab: true,
        thirdTab: true,
        fourthTab: true,
        // fifthTab: true,
      },
      values: {
        userId: '',
        firstName: '',
        lastName: '',
        phone: '',
        customerNo: '',
        zipCode: '',
        state: '',
        region_id: '',
        extended: '',
        email: '',
        allCity: '',
        studio: '',
        customerInfo: false,
      },
    }
  },
  watch: {
    currentStep(newValue) {
      this.isForceUpdate = false
      switch (newValue) {
        case '2':
          this.disabled.secondTab = false
          break
        case '3':
          this.disabled.thirdTab = false
          break
        case '4':
          this.disabled.fourthTab = false
          break
        // case '5':
        //   this.disabled.fifthTab = false
        //   break
        default:
          this.disabled.firstTab = false
          break
      }
      scrollToTop()
    },
  },
  created() {
    if (!this.$authInfo.getToken()) {
      this.$router.push({
        name: 'login',
      })
    }
  },
  methods: {
    assignSwipeLeft() {
      this.isForceUpdate = true
    },
    assignSwipeRight() {
      this.isForceUpdate = false
      this.prevToStep()
    },
    nextToStep() {
      const nextStep = parseInt(this.currentStep) + 1
      this.currentStep = nextStep.toString()
    },
    prevToStep() {
      const prevStep = parseInt(this.currentStep) - 1
      this.currentStep = prevStep.toString()
    },
    onHandleChange(field, value) {
      this.values[field] = value
    },
    nextToMyPage() {
      this.$router.push({
        name: 'mypage',
      })
    },
    onTouchEvent() {
      return {
        left: () => this.assignSwipeLeft(),
        right: () => this.assignSwipeRight(),
      }
    },
    onValidateSwipe(value) {
      this.isForceUpdate = value
    },
    startLoading() {
      this.$emit('startLoading')
    },
    endLoading() {
      this.$emit('endLoading')
    },
  },
}
</script>
