<template>
  <section class="l-content-area">
    <div class="l-content l-content--reservation">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="c-title__bloc c-title__bloc--reservation">
            <h1 v-if="isReservation === 'isDetail' || isReservation === 'isProvisional'">
              予約キャンセル
            </h1>
            <h1 v-else>キャンセル待ち解除</h1>
            <div class="c-btn c-btn--title-back"><a href="#" @click="prevToStep()">戻る</a></div>
          </section>

          <section class="p-bloc p-bloc__mypage">
            <div class="p-reservation__cancel">
              <p v-if="isReservation === 'isDetail' || isReservation === 'isProvisional'">
                ご予約をキャンセルされる場合は、<strong>下記のご予約情報、注意事項をご確認の上、</strong>ご予約のキャンセルを実行してください。
              </p>
              <p v-else>
                キャンセル待ちを解除される場合は、下記のキャンセル待ち解除ボタンから実行してください。
              </p>

              <h2>ご予約情報</h2>
              <div class="p-reservation__confirm">
                <div class="reservation-confirm__bloc">
                  <div class="flex-wrapper">
                    <div class="row">
                      <div class="column label">
                        ご予約日時
                      </div>
                      <div class="column value">
                        {{ formatReservationDateTime(reservationDetail.start_date, reservationDetail.start_date_display_minutes_diff) }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="column label">
                        ご予約ID
                      </div>
                      <div class="column value">
                        {{ reservationDetail.code }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="column label">
                        ご来店店舗
                      </div>
                      <div class="column value">
                        {{ reservationDetail.store.name }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <h2>施術内容</h2>
              <div class="p-reservation__confirm">
                <div class="reservation-confirm__bloc">
                  <div class="flex-wrapper">
                    <div class="row">
                      <div class="column label">
                        施術
                      </div>
                      <div class="column value">
                        <span v-if="services_treatment.length >= 1">
                          <div v-for="service in services_treatment" :key="service._id">
                            {{ service.name }}
                          </div>
                        </span>
                        <span v-else>
                          選択なし
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="column label">
                        施術オプション
                      </div>
                      <div class="column value">
                        <span v-if="services_subtreatment.length >= 1">
                          <div v-for="service in services_subtreatment" :key="service._id">
                            {{ service.name }}
                          </div>
                        </span>
                        <span v-else>
                          選択なし
                        </span>
                      </div>
                    </div>
                    <div class="row">
                      <div class="column label">
                        担当者
                      </div>
                      <div class="column value">
                        {{  displayStylistName }}
                      </div>
                    </div>
                    <div class="row">
                      <div class="column label">
                        施術時間目安
                      </div>
                      <div class="column value">
                        {{
                          reservationDetail.estimated_duration
                            ? '約' + parseInt(reservationDetail.estimated_duration) / 60 + '時間'
                            : ''
                        }}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <button-registration
                v-if="isReservation === 'isDetail' || isReservation === 'isProvisional'"
                :prev-text-color="'#787878'"
                :prev-text="'戻る'"
                :next-text="'この予約をキャンセルする'"
                :handle-next-button="nextToStep"
                :handle-prev-button="prevToStep"
              >
              </button-registration>
              <button-registration
                v-else
                :prev-text-color="'#787878'"
                :prev-text="'戻る'"
                :next-text="'キャンセル待ち解除'"
                :handle-next-button="nextToStep"
                :handle-prev-button="prevToStep"
              >
              </button-registration>
            </div>

            <div class="p-reservation__attention">
              <div class="reservation-attention">
                <h3>ご注意事項</h3>
                <p>
                  一度ご予約をキャンセルした後に、再度ご予約をする場合は、来店予約フォームより改めてご予約をしていただく必要がございます。
                </p>
                <h4>【キャンセルに関する注意事項】</h4>
                <ul>
                  <li>
                    キャンセル受付締切：ご予約日の前日までマイページ、もしくはお電話よりキャンセルが可能です。
                  </li>
                  <li>キャンセルポリシー：ご予約の店舗までお電話をお願いいたします。</li>
                </ul>
              </div>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import ButtonRegistration from '../common/ButtonRegistration.vue'
import { getDetailReservation, cancelReservation } from '../../actions/requestMypage'
import { formatReservationDateTime } from '../../helpers/formatDate'
import { scrollToTop } from '../../helpers/index'
export default {
  name: 'MypageReservationCancel',
  components: {
    ButtonRegistration,
  },
  data() {
    return {
      reservationId: '',
      isReservation: '',
      status: 'cancelled',
      reservationDetail: {
        store: {},
      },
      services: [],
      services_treatment: [],
      services_subtreatment: [],
      stylists: [],
      formatReservationDateTime,
    }
  },
  computed: {
    displayStylistName() {
      const { auto_assigned, free_status, gender_preference, stylists } = this.reservationDetail;
      if (auto_assigned && free_status) {
        switch (gender_preference) {
          case 'm':
            return '男性スタイリスト希望';
          case 'f':
            return '女性スタイリスト希望';
          default:
            return '指名なし';
        }
      }

      return (stylists && !free_status) ? stylists[0].name : '指名なし';
    },
  },

  created() {
    scrollToTop()
    if (localStorage.getItem('reservationDetail') && localStorage.getItem('reservationDetailId')) {
      this.isReservation = localStorage.getItem('reservationDetail')
      this.reservationId = localStorage.getItem('reservationDetailId')
      this.$authInfo.setToken(sessionStorage.getItem('token'))
      this.$emit('startLoading')
      getDetailReservation(this.reservationId)
        .then(res => {
          const {
            data: { success },
          } = res
          if (success && success === 1) {
            const {
              data: { data },
            } = res
            this.reservationDetail = res.data.data
            this.services = data['services']
            for (var key in this.services) {
              if (this.services[key]['is_sub_treatment'] == false) {
                this.services_treatment.push(this.services[key])
              } else {
                this.services_subtreatment.push(this.services[key])
              }
            }
            if (data['stylists'].length > 0) {
              this.stylists = data['stylists'][0]
            }
          }
          this.$emit('endLoading')
        })
        .catch(err => {
          this.$emit('endLoading')
          return err
        })
    } else {
      this.$router.push({
        name: 'mypage',
      })
    }
  },
  methods: {
    nextToStep() {
      if (this.reservationId) {
        this.$emit('startLoading')
        cancelReservation(this.reservationId)
          .then(res => {
            const {
              data: { success },
            } = res
            if (success && success === 1) {
              localStorage.removeItem('reservationDetailId')
              this.$router.replace({
                name: 'reservation-cancel-finish',
              })
            }
            this.$emit('endLoading')
          })
          .catch(err => {
            this.$emit('endLoading')
            return err
          })
      }
    },
    prevToStep() {
      localStorage.removeItem('reservationDetailId')
      localStorage.removeItem('reservationDetail')
      this.$router.push({
        name: 'reservation-detail',
        params: { flag: this.reservationId },
      })
    },
  },
}
</script>
<style scoped>
.v-application ul,
.v-application ol {
  padding-left: 0 !important;
}

.v-application a {
  color: #fff;
}
.p-reservation__confirm {
  margin-top: 20px;
  padding: 20px 50px 20px;
}

.p-bloc__mypage h2:first-child {
  margin-top: 60px;
}

.p-bloc__mypage h2 {
  text-align: left;
  margin-top: 40px;
}

.flex-wrapper .row {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  height: 60px;
  align-items: center;
  border-bottom: 1px solid #e4e4e4;
  margin: 0;
  min-height: 60px;
  height: auto;
  text-align: justify;
}

.flex-wrapper .row:last-child {
  border-bottom: 0;
}

.column:first-child {
  min-width: 40%;
  width: 40%;
  padding-right: 20px;
}

.column:last-child {
  width: 60%;
}

.column {
  display: flex;
  flex-direction: column;
  padding: 5px;
}

.label {
  font-size: 14px;
  font-weight: normal;
}

.value {
  font-size: 16px;
  font-weight: bold;
}

@media screen and (max-width: 600px) {
  .p-reservation__confirm {
    margin-top: 20px;
    padding: 20px 40px 20px;
  }

  .flex-wrapper .row {
    flex-wrap: wrap;
    padding: 10px 0;
  }

  .column:first-child,
  .column:last-child {
    width: 100%;
    min-width: 100%;
  }
}

@media screen and (max-width: 375px) {
  .p-reservation__confirm {
    padding: 20px 20px 20px;
  }
}
</style>
