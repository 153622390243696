<template>
  <div class="modal-zoom-window">
    <v-row align="center">
      <v-date-picker
        :style="{ height: '100%' }"
        :value="value"
        full-width
        :events="arrayEvents"
        first-day-of-week="1"
        :show-current="false"
        :light="true"
        :no-title="true"
        :min="minDate"
        :max="maxDate"
        :day-format="titleDateFormat"
        :allowed-dates="allowedDates"
        locale="ja"
        :event-color="getEventColor"
        @change="onHandleChange"
      ></v-date-picker>
    </v-row>
  </div>
</template>

<script>
import { getDateTimeFormatString } from '../../helpers/formatDate'
import * as holiday_jp from '@holiday-jp/holiday_jp'
import { getPreSlots } from '../../actions/requestMypage'
import { getPreSlotsStore } from '../../actions/requestReservation'

export default {
  name: 'DatePicker',
  props: {
    value: {
      type: String,
      default: '',
    },
    name: {
      type: String,
      default: '',
    },
    listHoliday: {
      type: Array,
      default: () => {
        return []
      },
    },
    availableBookingStartPeriod: {
      type: String,
      default: '',
    },
    availableBookingEndPeriod: {
      type: String,
      default: '',
    },
    maxDate: {
      type: String,
      default: '',
    },
    isTimeCancel: {
      type: Boolean,
      default: false,
    },
    reservationId: {
      type: String,
      default: '',
    },
    isDesignCut: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    const minDate = getDateTimeFormatString()
    return {
      arrayEvents: [],
      minDate,
    }
  },
  watch: {
    listHoliday() {
      this.getListHoliday()
    },
  },
  created() {
    this.getListHoliday()
  },
  methods: {
    titleDateFormat(value) {
      const date = new Date(value)
      return date.getDate()
    },
    onHandleChange(value) {
      //get pre slots
      // this is for mypage
      if (this.isTimeCancel) {
        getPreSlots(this.reservationId, value, 0)
          .then(res => {
            this.isLoading = true
            if (res.data.success === 1) {
              const listPreSlot = res.data['stylist-ids']
              this.isLoading = false
              this.$emit('onSetPreSlots', listPreSlot)
              this.$emit('onHandleChange', 'date', value)
              const date = new Date(value)
              const holidays = this.getHolidaysByHolidayJP()
              if (date.getDay() === 0 || date.getDay() === 6 || holidays.indexOf(value) !== -1) {
                this.$emit('onSetHoliday', true)
              } else {
                this.$emit('onSetHoliday', false)
              }
            }
          })
          .catch(err => {
            console.log(err)
            this.isLoading = false
          })
      } // this is for anonymous
      else {
        if (this.isDesignCut) {
          getPreSlotsStore(this.reservationId, value)
            .then(res => {
              this.isLoading = true
              if (res.data.success === 1) {
                const listPreSlot = res.data['stylist-ids']
                this.isLoading = false
                this.$emit('onSetPreSlots', listPreSlot)
                this.$emit('onHandleChange', 'date', value)
                const date = new Date(value)
                const holidays = this.getHolidaysByHolidayJP()
                if (date.getDay() === 0 || date.getDay() === 6 || holidays.indexOf(value) !== -1) {
                  this.$emit('onSetHoliday', true)
                } else {
                  this.$emit('onSetHoliday', false)
                }
              }
            })
            .catch(err => {
              console.log(err)
              this.isLoading = false
            })
        } else {
          // this is for non-cut services of anonymous
          this.$emit('onHandleChange', 'date', value)
          const date = new Date(value)
          const holidays = this.getHolidaysByHolidayJP()
          if (date.getDay() === 0 || date.getDay() === 6 || holidays.indexOf(value) !== -1) {
            this.$emit('onSetHoliday', true)
          } else {
            this.$emit('onSetHoliday', false)
          }
        }
      }
    },
    allowedDates(value) {
      if (
        value >= this.availableBookingStartPeriod &&
        value <= this.availableBookingEndPeriod &&
        this.listHoliday.indexOf(value) === -1
      ) {
        return value
      }
    },
    getListHoliday() {
      const holidays = this.getHolidaysByHolidayJP()
      this.arrayEvents = holidays.concat(this.listHoliday)
    },
    getEventColor(date) {
      const holidays = this.getHolidaysByHolidayJP()
      if (holidays.indexOf(date) !== -1 && this.listHoliday.indexOf(date) === -1) {
        return '#e6eef5'
      } else {
        return '#F0F0F0'
      }
    },
    getHolidaysByHolidayJP() {
      const holidays = holiday_jp.between(new Date(), new Date(this.maxDate))
      return holidays.map(holiday => {
        const dateHoliday = new Date(holiday.date)
        const date =
          dateHoliday.getDate() < 10 ? '0' + dateHoliday.getDate() : dateHoliday.getDate()
        const month =
          dateHoliday.getMonth() + 1 < 10
            ? '0' + (dateHoliday.getMonth() + 1)
            : dateHoliday.getMonth() + 1
        const year = dateHoliday.getFullYear()
        return year + '-' + month + '-' + date
      })
    },
  },
}
</script>

<style>
.modal-zoom .modal__bloc .calendar__bloc table td {
  height: 60px;
  width: 60px;
  color: #505050;
  background-color: #f5f5f5;
  border: 1px solid #c8c8c8;
}

.modal-zoom .v-btn__content {
  font-family: 'Roboto', sans-serif;
  font-weight: 400;
  position: relative;
  text-align: center;
  font-size: 20px;
  line-height: 1;
  border-radius: 3px;
  padding: 0;
  margin: 0;
  height: 60px;
  display: flex;
  -webkit-box-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  align-items: center;
  color: #0a005a;
}

.modal-zoom .v-btn__content:disabled {
  color: red;
}

.modal-zoom .v-date-picker-table {
  height: 100% !important;
}

.modal-zoom .v-date-picker-header__value button {
  font-size: 20px;
  line-height: 44px;
  font-weight: bold;
  color: #0a005a !important;
}

.modal-zoom .modal__bloc .calendar__bloc table button.v-btn--rounded {
  border-radius: 0;
  width: 100%;
  height: 100%;
  color: #0a005a;
  background-color: #ffffff;
  opacity: 1;
}

.modal-zoom .modal__bloc .calendar__bloc table button.v-btn--active .v-btn__content {
  color: #ffffff;
  background: #0a005a;
}

.modal-zoom .modal__bloc .calendar__bloc .v-date-picker-table__events {
  left: -2px;
  position: absolute;
  text-align: center;
  white-space: pre;
  height: 100%;
  width: 100%;
  bottom: 0;
}

.modal-zoom .modal__bloc .calendar__bloc .v-date-picker-table__events > div {
  border-radius: 0;
  display: inline-block;
  height: 100%;
  width: 100%;
}

.modal-zoom .modal__bloc .calendar__bloc table td:nth-child(6) button {
  background: #e6eef5 !important;
}

.modal-zoom
  .modal__bloc
  .calendar__bloc
  table
  td:nth-child(6)
  button.v-btn--disabled
  div.v-date-picker-table__events {
  height: 100%;
  width: 100%;
}

.modal-zoom .modal__bloc .calendar__bloc table td:nth-child(7) button {
  background-color: #e6eef5 !important;
}

.modal-zoom .modal__bloc .calendar__bloc table td button.v-btn--disabled > .v-btn__content {
  color: #d4d3d9 !important;
  background-color: #f0f0f0 !important;
}

.modal-zoom
  .modal__bloc
  .calendar__bloc
  table
  td
  button.v-btn--disabled
  div.v-date-picker-table__events {
  height: 100%;
  bottom: 0;
  width: 100%;
}

.modal-zoom
  .modal__bloc
  .calendar__bloc
  table
  td:nth-child(6)
  button.v-btn--disabled
  div.v-date-picker-table__events {
  height: 100%;
  bottom: 0;
}

.modal-zoom .modal__bloc .calendar__bloc .v-btn__content {
  z-index: 999;
}
</style>
