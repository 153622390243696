<template>
  <p v-if="error" class="error-txt d-block">
    {{ error }}
  </p>
</template>

<script>
  export default {
    name: 'ErrorMessage',
    props: {
      error: {
        type: String,
        default: ''
      }
    }
  }
</script>
