<template>
  <section class="l-content-area">
    <div class="l-content l-content--login">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="p-bloc p-bloc__data-sync">
            <div class="p-data-sync__finish">
              <h2>{{ header }}</h2>
              <p class="desc">
                {{ title }}
              </p>
              <div class="info-para">
                <h3 class="mr-5">
                  <img
                    src="../../../assets/img/ui_parts/attention_icon.png"
                    alt="MEN'S SVENSON"
                    class="icon"
                  />
                  {{ errorMsg }}
                </h3>
                <ul>
                    予約の取得制限エラー、もしくは通信エラーが発生いたしました。<br/>
                    通常予約は1日1回まで、またひと月あたり合計2回もしくは4回までとなります。
                    <ul class="ml-5">
                      <li>通常メニュー：ひと月あたり2回まで</li>
                      <li>指定メニュー：ひと月あたり4回まで</li>
                    </ul>
                    <p class="mb-1">※指定メニューは以下の施術となります。</p>
                    <ul class="ml-5">
                      <li>リセット編み直し</li>
                      <li>シャンプー・カット</li>
                      <li>リユース</li>
                      <li>マイヘアプラス100～300ユニット</li>
                    </ul>
                    <p>また、キャンセル待ち予約はひと月あたり合計４回までとなります。<br/><br/>
                      ご不便をおかけしますが、何卒ご理解いただきますようお願いします。</p>
                </ul>
              </div>
              <button-registration
                next-text="戻る"
                :is-prev-button="false"
                :handle-next-button="nextToStep"
              ></button-registration>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ButtonRegistration from '../../common/ButtonRegistration';

export default {
  name: 'MypageReservationError',
  components: { ButtonRegistration },

  mounted() {
    localStorage.setItem('ErrorPage', true);
  },

  methods: {
    nextToStep() {
      this.$router.push({
        name: 'mypage-reservation',
      });
    },
  },
  computed: {
    header() {
      return '大変申し訳ありません';
    },
    title() {
      return '予約登録ができませんでした。下記をご確認のうえ、再登録をお願いいたします。';
    },
    errorMsg() {
      return 'エラー詳細内容';
    },
  }
};
</script>

<style scoped>
.info-para {
  background: #f5f5f5;
  padding: 4% 7% 1%;
}

p.desc {
  margin-bottom: 5%;
  text-align: center;
}

.p-data-sync__finish ul {
  margin-left: 0;
}

.v-application ul {
  padding-left: 0;
}

.icon {
  width: 21px;
  height: 18px;
  margin: 6px 4px 0 0;
}

h3 {
  margin-bottom: 3%;
}

.p-data-sync__finish {
  max-width: 680px;
}

@media only screen and (max-width: 500px) {
  .p-data-sync__finish ul {
    margin-left: 0;
  }
  .info-para {
    background: #f5f5f5;
    padding: 7% 10% 7%;
  }
}
</style>
