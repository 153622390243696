const listServices = [
	'ウィッグ・かつら',
	'エクステ',
	'植毛',
	'増毛パウダー',
	'その他'
]


const listService = [
  {
    id: '1',
    name: 'ウィッグ・かつら'
  },
  {
    id: '2',
    name: 'エクステ'
  },
  {
    id: '3',
    name: '増毛パウダー'
  },
  {
    id: '4',
    name: 'その他'
  },
]

export { listServices, listService }
