<template>
  <section class="l-content-area">
    <div class="l-content l-content--mypage">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="c-title__bloc c-title__bloc--mypage">
            <h1>退会処理が完了いたしました。</h1>
          </section>
          <section class="p-bloc p-bloc__mypage bloc_unsubscribe">
            <div class="p-mypage__complete unsubscribe_finish">
              <p>
                本サービスにおけるすべての情報は削除されました。今までサービスをご利用いた<br />
                だき、誠にありがとうございました。
              </p>

              <button-registration
                :is-prev-button="false"
                :next-text-color="'#787878'"
                :handle-next-button="nextToStep"
                :next-text="'men’s svenson公式サイト'"
              >
              </button-registration>
            </div>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import ButtonRegistration from '../../common/ButtonRegistration'
export default {
  name: 'MypageProfileUnsubscribeFinish',
  components: {
    ButtonRegistration,
  },
  methods: {
    nextToStep() {
      window.location.href = 'https://www.mens-svenson.net/'
    },
  },
}
</script>
<style scoped>
.v-application ul,
.v-application ol {
  padding-left: 0 !important;
}

.v-application a {
  color: #fff;
}
</style>
