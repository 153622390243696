const auth = {
  state: () => ({
    token: null,
    user: {},
    booking: false,
    linked: false,
  }),
  mutations: {
    setToken(state, token) {
      state.token = token
    },
    setUser(state, user) {
      state.user = user
    },
    setBooking(state, booking) {
      state.booking = booking
    },
    setLinked(state, linked) {
      state.linked = linked
    }
  },
  actions: {
    setToken({ commit }, token) {
      commit('setToken', token)
    },
    setUser({ commit }, user) {
      commit('setUser', user)
    },
    setBooking({ commit }, booking) {
      commit('setBooking', booking)
    },
    setLinked({ commit }, linked) {
      commit('setLinked', linked)
    }
  },
}

export default auth
