<template>
  <section class="l-content-area">
    <div class="l-content l-content--login">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="p-bloc p-bloc__data-sync">
            <div class="p-data-sync__finish">
              <h2>アカウントの連携が<span>完了いたしました</span></h2>
              <button-registration
                next-text="マイページ"
                :is-prev-button="false"
                :handle-next-button="nextToStep"
                :prev-text-color="'#787878'"
              ></button-registration>
              <p class="pdt-25">
                顧客情報をご連携後はマイページより以下の機能をご利用いただけます。
              </p>
              <ul>
                <li><strong>ご来店予約</strong></li>
                <li><strong>ご予約情報の履歴確認</strong></li>
                <li><strong>ご登録情報の変更</strong></li>
              </ul>
            </div>
          </section>
          <!-- .p-bloc__data-sync -->
        </div>
      </div>
    </div>
    <!-- .l-content--login -->
  </section>
  <!-- .l-content-area -->
</template>
<script>
import ButtonRegistration from '../common/ButtonRegistration'
export default {
  name: 'DataSyncSuccess',
  components: { ButtonRegistration },
  props: {
    currentStep: {
      type: String,
      default: '4',
    },
  },
  methods: {
    nextToStep() {
      sessionStorage.setItem('linked', 1)
      this.$emit('nextToStep')
    },
  },
}
</script>
<style scoped>
.pdt-25 {
  padding-top: 25px;
}
</style>
