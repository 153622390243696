import * as yup from 'yup'
import * as errorMessage from '../constant/text'
import * as regex from './regex'

const emailSchema = yup.object().shape({
  email: yup
    .string()
    .required(errorMessage.EMAIL_REQUIRED)
    .matches(regex.patternEmail, errorMessage.EMAIL_INVALID)
    .email(errorMessage.EMAIL_INVALID),
})

const passwordSchema = yup.object().shape({
  password: yup.string().required(errorMessage.PASSWORD_REQUIRED),
})

const validateRequired = (value, message) => {
  return yup.object().shape({
    [value]: yup
      .string()
      .trim()
      .required(message),
  })
}

const validateDate = yup.object().shape({
  date: yup
    .string()
    .trim()
    .required(errorMessage.DATE_INVALID),
  month: yup
    .string()
    .trim()
    .required(errorMessage.DATE_INVALID),
  year: yup
    .string()
    .trim()
    .required(errorMessage.DATE_INVALID),
})

const validatePattern = (value, pattern, message) => {
  return yup.object().shape({
    [value]: yup
      .string()
      .trim()
      .matches(pattern, message),
  })
}

const validatePhoneNumber = (value, message) => {
  return yup.object().shape({
    [value]: yup
      .string()
      .trim()
      .required(message)
      .matches(regex.patternPhoneNumber, message),
  })
}

const validateZipCode = (value, message) => {
  return yup.object().shape({
    [value]: yup
      .string()
      .trim()
      .required(message)
      .min(7, errorMessage.ZIPCODE_IS_MIN7)
      .matches(regex.patternNumber, errorMessage.ZIPCODE_IS_NUMBER),
  })
}

const validateGender = yup.object().shape({
  gender: yup.string().required(errorMessage.GENDER_INVALID),
})

const rulesValidateAllReservation = yup.object().shape({
  firstName: yup
    .string()
    .required(errorMessage.NAME_INVALID)
    .matches(regex.patternName, errorMessage.NAME_INVALID)
    .test(
      'firstName',
      errorMessage.EMOJI_KANJI_INVALID,
      value => !regex.patternEmojiRestrict.test(value)
    )
    .test('firstName', errorMessage.EMOJI_KANJI_INVALID, value => !regex.patternKanji.test(value)),
  lastName: yup
    .string()
    .required(errorMessage.NAME_INVALID)
    .matches(regex.patternName, errorMessage.NAME_INVALID)
    .test(
      'lastName',
      errorMessage.EMOJI_KANJI_INVALID,
      value => !regex.patternEmojiRestrict.test(value)
    )
    .test('lastName', errorMessage.EMOJI_KANJI_INVALID, value => !regex.patternKanji.test(value)),
  firstNameKana: yup
    .string()
    .required(errorMessage.NAME_INVALID)
    .matches(regex.patternKana, errorMessage.NAME_KANA_INVALID)
    .matches(regex.patternName, errorMessage.NAME_INVALID)
    .test(
      'firstNameKana',
      errorMessage.EMOJI_KANJI_INVALID,
      value => !regex.patternEmojiRestrict.test(value)
    )
    .test(
      'firstNameKana',
      errorMessage.EMOJI_KANJI_INVALID,
      value => !regex.patternKanji.test(value)
    ),
  lastNameKana: yup
    .string()
    .required(errorMessage.NAME_INVALID)
    .matches(regex.patternKana, errorMessage.NAME_KANA_INVALID)
    .matches(regex.patternName, errorMessage.NAME_INVALID)
    .test(
      'lastNameKana',
      errorMessage.EMOJI_KANJI_INVALID,
      value => !regex.patternEmojiRestrict.test(value)
    )
    .test(
      'lastNameKana',
      errorMessage.EMOJI_KANJI_INVALID,
      value => !regex.patternKanji.test(value)
    ),
  email: yup
    .string()
    .required(errorMessage.EMAIL_REQUIRED)
    .matches(regex.patternEmail, errorMessage.EMAIL_INVALID)
    .email(errorMessage.EMAIL_INVALID),
  gender: yup.string().required(errorMessage.GENDER_INVALID),
  phone: yup
    .string()
    .trim()
    .required(errorMessage.PHONE_INVALID)
    .matches(regex.patternPhoneNumber, errorMessage.PHONE_IS_NUMBER),
  year: yup
    .string()
    .trim()
    .test(
      'relatedFieldsRequired',
      errorMessage.DATE_INVALID,
      (item,testContext)=>{
        return (testContext.parent.date && testContext.parent.month && testContext.parent.year)
      }
    ),
  month: yup
    .string()
    .trim()
    .test(
      'relatedFieldsRequired',
      errorMessage.DATE_INVALID,
      (item,testContext)=>{
        return (testContext.parent.date && testContext.parent.month && testContext.parent.year)
      }
    ),
  date: yup
    .string()
    .trim()
    .test(
      'relatedFieldsRequired',
      errorMessage.DATE_INVALID,
      (item,testContext)=>{
        return (testContext.parent.date && testContext.parent.month && testContext.parent.year)
      }
    )
})

const rulesValidateReservationAddress = yup.object().shape({
  address: yup
    .string()
    .trim()
    .required(errorMessage.ADDRESS_INVALID)
    .test(
      'address',
      errorMessage.EMOJI_KANJI_INVALID,
      value => !regex.patternEmojiRestrict.test(value)
    )
    .test('address', errorMessage.EMOJI_KANJI_INVALID, value => !regex.patternKanji.test(value)),
  city: yup
    .string()
    .trim()
    .required(errorMessage.CITY_INVALID)
    .test(
      'city',
      errorMessage.EMOJI_KANJI_INVALID,
      value => !regex.patternEmojiRestrict.test(value)
    )
    .test('city', errorMessage.EMOJI_KANJI_INVALID, value => !regex.patternKanji.test(value)),
  state: yup
    .string()
    .trim()
    .required(errorMessage.STATE_INVALID),
  studio: yup
    .string()
    .trim()
    .required(errorMessage.STUDIO_INVALID),
  zipCode: yup
    .string()
    .trim()
    .required(errorMessage.ZIP_CODE_INVALID)
    .min(7, errorMessage.ZIPCODE_IS_MIN7)
    .matches(regex.patternNumber, errorMessage.ZIPCODE_IS_NUMBER),
})

const rulesValidateDatasync = yup.object().shape({
  firstName: yup
    .string()
    // .trim()
    .matches(regex.patternDataSyncName, errorMessage.NAME_INVALID)
    .required(errorMessage.NAME_INVALID),
  lastName: yup
    .string()
    // .trim()
    .matches(regex.patternDataSyncName, errorMessage.NAME_INVALID)
    .required(errorMessage.NAME_INVALID),
  customerNo: yup
    .string()
    .trim()
    .required(errorMessage.CUSTOMER_NO_IS_NUMBER)
    .matches(regex.patternCustomerNo, errorMessage.CUSTOMER_NO_IS_NUMBER),
  phone: yup
    .string()
    .trim()
    .required(errorMessage.PHONE_INVALID)
    .matches(regex.patternPhoneNumber, errorMessage.PHONE_IS_NUMBER),
})

const rulesValidateReservationQuestion = yup.object().shape({
  contact: yup
    .string()
    .trim()
    .required(errorMessage.CONTACT_INVALID),
  comment: yup
    .string()
    .trim()
    .test(
      'comment',
      errorMessage.EMOJI_KANJI_INVALID,
      value => !regex.patternEmojiRestrict.test(value)
    )
    .test('comment', errorMessage.EMOJI_KANJI_INVALID, value => !regex.patternKanji.test(value)),
  other: yup
    .string()
    .trim()
    .test(
      'other',
      errorMessage.EMOJI_KANJI_INVALID,
      value => !regex.patternEmojiRestrict.test(value)
    )
    .test('other', errorMessage.EMOJI_KANJI_INVALID, value => !regex.patternKanji.test(value)),
})

const rulesValidateReservationNextQuestion = yup.object().shape({
  experience: yup
    .string()
    .trim()
    .required(errorMessage.EXPERIENCE_INVALID),
  comment: yup
    .string()
    .trim()
    .test(
      'comment',
      errorMessage.EMOJI_KANJI_INVALID,
      value => !regex.patternEmojiRestrict.test(value)
    )
    .test('comment', errorMessage.EMOJI_KANJI_INVALID, value => !regex.patternKanji.test(value)),
  request: yup
    .string()
    .trim()
    .test(
      'request',
      errorMessage.EMOJI_KANJI_INVALID,
      value => !regex.patternEmojiRestrict.test(value)
    )
    .test('request', errorMessage.EMOJI_KANJI_INVALID, value => !regex.patternKanji.test(value)),
  service: yup.array().min(1, errorMessage.SERVICE_INVALID),
})

const rulesValidateReservationNextQuestionNoService = yup.object().shape({
  experience: yup
    .string()
    .trim()
    .required(errorMessage.EXPERIENCE_INVALID),
  comment: yup
    .string()
    .trim()
    .test(
      'comment',
      errorMessage.EMOJI_KANJI_INVALID,
      value => !regex.patternEmojiRestrict.test(value)
    )
    .test('comment', errorMessage.EMOJI_KANJI_INVALID, value => !regex.patternKanji.test(value)),
  request: yup
    .string()
    .trim()
    .test(
      'request',
      errorMessage.EMOJI_KANJI_INVALID,
      value => !regex.patternEmojiRestrict.test(value)
    )
    .test('request', errorMessage.EMOJI_KANJI_INVALID, value => !regex.patternKanji.test(value)),
})

const rulesValidateArrayRequired = (name, message) => {
  return yup.object().shape({
    [name]: yup.array().min(1, message),
  })
}

const rulesValidateReservationTime = yup.object().shape({
  firstTimeDisplay: yup
    .string()
    .trim()
    .required(errorMessage.DATE_TIME_INVALID),
  secondTimeDisplay: yup
    .string()
    .trim()
    .required(errorMessage.DATE_TIME_INVALID),
})

const rulesValidateDateTime = yup.object().shape({
  date: yup
    .string()
    .trim()
    .required(errorMessage.DATE_TIME_INVALID),
  hour: yup
    .string()
    .trim()
    .required(errorMessage.DATE_TIME_INVALID),
  minute: yup
    .string()
    .trim()
    .required(errorMessage.DATE_TIME_INVALID),
})

const rulesValidateDataSyncProfile = yup.object().shape({
  /*firstName: yup
    .string()
    .trim()
    .required(errorMessage.NAME_INVALID),
  lastName: yup
    .string()
    .trim()
    .required(errorMessage.NAME_INVALID),
  firstNameKana: yup
    .string()
    .trim()
    .required(errorMessage.NAME_INVALID)
    .matches(regex.patternKana, errorMessage.NAME_KANA_INVALID),
  lastNameKana: yup
    .string()
    .trim()
    .required(errorMessage.NAME_INVALID)
    .matches(regex.patternKana, errorMessage.NAME_KANA_INVALID),*/
  email: yup
    .string()
    .trim()
    .required(errorMessage.EMAIL_REQUIRED)
    .matches(regex.patternEmail, errorMessage.EMAIL_INVALID)
    .email(errorMessage.EMAIL_INVALID),
  //gender: yup.string().required(errorMessage.GENDER_INVALID),
  phone: yup
    .string()
    .trim()
    .required(errorMessage.PHONE_INVALID)
    .matches(regex.patternPhoneNumber, errorMessage.PHONE_IS_NUMBER),
  /* year: yup
    .string()
    .trim()
    .required(errorMessage.DATE_INVALID),
  month: yup
    .string()
    .trim()
    .required(errorMessage.DATE_INVALID),
  date: yup
    .string()
    .trim()
    .required(errorMessage.DATE_INVALID),*/
  studio: yup
    .string()
    .trim()
    .required(errorMessage.STUDIO_INVALID),
})

const validateEmoji = yup.object().shape(
  {
    comment: yup
      .string()
      .trim()
      .test(
        'comment',
        errorMessage.EMOJI_KANJI_INVALID,
        value => !regex.patternEmojiRestrict.test(value)
      )
      .test('comment', errorMessage.EMOJI_KANJI_INVALID, value => !regex.patternKanji.test(value)),
    requestMsg: yup
      .string()
      .trim()
      .test(
        'requestMsg',
        errorMessage.EMOJI_KANJI_INVALID,
        value => !regex.patternEmojiRestrict.test(value)
      )
      .test(
        'requestMsg',
        errorMessage.EMOJI_KANJI_INVALID,
        value => !regex.patternKanji.test(value)
      ),
  },
  { abortEarly: false }
)

export {
  emailSchema,
  passwordSchema,
  validateRequired,
  validatePattern,
  validatePhoneNumber,
  validateZipCode,
  validateDate,
  validateGender,
  rulesValidateAllReservation,
  rulesValidateReservationAddress,
  rulesValidateReservationQuestion,
  rulesValidateReservationNextQuestion,
  rulesValidateArrayRequired,
  rulesValidateReservationTime,
  rulesValidateDateTime,
  rulesValidateDatasync,
  rulesValidateDataSyncProfile,
  rulesValidateReservationNextQuestionNoService,
  validateEmoji,
}
