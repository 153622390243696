<template>
  <section class="l-content-area">
    <div class="l-content l-content--login">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="p-bloc p-bloc__data-sync bloc_unsubscribe">
            <div class="p-data-sync__finish">
              <h2>アカウント連携機能が<span>ロックされました。</span></h2>
              <p>アカウント連携の試行失敗回数が最大数に達したため、３０分間アカウント連携機能がご利用いただけません。時間を空けてから、再度お試しください。不明点がございましたら、ご利用店舗にお問い合わせをお願いいたします。<br /></p>
              <a href="https://www.mens-svenson.net/studio/" target="_blank" rel="noopener"><button-registration
                :next-text="'SVENSONスタジオ一覧'"
                :is-prev-button="false"
              ></button-registration>
              </a>
              <button-registration
                next-text="ログアウト"
                :is-prev-button="false"
                :handle-next-button="Logout"
              ></button-registration>
            </div>
          </section><!-- .p-bloc__data-sync -->
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import ButtonRegistration from '../common/ButtonRegistration'
import { logout } from '../../actions/requestLogin'
export default {
  name: 'DataSyncFreeze',
  components: { ButtonRegistration },
  methods: {
    redirectLoginPage() {
        this.$router.push({
        name: 'login'
        })
    },
    Logout() {
        this.$authInfo.setToken(sessionStorage.getItem('token'))
        logout()
        .then(res => {
            const {data: {success}} = res
            if(success && success === 1) {
            this.$authInfo.setToken('')
            this.$authInfo.setUser({})
            sessionStorage.removeItem('token')
            sessionStorage.removeItem('userInfo')
            sessionStorage.removeItem('userLinked')
            sessionStorage.removeItem('studioInfo')
            sessionStorage.removeItem('userId')
            sessionStorage.removeItem('user')
            sessionStorage.removeItem('booking')
            sessionStorage.removeItem('linked')
            this.redirectLoginPage()
            }
        })
        .catch(err => {
            return err
        })
    }
  }
}
</script>
