<template>
  <div class="kiyaku-box p-kiyaku__txt">
    <h2>ご利用規約</h2>
    <p>
      本規約は、株式会社スヴェンソン（以下「当社」といいます。）のメンズ事業部が運営する「予約システム」（以下「本サービス」といいます。）をご利用いただくにあたり、必要な事項を定めたものです。本サービスをご利用いただくには、本規約への同意が必要となりますので、本規約をご確認のうえ同意いただきますようお願いいたします。本規約に同意いただけない場合には、本サービスをご利用いただくことはできません。本サービスの利用に際しては、本規約が適用されますので、本規約を遵守していただきますようお願いいたします。なお、本規約の同意につきましては、本サービスを利用することにより、本規約に同意したものとします。
    </p>
    <p>
      本サービスは、hubsynch株式会社（以下「システム提供者」といいます。）が提供する会員管理システム「hubsynch」（以下「hubsynch」といいます。）を利用しますので、システム提供者が規定する各種規約、利用ガイド等を確認・同意のうえシステム提供者への会員登録等をお願いいたします。なお、登録、利用、パスワード管理、責任、その他のすべての事項については、システム提供者が規定する各種規約、利用ガイド等が適用され、またシステム提供者から会員情報等に関するメールが配信されますので予めご了承ください。
    </p>
    <h3>第1条&nbsp;用語の意味</h3>
    <p>本規約で使用される用語は、以下の意味を有するものとします。</p>
    <ol>
      <li>「会員」とは、本規約を確認・承諾のうえ、本サービスを利用するために会員登録を申込み、当社が認証した方をいいます。</li>
      <li>「会員パスワード」とは、各会員が任意に指定した会員を識別するための当社所定の記号の組み合わせをいいます。</li>
    </ol>
    <h3>第2条&nbsp;会員登録・会員パスワードの管理等</h3>
    <ol>
      <li>本サービスの利用を希望する方は、当社所定の手続きにより、必要事項を入力し、会員登録をするものとします。なお、hubsynchへの登録、利用については、システム提供者が規定する各種規約、利用ガイド等を確認・同意のうえ、これらに従い、必要な登録等の手続き及びパスワード管理を行うものとします。</li>
      <li>会員は、本サービスに登録し、利用するために、会員の正確、完全かつ最新の情報を当社へ同意のうえ、提供するものとします。</li>
      <li>当社は、前各項の入力事項に不備がない場合は会員として認証します。ただし、会員として認証することが不適切であると当社が判断した場合は、会員認証しない場合があります。</li>
      <li>会員は、会員の責任のもと、会員パスワードの使用及び管理を行うものとします。</li>
    </ol>
    <h3>第3条&nbsp;本サービスの利用</h3>
    <ol>
      <li>会員は、前条に定める会員登録をすることにより、本サービスを利用することができます。なお、hubsynchの利用については、システム提供者が規定する各種規約、利用ガイド等を確認・同意のうえ、これらに従い、必要な手続きを行うことで利用することができます。</li>
      <li>会員は、本サービスを利用する際に、当社所定の方法で登録のメールアドレスおよび会員パスワードを入力することにより、本サービスを利用することができます。</li>
    </ol>
    <h3>第4条&nbsp;利用照会等</h3>
    <ol>
      <li>会員は、マイページ上で本サービスの利用履歴、登録情報等を確認することができます。</li>
      <li>会員は、前項の表示された内容に疑義がある場合は、当社に申し出るものとします。</li>
    </ol>
    <h3>第5条&nbsp;会員カウント等の管理</h3>
    <ol>
      <li>会員は、メールアドレス、会員パスワード、その他本サービスの利用にあたって必要となる情報（以下「会員情報」といいます。）を第三者に対して譲渡、売買、名義変更、または利用させてはならないものとします。</li>
      <li>会員は、自己責任において会員情報を第三者に知られないよう管理するものとし、また会員パスワードは他人に推測されにくく、ツールなどの機械的な処理で割り出しにくいものにする、他人に知られないよう定期的に変更する等、十分に注意して管理するものとします。なお、hubsynchに関するパスワード等の管理、利用については、システム提供者が規定する各種規約、利用ガイド等を確認のうえ、これらに従いご利用ください。</li>
      <li>万一、会員情報の盗難、不正使用、紛失等が発覚した場合は、会員は速やかに当社へ連絡するものとします。</li>
    </ol>
    <h3>第6条&nbsp;会員情報の確認・変更等</h3>
    <ol>
      <li>会員は、マイページにて、当社所定の方法により、登録した会員情報を変更することができます。なお、hubsynchへの登録情報については、システム提供者が規定する各種規約、利用ガイド等を確認のうえ、これらに従い変更することができます。</li>
      <li>会員は、登録していた情報に変更が生じた場合は、遅滞なく変更登録をする義務を負うものとします。</li>
      <li>前項の変更登録がなされなかったことにより、当社からの通知が会員に延着、または到着しなかった場合には、当該通知が通常到着すべき時期に会員に到着したとみなすことができるものとします。</li>
    </ol>
    <h3>第7条&nbsp;利用規約の変更</h3>
    <ol>
      <li>当社は、会員に対して、その旨を通知、または本サービスホームページにその旨を告知することにより、本規約を変更することができるものとします。</li>
      <li>本規約を変更した場合、本サービスに関する一切の事項は、変更後の規約によるものとします。</li>
      <li>本規約変更後、会員が本サービスの利用を継続された場合、当社は会員が変更後の規約を承認したものとします。</li>
      <li>会員は前各項の内容を十分に理解し、本規約の内容を定期的に確認するものとします。</li>
    </ol>
    <h3>第8条&nbsp;提供サービスの内容、条件、変更、廃止</h3>
    <ol>
      <li>本サービスの主な内容は、次のとおりとします。
        <ol class="paren">
          <li>施術のご予約に関するサービス</li>
          <li>前号に付随するサービス</li>
        </ol>
      </li>
      <li>本サービスの利用条件は、次のとおりとします。
        <ol class="paren">
          <li>web先行予約は、3か月前から予約可能です。</li>
          <li>1か月間での予約数は2件を上限とし、また１日の予約は１件までとします。</li>
          <li>1か月間でのキャンセル待ちは4回を上限とし、また1日のキャンセル待ちは1件までとします。</li>
          <!-- <li>1か月でのキャンセル待ち可能数は4回までとします。1日でのキャンセル待ち可能数は1件までとします。</li> -->
        </ol>
      </li>
      <li>当社は、当社の判断により本サービスの全部または一部を適時変更、廃止することができるものとします。</li>
      <li>本サービスを変更、廃止する際は、前条第１項と同様の手続きをとるものとします。</li>
    </ol>
    <h3>第9条&nbsp;当社による本サービスの提供停止</h3>
    <p>当社は、システムの定期保守および緊急保守のために必要な場合、停電や第三者の行為によりシステムに回復困難な損害が生じた場合、その他相当の理由がある場合には、会員に対して、その旨を通知、または本サービスホームページにその旨を告知することにより本サービスの全部または一部のサービス提供を停止することができるものとします。</p>
    <h3>第10条&nbsp;会員による本サービスの利用中止手続き</h3>
    <ol>
      <li>会員が本サービスの利用の中止を希望する場合は、マイページにて、当社所定の方法により、利用中止（登録の解除）の手続きを行うものとします。</li>
      <li>前項に基づき、会員が利用中止（登録の解除）の手続きを行った場合、当社は、当該会員に対し、本サービスの提供を中止します。</li>
    </ol>
    <h3>第11条&nbsp;当社の免責について</h3>
    <ol>
      <li>当社の責によらない、または天災地変等の不可抗力による通信回線やコンピュータ等の障害によるシステムの中断・遅滞・中止・データの消失、データへの不正アクセスにより生じた損害、その他本サービスの利用に関して会員に生じた損害について、当社は一切責任を負わないものとします。</li>
      <li>当社は、本サービスのウェブページ、サーバ、ドメイン等から会員に送られるメール、　　本サービス内のコンテンツ等に、コンピューターウイルス等の有害なものが含まれないことを保証いたしません。</li>
      <li>当社は、当社の責に起因し、会員が本サービスの利用により損害を被りまたは損失、責任、費用等を負担することとなった場合、相当の因果関係の範囲内で現実に生じた通常損害を賠償するものとし、その範囲は対象となる個別契約、個別サービスの対価を限度として賠償するものとします。但し、当社の故意又は重過失による場合はこの限りではありません。</li>
      <li>hubsynchに関する事項については、システム提供者が規定する各種規約、利用ガイド等に従うものとします。</li>
    </ol>
    <h3>第12条&nbsp;禁止事項</h3>
    <ol>
      <li>会員は、本サービスの利用にあたり、以下各号の行為をしてはならないものとします。
        <ol class="paren">
          <li>会員登録において、虚偽申請をすること</li>
          <li>他人になりすまして予約をすること、虚偽の情報を意図的に提供すること、予約する意思がないにもかかわらず予約すること、その他不正行為</li>
          <li>コンピュータウィルス等で本サービスの運営を妨害する行為、その他当社の業務を妨害ないしそれに類似する行為</li>
          <li>当社の権利、利益、名誉または信用を損ねる行為、またはイメージを低下させる行為</li>
          <li>当社の特許権、実用新案権、意匠権、商標権、著作権、肖像権等の権利を侵害する行為、または侵害するおそれのある行為</li>
          <li>本サービスで購入した商品等を営利目的として第三者に転売、または転売のために第三者に提供する行為</li>
          <li>本規約および各規約に違反すること</li>
          <li>その他、当社が不適切と判断する行為</li>
        </ol>
      </li>
      <li>前項の違反があった場合、当社は会員の本サービス利用を停止することがあります。</li>
      <li>会員の違反行為により、当社およびその他第三者に損害が生じた場合は、会員はその一切を賠償する責任を負うものとします</li>
    </ol>
    <h3>第13条&nbsp;会員の個人情報等の取扱について</h3>
    <ol>
      <li>当社は、会員の個人情報および取引情報等の会員に係る一切の情報（以下「個人情報等」といいます。）を本サービスのシステムにおいて、当社が別に定める「個人情報の取扱に関して（プライバシーポリシー）」に基づき、適切に管理するものとします。</li>
      <li>会員は、本サービスに登録した個人情報等がメルマガの配信、本サービスの運営・管理のために、当社が利用することについて予め承諾するものとします。</li>
    </ol>
    <h3>第14条&nbsp;著作権について</h3>
    <p>本サービスに掲載されている著作物（文書、写真、イラスト、動画、音声、ソフトウエア等）の著作権は、当社または第三者が保有しており、著作権法その他の法律により保護されていますので、著作権法上認められている範囲を除き、著作権者の承諾なしにこれらの著作物を複製、公衆送信等することはできません。</p>
    <h3>第15条&nbsp;当社からの通知</h3>
    <ol>
      <li>当社は、Eメール、本サービスホームページ上の告知等、当社が適切と考える手段により、会員に対し必要な事項を随時通知します。</li>
      <li>会員は、前項に定める事項を速やかに確認する義務を負うものとします。</li>
      <li>会員は、本条第１項に定める通知は、当社が当該通知を発信した時点をもって、会員に到達したものとみなすことに同意するものとします。</li>
    </ol>
    <h3>第16条（会員登録の抹消等）</h3>
    <p>以下の場合に該当すると当社が判断した会員については、当社は事前に会員の承諾なしに、そのサービスの停止または会員登録の抹消を行うことができるものとします。</p>
    <ol>
      <li>反社会的勢力またはそのおそれのある団体等に所属していることが判明した場合</li>
      <li>その他会員として不適切な事由が判明した場合</li>
    </ol>
    <h3>第17条&nbsp;Cookie（クッキー）情報の取扱いについて</h3>
    <ol>
      <li>本サービスホームページでは、オンライン利用およびインターネット全般のご利用をより充実したものとするために、当社や、当社の第三者プロバイダー、および当社の業務委託先が会員のコンピュータに「Cookie（クッキー）」を送付したり、同種の技術を利用することがあります。</li>
      <li>「Cookie（クッキー）」とは、会員を特定のカスタマーとして識別し、会員の個人的な嗜好を、技術的情報とともに保存することができる小さなテキストファイルで、本サービスの内容や提供するサービスを向上させるための行動の観察、集計データの収集、広告配信のために利用致します。</li>
      <li>「Cookie（クッキー）」は、それ自体が個人情報を含んだり明らかにしたりするものではありませんが、会員が個人情報を提供することを選択した場合には、「Cookie（クッキー）」に保存されたデータにリンクされることがあり得ます。</li>
      <li>「Cookie（クッキー）」の利用による情報・データ収集を希望しない場合は、会員が使用しているブラウザの「ヘルプ」メニューをご確認いただき、「Cookie（クッキー）」の機能を無効にするようブラウザの設定を変更ください。ただし「Cookie（クッキー）」の機能を無効にした場合、本サービスを充分にご利用いただけない場合があることを予めご了承ください。</li>
    </ol>
    <h3>第18条&nbsp;規定外事項</h3>
    <p>本規約に定めのない事項、またはその解釈に疑義を生じた事項については、会員および当社が誠実に協議し、その解決を図るものとします。</p>
    <h3>第19条&nbsp;管轄裁判所</h3>
    <p>本規約および本サービスに関し、会員と当社との間で訴訟が生じた場合、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>
    <p class="right-side">以上<br>株式会社スヴェンソン<br>制定日：2023年4月1日</p>
  </div>
</template>

<script>
  export default {
    name: 'Terms',
  }
</script>

<style scoped>

</style>
