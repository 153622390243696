<template>
  <section class="l-content-area">
    <div class="l-content l-content--reservation">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="c-title__bloc c-title__bloc--reservation">
            <h1>ご来店予約</h1>
            <div class="c-btn c-btn--title-back"><a href="#" @click="prevToStep()">戻る</a></div>
          </section>

          <section class="p-bloc p-bloc__reservation">
            <h2 class="p-reservation__step">STEP 3</h2>

            <div class="p-reservation__bloc">
              <p>
                希望されない場合はそのままお進みください。<br />
                <span class="p-reservation__subtext">
                  製品のカラー / パーマの場合は、当日ご来店の際にお申し付けください。</span
                >
              </p>
              <div class="p-reservation__service p-reservation__custom">
                <template>
                  <div
                    v-for="(subTreatment, index) in listSubTreatment"
                    v-if="!disabledMenu(subTreatment)"
                    :key="index"
                    class="d-box"
                  >
                    <input
                      :id="`consult-${subTreatment.id}`"
                      v-model="listServicesOptionPush"
                      type="checkbox"
                      :value="subTreatment.id"
                      hidden
                      @click="addService(subTreatment.id, subTreatment.name)"
                    />
                    <label class="checkLabel" :for="`consult-${subTreatment.id}`">
                      <h2>{{ subTreatment.name }}</h2>
                      <dl>
                        {{ subTreatment.description }}
                        <dt>追加施術時間の目安：</dt>
                        <span
                          v-if="
                            subTreatment.duration_male !== null && subTreatment.duration_male !== 0
                          "
                          >男性+{{ subTreatment.duration_male / 60 }}時間</span
                        ><span
                          v-if="
                            subTreatment.duration_female !== null &&
                              subTreatment.duration_female !== 0
                          "
                          >、女性+{{ subTreatment.duration_female / 60 }}時間</span
                        >
                      </dl>
                    </label>
                  </div>
                </template>
              </div>
            </div>

            <button-registration
              :prev-text="'戻る'"
              :next-text="'次へ'"
              :prev-text-color="'#787878'"
              :handle-prev-button="prevToStep"
              :handle-next-button="nextToStep"
            >
            </button-registration>
          </section>
        </div>
      </div>
    </div>
  </section>
</template>
<script>
import ButtonRegistration from '../../common/ButtonRegistration.vue'
import { requestRegisterReservation } from '../../../actions/requestMypage'

export default {
  name: 'MypageReservationOption',
  components: { ButtonRegistration },
  props: {
    currentStep: {
      type: String,
      default: '3',
    },
    category: {
      type: String,
      default: '',
    },
    services: {
      type: Array,
      default: () => {
        return []
      },
    },
    store: {
      type: String,
      default: '',
    },
    userId: {
      type: String,
      default: '',
    },
    checkPush: {
      type: Boolean,
      default: false,
    },
    option: {
      type: Array,
      default: () => {
        return []
      },
    },
    treatment: {
      type: Array,
      default: () => {
        return []
      },
    },
    listTimeMale: {
      type: Array,
      default: () => {
        return []
      },
    },
    listTimeFemale: {
      type: Array,
      default: () => {
        return []
      },
    },
    hasOption: {
      type: Boolean,
      default: true,
    },
    selectedServices: {
      type: Array,
      default: () => {
        return []
      },
    },
    selectedServiceNames: {
      type: Array,
      default: () => {
        return []
      },
    },
    listAllServices: {
      type: Array,
      default: () => {
        return []
      },
    },
    compatibility: {
      type: Array,
      default: () => {
        return []
      },
    },
    reservationId: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      listSubTreatment: [],
      listSubTimeMale: [],
      listSubTimeFemale: [],
      isModalVisible: false,
      errorMessage: '',
      selectedSubTreatments: [],
      listServicesOptionPush: [],
      listServicesOptionNamePush: [],
      compatibilityPush: [],
    }
  },
  created() {
    this.$emit('startLoading')
    this.listSubTreatment = this.listAllServices.filter(service => {
      return service.is_sub_treatment
    })
    this.$emit('endLoading')
  },
  methods: {
    addService(optionId, optionName) {
      //this.checkMenu(optionId)
      if (optionId && this.checkPush === true) {
        //if checkbox is check or uncheck
        let name_index = this.listServicesOptionNamePush.indexOf(optionName)
        if (name_index === -1) {
          this.listServicesOptionNamePush.push(optionName)
        } else {
          this.listServicesOptionNamePush.splice(name_index, 1)
        }
      }
    },
    prevToStep() {
      this.selectedSubTreatments = []
      //this.compatibilityPush = []
      //this.listServicesOptionPush = []
      //this.listServicesOptionNamePush = []
      this.$emit('prevToStep')

      //remove pre selected services
      const listServices = this.services.filter(s => {
        return this.selectedServices.indexOf(parseInt(s)) === -1
      })
      const listTreatment = this.treatment.filter(s => {
        return this.selectedServiceNames.indexOf(s.trim()) === -1
      })

      this.$emit('onHandleChange', 'services', listServices)
      this.$emit('onHandleChange', 'treatment', listTreatment)

      if (listServices.length === 0) {
        this.$emit('onHandleChange', 'compatibility', [])
      }
    },
    handleMenu(tmpServices) {
      //remove for unselect state
      let removeUnselectedServices = []
      if (this.option.length > 0) {
        let optionServices = []
        this.listSubTreatment.map(s => {
          if (this.option.indexOf(s.name.trim()) !== -1) {
            optionServices.push(s.id)
          }
        })
        removeUnselectedServices = tmpServices.filter(s => {
          return optionServices.indexOf(parseInt(s)) === -1
        })
      } else {
        removeUnselectedServices = tmpServices
      }
      return removeUnselectedServices
    },
    handleMenuName(tmpServices) {
      let names = []
      this.listSubTreatment.map(s => {
        if (tmpServices.indexOf(parseInt(s.id)) !== -1) {
          names.push(s.name)
        }
      })
      return names
    },
    nextToStep() {
      localStorage.removeItem('specialServices')
      localStorage.removeItem('servicesMsg')
      localStorage.removeItem('statusMsg')
      if (this.listServicesOptionPush.length > 0 && this.listServicesOptionNamePush.length > 0) {
        this.selectedSubTreatments = this.listServicesOptionPush

        let tmpServices = [...this.services]
        tmpServices = this.handleMenu(tmpServices)

        //combine services + current selected option services
        tmpServices = tmpServices.concat(this.listServicesOptionPush)
        //let listNameSubTreatmentPush = [...this.option]
        //listNameSubTreatmentPush = listNameSubTreatmentPush.concat(this.listServicesOptionNamePush)

        let listNameSubTreatmentPush = this.handleMenuName(tmpServices)
        //remove duplicate data
        tmpServices = [...new Set(tmpServices)]
        listNameSubTreatmentPush = [...new Set(listNameSubTreatmentPush)]

        const dateTime = ''
        const isDesignCut = true
        this.$emit('startLoading')
        if ([4, 5, 47, 48, 49, 51].some(specialService => tmpServices.includes(specialService))) {
          localStorage.setItem('specialServices', true)
        } else {
          localStorage.setItem('servicesMsg', true)
        }

        requestRegisterReservation({
          reservationId: this.reservationId,
          currentStep: 3,
          userId: this.userId,
          storeId: this.store,
          timeVisited: dateTime,
          isDesignCut,
          services: tmpServices,
          status: null,
          stylist: null,
          genderPreference: null,
          waiting: 0,
          requestMsg: '',
        })
          .then(res => {
            const {
              data: { success },
            } = res
            if (success && success === 1) {
              this.$emit('onHandleChange', 'reservationId', res.data.data.id.toString())
              this.$emit(
                'onHandleChange',
                'estimated_duration',
                res.data.data.estimated_duration.toString()
              )
            }
            this.$emit('endLoading')
            this.$emit('onHandleChange', 'option', listNameSubTreatmentPush)
            this.$emit('onHandleChange', 'services', tmpServices)
            this.$emit('onChangeStep', 'hasOption', true)
            //this.listServicesOptionPush = []
            //this.listServicesOptionNamePush = []
            this.selectedSubTreatments = []
            this.$emit('nextToStep')
          })
          .catch(err => {
            this.$emit('endLoading')
            this.$router.push({ name: 'mypageError' })
            return err
          })
      } else {
        const dateTime = ''
        const isDesignCut = true
        this.$emit('startLoading')
        if ([4, 5, 47, 48, 49, 51].some(specialService => this.services.includes(specialService))) {
          localStorage.setItem('specialServices', true)
        } else {
          localStorage.setItem('servicesMsg', true)
        }
        requestRegisterReservation({
          reservationId: this.reservationId,
          currentStep: 3,
          userId: this.userId,
          storeId: this.store,
          timeVisited: dateTime,
          isDesignCut,
          services: this.services,
          status: null,
          stylist: null,
          genderPreference: null,
          waiting: 0,
          requestMsg: '',
        })
          .then(res => {
            const {
              data: { success },
            } = res
            if (success && success === 1) {
              this.$emit('onHandleChange', 'reservationId', res.data.data.id.toString())
              this.$emit(
                'onHandleChange',
                'estimated_duration',
                res.data.data.estimated_duration.toString()
              )
            }
            this.$emit('endLoading')
            let listServicesPush = [...this.services]
            listServicesPush = this.handleMenu(listServicesPush)
            //const listNameSubTreatmentPush = [...this.option]
            const listNameSubTreatmentPush = this.handleMenuName(listServicesPush)
            const listTimeMalePush = [...this.listTimeMale]
            const listTimeFemalePush = [...this.listTimeFemale]
            this.$emit('onHandleChange', 'option', listNameSubTreatmentPush)
            this.$emit('onHandleChange', 'services', listServicesPush)
            this.$emit('onHandleChange', 'listTimeMale', listTimeMalePush)
            this.$emit('onHandleChange', 'listTimeFemale', listTimeFemalePush)
            this.$emit('onChangeStep', 'hasOption', false)
            this.$emit('nextToStep')
          })
          .catch(err => {
            this.$emit('endLoading')
            this.$router.push({ name: 'mypageError' })
            return err
          })
      }
    },
    disabledMenu(subTreatment) {
      // TODO: Investigate why we are using this.compatibility and not flags e.g "subTreatment.is_in_new_users_menu"
      if (
        this.compatibility.length > 0 &&
        !this.compatibility.includes(parseInt(subTreatment.id)) &&
        this.services.length > 0 &&
        !this.services.includes(parseInt(subTreatment.id))
      ) {
        return true
      } else {
        return false
      }
    },
  },
}
</script>
<style scoped>
.v-application ul,
.v-application ol {
  padding-left: 0 !important;
}

.v-application a {
  color: #0a005a;
}

.p-reservation__custom .d-box label:before {
  display: block;
  position: absolute;
  top: 37%;
  left: 37px;
  z-index: 1;
  content: '';
  width: 27px;
  height: 27px;
  margin-top: 0px;
  background: #ffffff;
  border: 1.5px solid #9a9b9e;
  border-radius: 5px;
}
.p-reservation__custom .d-box input[type='checkbox']:checked + label:before,
.p-reservation__custom .d-box input[type='checkbox'].checked + label.checkLabel:before {
  background-image: url(../../../../src/assets/img/ui_parts/checked_icon.png);
  background-size: 20px;
  background-repeat: no-repeat;
  background-position: 2px;
}

.p-reservation__custom .d-box.disableMenu label.checkLabel:before {
  border-color: #9a9b9e7d;
  background-color: #ffffff;
}

.disableMenu {
  pointer-events: none;
  cursor: default;
  text-decoration: none;
  opacity: 0.6;
}

.p-reservation__custom .d-box {
  margin-bottom: 15px;
  padding: 13px 13px 13px 100px;
  position: relative;
  border: 1px solid #ebebeb;
  background-color: #f5f5f5;
  border-radius: 8px;
  cursor: pointer;
}

.p-reservation__custom .d-box label {
  color: #0a005a;
  text-align: left;
  cursor: pointer;
}

.p-reservation__custom .d-box.disableMenu label {
  color: #00000099;
}

.p-reservation__custom .d-box h2 {
  margin: 0;
  padding: 0;
  border: none;
  font-size: 18px;
  margin-bottom: 4px;
}

.p-reservation__custom .d-box dl dt {
  font-size: 12px;
}
.p-reservation__custom .d-box dl {
  font-size: 15px;
}
.p-reservation__subtext {
  color: red;
  font-size: 13px;
}
</style>
