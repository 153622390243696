<template>
  <v-card height="100%">
    <v-tabs
      v-show="false"
      v-model="currentStep"
      centered
      grow
      :hide-slider="true"
    >
      <v-tabs-slider></v-tabs-slider>
      <v-tab href="#1"
             :align-with-title="true"
             :disabled="disabled.firstTab"
      >
        ステップ1
      </v-tab>
      <v-tab href="#2"
             :align-with-title="true"
             :disabled="disabled.secondTab"
      >
        ステップ2
      </v-tab>
    </v-tabs>

    <v-tabs-items v-model="currentStep" :touch="onTouchEvent()">
      <v-tab-item value="1">
        <first-step-registration-reenter
          :is-force-update="isForceUpdate"
          @nextToStep="nextToStep"
        ></first-step-registration-reenter>
      </v-tab-item>
      <v-tab-item value="2">
        <second-step-registration-reenter
          :is-force-update="isForceUpdate"
          @nextToStep="nextToStep"
          @prevToStep="prevToStep"
        ></second-step-registration-reenter>
      </v-tab-item>
    </v-tabs-items>
  </v-card>
</template>

<script>
  import FirstStepRegistrationReenter from './first-step/index'
  import SecondStepRegistrationReenter from './second-step/index'
  export default {
    name: 'RegistrationReenterComponent',
    components: {
      SecondStepRegistrationReenter,
      FirstStepRegistrationReenter
    },
    data() {
      return {
        isForceUpdate: false,
        currentStep: '1',
        disabled: {
          firstTab: false,
          secondTab: true,
        }
      }
    },
    watch: {
      currentStep(newValue) {
        this.isForceUpdate = false
        if (newValue === '2') {
          this.disabled.secondTab = false
        }
      }
    },
    created() {
      if (!this.$authInfo.getToken()) {
        this.$router.push({
          'name': 'login',
        })
      }
    },
    methods: {
      assignSwipeLeft() {
        this.isForceUpdate = true
      },
      assignSwipeRight() {
        this.isForceUpdate = false
        this.prevToStep()
      },
      nextToStep() {
        const nextStep = parseInt(this.currentStep) + 1
        this.currentStep = nextStep.toString()
      },
      prevToStep() {
        const prevStep = parseInt(this.currentStep) - 1
        this.currentStep = prevStep.toString()
      },
      onTouchEvent() {
        return {
          left: () => this.assignSwipeLeft(),
          right: () => this.assignSwipeRight(),
        }
      },
    }
  }
</script>
