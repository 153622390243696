const patternName = /^\S*$/
//eslint-disable-next-line
const patternDataSyncName = /^([^0-9 ^*|\"!:~<>[\]{}#`\\\_\-\+\/\|?.()',;%@&$=])*$/
const patternKana = /^([ァ-ヶ]|ー)+$/
const patternEmail = /^([a-zA-Z0-9._+-]{3,}@[a-zA-Z0-9.-]{2,}\.[a-zA-Z]{2,})$/
const patternPhoneNumberWithDashes = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/
const patternPhoneNumber = /^(?:\d{9,11}|\d{3}-\d{3}-\d{4}|\d{2}-\d{4}-\d{4}|\d{3}-\d{4}-\d{4})$/
const patternPhoneNumberGlobal = /^((\\[1-9]{1,4})|(\\([0-9]{2,3}\\))|([0-9]{2,4}))*?[0-9]{3,4}?[0-9]{3,4}?$/
const patternNumber = /^[0-9]+$/
const patternCustomerNo = /^[0-9]{8}$/
// const patternEmoji = /^[A-Za-z0-9 ]*$/g
const patternEmoji = /^([a-zA-Z0-9_\u3040-\u309F]|)+/g
const patternEmojiRestrict = /([\u2700-\u27BF]|[\uE000-\uF8FF]|\uD83C[\uDC00-\uDFFF]|\uD83D[\uDC00-\uDFFF]|[\u2011-\u26FF]|\uD83E[\uDD10-\uDDFF])/g
const patternKanji = /(?:[\uD800-\uDBFF][\uDC00-\uDFFF])/g

export {
  patternName,
  patternDataSyncName,
  patternKana,
  patternEmail,
  patternPhoneNumberWithDashes,
  patternPhoneNumber,
  patternPhoneNumberGlobal,
  patternEmoji,
  patternEmojiRestrict,
  patternNumber,
  patternCustomerNo,
  patternKanji,
}
