<template>
  <div class="kiyaku-box p-kiyaku__txt">
    <h2>hubsynchユーザーアカウント利用規約</h2>
    <p>
      本規約は、hubsynch株式会社（以下「当社」といいます。）が提供するログイン機能付き決済マネージメントサービス「hubsynch」（以下「本サービス」といいます。）をご利用いただくにあたり、必要な事項を定めたものです。本サービスをご利用いただくには、本規約への同意が必要となります。本規約に同意いただけない場合には、本サービスをご利用いただくことはできません。
    </p>
    <p>
      本サービスの利用に際しては、本規約が適用されるものとし、hubsynchユーザーアカウント（以下「ユーザー」といいます）として登録手続を行う場合、および本サービスを利用する場合は、本規約を遵守していただく必要があります。本サービスを利用した場合は、ユーザーが本規約に同意したものとみなします。
    </p>
    <h3>第１条&nbsp;用語の意味</h3>
    <p>本規約で使用される用語は、以下の意味を有するものとします。</p>
    <ol>
      <li>「hubsynch」とは、当社が提供するログイン機能付き決済マネージメントサービスのことをいいます。</li>
      <li>「ユーザー」とは、本規約を承諾の上、本サービスを利用するためのユーザー登録を申込み、当社が認証した方をいいます。</li>
      <li>「ユーザーパスワード」とは、各ユーザーが任意に指定したユーザーを識別するための当社所定の記号の組み合わせをいいます。</li>
      <li>
        「hubsynchマーチャントアカウント（以下「マーチャント」といいます。」とは、本サービスを通じて、商品等を販売または役務提供する事業者のことをいいます。
      </li>
    </ol>
    <h3>第２条&nbsp;ユーザー登録</h3>
    <ol>
      <li>本サービスの利用を希望する方は、当社所定の手続により、必要事項を入力し、ユーザー登録をするものとします。</li>
      <li>ユーザーは、本サービスに登録し、利用するために、ユーザーの正確、完全かつ最新の情報を当社へ同意の上、提供するものとします。</li>
      <li>当社は、前各項の入力事項に不備がない場合、ユーザーとして認証します。</li>
    </ol>
    <h3>第３条&nbsp;本サービスの利用</h3>
    <ol>
      <li>ユーザーは、前条に定めるユーザー登録をすることにより、本サービスを利用することができます。</li>
      <li>
        ユーザーは、マーチャントサービスサイト等において商品等を購入する際に、当社所定の方法で登録のメールアドレスおよびユーザーパスワードを入力することにより、本サービスを利用することができます。
      </li>
      <li>
        ユーザーは、本サービスを利用して、クレジットカード決済、銀行振込決済、コンビニ決済等の各決済手段を利用し、マーチャントから商品等を購入することができます。なお、利用できる決済手段については、マーチャント毎に異なる場合があります。
      </li>
      <li>当社は、ユーザーがマーチャントから商品等を購入した際、マーチャントの代行として、本サービスを通じてユーザーから代金を受け取ります。</li>
      <li>
        クレジットカード情報等のユーザーの決済情報は、本サービスで一括管理しているため、ユーザーはマーチャントに決済情報を伝えることなく、支払をすることができます。
      </li>
      <li>当社は、クレジットカードの利用等を一定の年齢以上の方に限定したり、本サービスの利用に際して条件を付すことがあります。</li>
      <li>ユーザーは、マーチャントサービスサイト等において別途定めのある「利用規約」「利用ガイド」等の各規約も遵守するものとします。</li>
    </ol>
    <h3>第４条&nbsp;利用照会等</h3>
    <ol>
      <li>ユーザーは、マイページ上で本サービスの利用履歴、登録情報等を確認することができます。</li>
      <li>ユーザーは、前項の表示された内容に疑義がある場合は、当社に申し出るものとします。</li>
    </ol>
    <h3>第５条&nbsp;ユーザーアカウント等の管理</h3>
    <ol>
      <li>
        ユーザーは、メールアドレス、ユーザーパスワード、その他本サービスの利用にあたって必要となる情報（以下「ユーザー情報」といいます。）を第三者に対して譲渡、売買、名義変更、または利用させてはならないものとします。
      </li>
      <li>
        ユーザーは、自己責任においてユーザー情報を第三者に知られないよう、ユーザーパスワードを定期的に変更する等、十分に注意して管理するものとし、ユーザー情報を利用してなされた全ての行為に関して一切の責任を負うものとします。
      </li>
      <li>
        当社は、ユーザー情報の盗難、不正使用、紛失等に関して、一切責任を負わず、ユーザー情報を利用してなされた行為は、全て当該ユーザーによってなされたものとみなします。また、不正使用等が発覚した場合は、ユーザーは速やかに当社へ連絡するものとします。
      </li>
    </ol>
    <h3>第６条&nbsp;ユーザー情報の確認・変更等</h3>
    <ol>
      <li>ユーザーは、本サービスおよびマーチャントサービスサイト等のマイページにて、当社所定の方法により、登録したユーザー情報を変更することができます。</li>
      <li>ユーザーは、登録していた情報に変更が生じた場合は、遅滞なく変更登録をする義務を負うものとします。</li>
      <li>
        前項の変更登録がなされなかったことにより、当社からの通知がユーザーに延着、または到着しなかった場合には、当該通知が通常到着すべき時期にユーザーに到着したとみなすことができるものとし、これにより生じたユーザーの損害について、当社は一切責任を負わないものとし、ユーザーはこれに同意するものとします。
      </li>
    </ol>
    <h3>第７条&nbsp;利用規約の変更</h3>
    <ol>
      <li>当社は、ユーザーに対して、その旨を通知、または本サービスホームページにその旨を告知することにより、本規約を変更することができるものとします。</li>
      <li>本規約を変更した場合、本サービスに関する一切の事項は、変更後の規約によるものとします。</li>
      <li>本規約変更後、ユーザーが本サービスの利用を継続された場合、ユーザーは変更後の規約を承認したものとみなし、ユーザーはこれに同意するものとします。</li>
      <li>ユーザーは前各項の内容を十分に理解し、本規約の内容を定期的に確認するものとします。</li>
      <li>
        当社は、本規約の変更により、ユーザーに生じた一切の損害について直接損害か間接損害か否か、予見できたか否かを問わず、一切の責任を負わないものとします。
      </li>
    </ol>
    <h3>第８条&nbsp;提供サービスの変更、廃止</h3>
    <ol>
      <li>当社は、当社の判断により本サービスの全部または一部を適時変更、廃止することができるものとします。</li>
      <li>変更、廃止する際は、前条と同様の手続をとるものとします。</li>
    </ol>
    <h3>第９条&nbsp;当社による本サービスの提供停止</h3>
    <p>
      当社は、システムに回復不能の損害が生じた場合、その他相当の理由がある場合には、ユーザーに対して、その旨を通知、または本サービスホームページにその旨を告知することにより本サービスの全部または一部のサービス提供を停止することができるものとします。
    </p>
    <h3>第10条&nbsp;ユーザーによる本サービスの利用中止手続</h3>
    <ol>
      <li>ユーザーが本サービスの利用の中止を希望する場合は、マイページにて、当社所定の方法により、利用中止（退会）の手続を行うものとします。</li>
      <li>
        前項に基づき、ユーザーが利用中止（退会）の手続を行った場合、当社は、当該ユーザーに対し、本サービスの提供を中止し、当該ユーザー情報を利用不可能な状態にするものとします。
      </li>
    </ol>
    <h3>第11条&nbsp;当社の免責について</h3>
    <ol>
      <li>
        本サービスは、ユーザーに対して、ログイン機能付き決済マネージメントサービスを提供するものであり、当社は、本サービスを利用して購入された商品等に関する責任は負いません。購入された商品等に関してトラブルが生じた際には、購入したマーチャントへお問合せください。
      </li>
      <li>
        商品等の購入に関する取引内容、取扱商品およびサービス、商品等に関する記載内容、マーチャントにおける個人情報の取扱等につきましては、マーチャントへお問合せください。
      </li>
      <li>
        通信回線やコンピューター等の障害によるシステムの中断・遅滞・中止・データの消失、データへの不正アクセスにより生じた損害、その他本サービスの利用に関してユーザーに生じた損害について、当社は一切責任を負わないものとします。
      </li>
      <li>
        当社は、本サービスのウェブページ、サーバ、ドメイン等から送られるメール、本サービス内のコンテンツ等に、コンピュータウイルス等の有害なものが含まれないことを保証いたしません。
      </li>
    </ol>
    <h3>第12条&nbsp;禁止事項</h3>
    <ol>
      <li>
        ユーザーは、本サービスの利用にあたり、以下の各号の行為をしてはならないものとします。
        <ol class="paren">
          <li>ユーザー登録において、虚偽申請をすること</li>
          <li>他人になりすまして商品等の購入をすること、虚偽の情報を意図的に提供すること、支払う意思がないにもかかわらず注文すること、その他不正行為</li>
          <li>コンピュータウィルス等で当社、マーチャント、その他第三者の業務を妨害する行為、およびそれに類似する行為</li>
          <li>当社、マーチャント、その他第三者の権利、利益、名誉または信用を損ねる行為、またはイメージを低下させる行為</li>
          <li>当社、マーチャント、その他第三者の特許権、実用新案権、意匠権、商標権、著作権、肖像権等の権利を侵害する行為、または侵害するおそれのある行為</li>
          <li>当社、マーチャント、その他第三者の設備等の利用、運営に支障を与える行為、または与えるおそれのある行為</li>
          <li>当社、マーチャント、その他第三者不利益を与える行為</li>
          <li>法令に違反する行為、または違反するおそれのある行為</li>
          <li>当社が定める本規約および各規約に違反すること</li>
          <li>本サービスを利用して商品等を販売するマーチャントが定める規約に違反すること</li>
          <li>その他スパム行為等、当社が不適切と判断した行為</li>
        </ol>
      </li>
      <li>前項の違反があった場合、当社はユーザーの本サービス利用を停止することがあります。</li>
      <li>ユーザーの違反行為により、当社およびその他第三者に損害が生じた場合は、ユーザーはその一切を賠償する責任を負うものとします。</li>
    </ol>
    <h3>第13条&nbsp;ユーザーの個人情報等の取扱について</h3>
    <ol>
      <li>
        当社は、本サービスを利用するユーザーの個人情報および取引情報等のユーザーに係る一切の情報（以下「個人情報等」といいます。）を本サービスのシステムにおいて、当社が別に定める「hubsynchにおける個人情報の取扱いについて」に基づき、適切に管理するものとします。
      </li>
      <li>ユーザーは、本サービスに登録した個人情報等が本サービスの運営・管理のために、当社が利用することについて予め承諾するものとします。</li>
    </ol>
    <h3>第14条&nbsp;当社からの通知</h3>
    <ol>
      <li>当社は、Eメール、本サービスホームページ上の告知等、当社が適切と考える手段により、ユーザーに対し必要な事項を随時通知します。</li>
      <li>ユーザーは、前項に定める事項を速やかに確認する義務を負うものとします。</li>
      <li>ユーザーは、本条第１項に定める通知は、当社が当該通知を発信した時点をもって、ユーザーに到達したものとみなすことに同意するものとします。</li>
    </ol>
    <h3>第15条&nbsp;クッキー（Cookie）、SSL、アクセスログ等について</h3>
    <ol>
      <li>
        クッキー（Cookie）の利用について<br>当社のWEBサイトでは、お客様のコンピュータを特定し利便性を高めるために、クッキー（Cookie）と呼ばれる情報を、お客様のコンピュータに送ります。クッキー情報はお客様のコンピュータを特定するために使用し、お客様個人を特定するために使用することはありません。
      </li>
      <li>
        ＳＳＬについて<br>当社のWEBサイトは、お客様の個人情報を保護するために「SSL」に対応しています。
        お客様が入力される名前や住所あるいは電話番号などの個人情報が自動的に暗号化されて送受信されるため、万が一、送信データが第三者に傍受された場合でも、内容が盗み取られる心配はありません。
      </li>
      <li>
        アクセスログについて<br>当社のWEBサイトでは、アクセスされた方の情報をアクセスログという形で記録しています。アクセスログは、アクセスされた方のドメイン名やIPアドレス、使用しているブラウザの種類、アクセス日時等が含まれますが、通常は個人を特定できる情報を含むものではありません。これらのアクセスログは、WEBサイトの保守管理や利用状況に関する統計分析のために活用されますが、それ以外の目的で利用されることはありません。
      </li>
      <li>
        Googleアナリティクスの利用について<br>当社のWEBサイトにおいて、その利用状況を把握するために、Googleアナリティクスを利用することがあります。Googleアナリティクスは、ファーストパーティクッキーを利用して、当社WEBサイトへのアクセス情報を個人を特定することなく収集します。アクセス情報の収集方法および利用方法については、Googleアナリティクスサービス利用規約およびGoogleプライバシーポリシーによって定められています。<br>Googleアナリティクスについての詳細は、次のページをご参照ください。<br><a
          href="http://www.google.com/analytics" target="_blank"
        >http://www.google.com/analytics</a>
      </li>
    </ol>
    <h3>第16条 規定外事項</h3>
    <p>本規約に定めのない事項、またはその解釈に疑義を生じた事項については、ユーザーおよび当社が誠実に協議し、その解決を図るものとします。</p>
    <h3>第17条 管轄裁判所</h3>
    <p>本規約および本サービスに関し、ユーザーと当社との間で訴訟が生じた場合、東京地方裁判所を第一審の専属的合意管轄裁判所とします。</p>
    <p class="right-side">以上<br>hubsynch株式会社<br>最終改定日：平成29年7月3日<br>制定日：平成26年6月1日</p>
    <h2>hubsynchにおける個人情報の取扱いについて</h2>
    <p>
      当社が取得または保有する個人情報は、以下の目的で利用いたします。当社は、ユーザーに質の高い事業・サービスを提供する会社として、お客様にご満足いただき、便利で有益な質の高い商品・サービスを提供することを目指しています。このため、当社ではお客様からお預かりする個人情報を必要な範囲で利用いたします。なお、当社のプライバシーポリシー（個人情報保護方針）につきましては、<a
        target="_brank" href="https://hubsynch.com/privacypolicy"
      >こちら</a>をご覧ください。
    </p>
    <h3>１．当社の名称・住所・代表者の氏名</h3>
    <p>
      名 称：hubsynch株式会社住<br/>
      所：〒106-0031 東京都港区西麻布三丁目 21番 20号霞町コーポ B1F<br/>
      代表者：代表取締役 福光了良
    </p>
    <h3>２．個人情報保護管理者</h3>
    <p>
      hubsynch株式会社 代表取締役 福光了良<br/>
      連絡先：下記 5の「hubsynchサポートセンター」参照
    </p>
    <h3>３．個人情報の利用目的</h3>
    <p>
      当社が取得または保有するお客さま等の個人情報は、以下の目的で利用いたします。その他の利用目的で個人情報を利用する場合には、個人情報を取得するときに、その利用目的と問合せ先を明示します。なお、これらの利用目的の範囲を超えてお客さま等の個人情報を利用する場合は、改めて利用目的をお知らせし、同意を得ることとします。ただし、取得状況からみて利用目的が明らかであると認められる場合は、除きます。
    </p>
    <p class="my-1 py-1">■当社の保有個人データの利用目的</p>
    <table class="mb-4">
      <tr>
        <td>顧客情報</td>
        <td>当社サービスを提供する目的および事務連絡・アフターフォロー等、それに付帯する業務を実行するためDM発送等、当社のサービスをご紹介するため</td>
      </tr>
      <tr>
        <td>問合せ情報</td>
        <td>製品やサービス等に関する意見、要望、問合せについての受付と回答するため</td>
      </tr>
      <tr>
        <td>取引先情報</td>
        <td>当社との取引に関する事務、その他それに付帯する業務を実行するため</td>
      </tr>
      <tr>
        <td>従業者情報</td>
        <td>従業者管理に係わる業務に利用するため（業務・労務・人事管理業務、給与関連業務、福利厚生業務等）</td>
      </tr>
      <tr>
        <td>採用情報</td>
        <td>採用に係わる業務に利用するため（採用に関する情報提供、採用可否判断、採用業務に関する連絡等）</td>
      </tr>
      <tr>
        <td>ユーザー情報</td>
        <td>
          <ol class="alphabetic-list">
            <li>ユーザーのお問合せ対応のため</li>
            <li>ユーザーの登録情報管理のため</li>
            <li>ユーザーが購入した商品等の発送業務のため</li>
            <li>商品、デジタルコンテンツおよびサービス提供のため</li>
            <li>商品、デジタルコンテンツおよびサービス代金ご請求のため</li>
            <li>メールマガジン配信のため</li>
            <li>当社顧客および第三者の商品等のご案内のため</li>
            <li>マーケティング、商品企画等のための統計データ作成のため（個人は識別できません。）</li>
          </ol>
        </td>
      </tr>
      <tr>
        <td>本人および代理人の情報（開示等請求時）</td>
        <td>開示等の求めに回答するため</td>
      </tr>
    </table>
    <h3>４．クレジットカード情報を含む個人情報の取扱いについて</h3>
    <p>
      当社がユーザーからクレジット情報(カード名義・カード番号・有効期間）を取得する目的、クレジット情報の取得者名、提供先名、保存期間は、以下のとおりです。
    </p>
    <ol>
      <li>
        <h5>利用目的</h5>
        <p>ご購入した商品およびサービスの代金を決済するため</p>
      </li>
      <li>
        <h5>情報の取得者名</h5>
        <p>hubsynch株式会社</p>
      </li>
      <li>
        <h5>情報の提供先名</h5>
        <p>
          ユーザーがクレジットカード決済時にご利用いただいた各カード会社を含む各種決済事業者および以下いずれかの決済代行会社
          <ul class="pb-3">
            <li>株式会社ペイジェント</li>
            <li>三菱 UFJニコス株式会社</li>
            <li>SBペイメントサービス株式会社</li>
            <li>SMBCファイナンスサービス株式会社</li>
          </ul>
        </p>
      </li>
      <li>
        <h5>保存期間</h5>
        <p>クレジットカードの決済および登録は、上記「3.情報の提供先名」記載の各種決済事業者および決済代行会社を利用しております。</p>
        <p>各種決済事業者および決済代行会社への開示後は、当社では一切クレジットカード情報を保持いたしません。ただし、ユーザーが hubsynchを継続利用するため、クレジットカード情報の登録をされた場合は、当社においても、クレジットカード情報を登録します。</p>
        <p class="mb-2">
          hubsynchでクレジットカード情報を登録されている場合には、以下いずれかのタイミングで登録情報の廃棄を行います。
          <ul class="pb-3">
            <li>ユーザーがクレジットカード登録を解除したとき</li>
            <li>ユーザーが hubsynchを退会したとき</li>
          </ul>
        </p>
      </li>
    </ol>
    <h3>５．個人データの取扱いに関する苦情・相談について</h3>
    <p class="pb-1">
      当社の個人情報の取扱いに関するお問合せ、苦情については、下記窓口にご連絡ください。
    </p>
    <h5>【hubsynchサポートセンター】</h5>
    <p>
      窓口責任者：個人情報保護管理者<br/>
      住所：〒106-0031 東京都港区西麻布三丁目 21番 20号霞町コーポ B1F <br/>
      <a href="https://hubsynch.com/contactus" target="_blank">お問合せフォーム</a>
    </p>
    <h3>６．開示等のご請求手続</h3>
    <p>
      当社の保有個人データについて、ご本人、法定代理人または委任による代理人から、開示等（利用目的の通知、開示、内容の訂正、追加または削除、利用の停止、消去および第三者への提供の停止、第三者提供記録の開示）のご請求が可能です。
    </p>
    <ol class="paren">
      <li>
        手続<br/>
        開示等のご請求は、上記５の【hubsynchサポートセンター】にご連絡ください。請求内容確認後、当社所定の様式をお送りしますので、必要事項をご記入のうえ、返送ください。その際、請求者または代理人がご本人であること、また代理人が代理権を有することを当社所定の方法により確認させていただきます。なお、保有個人データの削除、利用の停止、消去および第三者への提供の停止をご希望の場合は、当社サービスのご提供ができなくなることをご了承ください。
      </li>
      <li>
        手数料等<br/>
        当社への手数料はいただきませんが、ご請求にかかる切手代、公文書の発行費用、電話代等のすべての費用は、ご請求者様にご負担いただきます。
      </li>
    </ol>
    <h3>７．安全管理措置</h3>
    <p>
      当社が講じる個人情報の安全管理措置は、以下のとおりです。
    </p>
    <ol class="paren">
      <li>
        基本方針の策定<br/>
        個人情報の適正な取扱いの確保のため「関係法令・ガイドライン等の遵守」、「個人情報の安全管理措置に関する事項」、「質問および苦情相談の窓口」等について、「個人情報保護方針」を策定しています。
      </li>
      <li>
        組織的安全管理措置<br/>
        個人情報の管理に関する責任者の設置とその役割の明確化、個人情報の取扱状況の点検および監査の体制整備と実施、漏えい事案等に対する体制の整備等を実施しています。
      </li>
      <li>
        人的安全管理措置<br/>
        従業者との「非開示契約」の締結、個人情報の取扱いに関する留意事項についての従業者への周知徹底および研修等を実施しています。
      </li>
      <li>
        物理的安全管理措置<br/>
        紛失・盗難を防ぐための書類・外部媒体等の施錠保管、裁断・焼却・溶解等復元が著しく困難な手段での個人情報廃棄、責任者の承諾なく個人情報を持ち出すことの禁止、個人情報を持ち運ぶ際の暗号化や封緘等の措置を実施しています。
      </li>
      <li>
        技術的安全管理措置<br/>
        個人情報を取扱う機器への外部からの不正アクセスまたは不正ソフトウェアから保護する仕組みの導入、担当者および取扱う個人データの範囲の限定などのアクセス制御の実施、不正アクセスを防止するための強固なパスワードの使用、IDの共有禁止、暗号化設定可能な機器の使用等の措置を実施しています。
      </li>
      <li>
        外的環境の把握<br/>
        個人情報の一部を含めた情報の保管についてクラウドサービスを利用しており、これらの個人情報は、当該事業者の管理のもと、海外に保管されております。当社は、各国の個人情報の保護に関する制度を把握したうえで、必要かつ適切な安全管理措置を実施しています。
      </li>
      ■参考：個人情報保護委員会 外国における個人情報の保護に関する制度等の調査 <a href="https://www.ppc.go.jp/personalinfo/legal/kaiseihogohou/#gaikoku" target="_blank">https://www.ppc.go.jp/personalinfo/legal/kaiseihogohou/#gaikoku</a>
    </ol>
    <h3>８．WEBサイトにおける個人情報の取扱いについて</h3>
    <ol class="paren">
      <li>
        クッキー（Cookie）の利用について<br>当社の WEBサイトでは、お客さま等のコンピュータを特定し利便性を高めるために、クッキー（Cookie）と呼ばれる情報を、お客さま等のコンピュータに送ります。クッキー情報はお客さま等のコンピュータを特定するために使用し、お客さま等個人を特定するために使用することはありません。
      </li>
      <li>
        ＳＳＬについて<br>当社の WEBサイトは、お客さま等の個人情報を保護するために「SSL」に対応しています。 お客さま等が入力される名前や住所あるいは電話番号などの個人情報が自動的に暗号化されて送受信されるため、万が一、送信データが第三者に傍受された場合でも、内容が盗み取られる心配はありません。
      </li>
      <li>
        アクセスログについて<br>当社の WEBサイトでは、アクセスされた方の情報をアクセスログという形で記録しています。アクセスログは、アクセスされた方のドメイン名や IPアドレス、使用しているブラウザの種類、アクセス日時等が含まれますが、通常は個人を特定できる情報を含むものではありません。これらのアクセスログは、WEBサイトの保守管理や利用状況に関する統計分析のために活用されますが、それ以外の目的で利用されることはありません。
      </li>
      <li>
        Googleアナリティクスの利用について<br>当社の WEBサイトにおいて、その利用状況を把握するために、Googleアナリティクスを利用することがあります。Googleアナリティクスは、ファーストパーティクッキーを利用して、当社 WEBサイトへのアクセス情報を個人を特定することなく収集します。アクセス情報の収集方法および利用方法については、Googleアナリティクスサービス利用規約および Googleプライバシーポリシーによって定められています。<br>Googleアナリティクスについての詳細は、以下のページをご参照ください。<br>
        <a href="http://www.google.com/analytics" target="_blank">http://www.google.com/analytics
        </a>
      </li>
    </ol>
    <h3>９．個人情報の共同利用</h3>
    <p>当社は、利用目的の達成に必要な範囲内において、お客さま等の個人情報を以下のとおり、個人情報保護法第 23条第５項第３号に基づき共同利用させていただく場合があります。なお、共同利用の取扱いについて見直しを行う場合には、あらかじめその内容を公表いたします。</p>
    <ol class="paren">
      <li>
        共同利用する個人情報の項目
        <ol class="custom_ol">
          <li>本サービスにご登録いただいた個人情報</li>
          <li>お問合せフォームにてご提供いただいた個人情報</li>
          <li>その他サービスの提供に必要な情報等</li>
        </ol>
      </li>
      <li>
        共同利用者の範囲
        <br>共同利用者は、以下のとおりです。
        <ol class="custom_ol">
          <li>・株式会社ハイベロシティ（当初の親会社）</li>
        </ol>
      </li>
      <li>共同利用における利用目的<br>お問合せ内容に対する返答、またはご利用のサービスを円滑に提供するため</li>
      <li>
        共同利用を行う個人情報の管理について責任を有する者の名称hubsynch株式会社
      </li>
    </ol>
    <p class="right-side">
      最終改定日：2023年 02月 01日<br> 制定日：2014年 06月 01日<br> hubsynch株式会社
      <br />
      代表取締役&nbsp;福光了良
    </p>
    <p class="right-side"> ＜個人情報保護管理者＞<br /> hubsynch株式会社<br /> 代表取締役&nbsp;福光了良 </p>
  </div>
</template>

<script>
  export default {
    name: 'HubSynchTerms',
  }
</script>

<style scoped>
ol.alphabetic-list {
  list-style-type: lower-alpha;
}
ol.paren ol.custom_ol li {
    list-style-type: none;
    list-style-position: inside;
    counter-increment: cunt;
    margin-left: 28px;
}
ol.paren ol.custom_ol li:before {
    display: marker;
    content: counter(cunt);
    margin-left: -26px;
    margin-right: 6px;
    padding: 0px 4px;
    border: 1px solid;
    border-radius: 50%;
    font-size: 12px;
}
table, td {
  border: 1px solid black;
  border-collapse: collapse;
  padding: 10px;
}
td:first-child {
  width: 40%;
}
</style>
