<template xmlns:input-email="http://www.w3.org/1999/html">
  <section class="l-content-area">
    <div class="l-content l-content--registration">
      <div class="l-wrap__outer">
        <div class="l-wrap__inner">
          <section class="c-title__bloc c-title__bloc--registration">
            <h1>既存顧客様向け<span>アカウント登録</span></h1>
          </section>
          <form>
            <section class="p-bloc p-bloc__registration">
              <div class="p-registration__bloc">
                <p>
                  ご登録されるメールアドレスをご入力ください。既にSVENSON他サービス（ECサイト）でhubsynchアカウントをお持ちの方は
                  <a href="/login">こちら</a>よりログインをお願いします。
                </p>
                <div class="p-registration__form">
                  <dl class="c-form-parts">
                    <dt>メールアドレス</dt>
                    <dd>
                      <input-login
                        :value="values.email"
                        :error="errors.email"
                        :placeholder="'svenson@svenson.co.jp'"
                        @onHandleChange="onHandleChangeEmail"
                        @onValidate="onValidateEmail"
                      >
                      </input-login>
                    </dd>
                    <dt>パスワード</dt>
                    <dd>
                      <input-password
                        :placeholder="'＊＊＊＊＊＊＊＊＊＊'"
                        :error="errors.password"
                        :value="values.password"
                        @onHandleChange="onHandleChangePassword"
                        @onValidate="onValidatePassword"
                      >
                      </input-password>
                    </dd>
                  </dl>
                </div>
              </div>
              <button-registration
                :next-text="'次へ'"
                :prev-text="'戻る'"
                :handle-next-button="nextToStep"
                :handle-prev-button="prevToStep"
              >
              </button-registration>
            </section>
          </form>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import InputLogin from '../../common/InputLogin'
import InputPassword from '../../common/InputPassword'
import ButtonRegistration from '../../common/ButtonRegistration'
import { submitPassword } from '../../../actions/requestRegister'
import * as yup from 'yup'
import * as regex from '../../../helpers/regex'
import * as errorString from '../../../constant/text'

const loginSchema = yup.object().shape({
  email: yup
    .string()
    .required(errorString.EMAIL_REQUIRED)
    .matches(regex.patternEmail, errorString.EMAIL_INVALID)
    .email(errorString.EMAIL_INVALID),
  password: yup.string().required(errorString.PASSWORD_REQUIRED),
})

export default {
  name: 'SecondStepRegistration',
  components: {
    InputLogin,
    InputPassword,
    ButtonRegistration,
  },
  props: {
    isForceValidate: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      values: {
        email: '',
        password: '',
      },
      errors: {
        email: '',
        password: '',
      },
      isModalVisible: false,
    }
  },
  watch: {
    isForceValidate(newVal) {
      if (newVal) {
        this.nextToStep()
      }
    },
  },
  methods: {
    onValidateEmail(field, message) {
      this.errors.email = message
    },
    onValidatePassword(field, message) {
      this.errors.password = message
    },
    onValidate(value) {
      this.errors = value
    },
    onHandleChangeEmail(field, value) {
      this.values.email = value
    },
    onHandleChangePassword(value) {
      this.values.password = value
    },

    prevToStep() {
      this.$emit('prevToStep')
    },

    nextToStep() {
      this.startLoading()
      loginSchema
        .validate(this.values, { abortEarly: false })
        .then(() => {
          submitPassword(this.values)
            .then(res => {
              const {
                data: { success },
              } = res
              if (success && success === 1) {
                this.endLoading()
                this.$emit('nextToStep')
              }
            })
            .catch(err => {
              const {
                response: {
                  data: {
                    errors: { email, password },
                  },
                },
              } = err
              if (email) {
                this.errors.email = email
              }
              if (password) {
                this.errors.password = 'パスワードは8文字以上である必要があります'
              }
              this.endLoading()
            })
        })
        .catch(err => {
          this.endLoading()
          err.inner.forEach(error => {
            this.errors[error.path] = error.message
          })
        })
    },
    startLoading() {
      this.$emit('startLoading')
    },
    endLoading() {
      this.$emit('endLoading')
    },
  },
}
</script>

<style scoped></style>
