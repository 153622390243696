import MypageRepository from './MypageRepository'

const repository = {
  mypage: MypageRepository,
}

const MypageRepositoryFactory = {
  get: (name) => repository[name],
}

export default MypageRepositoryFactory
