<template>
  <div>
    <section class="l-content-area">
      <div class="l-content l-content--reservation">
        <div class="l-wrap__outer">
          <div class="l-wrap__inner">
            <section class="c-title__bloc c-title__bloc--reservation">
              <h1>ご来店予約</h1>
              <div class="c-btn c-btn--title-back">
                <a href="#" @click.prevent="prevToStep()">戻る</a>
              </div>
            </section>

            <section class="p-bloc p-bloc__reservation">
              <h2 class="p-reservation__step">STEP 6</h2>

              <div class="p-reservation__bloc">
                <p>
                  施術や担当者に対して、ご質問やご要望など<br />
                  ございましたらご入力をお願いいたします。
                </p>

                <div class="p-reservation__form">
                  <dl class="c-form-parts">
                    <dt>ご質問・ご要望 <span>※入力は100文字まで</span></dt>
                    <dd>
                      <text-area
                        :name="'requestMsg'"
                        :max-length="100"
                        :placeholder="'ご質問・ご要望等ございましたらご入力をお願いいたします。'"
                        :value="values.requestMsg"
                        :pattern="regex"
                        :error="errors.requestMsg"
                        :is-show-error="false"
                        @onValidate="onValidate"
                        @onHandleChange="onHandleChange"
                      >
                      </text-area>
                      <error-message
                        v-if="errors.requestMsg"
                        :error="errors.requestMsg"
                      ></error-message>
                    </dd>
                  </dl>
                </div>

                <button-registration
                  :prev-text="'戻る'"
                  :next-text="'次へ'"
                  :handle-prev-button="prevToStep"
                  :handle-next-button="nextToStep"
                  :prev-text-color="'#787878'"
                >
                </button-registration>
              </div>
            </section>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
import TextArea from '../../common/TextArea.vue'
import ButtonRegistration from '../../common/ButtonRegistration'
import ErrorMessage from '../../common/ErrorMessage'
import { requestRegisterReservation } from '../../../actions/requestMypage'
import * as regex from '../../../helpers/regex'
import { validateEmoji } from '../../../helpers/schemaValidate'
import { EMOJI_KANJI_INVALID } from '../../../constant/text'
export default {
  name: 'MypageResevationRequests',
  components: {
    TextArea,
    ButtonRegistration,
    ErrorMessage,
  },
  props: {
    currentStep: {
      type: String,
      default: '6',
    },
    isForceValidate: {
      type: Boolean,
      default: false,
    },
    userId: {
      type: String,
      default: '',
    },
    store: {
      type: String,
      default: '',
    },
    genderPreference: {
      type: String,
      default: '',
    },
    treatmentContent: {
      type: String,
      default: '',
    },
    isDesignCut: {
      type: Boolean,
      default: false,
    },
    services: {
      type: Array,
      default: () => {
        return []
      },
    },
    status: {
      type: String,
      default: '',
    },
    timeVisited: {
      type: Object,
      default: () => {
        return {
          firstTime: {
            date: '',
            hour: '',
            minute: '',
          },
          secondTime: {
            date: '',
            hour: '',
            minute: '',
          },
        }
      },
    },
    reservationId: {
      type: String,
      default: '',
    },
    stylist: {
      type: Array,
      default: () => {
        return []
      },
    },
    values: {
      type: Object,
      default: () => {
        return {
          requestMsg: '',
        }
      },
    },
  },
  data() {
    return {
      regex: regex.patternEmoji,
      errors: {
        requestMsg: '',
      },
    }
  },
  methods: {
    onValidate(field, value) {
      this.errors[field] = value
      this.$emit('onValidateSwipe', false)
    },
    onHandleChange(field, value) {
      this.$emit('onHandleChange', field, value)
    },
    prevToStep() {
      this.$emit('prevToStep')
    },
    nextToStep() {
      validateEmoji
        .validate(this.values, { abortEarly: false })
        .then(() => {
          this.$emit('startLoading')
          this.values.requestMsg = this.values.requestMsg.replaceAll(regex.patternEmojiRestrict, '')
          let waiting = this.status === 'waiting' ? 1 : 0
          requestRegisterReservation({
            currentStep: 6,
            userId: this.userId,
            storeId: this.store,
            timeVisited: this.timeVisited,
            isDesignCut: this.isDesignCut,
            services: this.services,
            reservationId: this.reservationId,
            status: null,
            stylist: this.stylist,
            genderPreference: this.genderPreference,
            waiting,
            requestMsg: this.values.requestMsg,
          })
            .then(response => {
              const {
                data: { success },
              } = response
              if (success && success === 1) {
                const {
                  data: { data },
                } = response
                this.$emit('setReservationId', data.id.toString())
              }
              this.$emit('nextToPage')
              this.$emit('endLoading')
            })
            .catch(error => {
              this.$router.push({ name: 'mypageError' })
              return error
            })
        })
        .catch(err => {
          err.inner.forEach(error => {
            this.errors[error.path] = EMOJI_KANJI_INVALID
          })
          this.$emit('endLoading')
        })
    },
  },
}
</script>

<style scoped>
.v-application ul,
.v-application ol {
  padding-left: 0 !important;
}

.v-application a {
  color: #0a005a;
}

.p-bloc__call .telnum span:before,
.p-bloc__call .telnum a:before {
  display: inline-block;
  vertical-align: top;
  content: '';
  width: 27px;
  height: 27px;
  margin: 0px 10px 0 0;
  background-image: url(../../../../src/assets/img/form/phone.png); 
  background-repeat: no-repeat;
  background-position: 0 0;
  background-size: 100% 100%;
}

.time {
  display: flex;
  justify-content: center;
}
.text-left {
  text-align: left !important;
}
</style>
